import React, { useState, useEffect, useRef } from 'react';
import ServiceProviderDetailsForm from './ServiceProviderDetailsForm';
import editModeIcon from '../../../images/editMode.svg';
import {
	editServiceProvider, createServiceProvider, deleteServiceProvider, getServiceProvider,
	getServiceProviderDetails, saveServiceProviderDetail, listSPAutoAcceptSettings,
	updateSPAutoAcceptSetting, removeSPAutoAcceptSetting, updateServiceProviderContact
} from '../../api/serviceProvidersAPI';
import { getReport } from '../../api/reportingAPI';
import { reportRowToObject } from '../../utils/reporting';
import { getForm, getFormFields } from '../../api/formsAPI';
import { checkDuplicates } from '../../api/duplicatesAPI';
import { ModalDuplicateContext } from '../../contexts/ModalDuplicateContext';
import { updateURL } from '../../utils/url';
import validator from 'validator';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CloseIcon from '@material-ui/icons/Close';
import differenceBy from 'lodash/differenceBy'

const ServiceProviderDetails = (props) => {
	const [editMode, setEditMode] = useState(false);
	const [deleteMode, setDeleteMode] = useState(false);
	const [data, setData] = useState({});
	const [detailsForm, setDetailsForm] = useState({}); // FIXME this is a hack for a demo on 2020-10-30
	const [apiErrors, setApiErrors] = useState({});
	const [isNew, setIsNew] = useState(props.newAddition);
	const [providerContacts, setProviderContacts] = useState([]);
	const [AASettings, setAASettings] = useState([]);
	const [originalAASettings, setOriginalAASettings] = useState([]);

	const AASettingsRef = useRef([]);

	useEffect(() => {
		if (props.newAddition) {
			setEditMode(true);
			getAdditionalDetailsInfo();
		} else {
			getServiceProviderData(props.uuid);
			getAASettings();
		}
	}, []);

	const getServiceProviderData = async (uuid) => {
		if (uuid !== undefined && uuid !== '') {
			getServiceProvider(uuid).then(response => {
				props.setHeader(response.name, response.active);
				getAdditionalDetailsInfo(response);
				// service providers contacts
				getReport("849587d7-6462-47f7-9a73-29cff8fc9c95", { "inputs": { "Service Provider UUID": uuid } }).then(report => {
					let contacts = [];
					report.data.rows.forEach(row => {
						// like we did in properties, reportRowToObject is supposed to convert types but that never got
						// done so doing it manually here for now as changing it would mean auditing everywhere its used
						let contact = reportRowToObject(row.values, report.data.outputs);
						contact.primary_contact = (contact.primary_contact.toLowerCase() === "true");
						contacts.push(contact);
					});
					setProviderContacts(contacts);
				})
			});
		}
	};

	const getAASettings = async () => {
		const settings = await listSPAutoAcceptSettings(props.uuid);
		setAASettings(settings.data["settings"]);
		setOriginalAASettings(settings.data["settings"])
	};

	const getAdditionalDetailsInfo = (serviceProvderData) => {
		if (serviceProvderData === undefined) {
			serviceProvderData = {};
		}
		serviceProvderData.c3_additional_details = {};

		getForm("service provider details").then(form => {
			if (form != null) {
				setDetailsForm({ form_uuid: form.form_uuid }); // FIXME this is a hack for a demo on 2020-10-30
				getFormFields(form.form_uuid).then(response => {
					response.form_fields.forEach((field) => {
						serviceProvderData.c3_additional_details[field.form_field_uuid] = field;
					});

					if (serviceProvderData.service_provider_uuid !== undefined) {
						getServiceProviderDetails(serviceProvderData.service_provider_uuid).then(response => {
							response.details.forEach((detail) => {
								// check to make sure we know about this field (detail) as we could have had old details
								// from old custom fields returned
								if (serviceProvderData.c3_additional_details[detail.form_field_uuid]) {
									serviceProvderData.c3_additional_details[detail.form_field_uuid].value = detail.value;
								}
							});
							setData(serviceProvderData);
						});
					} else {
						setData(serviceProvderData);
					}
				});
			} else {
				setData(serviceProvderData);
			}
		});
	}

	const saveDetails = async (ctx, uuid, payload) => {
		if (isNew) {
			checkDuplicates('service_providers', payload.name).then(response => {
				if (response.duplicates.length > 0) {
					ctx.setDuplicates(
						response.duplicates,
						[
							{ name: 'address', data: 'address1' },
							'city'
						], '/serviceProviders',
						payload,
						saveNewServiceProvider,
						useSelectedDuplicate
					);
				} else {
					saveNewServiceProvider(payload);
				}
			});
		} else {
			// primary contact is part of the contacts table, so pull it out of the payload
			const primaryContactUUID = payload.primary_contact_uuid;
			delete payload.primary_contact_uuid;
			editServiceProvider(uuid, payload)
				.then(response => {
					props.refresh();
					setEditMode(false);
					props.setHeader(response.name, response.active);
					saveAdditionalDetails(uuid, payload);
				})
				.catch(error => {
					if (error.name === 'APIUserError') {
						setApiErrors(error.apiFieldErrors);
					}
				});

			if (!primaryContactUUID) {
				// if we don't have a primary contact, they might be trying to update it to no one, so in that case
				// we need to find if we have a contact that is marked primary in our list, and update them to not be
				let primaryContact = providerContacts.find((contact) => contact.primary_contact);
				if (primaryContact !== undefined) {
					updateServiceProviderContact(
						uuid,
						primaryContact.contact_uuid,
						{ primary_contact: false, contact_type: primaryContact.type }
					);
				}
			} else {
				// find our primary contact object in our list, and if it isn't set as the primary, then the user
				// is trying to update it so tell the backend
				let primaryContact = providerContacts.find((contact) => contact.contact_uuid == primaryContactUUID);
				if (!primaryContact.primary_contact) {
					updateServiceProviderContact(
						uuid,
						primaryContact.contact_uuid,
						{ primary_contact: true, contact_type: primaryContact.type }
					);
				}
			}

			const settingsToRemove = differenceBy(originalAASettings, AASettingsRef.current["settings"], 'form_type');
			const settingsToUpdate = differenceBy(AASettingsRef.current["settings"], originalAASettings, 'auto_accept');

			const promises = [];
			settingsToUpdate.forEach((setting, idx) => {
				const promise = updateSPAutoAcceptSetting(props.uuid, setting.form_type, { auto_accept: JSON.parse(setting.auto_accept) });
				promises.push(promise);
			});
			settingsToRemove.forEach((setting, idx) => {
				const promise = removeSPAutoAcceptSetting(props.uuid, setting.form_type)
				promises.push(promise);
			});

			Promise.all(promises).then((results) => {
				getAASettings();
			})
		}
	};

	const saveNewServiceProvider = async (payload) => {
		createServiceProvider(payload)
			.then(response => {
				setIsNew(false);
				setEditMode(false);
				response.c3_additional_details = {};
				setData(response);
				AASettingsRef.current["settings"].forEach((setting) => {
					updateSPAutoAcceptSetting(response.service_provider_uuid, setting.form_type, { auto_accept: JSON.parse(setting.auto_accept) });
				})
				props.refresh();
				props.setHeader(response.name, response.active);
				saveAdditionalDetails(response.property_uuid, payload);
				props.openFlyout(response, false);
			})
			.catch(error => {
				if (error.name === 'APIUserError') {
					setApiErrors(error.apiFieldErrors);
				}
			});
	};

	const useSelectedDuplicate = async (uuid) => {
		getServiceProviderData(uuid);
		setEditMode(false);
		setIsNew(false);
		updateURL('/serviceProviders/' + uuid, {});
	}

	const saveAdditionalDetails = async (uuid, payload) => {
		for (let fieldName in payload) {
			// so janky but whatever
			if (validator.isUUID(fieldName)) {
				if (data.c3_additional_details[fieldName].value !== payload[fieldName]) {
					saveServiceProviderDetail(uuid, {
						"form_uuid": detailsForm.form_uuid, // FIXME this is a hack for a demo on 2020-10-30
						"form_field_uuid": fieldName,
						"value": "" + payload[fieldName]
					});
				}
			}
		}
	}

	const toggleEditMode = () => {
		setEditMode(!editMode);
		setDeleteMode(false);
	};

	const deleteThisServiceProvider = async (type) => {
		if (type === 'delete') {
			await deleteServiceProvider(data['service_provider_uuid']);
			await props.refresh();
			await props.closeFlyout();
		} else if (type === 'cancel') {
			setDeleteMode(false);
		} else {
			setDeleteMode(true);
			setTimeout(() => {
				document.getElementById('flyoutBase').scrollIntoView(false);
			}, 100);
		}
	};

	const setStatus = async () => {

		if (confirm(`Are you sure you want to set ${data['name']} as ${data.active ? "inactive" : "active"}?`)) {
			await editServiceProvider(data['service_provider_uuid'], {
				active: data.active ? false : true
			});
			await props.refresh();
			await props.closeFlyout();
		} else {
			null;
		}
	}


	return (
		<ModalDuplicateContext.Consumer>
			{(ctx) => {
				return (
					<div className="flyoutContentContainer" id="flyoutBase">
						<button className="editModeButton" onClick={() => toggleEditMode()}>
							{!editMode && (
								<img src={editModeIcon} alt='' />
							)}
						</button>
						{data.c3_additional_details !== undefined && (
							<ServiceProviderDetailsForm
								data={data}
								context={ctx}
								editMode={editMode}
								saveDetails={saveDetails}
								toggleEditMode={toggleEditMode}
								newAddition={props.newAddition}
								closeFlyout={props.closeFlyout}
								deleteThisServiceProvider={deleteThisServiceProvider}
								apiErrors={apiErrors}
								providerContacts={providerContacts}
								isOrgAdmin={props.isOrgAdmin}
								AASettingsRef={AASettingsRef}
								AASettings={AASettings}
								getAASettings={getAASettings}
							/>
						)}
						{editMode && !deleteMode && !props.newAddition && (
							<React.Fragment>
								<div className="flexButtonContainer" id="serviceProviderOptions">
									<div>
										<button className="medButtonSecondary" onClick={() => setStatus()}>{data.active ? 'Set as Inactive' : 'Set as Active'}</button>
									</div>
								</div>
								<div className="deleteButton__container">
									<button className="deleteButton" onClick={() => deleteThisServiceProvider()}><DeleteForeverIcon fontSize='inherit' color='inherit' /></button>
								</div>
							</React.Fragment>
						)}
						{editMode && deleteMode && (
							<div className="flyout__deleteMode">
								<span>Warning: These actions cannot be undone.</span>
								<button className="medButtonSecondary" type="button" onClick={() => deleteThisServiceProvider('delete')}>Delete Service Provider</button>
								<button className="exit" onClick={() => deleteThisServiceProvider('cancel')}><CloseIcon fontSize="large" /></button>
							</div>
						)}
					</div>
				);

			}}
		</ModalDuplicateContext.Consumer>
	);
};

export default ServiceProviderDetails;
