import React, { useState, useEffect } from 'react';
import { Formik, withFormik, Field, Form } from 'formik';
import * as Yup from 'yup';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import exit from '../../../images/x.svg';
import { createComplianceReportUpload, listComplianceReportUploads } from '../../api/complianceAPI';
import { uploadFile, getUpload } from '../../api/uploadsAPI';
import { NavLink } from 'react-router-dom';
import { acceptedFiletypes } from '../../utils/settings';

const SUPPORTED_FORMATS = ['image/png', 'image/jpg', 'application/pdf', 'image/jpeg'];
const FILE_SIZE = 1000000;

const formLayout = ({ fields, values, setFieldValue, reportUUID, disabled, sendFilesToFieldset, files, formType }) => {

	const [fileSelect, setFileSelect] = useState(false);
	const [isUploading, setIsUploading] = useState(false);
	const [isAdding, setIsAdding] = useState(false);
	const [uploadCount, setUploadCount] = useState(0);

	const setFieldsList = () => {
		return (
			fields.map((field, index) => {
				if (field.input_type !== "label" && field.label != "") {
					return (
						<option
							key={index}
							value={field.form_field_uuid}
						>
							{field.label}
						</option>
					);
				}
			})
		);
	};

	const getUploadCount = () => {
		listComplianceReportUploads(reportUUID).then((response) => {
			setUploadCount(response.compliance_report_uploads.length);
		});
	};

	const startFileSelect = () => {
		if (reportUUID) {
			getUploadCount();
		}
		setFileSelect(true);
	};

	const handleFileChange = (e) => {
		setFieldValue('file', e.target.files[0]);
	};

	const handleAddFile = async (type) => {
		if (type !== 'cancel') {
			if (!reportUUID) {
				sendFilesToFieldset(values);
				setIsAdding(true);
				setTimeout(() => {
					setFileSelect(false);
					setIsAdding(false);
					setFieldValue('file', null);
					setFieldValue('formFieldUUID', '');
				}, 2000);
			} else {
				setIsUploading(true);
				const data = new FormData();
				data.set('file', values.file);
				data.set('scope', "organization");

				try {
					const upload = await uploadFile(data);

					await createComplianceReportUpload(reportUUID, values.formFieldUUID, upload.uuid).then((response) => {
						if (response.error) {
							return null;
						} else {
							setIsAdding(true);
							setTimeout(() => {
								setFileSelect(false);
								setIsAdding(false);
								setIsUploading(false);
								setFieldValue('file', null);
								setFieldValue('formFieldUUID', '');
							}, 2000);
						}
					});
				}
				catch (error) {
					setFileSelect(false);
					setIsAdding(false);
					setIsUploading(false);
					setFieldValue('file', null);
					setFieldValue('formFieldUUID', '');
				}
			}
		} else {
			setFileSelect(false);
			setFieldValue('file', null);
			setFieldValue('formFieldUUID', '');
		}
	};

	let uploadCountFinal;

	if (reportUUID) {
		uploadCountFinal = uploadCount;
	} else {
		uploadCountFinal = files.length;
	}

	let reportPath = 'testReports';
	if (formType === 'survey') {
		reportPath = 'surveys';
	}

	if (disabled) {
		return null;
	} else {

		return (
			<Form>
				<div className='uploadPopup__wrapper'>
					{!fileSelect && <div className="xsmallButtonSecondary" onClick={() => startFileSelect()}><AttachFileIcon fontSize='large' /></div>}
					{fileSelect &&
						<div className='uploadPopup'>
							{isAdding && <span className="fileAddedMessage">File Successfully Uploaded</span>}
							<div className={isAdding ? 'uploadPopup__content noOpacity' : 'uploadPopup__content'} id="fileSelect">
								<div className="flex">
									<input type="file" id="file" name="file" accept={acceptedFiletypes.join()}
										onChange={(e) => handleFileChange(e)}
									/>
									<span className="acceptedFiletypes">{acceptedFiletypes.join(', ')}</span>
								</div>
								<Field
									component="select"
									name="formFieldUUID"
									onChange={(e) => setFieldValue(e.target.name, e.target.value)}
								>
									<option disabled value=''>Choose field associated with file</option>
									{setFieldsList()}
								</Field>
								<button className="smallButtonPrimary" type="submit"
									onClick={() => handleAddFile()} disabled={isUploading || values.file === null || values.formFieldUUID === ''}
								>
									{(isUploading) ? "Uploading..." : "Upload"}
								</button>
								<button className="smallButtonTertiary" type="button"
									onClick={() => handleAddFile('cancel')}
								>
									Cancel
								</button>
								<div
									className="uploadCount"
								>
									<a
										href={uploadCountFinal > 0 && reportUUID ? `/${reportPath}/${reportUUID}` : undefined}
										target='_blank'
									>
										{`${uploadCountFinal} files currently uploaded`}
									</a>

								</div>
								<button
									className="uploadPopup__exitButton"
									onClick={() => handleAddFile('cancel')}
								>
									<img src={exit} alt='exit' />
								</button>
							</div>
						</div>
					}
				</div>
			</Form>
		);
	}
};

const FileSelect = withFormik({
	enableReinitialize: true,
	mapPropsToValues() {
		return {
			file: null,
			formFieldUUID: ''
		};
	},

	// validationSchema: Yup.object().shape({
	// 	formFieldUUID: Yup.string()
	// 		.required('You must choose a form field associated with this file')
	// 	,
	// 	file: Yup.mixed()
	// 		.required('An image of the certification is required')
	// 		.test('fileType', 'Unsupported file format', value => { if (value) { SUPPORTED_FORMATS.includes(value.type); } return true; })
	// 		.test('fileSize', 'File size is too large', value => { if (value) { value.size <= FILE_SIZE; } return true; })
	// }),


})(formLayout);


export default FileSelect;
