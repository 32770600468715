import React from "react";
import { getURLModule } from "../../../utils/url";
import { AssignmentRounded, FlagRounded } from "@material-ui/icons";
import { Link } from "react-router-dom";
import Page from "../Page";
import Card from "../Card";
import styles from "../../../styles/common/configuration.module.css";

const Configuration = ({}) => {
    let sections;
    const { module } = getURLModule();

    switch (module) {
        case "pretreatment":
            sections = [
                { title: "Templates", icon: AssignmentRounded, link: "/pt/cfg/t" },
                { title: "POTW", icon: FlagRounded, link: "/pt/cfg/gc" },
            ];
            break;
    }

    return (
        <Page>
            <Card title="Configuration">
                <div className={styles.wrapper}>
                    {sections?.map((s) => {
                        const { title, icon: IconComponent, link } = s;

                        return (
                            <Link key={title} to={link} className={styles.tile}>
                                <IconComponent />
                                <div>{title}</div>
                            </Link>
                        );
                    })}
                </div>
            </Card>
        </Page>
    );
};

export default Configuration;
