import React from 'react';
import Table from '../Table';
import TextField from '@material-ui/core/TextField';
import MessageBox from '../MessageBox';
import { getServiceProvidersReport, getServiceProviderUsersReport } from '../../api/serviceProvidersAPI';
import { getUsersReport } from '../../api/usersAPI';
import { setReportingParams, getDefaultReportingParams, getReportValue } from '../../utils/reporting';
import { withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';

const styles = theme => ({
	textFieldInput: {
		height: 5,
		fontSize: '1.4rem',
		color: '#506788',
	},
	label: {
		'&$focused': {
			color: '#506788'
		},
	},
	focused: {},
	outlinedInput: {
		'&$focused $notchedOutline': {
			borderColor: '#506788',
			borderWidth: 1
		},
	},
	notchedOutline: {},
});

class SurveyorInfo extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			activeTable: 'serviceProviders',
			searchErrorLevel: 'error',
			searchError: '',
			serviceProvidersList: null,
			serviceProviderUUID: null,
			usersList: null,
			reportingParams: {}
		};

		this.state.reportingParams = getDefaultReportingParams();
	}

	componentDidMount() {
		if (this.props.surveyDoneByProvider) {
			this.getServiceProvidersList();
		} else {
			this.getUsersList();
		}
	}

	getServiceProvidersList = async (newReportingParams) => {
		let reportingParams = (newReportingParams !== undefined) ? newReportingParams : this.state.reportingParams;

		const reportOutput = await getServiceProvidersReport(reportingParams);
		this.setState(() => ({ activeTable: 'serviceProviders', serviceProvidersList: reportOutput.data, reportingParams }));
	}

	getServiceProviderUsersList = async (serviceProviderUUID, newReportingParams) => {
		let reportingParams = (newReportingParams !== undefined) ? newReportingParams : this.state.reportingParams;

		const reportOutput = await getServiceProviderUsersReport(serviceProviderUUID, reportingParams);
		reportingParams.nextPage = reportOutput.nextPage;
		this.setState(() => ({ serviceProviderUUID, activeTable: 'surveyors', usersList: reportOutput.data, reportingParams }));
	}

	getUsersList = async (newReportingParams) => {
		let reportingParams = (newReportingParams !== undefined) ? newReportingParams : this.state.reportingParams;
		const reportOutput = await getUsersReport("normal", reportingParams);
		reportingParams.nextPage = reportOutput.nextPage;
		this.setState(() => ({ activeTable: 'surveyors', usersList: reportOutput.data, reportingParams }));
	}

	fetchData = (direction) => {
		let newReportingParams = setReportingParams(this.state.reportingParams, { direction });
		if (newReportingParams !== undefined) {
			if (this.state.activeTable === 'serviceProviders') {
				this.getServiceProvidersList(newReportingParams);
			} else if (this.state.activeTable === 'surveyors') {
				if (this.props.surveyDoneByProvider) {
					this.getServiceProviderUsersList(this.state.serviceProviderUUID, newReportingParams);
				} else {
					this.getUsersList(newReportingParams);
				}
			}
		}
	}

	collectData = (type, row) => {
		const { sendDataToReport } = this.props;

		if (type === 'serviceProviders') {
			let data = {
				service_provider_uuid: getReportValue(this.state.serviceProvidersList, row, "Service Provider UUID"),
				name: getReportValue(this.state.serviceProvidersList, row, "Name")
			}
			sendDataToReport('serviceProvider', data);
			this.getServiceProviderUsersList(data.service_provider_uuid, getDefaultReportingParams());
		} else if (type === 'surveyors') {
			let data = {
				user_uuid: getReportValue(this.state.usersList, row, "User UUID"),
				service_provider_uuid: getReportValue(this.state.usersList, row, "Service Provider UUID"),
				name: getReportValue(this.state.usersList, row, "Name"),
				phone: getReportValue(this.state.usersList, row, "Phone")
			}
			sendDataToReport('surveyor', data);
		}
	}

	serviceProviderSearch = () => {
		let name = document.getElementById('serviceprovider_name');

		let payload = { search: { name: name.value } };

		getServiceProvidersReport(payload).then(response => {
			if (response.data.rows.length == 0) {
				this.setState({
					searchError: "No providers found, please revise your search and try again",
					searchErrorLevel: "info",
					serviceProvidersList: null
				});
			} else {
				this.setState({
					searchError: "",
					serviceProvidersList: response.data,
				});
			}
		}).catch(error => {
			this.setState({
				searchError: error.message,
				searchErrorLevel: "error",
				serviceProvidersList: null
			});
		});
	}

	toggleTable = (type) => {
		this.setState(() => ({ activeTable: type, reportingParams: getDefaultReportingParams() }));
	}

	render() {
		const { serviceProvidersList, usersList, activeTable } = this.state;
		const { classes } = this.props;
		return (
			<div className='locationAssemblyInfo'>
				<div className='locationAssemblyInfo__wrapper' >
					{this.props.surveyDoneByProvider && (
						<React.Fragment>
							<div className="locationAssemblyInfo__header-wrapper">
								<span className='locationAssemblyInfo__header'>Search Service Providers</span>
							</div>
							<MessageBox options={{ type: this.state.searchErrorLevel, message: this.state.searchError }} />
							<div className="locationAssemblyInfo__inputs">
								<TextField
									label="Service Provider Company Name"
									name="serviceprovider_name"
									id="serviceprovider_name"
									variant="outlined"
									InputProps={{
										classes: {
											input: classes.textFieldInput,
											root: classes.outlinedInput,
											focused: classes.focused,
											notchedOutline: classes.notchedOutline,
										}
									}}
									onKeyDown={(e) => {
										if (e.keyCode === 13) {
											this.serviceProviderSearch();
										}
									}}
								/>
								<button className="xsmallButtonPrimary" onClick={this.serviceProviderSearch}>
									<SearchIcon fontSize='large' />
								</button>
							</div>
						</React.Fragment>
					)}
					{serviceProvidersList !== null ?
						<React.Fragment>
							<div className="initialInfo__header-wrapper">
								<span className='initialInfo__header'>Choose a Service Provider</span>
							</div>
							<Table
								highlight='true'
								tableType='Service Providers'
								tableSize='smallFlex'
								tableData={serviceProvidersList}
								openFlyout={(row) => { this.collectData('serviceProviders', row); }}
								fetchData={this.fetchData}
								columnsToShow={['Name', 'Address', 'City']}
							/>
						</React.Fragment>
						: null}
					{usersList !== null && activeTable === 'surveyors' ?
						<React.Fragment>
							<div className="initialInfo__header-wrapper">
								<span className='initialInfo__header'>Choose a Surveyor</span>
							</div>
							<Table
								highlight='true'
								tableType='Surveyors'
								tableSize='smallFlex'
								tableData={usersList}
								openFlyout={(row) => { this.collectData('surveyors', row); }}
								fetchData={this.fetchData}
								columnsToShow={['Name', 'Phone']}
								paginationParams={this.state.reportingParams}
							/>
						</React.Fragment>
						: null
					}
				</div>
			</div>
		);
	}
}

export default withStyles(styles)(SurveyorInfo);