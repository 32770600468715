import React, { useEffect, useState, useMemo } from "react";
import { withRouter } from "react-router";
import { getURLModule } from "../../../utils/url";
import MultiStepForm from "../../Common/Forms/MultiStepForm";
import useIndustrialUsers from "./useIndustrialUsers";
import usePermitTemplates from "./usePermitTemplates";
import Snackbar from "../../Common/Snackbar";
import Button from "../../Common/Button";
import styles from "../../../styles/common/multiStepFormReportTable.module.css";
import { AddPermitSteps } from "./AddPermitSteps";
import {
    createTemplateDocument,
    updateTemplateDocument,
    createLegalControl,
    updateLegalControl,
    getTemplateDocument,
    deleteTemplateDocument,
    deleteLegalControlPending,
    getPermit,
} from "../../../api/permitsAPI";
import { getReport } from "../../../api/reportingAPI";
import {
    convertFormStateToPermitData,
    constructTableSelections,
    handleError,
    convertDocumentFieldsToSnakeCase,
    mapInputType,
    constructRecurrenceData,
} from "./PermitUtils";
import SamplingConfigDialog from "./SamplingConfigDialog";
import { scheduleToRecurrenceConfig } from "../../../api/analytesAPI";

const AddPermit = ({ history, location }) => {
    const { slug } = getURLModule();
    const [templateDataLoading, setTemplateDataLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [pdfData, setPdfData] = useState(null);
    const [currentStep, setCurrentStep] = useState(0);
    const [templateData, setTemplateData] = useState(null);
    const [formState, setFormState] = useState({});
    const [tableSelections, setTableSelections] = useState({});
    const [recurrenceData, setRecurrenceData] = useState({});
    const [fetchedReports, setFetchedReports] = useState({});
    const [permitData, setPermitData] = useState(null);
    const [templateDocumentUUID, setTemplateDocumentUUID] = useState(null);
    const [legalControlUUID, setLegalControlUUID] = useState(null);
    const [showSamplingConfigDialog, setShowSamplingConfigDialog] = useState(false);
    const [selectedSampleReportConfigID, setSelectedSampleReportConfigID] = useState(null);
    const [validationErrors, setValidationErrors] = useState([]);
    const [showSaveDraftSuccess, setShowSaveDraftSuccess] = useState(false);
    const [showSaveDraftError, setShowSaveDraftError] = useState(false);
    const [isDraftSaveEnabled, setIsDraftSaveEnabled] = useState(false);
    const [generalError, setgeneralError] = useState(null);

    const { industrialUsers, error: industrialUsersError, loading: industrialUsersLoading } = useIndustrialUsers();
    const { permitTemplates, error: permitTemplatesError, loading: permitTemplatesLoading } = usePermitTemplates();

    useEffect(() => {
        const error = handleError(industrialUsersError, "Error fetching industrial users: ");
        if (error) {
            setgeneralError(error);
        }
    }, [industrialUsersError]);

    useEffect(() => {
        const error = handleError(permitTemplatesError, "Error fetching permit templates: ");
        if (error) {
            setgeneralError(error);
        }
    }, [permitTemplatesError]);

    useEffect(() => {
        const { templateDocumentUUID, legalControlUUID, status } = location.state || {};
        console.log("Initial state:", { templateDocumentUUID, legalControlUUID, status });

        if (templateDocumentUUID) {
            setTemplateDocumentUUID(templateDocumentUUID);
        }

        if (legalControlUUID) {
            setLegalControlUUID(legalControlUUID);
            // Fetch permit data when entering from a pending state
            getPermit(legalControlUUID)
                .then(setPermitData)
                .catch((error) => console.error("Error fetching permit:", error));
        }

        if (templateDocumentUUID) {
            getTemplateDocument(templateDocumentUUID)
                .then((response) => {
                    console.log("Template document response:", response);
                    const { template_data, template_document_uuid } = response;

                    setTemplateData(template_data);
                    setTemplateDocumentUUID(template_document_uuid);

                    let initialFormState = {
                        industrial_user: template_data.system_uuids.property_uuid,
                        template_uuid: response.template_uuid,
                    };

                    // Add data from data_entry and document_fields to initialFormState
                    [...(template_data.data_entry || []), ...(template_data.document_fields || [])].forEach((field) => {
                        initialFormState[field.template_string] = field.value;
                    });

                    // If form_data exists, merge it with initialFormState
                    if (template_data.form_data) {
                        initialFormState = { ...initialFormState, ...template_data.form_data };
                    }

                    setFormState(initialFormState);

                    const initialTableSelections = constructTableSelections(template_data, initialFormState);
                    setTableSelections(initialTableSelections);

                    // Add this line to set the initial recurrence data
                    const initialRecurrenceData = constructRecurrenceData(template_data);
                    setRecurrenceData(initialRecurrenceData);

                    // Set current step based on status
                    if (status === "pending" || status === "draft") {
                        const stepTitle = status === "pending" ? "Sign Document" : "Fill Form";
                        const stepIndex = AddPermitSteps.findIndex((step) => step.title === stepTitle);
                        if (stepIndex !== -1) {
                            setCurrentStep(stepIndex);
                        }
                    }
                    // Fetch report data for each report in template_data.reports
                    template_data.reports.forEach((report) => {
                        memoizedFetchAndSetReportData(report.report_uuid, initialFormState.industrial_user, report.key);
                    });
                })
                .catch((error) => {
                    console.error("Error fetching template document:", error);
                    handleError(error, "Error fetching template document: ");
                });
        }

        if (legalControlUUID) {
            setLegalControlUUID(legalControlUUID);
            // Fetch permit data when entering from a pending state
            getPermit(legalControlUUID)
                .then(setPermitData)
                .catch((error) => console.error("Error fetching permit:", error));
        }
    }, [location.state?.templateDocumentUUID, location.state?.legalControlUUID, location.state?.status]);

    useEffect(() => {
        setIsDraftSaveEnabled(false);
    }, []);

    const handleRecurrenceDataChange = (newRecurrenceData, sampleReportConfigID) => {
        // Ensure interval, start, and until are numbers
        const recurrenceDataWithNumbers = {
            ...newRecurrenceData,
            interval: Number(newRecurrenceData.interval),
            start: Number(newRecurrenceData.start),
            until: Number(newRecurrenceData.until),
        };

        const translatedRecurrenceData = {
            [sampleReportConfigID]:
                recurrenceDataWithNumbers.schedule_type === "rep"
                    ? {
                          recurrence_config: scheduleToRecurrenceConfig(recurrenceDataWithNumbers),
                      }
                    : {
                          ad_hoc_dates: [Number(recurrenceDataWithNumbers.ad_hoc_date)],
                      },
        };

        // Ensure the ad_hoc_date is a valid number
        if (
            recurrenceDataWithNumbers.schedule_type === "one" &&
            isNaN(translatedRecurrenceData[sampleReportConfigID].ad_hoc_dates[0])
        ) {
            console.error("Invalid ad_hoc_date:", recurrenceDataWithNumbers.ad_hoc_date);
            return; // Don't update the state if the date is invalid
        }

        setRecurrenceData((prevRecurrenceData) => ({
            ...prevRecurrenceData,
            ...translatedRecurrenceData,
        }));

        setTemplateData((prevTemplateData) => ({
            ...prevTemplateData,
            association_data: {
                ...prevTemplateData.association_data,
                sample_report_with_sampling_config_iu: {
                    ...prevTemplateData.association_data?.sample_report_with_sampling_config_iu,
                    ...translatedRecurrenceData,
                },
            },
        }));

        setIsDraftSaveEnabled(true);
    };

    const handleTableSelectionsChange = (newSelections) => {
        setTableSelections(newSelections);
        setIsDraftSaveEnabled(true);
    };

    const handleTemplateDataChange = (newTemplateData) => {
        setTemplateData(newTemplateData);
    };

    const handleOpenSamplingConfigDialog = (sampleReportConfigID) => {
        setSelectedSampleReportConfigID(sampleReportConfigID);
        setShowSamplingConfigDialog(true);
    };

    const getSteps = () => {
        const steps = AddPermitSteps.map((step) => ({ ...step }));

        // Update fields in the first step
        const updateField = (fieldId, options, value) => {
            const field = steps[0].fields.find((f) => f.id === fieldId);
            if (field) {
                field.options = options;
                field.value = value;
            }
        };

        updateField("industrial_user", industrialUsers, formState?.industrial_user);
        updateField("template_uuid", permitTemplates, formState?.template_uuid);

        // Populate the second step with fields and tables based on the template data
        if (templateData) {
            const { data_entry = [], document_fields = [], system_fields = {}, reports = [] } = templateData;

            // Map template data to form fields
            const mapFields = (fields, disabled = false) =>
                Array.isArray(fields)
                    ? fields.map((field) => ({
                          id: field.template_string,
                          name: field.name,
                          label: field.label,
                          ...mapInputType(field.type),
                          required: field.required,
                          value: formState[field.template_string] || field.value || "",
                          ...(disabled && { disabled: true }),
                      }))
                    : [];

            steps[1].fields = [
                ...steps[1].fields,
                ...mapFields(data_entry),
                // Map document_fields and update 'EffectiveDate' and 'ExpirationDate' fields
                ...mapFields(document_fields).map((field) => {
                    if (field.name === "EffectiveDate" || field.name === "ExpirationDate") {
                        // Adjust field.id to match IDs used in Step 1
                        field.id = field.name;
                        // Set the value from formState (values entered in Step 1)
                        field.value = formState[field.name] || formState[field.template_string] || "";
                        // Disable the field to make it read-only
                        field.disabled = true;
                    }
                    return field;
                }),
                // Map system_fields
                ...Object.entries(system_fields).flatMap(([category, fields]) =>
                    mapFields(fields, true).map((field) => ({
                        ...field,
                        label: `${category}: ${field.label}`,
                        value: field.value || formState[field.template_string] || "",
                    }))
                ),
            ];

            steps[1].tables = reports.map((report) => ({
                reportUUID: report.report_uuid,
                key: report.key,
                visibleColumns: report.visible_columns || [],
            }));
        }

        console.log("steps", steps);

        return steps;
    };

    const handleLegacyFormStateChange = (fieldId, value) => {
        setFormState((prevState) => {
            const updatedState = { ...prevState, [fieldId]: value };

            if (value && typeof value === "object" && value.selectedRows !== undefined) {
                setTableSelections((prevSelections) => ({
                    ...prevSelections,
                    [fieldId]: value.selectedRows,
                }));
            }

            // Clear validation errors when the user selects values for required fields
            if (fieldId === "industrial_user" || fieldId === "template_uuid") {
                setValidationErrors((prevErrors) =>
                    prevErrors.filter((error) => error.id !== "industrial_user" && error.id !== "template_uuid")
                );
            }

            // Re-validate dates when date fields change
            if (fieldId === "EffectiveDate" || fieldId === "ExpirationDate") {
                const dateErrors = validateDates(updatedState);
                setValidationErrors((prevErrors) => {
                    // Remove existing date errors
                    const errorsWithoutDateErrors = prevErrors.filter(
                        (error) => error.id !== "EffectiveDate" && error.id !== "ExpirationDate"
                    );
                    return [...errorsWithoutDateErrors, ...dateErrors];
                });
            }

            setIsDraftSaveEnabled(true);

            return updatedState;
        });
    };

    /**
     * Validates that the expiration date is after the effective date.
     *
     * @param {Object} formState - The current state of the form
     * @returns {Array} An array of validation errors, empty if no errors
     */
    const validateDates = (formState) => {
        const errors = [];
        const effectiveDateTimestamp = Number(formState["EffectiveDate"]);
        const expirationDateTimestamp = Number(formState["ExpirationDate"]);

        // Check if EffectiveDate is present and valid
        if (!formState["EffectiveDate"]) {
            errors.push({
                id: "EffectiveDate",
                error: "Effective Date is required.",
            });
        } else if (isNaN(effectiveDateTimestamp)) {
            errors.push({
                id: "EffectiveDate",
                error: "Effective Date is invalid.",
            });
        }

        // If both dates are valid, compare them
        if (errors.length === 0) {
            const effectiveDate = new Date(effectiveDateTimestamp * 1000);
            const expirationDate = new Date(expirationDateTimestamp * 1000);

            if (effectiveDate >= expirationDate) {
                errors.push({
                    id: "ExpirationDate",
                    error: "The expiration date must be later than the effective date.",
                });
            }
        }

        return errors;
    };

    const handleNextStep = (formState) => {
        // Early return if required fields are not filled
        if (!formState.industrial_user || !formState.template_uuid) return;

        // Validate dates
        const dateErrors = validateDates(formState);
        if (dateErrors.length > 0) {
            setValidationErrors(dateErrors);
            return;
        }

        setTemplateDataLoading(true);

        // Function to check if an existing template document matches the selected template or industrial user
        const checkExistingTemplate = () => {
            if (templateDocumentUUID) {
                return getTemplateDocument(templateDocumentUUID).then((data) => {
                    const isSameTemplate = data.template_uuid === formState.template_uuid;
                    const isSameIndustrialUser =
                        data.template_data.system_uuids.property_uuid === formState.industrial_user;

                    if (isSameTemplate && isSameIndustrialUser) {
                        // If both template and industrial user are the same, move to next step without creating a new template document
                        setCurrentStep(1);
                        return Promise.reject("Same template and industrial user");
                    }
                    // If either template or industrial user has changed, we'll create a new template document
                    return Promise.resolve();
                });
            }
            return Promise.resolve();
        };

        // Function to create a new template document
        const createNewTemplateDocument = () => {
            return createTemplateDocument({
                legal_control_type: "permit",
                template_uuid: formState.template_uuid,
                template_data: {
                    system_uuids: {
                        property_uuid: formState.industrial_user,
                    },
                },
            });
        };

        // Function to process the template data after creation
        const processTemplateData = (data) => {
            setTemplateData(data.template_data);
            setTemplateDocumentUUID(data.template_document_uuid);

            const initialFormState = {
                ...formState,
                ...createInitialFormState(data.template_data),
                legal_control_type: "permit",
            };

            setFormState(initialFormState);
            fetchReportData(data.template_data.reports, initialFormState.industrial_user);
        };

        // Chain of operations: check existing template, create new if needed, process data
        checkExistingTemplate()
            .then(createNewTemplateDocument)
            .then(processTemplateData)
            .catch((error) => {
                // Only handle errors that are not from the 'Same template' case
                if (error !== "Same template") {
                    handleError(error, "Error fetching template data: ");
                }
            })
            .finally(() => {
                setTemplateDataLoading(false);
                setCurrentStep(1);
            });
    };

    // Helper function to create initial form state from template data
    const createInitialFormState = (templateData) => {
        return ["document_fields", "data_entry"].reduce((acc, fieldType) => {
            templateData[fieldType].forEach((field) => {
                acc[field.template_string] = field.value || "";
            });
            return acc;
        }, {});
    };

    // Helper function to fetch report data for each report in the template
    const fetchReportData = (reports, industrialUser) => {
        reports.forEach((report) => {
            memoizedFetchAndSetReportData(report.report_uuid, industrialUser, report.key);
        });
    };

    const memoizedFetchAndSetReportData = useMemo(() => {
        return (reportUUID, industrialUser, tableKey) => {
            if (fetchedReports[reportUUID]?.[tableKey]) return;

            const reportObject = templateData?.reports?.find(
                (report) => report.report_uuid === reportUUID && report.key === tableKey
            );

            if (reportObject) {
                const inputs = {};

                if (reportObject.required_input_keys) {
                    reportObject.required_input_keys.forEach((key) => {
                        if (key === "Property UUID") {
                            inputs[key] = formState.industrial_user;
                        } else if (key === "Effective Date") {
                            formState?.["EffectiveDate"]
                                ? (inputs[key] = formState["EffectiveDate"])
                                : console.error("Expected EffectiveDate value and it was missing");
                        } else if (key === "Expiration Date") {
                            formState?.["ExpirationDate"]
                                ? (inputs[key] = formState["ExpirationDate"])
                                : console.error("Expected ExpirationDate value and it was missing");
                        } else {
                            inputs[key] = industrialUser;
                        }
                    });
                }

                if (reportObject.input_baseline) {
                    Object.entries(reportObject.input_baseline).forEach(([key, value]) => {
                        inputs[key] = value;
                    });
                }

                getReport(reportUUID, {
                    count: 200,
                    inputs: inputs,
                })
                    .then((data) => {
                        setFormState((prevState) => ({
                            ...prevState,
                            [reportUUID]: { ...prevState?.[reportUUID], [reportObject.key]: data.data },
                        }));
                        setFetchedReports((prevFetchedReports) => ({
                            ...prevFetchedReports,
                            [reportUUID]: { ...prevFetchedReports?.[reportUUID], [reportObject.key]: true },
                        }));
                    })
                    .catch((error) => {
                        console.error("Error fetching report data:", error);
                    });
            }
        };
    }, [fetchedReports, templateData, formState]);

    const onSubmit = (formState) => {
        const convertedFormState = convertFormStateToPermitData(
            formState,
            templateData,
            tableSelections,
            recurrenceData
        );

        if (permitData) {
            // Update the permit with the signature and status
            const updatedPermitData = {
                property_uuid: convertedFormState.template_data.system_uuids.property_uuid,
                legal_control_uuid: permitData.legal_control_uuid,
                status: "completed",
                template_document_uuid: templateDocumentUUID,
                signature: formState.signature_name,
                effective_date: formState.EffectiveDate,
                expiration_date: formState.ExpirationDate,
                ...convertDocumentFieldsToSnakeCase(convertedFormState),
            };

            updateLegalControl(updatedPermitData)
                .then((updatedPermit) => {
                    history.push(`/${slug}/p`);
                })
                .catch((error) => {
                    console.error("Error updating permit:", error);
                });
        } else {
            console.error("Permit data not available");
        }
    };

    const columnConfig = {
        "Analyte(s)": {
            render: ({ value, isSelected, sampleReportConfigUUID, handleAnalyteClick }) => (
                <div
                    className={`${styles.analyteLink} ${isSelected ? styles.disabled : ""}`}
                    onClick={() => handleAnalyteClick(sampleReportConfigUUID)}
                >
                    {value}
                </div>
            ),
        },
    };

    const onCancel = () => {
        history.goBack();
    };

    const onStepChange = (stepIndex, isGoingBack) => {
        // Convert form state and prepare common fields
        const convertedFormState = convertFormStateToPermitData(
            formState,
            templateData,
            tableSelections,
            recurrenceData
        );

        const commonFields = {
            property_uuid: formState.industrial_user,
            template_document_uuid: templateDocumentUUID,
            effective_date: Number(formState.EffectiveDate),
            expiration_date: Number(formState.ExpirationDate),
            ...convertDocumentFieldsToSnakeCase(convertedFormState),
        };

        if (isGoingBack && stepIndex === 2) {
            // Handle going back from 'Sign Document' to 'Preview'
            if (legalControlUUID) {
                deleteLegalControlPending(legalControlUUID)
                    .then(() => {
                        // Update template document after deleting pending legal control
                        return updateTemplateDocument(
                            templateDocumentUUID,
                            {
                                ...convertedFormState,
                                ...commonFields,
                                status: "draft",
                            },
                            formState
                        );
                    })
                    .then(() => {
                        // Create a new legal control with draft status
                        return createLegalControl({ ...commonFields, status: "draft" });
                    })
                    .then((response) => {
                        setPdfData(response.pdf);
                        setLegalControlUUID(response.legal_control_uuid);
                        setPermitData({ ...permitData, status: "draft" });
                    })
                    .catch((error) => {
                        console.error("Error handling step back to Preview:", error);
                    });
            } else {
                console.warn("No legalControlUUID found for deletion.");
            }
        } else if (stepIndex === 2) {
            // Handle moving forward to 'Preview'
            updateTemplateDocument(templateDocumentUUID, { ...convertedFormState, ...commonFields, status: "draft" })
                .then(() => {
                    // Create a new legal control with draft status
                    return createLegalControl({ ...commonFields, status: "draft" });
                })
                .then((response) => {
                    setPdfData(response.pdf);
                    setPermitData(response);
                })
                .catch((error) => {
                    console.error("Error handling step forward to Preview:", error);
                });
        } else if (stepIndex === 3) {
            // Handle moving forward to 'Sign Document'
            createLegalControl({ ...commonFields, status: "pending" })
                .then((updatedPermit) => {
                    setPermitData(updatedPermit);
                    setLegalControlUUID(updatedPermit.legal_control_uuid);
                    // Update template document after creating legal control
                    return updateTemplateDocument(templateDocumentUUID, {
                        ...convertedFormState,
                        ...commonFields,
                        status: "pending",
                    });
                })
                .catch((error) => {
                    console.error("Error handling step forward to Sign Document:", error);
                });
        }

        setCurrentStep(stepIndex);
    };

    const renderExtraButtons = () => {
        if (currentStep === 1 || currentStep === 2) {
            return (
                <Button
                    onClick={() => {
                        if (!templateData) {
                            console.error("Template data is not available");
                            setShowSaveDraftError(true);
                            return;
                        }

                        const convertedFormState = convertFormStateToPermitData(
                            formState,
                            templateData,
                            tableSelections,
                            recurrenceData
                        );

                        updateTemplateDocument(templateDocumentUUID, convertedFormState, formState)
                            .then(() => {
                                setShowSaveDraftSuccess(true);
                                setShowSaveDraftError(false);
                                setIsDraftSaveEnabled(false);
                            })
                            .catch((error) => {
                                console.error("Error saving draft:", error);
                                setShowSaveDraftError(true);
                                setShowSaveDraftSuccess(false);
                            });
                    }}
                    disabled={!isDraftSaveEnabled}
                >
                    Save Draft
                </Button>
            );
        }
        return null;
    };

    const onDelete = () => {
        if (currentStep === 2 && legalControlUUID) {
            // Use deleteLegalControlPending endpoint on 'Preview' step if legal control exists
            deleteLegalControlPending(legalControlUUID)
                .then(() => {
                    return deleteTemplateDocument(templateDocumentUUID);
                })
                .then(() => {
                    history.push(`/${slug}/p`);
                })
                .catch((error) => {
                    console.error("Error deleting legal control or template document:", error);
                });
        } else if (currentStep === 1 || currentStep === 2) {
            // Use deleteTemplateDocument endpoint on steps 1 and 2
            deleteTemplateDocument(templateDocumentUUID)
                .then(() => {
                    history.push(`/${slug}/p`);
                })
                .catch((error) => {
                    console.error("Error deleting template document:", error);
                });
        }
    };

    const onValidationError = (errors) => {
        const dateErrors = validateDates(formState);
        setValidationErrors([...errors, ...dateErrors]);
    };

    const isLoading = industrialUsersLoading || permitTemplatesLoading || templateDataLoading;

    console.log("formState", formState);
    console.log("validationErrors", validationErrors);
    console.log("templateData", templateData);

    return (
        <>
            <MultiStepForm
                steps={getSteps()}
                initialState={formState}
                onSubmit={onSubmit}
                onCancel={onCancel}
                onStepChange={onStepChange}
                // onFormStateChange={handleFormStateChange}
                onLegacyFormStateChange={handleLegacyFormStateChange}
                tableSelections={tableSelections}
                setTableSelections={setTableSelections}
                columnConfig={columnConfig}
                onOpenSamplingConfigDialog={handleOpenSamplingConfigDialog}
                pdfData={pdfData}
                validationErrors={validationErrors}
                onValidationError={onValidationError}
                templateDataLoading={templateDataLoading}
                templateData={templateData}
                onNextStep={handleNextStep}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                fetchAndSetReportData={memoizedFetchAndSetReportData}
                renderExtraButtons={renderExtraButtons}
                deleteButtonStep={1}
                onDelete={onDelete}
                showDelete={currentStep === 1 || currentStep === 2 || currentStep === 3}
                loading={isLoading}
                onTemplateDataChange={handleTemplateDataChange}
                recurrenceData={recurrenceData}
                onRecurrenceDataChange={handleRecurrenceDataChange}
                generalError={generalError}
                setgeneralError={setgeneralError}
                onTableSelectionsChange={handleTableSelectionsChange}
            />
            <SamplingConfigDialog
                open={showSamplingConfigDialog}
                onClose={() => setShowSamplingConfigDialog(false)}
                sampleReportConfigID={selectedSampleReportConfigID}
            />
            {showSaveDraftSuccess && (
                <Snackbar
                    message="Your changes have been saved as a draft."
                    onClose={() => setShowSaveDraftSuccess(false)}
                    severity="info"
                />
            )}
            {showSaveDraftError && (
                <Snackbar message="Unable to save as draft." onClose={() => setShowSaveDraftError(false)} />
            )}
        </>
    );
};

export default withRouter(AddPermit);
