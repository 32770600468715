import React, { useState, useEffect } from 'react';
import ContactDetailsForm from './ContactDetailsForm';
import editModeIcon from '../../../images/editMode.svg';
import {
	updateContact, createContact, deleteContact, getContact,
	getContactDetails, saveContactDetail
} from '../../api/contactsAPI';
import { getPropertyContact, createPropertyContact } from '../../api/propertiesAPI';
import { checkDuplicates } from '../../api/duplicatesAPI';
import { ModalDuplicateContext } from '../../contexts/ModalDuplicateContext';
import { createServiceProviderContact, getServiceProviderContact } from '../../api/serviceProvidersAPI';
import { getForm, getFormFields } from '../../api/formsAPI';
import validator from 'validator';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CloseIcon from '@material-ui/icons/Close';

const ContactDetails = (params) => {
	const [editMode, setEditMode] = useState(false);
	const [deleteMode, setDeleteMode] = useState(false);
	const [data, setData] = useState({});
	const [props] = useState(params);
	const [apiErrors, setApiErrors] = useState({});
	const [isNew, setIsNew] = useState(props.newAddition);
	const [detailsForm, setDetailsForm] = useState({});

	const contactTypeTranslation = {
		'ct_owner': 'owner',
		'ct_propmgr': 'property manager',
		'ct_onsite': 'onsite',
		'ct_mailing': 'mailing',
		'ct_maint': 'maintenance'
	};

	useEffect(() => {
		if (isNew) {
			setEditMode(true);
			getAdditionalDetailsInfo();
		} else {
			getContactData(props.uuid);
		}
	}, []);

	const getContactData = (uuid) => {
		if (uuid !== undefined && uuid !== '') {
			getContact(uuid).then(response => {
				let data = response;
				props.setHeader(data.firstname + ' ' + data.lastname, data.active);
				if (props.source == "property") {
					getPropertyContact(props.sourceUUID, uuid).then(response => {
						// Curently on prod users were saving contact types to upper case. While that is no longer the case,
						// this should allow the checkboxes to still work with the old saved contact type data.
						const lowerCaseContactTypes = response.contact_types.map(type => type.toLowerCase());
						data.contact_types = lowerCaseContactTypes;
						data.primary_contact = response.primary_contact;
						getAdditionalDetailsInfo(data);
					});
				} else if (props.source == "serviceProviders") {
					getServiceProviderContact(props.sourceUUID, uuid).then(response => {
						getAdditionalDetailsInfo(data);
					});
				} else {
					getAdditionalDetailsInfo(data);
				}
			});
		}
	};

	const getAdditionalDetailsInfo = (data) => {
		if (data === undefined) {
			data = {};
		}
		data.c3_additional_details = {};

		getForm("contact details").then(form => {
			if (form != null) {
				setDetailsForm({ form_uuid: form.form_uuid });
				getFormFields(form.form_uuid).then(response => {
					response.form_fields.forEach((field) => {
						data.c3_additional_details[field.form_field_uuid] = field;
					});

					if (data.contact_uuid !== undefined) {
						getContactDetails(data.contact_uuid).then(response => {
							response.details.forEach((detail) => {
								// check to make sure we know about this field (detail) as we could have had old details
								// from old custom fields returned
								if (data.c3_additional_details[detail.form_field_uuid]) {
									data.c3_additional_details[detail.form_field_uuid].value = detail.value;
								}
							});
							setData(data);
						});
					} else {
						setData(data);
					}
				});
			} else {
				setData(data);
			}
		});
	}

	const saveDetails = async (ctx, uuid, payload) => {
		payload.contact_types = [];
		for (const [key, value] of Object.entries(contactTypeTranslation)) {
			if (payload[key]) {
				payload.contact_types.push(value.toLowerCase());
			}
			delete payload[key];
		}

		if (isNew) {
			let dupecheck = await checkDuplicates('contacts', payload.firstname + ' ' + payload.lastname);
			if (dupecheck.duplicates.length > 0) {
				ctx.setDuplicates(
					dupecheck.duplicates,
					['email', 'phone'],
					'/contacts',
					payload,
					saveNewContact,
					(uuid) => {
						useSelectedDuplicate(uuid, payload.contact_types);
					}
				);

			} else {
				saveNewContact(payload);
			}
		} else {
			updateContact(uuid, payload)
				.then(response => {
					// calling a "full get" again because the update response won't have additional detail info in it
					getContactData(uuid);
					if (props.update) {
						props.update(response, payload).then(() => {
							props.refresh();
						});
					} else {
						props.refresh();
					}
					setEditMode(false);
					props.setHeader(response.firstname + ' ' + response.lastname, response.active);
					saveAdditionalDetails(uuid, payload);
				})
				.catch(error => {
					if (error.name === 'APIUserError') {
						setApiErrors(error.apiFieldErrors);
					}
				});
		}
	};

	const saveNewContact = (payload) => {
		createContact(payload)
			.then(response => {
				// contact is no longer new, set this to false so a futher update will cause an update and not create
				setIsNew(false);
				setEditMode(false);

				// update our component with our new contact
				response.c3_additional_details = {};
				props.setHeader(response.firstname + ' ' + response.lastname, response.active);

				// if we have a create callback, call it now
				if (props.create !== undefined) {
					props.create(response, payload).then((cbResponse) => {
						// other than the type of contact not sure what else might be set by the callback, and we need it
						// to properly set the value on our flyout so check for it here
						if (cbResponse.contact_types !== undefined) {
							response.contact_types = cbResponse.contact_types;
						}
						props.refresh();
						setData(response);
						props.openFlyout(response, null);
					});
				} else {
					props.refresh();
					setData(response);
					props.openFlyout(response, null);
				}
				saveAdditionalDetails(response.contact_uuid, payload);
				setEditMode(false);
			})
			.catch(error => {
				if (error.name === 'APIUserError') {
					setApiErrors(error.apiFieldErrors);
				}
			})
	}

	const useSelectedDuplicate = async (uuid, contactTypes) => {
		// If a duplicate was selected when creating a contact from service providers,
		// be sure to make an entry in service_provider_contacts table; it doesn't matter
		// if it is already there because it will just return a dup key error and
		// we can get on with our lives.
		if (props.source == 'serviceProviders') {
			createServiceProviderContact(props.sourceUUID, uuid, { contact_type: 'contact' }).then(() => {
				props.refresh();
				props.closeFlyout();
			})
		} else if (props.source == 'property') {
			createPropertyContact(props.sourceUUID, uuid, { contact_types: contactTypes }).then(() => {
				props.refresh();
				props.closeFlyout();
			});
		}
		getContactData(uuid);
		setEditMode(false);
	}

	const saveAdditionalDetails = async (uuid, payload) => {
		for (let fieldName in payload) {
			// so janky but whatever
			if (validator.isUUID(fieldName)) {
				if (data.c3_additional_details[fieldName].value !== payload[fieldName]) {
					saveContactDetail(uuid, {
						"form_uuid": detailsForm.form_uuid,
						"form_field_uuid": fieldName,
						"value": "" + payload[fieldName] // make sure what we send to the API is a string
					});
				}
			}
		}
	}

	const toggleEditMode = () => {
		setEditMode(!editMode);
		setDeleteMode(false);
	};

	const handleDelete = async (type) => {

		if (type === 'delete') {
			deleteContact(data['contact_uuid']).then(response => {
				props.closeFlyout();
				props.refresh();
			});
		} else if (type === 'cancel') {
			setDeleteMode(false);
		} else {
			setDeleteMode(true);
			setTimeout(() => {
				document.getElementById('flyoutBase').scrollIntoView(false);
			}, 100);
		}
	};

	const setContactAsInactive = async () => {
		if (
			data.active ?
				confirm(`Are you sure you want to set the contact "${data['firstname']} ${data['lastname'] ? data['lastname'] : ""}" as inactive?`) :
				confirm(`Are you sure you want to reactivate the contact "${data['firstname']} ${data['lastname'] ? data['lastname'] : ""}" ?`)
		) {
			await updateContact(data['contact_uuid'], {
				active: data.active ? false : true
			});
			await props.refresh();
			await setEditMode(false);
			await getContactData(props.uuid);
		} else {
			null;
		}
	}

	return (
		<ModalDuplicateContext.Consumer>
			{(ctx) => {
				return (
					<div className="flyoutContentContainer" id="flyoutBase">
						<button className="editModeButton" onClick={() => toggleEditMode()}>
							{!editMode && (
								<img src={editModeIcon} alt='' />
							)}
						</button>
						{data.c3_additional_details !== undefined && (
							<ContactDetailsForm
								data={data}
								context={ctx}
								editMode={editMode}
								saveDetails={saveDetails}
								toggleEditMode={toggleEditMode}
								newAddition={isNew}
								closeFlyout={props.closeFlyout}
								handleDelete={handleDelete}
								handleUnlink={props.unlink}
								source={props.source}
								apiErrors={apiErrors}
								contactTypeTranslation={contactTypeTranslation}
							/>
						)}
						{editMode && !props.newAddition && (
							<div className="flexButtonContainer" id="contactOptions">
								<div>
									<button className="medButtonSecondary" onClick={() => setContactAsInactive()}>{data.active ? 'Set as Inactive' : 'Reactivate Contact'}</button>
								</div>
							</div>
						)}
						{editMode && !deleteMode && !isNew && (
							<div className="deleteButton__container">
								<button className="deleteButton" onClick={() => handleDelete()}><DeleteForeverIcon fontSize='inherit' color='inherit' /></button>
							</div>
						)}
						{editMode && deleteMode && (
							<div className="flyout__deleteMode">
								<span>Warning: These actions cannot be undone.</span>
								{props.source && (props.source == "property" || props.source == "serviceProviders") && (
									<button className="medButtonSecondary" type="button" onClick={() => props.unlink(data)}>Delete from {props.source == "property" ? "Location" : "Service Provider"}</button>
								)}
								<button className="medButtonSecondary" type="button" onClick={() => {
										if (confirm("Are you sure you want to delete this contact?")) {
											handleDelete('delete')
										}}}>Delete Contact</button>
								<button className="exit" onClick={() => handleDelete('cancel')}><CloseIcon fontSize="large" /></button>
							</div>
						)}
					</div>
				)
			}}
		</ModalDuplicateContext.Consumer>
	);
};

export default ContactDetails;
