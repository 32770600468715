import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getURLModule, getURLObjects, URLSectionInfo } from "../../../utils/url";
import { ChevronRightRounded } from "@material-ui/icons";
import { Capitalize } from "../../../utils/misc";
import styles from "../../../styles/common/breadcrumbs.module.css";
import scLogo from "../../../../images/SwiftComplyRetina.png";

const BreadCrumbs = ({}) => {
    const { module, path } = getURLModule();
    const objects = getURLObjects();
    const [crumbLabels, setCrumbLabels] = useState(
        getURLObjects().map((obj) => URLSectionInfo[obj.section]?.title ?? obj.section)
    );

    useEffect(() => {
        // This looks complicated because it has to fetch an object for each section in the path, then get the name of
        // that object, and it must allow for the case where the section does not even have an object
        objects
            .forEach((obj, i) =>
                obj.id && URLSectionInfo[obj.section]
                    .getObject(obj.id)
                    .then(URLSectionInfo[obj.section].getObjectName)
                    .then((name) => setCrumbLabels((prev) => prev.map((prevObj, j) => (i === j ? name : prev[j]))))
            );
    }, []);

    // TODO: Remove this hack once the root paths "/pt" and "/bf" actually go somewhere
    //  For now, just redirect to the "properties" section
    const modulePath = path === "/pt" ? "/pt/iu" : "/bf/lo";

    return (
        <div className={styles.breadcrumbs}>
            <Link to={modulePath}>
                <img src={scLogo} className={styles.logo} alt="Swift Comply logo" />
            </Link>
            <ChevronRightRounded className={styles.chevron} />
            <Link to={modulePath} className={styles.crumb}>
                {Capitalize(module)}
            </Link>
            {objects.map(({ path, section, id }, i) => (
                <React.Fragment key={`crumb ${section} ${id}`}>
                    <ChevronRightRounded className={styles.chevron} />
                    <Link to={path} className={styles.crumb}>
                        {crumbLabels[i]}
                    </Link>
                </React.Fragment>
            ))}
        </div>
    );
};

export default BreadCrumbs;
