import React from "react";
import { Stepper, Step, StepLabel } from "@material-ui/core";
import styles from "../../../styles/common/progressBar.module.css";

const ProgressBar = ({ currentStep, stepTitles }) => {
    return (
        <Stepper activeStep={currentStep} alternativeLabel className={styles.progressBar}>
            {stepTitles.map((title, index) => (
                <Step key={index}>
                    <StepLabel
                        StepIconProps={{
                            classes: {
                                root: styles.stepIcon,
                                active: styles.activeStepIcon,
                                completed: styles.completedStepIcon,
                                text: styles.stepIconText,
                            },
                        }}
                        classes={{
                            label: styles.stepLabel,
                        }}
                    >
                        {title}
                    </StepLabel>
                </Step>
            ))}
        </Stepper>
    );
};

export default ProgressBar;
