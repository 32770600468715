import axios from "axios";

export const getAnalytesList = () => {
    const url = "/api/v1/analytes";

    return axios.get(url).then((response) => response.data);
};

const constructSampleReportConfigPayload = ({
    reference_point_uuid,
    name,
    assigned_to,
    control_type,
    general_control_uuid,
    lab_report_required,
    custody_chain_required,
    report_schedule,
    compliance_determinants,
    additional_requirements,
}) => {
    const isIndustry = assigned_to === "property";
    const isSpecific = isIndustry && control_type === "specific_control";
    const isRecurring = report_schedule?.schedule_type === "rep";

    return {
        reference_point_uuid,
        name,
        assigned_to,
        general_control_uuid: isIndustry ? general_control_uuid : null,
        legal_control_uuid: null,
        lab_report_required,
        custody_chain_required,
        additional_requirements,
        compliance_determinants,
        recurrence_config: !isSpecific && isRecurring ? scheduleToRecurrenceConfig(report_schedule) : null,
        ad_hoc_dates:
            !isSpecific && !isRecurring && report_schedule?.ad_hoc_date ? [report_schedule.ad_hoc_date] : null,
    };
};

export const createSampleReportConfig = (payload) =>
    axios
        .post("/api/v1/analytes/sample_report_config", constructSampleReportConfigPayload(payload))
        .then((response) => response.data);

export const getSampleReportConfig = (reportConfigID) =>
    axios.get(`/api/v1/analytes/sample_report_config/${reportConfigID}`).then((response) => {
        const { data } = response;
        const { recurrence_config, ...rest } = data || {};

        if (recurrence_config) {
            rest.report_schedule = {
                schedule_type: "rep",
                frequency: recurrence_config.frequency?.toLowerCase(),
                interval: `${recurrence_config.interval}`,
                start: recurrence_config.start,
                until: recurrence_config.until,
            };

            if (recurrence_config.frequency === "monthly_last_day") {
                rest.report_schedule.frequency = "monthly";
                rest.report_schedule.day_or_date = "last";
            } else if (recurrence_config.by_day_config) {
                rest.report_schedule.day_or_date = "weekday";
                rest.report_schedule.nth = `${recurrence_config.by_day_config.nth}`;
                rest.report_schedule.day_of_week = recurrence_config.by_day_config.weekday;
                delete rest.by_day_config;
            } else {
                rest.report_schedule.day_or_date = "date";
            }
        } else {
            rest.report_schedule = {
                schedule_type: "one",
                ad_hoc_date: data.ad_hoc_dates?.[0],
            };
        }

        rest.control_type =
            rest.assigned_to === "property"
                ? rest.general_control_uuid
                    ? "general_control"
                    : "specific_control"
                : null;

        return rest;
    });

export const updateSampleReportConfig = (reportConfigID, payload) =>
    axios
        .put(`/api/v1/analytes/sample_report_config/${reportConfigID}`, constructSampleReportConfigPayload(payload))
        .then((response) => response.data);

export const deleteSampleReportConfig = (reportConfigID) => {
    const url = `/api/v1/analytes/sample_report_config/${reportConfigID}`;

    return axios.delete(url).then((response) => response.data);
};

const constructSamplingConfigPayload = ({
    sample_report_config_id,
    name,
    sample_type,
    min_aliquots,
    pacing,
    pacing_value_time,
    pacing_value_flow,
    lab_methods,
    container_types,
    container_volumes,
    preservatives,
    analytes,
    max_hold_time,
    notes,
    sampling_period,
    administrative_period,
    active_sampling_hours,
    compliance_determinants,
    sample_schedule,
}) => {
    const pacing_string = pacing === "flow" ? pacing_value_flow : pacing_value_time;
    const [pacing_value, pacing_unit] = pacing_string?.split(" ") ?? [];

    const ret = {
        sample_report_config_id,
        name,
        sample_type,
        min_aliquots,
        pacing,
        pacing_value,
        pacing_unit,
        lab_methods,
        container_types,
        container_volumes,
        preservatives,
        analytes,
        max_hold_time,
        notes,
        sampling_period,
        administrative_period,
        active_sampling_hours,
        compliance_determinants: [],
        sampling_frequency: sample_schedule?.frequency,
        sampling_interval: sample_schedule?.interval,
        sampling_count: sample_schedule?.stop_after,
    };

    compliance_determinants?.forEach((val) => {
        if (val === "pacing_value_time" || val === "pacing_value_flow") {
            ret.compliance_determinants.push("pacing_value");
            ret.compliance_determinants.push("pacing_unit");
        } else {
            ret.compliance_determinants.push(val);
        }
    });

    return ret;
};

export const createSamplingConfig = (payload) =>
    axios
        .post("/api/v2/analytes/sampling_config", constructSamplingConfigPayload(payload))
        .then((response) => response.data);

export const getSamplingConfig = (samplingConfigID) => {
    return axios.get(`/api/v2/analytes/sampling_config/${samplingConfigID}`).then((response) => {
        const { data } = response;
        const {
            pacing_value,
            pacing_unit,
            sampling_frequency,
            sampling_interval,
            sampling_count,
            compliance_determinants,
            ...rest
        } = data;
        const pacing_value_id = `pacing_value_${rest.pacing}`;

        if (rest.pacing && pacing_value && pacing_unit) {
            rest[pacing_value_id] = `${pacing_value} ${pacing_unit}`;
        }

        rest.sample_schedule = {
            schedule_type: "rep",
            frequency: sampling_frequency,
            interval: sampling_interval,
            stop_after: sampling_count,
        };

        if (compliance_determinants) {
            rest.compliance_determinants = [];

            compliance_determinants.forEach((val) => {
                if (val === "pacing_value") {
                    rest.compliance_determinants.push(pacing_value_id);
                } else {
                    rest.compliance_determinants.push(val);
                }
            });
        }

        return rest;
    });
};

export const updateSamplingConfig = (samplingConfigId, payload) =>
    axios
        .put(`/api/v2/analytes/sampling_config/${samplingConfigId}`, constructSamplingConfigPayload(payload))
        .then((response) => response.data);

export const deleteSamplingConfig = (samplingConfigId) => {
    const url = `/api/v2/analytes/sampling_config/${samplingConfigId}`;

    return axios.delete(url).then((response) => response.data);
};

export const scheduleToRecurrenceConfig = ({ frequency, interval, day_or_date, nth, day_of_week, start, until }) => ({
    frequency: day_or_date === "last" ? "monthly_last_day" : frequency,
    interval,
    start,
    until,
    by_day_config:
        frequency === "monthly"
            ? day_or_date === "weekday"
                ? {
                      nth,
                      weekday: day_of_week,
                  }
                : null
            : null,
    timezone: Intl?.DateTimeFormat?.()?.resolvedOptions?.()?.timeZone || "Etc/UTC",
});

export const getRecurrences = ({ frequency, interval, day_or_date, nth, day_of_week, start, until }) =>
    axios
        .post(
            "/api/v1/analytes/recurrences",
            scheduleToRecurrenceConfig({ frequency, interval, day_or_date, nth, day_of_week, start, until })
        )
        .then((response) => response.data);
