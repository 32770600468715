import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { getURLParentObject } from "../../../utils/url";
import { deleteSamplingConfig, getSampleReportConfig, updateSamplingConfig } from "../../../api/analytesAPI";
import useAnalytes from "../useAnalytes";
import HomeForm from "../../Common/Home/HomeForm";
import { getErrorText } from "../../../utils/errors";
import LoadingSpinner from "../../Common/LoadingSpinner";
import { getSamplingConfigFields } from "./AddSamplingConfig";
import { BasicSamplingConfigFields } from "./BasicSamplingConfigFields";

const deleteText = (
    <>
        <span>Deleting this Sampling Configuration will delete all associated unsubmitted Sample Reports</span>
        <br />
        <span>Are you sure you want to continue?</span>
        <br />
        <span>This action cannot be undone!</span>
    </>
);

const EditSamplingConfig = ({ preloadedBasicState, onEditComplete, onEditCancelled, history }) => {
    const { id: samplingConfigId } = getURLParentObject("sc") || {};
    const { id: sampleReportConfigId, path: sampleReportConfigPath } = getURLParentObject("src") || {};
    const { analytes, error } = useAnalytes();
    const [isAuthority, setIsAuthority] = useState(false);

    useEffect(() => {
        getSampleReportConfig(sampleReportConfigId)
            .then((data) => setIsAuthority(data.assigned_to === "organization"))
            .catch(console.error);
    }, []);

    const onSubmit = (basic) =>
        updateSamplingConfig(samplingConfigId, {
            ...basic,
            sample_report_config_id: sampleReportConfigId,
        }).then(onEditComplete);

    const onDelete = () => deleteSamplingConfig(samplingConfigId).then(() => history.push(sampleReportConfigPath));

    if (error) {
        return getErrorText(error);
    }

    if (!analytes.length) {
        return <LoadingSpinner />;
    }

    const fields = [
        ...BasicSamplingConfigFields.map((f) => ({
            ...f,
            options: f.id === "analytes" ? analytes : f.options,
        })),
        { id: "isAuthority", inputType: "hidden", defaultValue: isAuthority }
    ];

    return (
        <HomeForm
            basicFields={fields}
            onSubmit={onSubmit}
            onCancel={onEditCancelled}
            onDelete={onDelete}
            preloadedBasicState={preloadedBasicState}
            deleteText={deleteText}
        />
    );
};

export default withRouter(EditSamplingConfig);
