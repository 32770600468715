import React, { useState } from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Radio,
    RadioGroup,
} from "@material-ui/core";
import styles from "../../../styles/common/duplicatesDialog.module.css";
import Button from "../Button";

/**
 * Object describing a duplicate entry
 *
 * @typedef {Object} duplicate
 * @property {string} label - The label displayed in the list
 * @property {string} value - The value passed to the onSelect callback if this duplicate is selected by the user
 */

/**
 * Dialog that displays a list of possible duplicates and lets the user select one or just "save" and ignore duplicates
 * Note - the parent component should show/hide the dialog on select/save/cancel
 *
 * @param {boolean} open - Set to true to show the dialog
 * @param {duplicate[]} options - Array of duplicates, if empty will show "No Duplicates Found"
 * @param {(duplicate) => void} onSelect - Called when the user selects a duplicate and clicks "Use selected"
 * @param {() => void}onSave - Called when the user clicks "Save"
 * @param {() => void}onCancel - Called when the user clicks "Cancel"
 */
const DuplicatesDialog = ({ open, options, onSelect, onSave, onCancel }) => {
    const [selected, setSelected] = useState(null);

    // This prevents a weird re-render flicker when the dialog is closed
    if (!open) {
        return null;
    }

    return (
        <Dialog open={open} onClose={onCancel}>
            <DialogTitle>Duplicates Check</DialogTitle>
            <DialogContent>
                {options?.length ? (
                    <>
                        <div className={styles.message}>
                            The following are possible duplicates for the data you have entered. To use one of these,
                            select it and click "Use selected." To ignore these and use the data you have entered, click
                            "Save."
                        </div>
                        <RadioGroup value={selected} onChange={(e) => setSelected(e.target.value)}>
                            {options.map((o, i) => (
                                <FormControlLabel {...o} key={`option-${i}`} control={<Radio />} />
                            ))}
                        </RadioGroup>
                    </>
                ) : (
                    <span>No Duplicates Found</span>
                )}
            </DialogContent>
            <DialogActions>
                {!!options?.length && (
                    <>
                        <Button color="primary" onClick={() => onSelect(selected)} disabled={!selected}>
                            Use Selected
                        </Button>
                        <Button color="primary" onClick={onSave}>
                            Save
                        </Button>
                    </>
                )}
                <Button onClick={onCancel}>{options?.length ? "Cancel" : "OK"}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default DuplicatesDialog;
