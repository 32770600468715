import React from "react";
import PDFPreview from "../PDFPreview";

export const AddPermitSteps = [
    {
        title: "Template Selection",
        fields: [
            {
                id: "industrial_user",
                label: "Industrial User",
                inputType: "select",
                options: [], // Options are populated dynamically
                required: true,
            },
            {
                id: "template_uuid",
                label: "Select Permit Template",
                inputType: "select",
                options: [], // Options are populated dynamically
                required: true,
            },
            {
                id: "EffectiveDate",
                label: "Effective Date",
                inputType: "date",
                required: true,
            },
            {
                id: "ExpirationDate",
                label: "Expiration Date",
                inputType: "date",
                required: true,
            },
        ],
    },
    {
        title: "Fill Form",
        fields: [],
        tables: [],
    },
    {
        title: "Preview",
        customStep: {
            render: (formState, pdfData) => <PDFPreview pdfData={pdfData} />,
        },
    },
    {
        title: "Sign Document",
        fields: [
            {
                id: "signature_text",
                label: "Sign and complete permit by typing your name",
                inputType: "label",
            },
            {
                id: "signature_name",
                label: "Type Your Name",
                inputType: "input",
                required: true,
            },
        ],
    },
];
