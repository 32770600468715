import React from 'react';
import ReactTable, { ReactTableDefaults } from 'react-table';
import 'react-table/react-table.css';
import medalSmall from '../../images/medalSmall.svg';
import warningSmall from '../../images/warningSmall.svg';
import horLine from '../../images/horLine.svg';
import edit from '../../images/edit.svg';
import del from '../../images/delete.svg';
// we use these for booleans too
import checkGreen from '../../images/checkGreen.svg';
import xRed from '../../images/xRed.svg';
// pending (booleans)
import moreHoriz from '../../images/moreHoriz.svg';
import Tooltip from './Tooltip';
import Moment from 'react-moment';
import { Checkbox } from '@material-ui/core';
import { getReportValue, reportRowToObject } from '../utils/reporting';

class BasicTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            clickable: (props.clickable !== undefined) ? props.clickable : props.multiSelect ? false : true,
            selectAll: 0,
            selected: {},
            dateFormats: (props.dateFormats !== undefined) ? props.dateFormats : {}
        };
    }

    componentDidMount = () => {
        if (this.props.selected) {
            let selected = {}

            this.props.selected.forEach((row) => {
                selected[row] = true
            })

            this.setState(() => ({ selected: selected }));
        }
    }

    defineColumns = (tableData) => {
        let columns = [];
        let lengths = [];

        tableData.outputs.forEach((output, idx) => {
            if (lengths[idx] === undefined) {
                lengths[idx] = 0;
            }

            let showColumn = true;
            // we don't show any of the UUID's in the UI
            if (output.name.endsWith('UUID')) {
                showColumn = false;
            } else if (this.props.dataSource === "list" && this.props.columns[idx].display === false) {
                showColumn = false;
            } else if (this.props.columnsToShow && this.props.columnsToShow.length > 0) {
                showColumn = this.props.columnsToShow.includes(output.name);
            }

            columns[idx] = {
                show: showColumn,
                Header: () => {
                    return (
                        <div className={output.type !== 'string'
                            ? 'centerHeader' : null}>
                            {output.name}
                        </div>
                    );
                },
                accessor: output.name,
                Cell: ({ original }) => {
                    let centerContent = { justifyContent: 'center ' };
                    const keys = Object.keys(original);
                    const valueFromKeys = original[keys[idx]];
                    if (output.type === 'url') {
                        return (
                            <div className="tableItemContainer" style={centerContent}>
                                <a href={`//${valueFromKeys}`}>{valueFromKeys}</a>
                            </div>
                        );
                    } else if (output.type === 'compliance_report_status') {
                        let color = '#7689A3';
                        let value = 'In Progress';
                        let title = 'In Progress';
                        if (valueFromKeys === 'submitted') {
                            value = 'Submitted';
                            title = 'Submitted For Review';
                        } else if (valueFromKeys === 'accepted') {
                            color = '#00D89D';
                            value = 'Accepted';
                            title = 'Accepted';
                        } else if (valueFromKeys === 'rejected') {
                            color = '#FF005C';
                            value = 'Rejected';
                            title = 'Rejected';
                        } else if (valueFromKeys === 'pending_payment') {
                            color = '#FF9800';
                            value = 'Pending Payment';
                            title = 'Pending Payment';
                        }
                        return (
                            <div className="tableItemContainer" style={centerContent}>
                                <p style={{ color: color }} title={title}>{value}</p>
                            </div>
                        )
                    } else if (output.type === 'compliance_result') {
                        let color = '#7689A3';
                        let value = '?';
                        let title = 'Unknown';
                        if (valueFromKeys === 'true') {
                            color = '#00D89D';
                            value = 'Pass';
                            title = 'Pass';
                        } else if (valueFromKeys === 'false') {
                            color = '#FF005C';
                            value = 'Fail';
                            title = 'Fail';
                        }
                        return (
                            <div className="tableItemContainer" style={centerContent}>
                                <p style={{ color: color }} title={title}>{value}</p>
                            </div>
                        )
                    } else if (output.type === 'boolean') {
                        let icon = moreHoriz;
                        let alt = 'Unknown';
                        if (valueFromKeys === 'true') {
                            icon = checkGreen;
                            alt = 'True';
                        } else if (valueFromKeys === 'false') {
                            icon = xRed;
                            alt = 'False';
                        }
                        return (
                            <div className="tableItemContainer" style={centerContent}>
                                <img style={{ padding: '1rem' }} src={icon} title={alt} alt={alt} />
                            </div>
                        )
                    } else if (output.type === 'timestamp') {
                        // The default since we don't need much resolution is the day.  However we will allow 
                        // components to pass in other formats for individual fields if we want to extend this,
                        // notably fields like last modified on can benefit from being YYYY-MM-DD hh:mm
                        let dateFormat = "YYYY-MM-DD";
                        if (this.state.dateFormats[output.name]) {
                            dateFormat = this.state.dateFormats[output.name]
                        }

                        return (
                            <div className="tableItemContainer" style={centerContent}>
                                {valueFromKeys && (
                                    <Moment unix format={dateFormat}>{valueFromKeys}</Moment>
                                )}
                            </div>
                        )
                    } else if (output.type === 'certification_status') {
                        let icon = horLine;
                        let alt = 'No Certifications';
                        if (valueFromKeys === 'certified') {
                            icon = medalSmall;
                            alt = 'All Certifications Valid';
                        } else if (valueFromKeys === 'expired') {
                            icon = warningSmall;
                            alt = 'One or More Certifications Expired';
                        }
                        return (
                            <div className="tableItemContainer" style={centerContent}>
                                <img style={{ padding: '1rem' }} src={icon} title={alt} alt={alt} />
                            </div>
                        )
                    } else {
                        return (
                            <div className="tableItemContainer"
                                style={(output.type === 'int' || output.type === 'numeric') ? centerContent : null}
                            >
                                {valueFromKeys}
                            </div>
                        );
                    }
                }
            };
        });

        tableData.rows.forEach((row, ignore) => {
            // we are going to look at each value in the col and take the max of their lengths and the header's length 
            //(so that doesn't get chopped)
            row.values.forEach((val, idx) => {
                let valLength = 0;
                if (val !== null && val !== undefined) {
                    valLength = val.length;
                }

                lengths[idx] = Math.max(
                    lengths[idx],
                    valLength,
                    tableData.outputs[idx].name.length
                );
            });
        });

        lengths.forEach((len, idx) => {
            // now, unless the minWidth is already set, i.e. by a special col, set the min to len + 25% * some multiplier
            // The big problem here is this lib wants a width in px but we use rem on the ui, so the conversion is weird
            // and to make it look decent we want a larger multiplier for small cols, but want to shrink that down as we
            // have more data as it just amplifies the white space
            if (columns[idx].minWidth === undefined) {
                let multiplier = 7;
                if (len < 5) {
                    multiplier = 9;
                }
                columns[idx].minWidth = (len * 1.25) * multiplier;
            }
        });

        if (this.props.multiSelect) {
            columns.unshift({
                id: "checkbox",
                accessor: "",
                Cell: ({ original }) => {
                    return (
                        <Checkbox
                            checked={this.state.selected[original.values] === true}
                            onChange={() => this.toggleRow(original.values)}
                            color="primary"
                            size="medium"
                        />
                    )
                },
                Header: title => {
                    return (
                        <Checkbox
                            checked={this.state.selectAll === 1}
                            onChange={() => this.toggleSelectAll()}
                            id="allCheck"
                            color="primary"
                            size="medium"
                        />
                    )
                },
                sortable: false
            })
        } else {
            columns.unshift(<span></span>);
        }
        columns.push(<span></span>);
        return columns;
    }

    toggleRow(row) {
        const newSelected = Object.assign({}, this.state.selected);
        newSelected[row] = !this.state.selected[row];
        this.setState({
            selected: newSelected,
            selectAll: 2
        }, () => {
            let list = [];

            for (const x in this.state.selected) {
                if (this.state.selected[x] === true) {
                    list.push(x)
                }
            }
            this.props.openFlyout(list)
        });
    }

    toggleSelectAll() {
        let newSelected = {};

        if (this.state.selectAll === 0) {
            this.props.tableData.rows.forEach(x => {
                newSelected[x.values] = true;
            });
        }

        this.setState({
            selected: newSelected,
            selectAll: this.state.selectAll === 0 ? 1 : 0
        }, () => {
            let list = [];

            for (const x in this.state.selected) {
                if (this.state.selected[x] === true) {
                    list.push(x)
                }
            }
            this.props.openFlyout(list)
        });
    }

    convertAPIListForTable = (tableData, columns) => {
        let conversion = {
            outputs: [],
            rows: []
        };
        // convert an api list call to something that looks like a reporting call
        tableData.forEach((data) => {
            let row = [];
            columns.forEach((col) => {
                let value = "";
                // if the dataName was passed in as an array, we assume we want to join all those values with a space
                if (Array.isArray(col.dataName)) {
                    let values = [];
                    col.dataName.forEach((name, idx) => {
                        if (data[name]) {
                            values.push(data[name]);
                        }
                    });
                    value = values.join(" ");
                } else {
                    // reporting returns everything as strings, but lists might not so make sure we convert to string
                    // so we can assume that is the case later on
                    value = "" + data[col.dataName];
                }
                row.push(value);
            })
            conversion.rows.push({ values: row })
        })

        // now toss the columns into a format like reporting as well, nothing will be searchable in this format
        columns.forEach((col) => {
            conversion.outputs.push({
                name: col.displayName,
                type: (col.dataType) ? col.dataType : "string",
                searchable: false
            })
        })
        return conversion;
    }

    getTableData = tableData => {
        /**
         * tranforms data into object formatted: accessor/value pairs
         * ex: data will be { firstName: 'Isaiah', ... }
         * accessor=firstName && value=Isaiah
         */

        const { outputs, rows } = tableData;

        // this will be returned/formatted as [ { accessor: value }, ... ]
        const data = [];

        // cache all accessors
        const accessors = outputs.map(data => data.name)

        // temp variable to be used to push to the data being returned
        let dataObj = {}
        rows.forEach(row => {
            // this is where we create the accessor/value pairs
            row?.values.forEach((val, i) => (dataObj[accessors[i]] = val))
            data.push(dataObj)

            // empty temp variable to be used in next iteration
            dataObj = {}
        })

        // returns array of accessor/value pairs
        return data
    }

    render() {
        let { tableData } = this.props;
        const columnDefaults = { ...ReactTableDefaults.column, headerClassName: 'wordwrap' };

        // some callers of this component pass their API response blindly and as such tableData might be undefined
        // so in that case, we probably want to display the "noDataText" message, so we can mimic blank tableData
        // to get that to display
        if (!tableData) {
            tableData = {
                outputs: [],
                rows: []
            }
        } else if (this.props.dataSource === "list") {
            tableData = this.convertAPIListForTable(tableData, this.props.columns);
        }

        let newTableData, sel, rowStyle;
        if (tableData && tableData.rows) {
            newTableData = tableData.rows;
        }
        else {
            newTableData = tableData;
        }

        if (this.props.selectedIndex !== undefined) {
            sel = this.props.selectedIndex
        } else if (this.props.flyoutActive) {
            sel = this.state.selected;
        } else if (this.props.highlight) {
            sel = this.state.selected;
        } else {
            sel = null;
        }

        return (
            <div>
                <div className="mainTable">
                    <ReactTable
                        data={this.getTableData(tableData)}
                        columns={this.defineColumns(tableData)}
                        column={columnDefaults}
                        noDataText={`No ${this.props.tableType} Found`}
                        className="-highlight"
                        resizable={false}
                        sortable={this.props.sortable}
                        multiSort={true}
                        minRows={0}
                        showPagination={false}
                        onFetchData={this.fetchData}
                        pageSize='250'
                        getProps={
                            (state, rowInfo, column, instance) => {
                                return {
                                    style: {
                                        height: 'auto',
                                        maxHeight: '50rem'
                                    },
                                };
                            }
                        }
                        getTrProps={(state, rowInfo, column, instance) => {
                            if (this.props.tableData.outputs) {
                                let outputs = this.props.tableData.outputs;
                                let activePosition = outputs.findIndex(output => output.name === 'Active')
                                if (rowInfo.original.values[activePosition] === 'false') {
                                    rowStyle = {
                                        background: rowInfo.index === sel ? '#b7c5d8' : null,
                                        cursor: (this.state.clickable) ? 'pointer' : 'default',
                                        fontStyle: 'italic',
                                        color: '#ACBACF'
                                    }
                                } else {
                                    rowStyle = {
                                        background: rowInfo.index === sel ? '#b7c5d8' : null,
                                        cursor: (this.state.clickable) ? 'pointer' : 'default'
                                    }
                                }
                            } else {
                                rowStyle = {
                                    background: rowInfo.index === sel ? '#b7c5d8' : null,
                                    cursor: (this.state.clickable) ? 'pointer' : 'default'
                                }
                            }
                            return {
                                onClick: () => {
                                    if (this.state.clickable) {
                                        this.props.openFlyout(rowInfo.original);
                                        this.setState({ selected: rowInfo.index });
                                    }
                                },
                                style: rowStyle
                            };
                        }}

                    />
                </div>
            </div>
        );
    }
}

export default BasicTable;
