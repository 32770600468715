import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import { dateToAPIString, timestampToDate } from "../../../../utils/forms";
import { FormControl, FormHelperText } from "@material-ui/core";
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { AccessTimeRounded } from "@material-ui/icons";
import styles from "../../../../styles/common/dateTimePicker.module.css";
import helperTextStyles from "../../../../styles/common/matieralUI/helperText.module.css";

const DateField = ({ label, timeLabel = "Time", disableFuture, value, setValue, error, disabled }) => {
    const date = value ? timestampToDate(value) : null;

    const handleDateChange = (newDate) =>
        updateParentState(newDate, date ?? new Date());

    const handleTimeChange = (newTime) =>
        updateParentState(date ?? new Date(), newTime);

    const updateParentState = (newDate, newTime) => {
        if (newDate && newTime) {
            setValue(
                dateToAPIString(
                    new Date(
                        newDate.getFullYear(),
                        newDate.getMonth(),
                        newDate.getDate(),
                        newTime.getHours(),
                        newTime.getMinutes(),
                    )
                )
            );
        } else {
            setValue(null);
        }
    };

    return (
        <FormControl error={!!error}>
            <div className={styles.wrapper}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        InputAdornmentProps={{ position: "start" }}
                        disableToolbar
                        disableFuture={disableFuture}
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        format="yyyy-MM-dd"
                        label={label}
                        value={date}
                        onChange={handleDateChange}
                        error={!!error}
                        disabled={!!disabled}
                        data-testid={`${label}-date`}
                    />
                    <KeyboardTimePicker
                        InputAdornmentProps={{ position: "start" }}
                        keyboardIcon={<AccessTimeRounded />}
                        style={{ flexGrow: 1 }}
                        ampm={true}
                        variant="inline"
                        inputVariant="outlined"
                        label={timeLabel}
                        value={date}
                        onChange={handleTimeChange}
                        error={!!error}
                        disabled={!!disabled}
                        data-testid={`${label}-time`}
                    />
                </MuiPickersUtilsProvider>
            </div>
            {error && <FormHelperText classes={helperTextStyles}>{error}</FormHelperText>}
        </FormControl>
    );
};

export default DateField;
