import React from 'react';
import { listComplianceReportUploads, deleteComplianceReportUpload, getComplianceReport } from '../../api/complianceAPI';
import { getField } from '../../api/formsAPI';
import { getUpload } from '../../api/uploadsAPI';
import LoadingSpinner from '../LoadingSpinner';
import ReactTable from 'react-table';
import * as Moment from 'moment';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

class TestReportFiles extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			data: [],
			columns: [],
			inProgress: true,
			loading: true,
			type: this.props.flyoutType === 'surveys' ? 'Survey' : 'Test Report'
		};
	}

	componentDidMount = async () => {
		await getComplianceReport(this.props.uuid).then((response) => {
			if (response.status !== 'in_progress') {
				this.setState(() => ({ inProgress: false }));
			}
		});
		this.getTestReportFilesList();
	}

	getTestReportFilesList = () => {
		listComplianceReportUploads(this.props.uuid).then((response) => {
			response.compliance_report_uploads.forEach(file => {
				this.setState((prevState) => ({ data: [...prevState.data, file] }));
				getField(file.form_field_uuid).then((response) => {
					this.setState((prevState) => ({
						data: prevState.data.map(el =>
							(el.form_field_uuid === response.form_field_uuid ?
								{
									...el,
									name: response.label,
									last_modified: Moment.unix(el.last_modified_on).format('YYYY-MM-DD')
								} : el
							))
					}));
				});
			});
			setTimeout(() => {
				this.setState(() => ({ loading: false }));
			}, 500);
		});

		let columns = [];

		columns.push({
			show: true,
			accessor: 'name',
			Header: 'Field Name',
			Cell: ({ original }) => (
				<div>
					<span
						onClick={() => this.handleFileSelection(original)}
						style={{ color: '#007AFF', cursor: 'pointer' }}
					>
						{original.name}
					</span>
				</div>
			)
		});

		columns.push({
			show: true,
			accessor: 'last_modified',
			Header: 'Last Modified',
			headerClassName: 'centered',
			className: 'textCentered'
		});

		if (this.state.inProgress) {
			columns.push({
				show: true,
				Header: 'Action',
				headerClassName: 'centered',
				Cell: ({ original }) => (
					<div onClick={() => this.handleDeleteFile(original)}
						style={{ color: '#ff6ba0', cursor: 'pointer', display: 'flex', justifyContent: 'center' }}
					>
						<DeleteForeverIcon color='inherit' fontSize='large' />
					</div>
				)
			});
		}


		columns.push(<span></span>);
		columns.unshift(<span></span>);

		this.setState(() => ({ columns }));
	}

	handleFileSelection = (payload) => {
		getUpload(payload.upload_uuid).then((response) => {
			window.open(URL.createObjectURL(response), '_blank');
		});
	};

	handleDeleteFile = (payload) => {
		if (confirm('Are you sure you want to delete this file?')) {
			deleteComplianceReportUpload(payload.compliance_report_uuid, payload.form_field_uuid, payload.upload_uuid).then(() => {
				this.setState(() => ({ data: [], columns: [] }), () => {
					this.getTestReportFilesList();
				});
			});
		}
	}

	render() {
		const { data, columns, loading } = this.state;

		return (
			<div className="flyoutContentContainer">
				{data.length > 0 && data[0].name &&
					<div className="mainTable" id='testReportFilesTable'>
						<ReactTable
							data={data}
							columns={columns}
							resizable={false}
							sortable={false}
							minRows={0}
							showPagination={false}
							getProps={
								(state, rowInfo, column, instance) => {
									return {
										style: {
											height: 'auto'
										},
									};
								}
							}
							getTheadThProps={(state, rowInfo, column, instance) => {
								return {
									style: {
										display: 'flex'
									}
								};
							}}
						/>
					</div>
				}
				{this.state.data.length < 1 && !loading && <span>{`There are currently no uploaded files for this ${this.state.type}.`}</span>}
			</div>
		);
	}
}

export default TestReportFiles;