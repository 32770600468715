import React from 'react';
import { withFormik, Form, Field, FieldArray } from 'formik';
import MessageBox from '../MessageBox';
import * as Yup from 'yup';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { Tooltip } from '../Tooltip';
import DatePicker from '../DatePicker';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const FormLayout = ({
    data,
    isSubmitting,
    errors,
    touched,
    editMode,
    toggleEditMode,
    resetForm,
    newAddition,
    closeFlyout,
    setFieldValue,
    apiErrors,
    messageOptions,
    values,
    formErrors,
    setFormErrors
}) => {

    const cancelAction = () => {
        if (newAddition) {
            resetForm();
            closeFlyout();
        } else {
            toggleEditMode();
            resetForm();
        }
    };

    const getError = (fieldname) => {
        if (apiErrors && apiErrors[fieldname]) {
            return apiErrors[fieldname];
        } else if (touched[fieldname] && errors[fieldname]) {
            return errors[fieldname];
        }
    };

    const handleFormChange = (e) => {
        // this is called when the user changed a value, so if they did, we are going to wipe out any api errors that
        // might be set so we can check it again
        if (apiErrors && apiErrors[e.target.name]) {
            delete apiErrors[e.target.name];
        }
    };

    const getFormErrors = () => {
        let errorList = [];
        messageOptions = {};
        ['method', 'notes', 'due_on', 'sent_on', 'status', 'tracking'].forEach((field) => {
            let err = getError(field);
            if (err) {
                errorList.push(err);
            }
        });

        if (errorList.length !== 0) {
            messageOptions = {
                type: "error",
                message: "Please correct the following errors",
                list: errorList,
            };
            if (formErrors != errorList.join(",")) {
                document.getElementById('commTypeDetailsForm').scrollIntoView();
            }
            setFormErrors(errorList.join(","));
        }

        return messageOptions;
    };

    const getSelectOptions = (optionType) => {
        let optionsList;
        const methodOptions = ['letter', 'postcard', 'email']

        if (optionType === 'method') {
            optionsList = methodOptions.map((type, index) => {
                return (
                    <option
                        value={type}
                        key={index}
                    >
                        {type.charAt(0).toUpperCase() + type.slice(1)}
                    </option>
                );
            });
        }

        return optionsList;
    };

    return (
        <Form className="commDetailsForm" id="commTypeDetailsForm">
            <MessageBox options={getFormErrors()} />
            <fieldset disabled={!editMode}>
                <div className="detailsFieldset">
                    <div className={'inputField medium ' + ((getError('name')) ? 'formRed' : '')} style={{ position: 'relative' }}>
                        <label htmlFor="name">Name</label>
                        {data && data.info && <OpenInNewIcon className="newTabButton" onClick={() => window.open(`/contacts/${data.info.contact_uuid}`, '_blank')} />}
                        <Field type="text" name="name" autoComplete="off" readOnly className="inputField__input inputField__input-first"
                            onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
                        />
                    </div>
                    <div className={'inputField medium ' + ((getError('email')) ? 'formRed' : '')}>
                        <label htmlFor="email">Email</label>
                        <Field type="text" name="email" autoComplete="off" readOnly className="inputField__input inputField__input-first"
                            onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
                        />
                    </div>
                    <div className={'inputField medium ' + ((getError('method')) ? 'formRed' : '')}>
                        <label htmlFor='method'>Method *</label>
                        <Field component="select" id='method' name='method' autoComplete="off" className="inputField__input inputField__input-first"
                            onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
                        >
                            <option value=""></option>
                            {getSelectOptions('method')}
                        </Field>
                    </div>
                    <div className={!values.status.includes('error') ? 'inputField medium' : 'inputField long'} style={{ position: 'relative' }}>
                        <label htmlFor="status">Status</label>
                        <Field type="text" id="status" name="status" readOnly className="inputField__input inputField__input-first"
                            onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
                        >
                        </Field>
                    </div>
                    <div className={'inputField medium ' + ((getError('due_on')) ? 'formRed' : '')}>
                        <label htmlFor="due_on">Due On</label>
                        <Field
                            name="due_on"
                            component={DatePicker}
                            valueType="epoch"
                            disabled={true}
                            editMode={editMode}
                            values={values}
                            setFieldValue={setFieldValue}
                        />
                    </div>
                    <div className={'inputField medium ' + ((getError('sent_on')) ? 'formRed' : '')}>
                        <label htmlFor="sent_on">Sent On</label>
                        <Field
                            name="sent_on"
                            component={DatePicker}
                            valueType="epoch"
                            disabled={true}
                            editMode={editMode}
                            values={values}
                            setFieldValue={setFieldValue}
                        />
                    </div>
                    {data?.sent_by?.email !== '' &&
                        <div className={'inputField long'}>
                            <label htmlFor="sent_by">Sent By</label>
                            <Field
                                name="sent_by"
                                type="text"
                                readOnly
                                disabled={true}
                            />
                        </div>
                    }
                    {/*<div className={'inputField long ' + ((getError('notes')) ? 'formRed' : '')}>
                        <label htmlFor="notes">Notes</label>
                        <Field component="textarea" rows="2" name="notes" autoComplete="off" className="inputField__input inputField__input-first"
                            onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
                        />
                    </div>*/}
                    {data.method !== 'email' &&
                        <div className={'inputField long ' + ((getError('tracking')) ? 'formRed' : '')} style={{ position: 'relative' }}>
                            <label htmlFor="tracking">Tracking Info</label>
                            <Field type="text" id="tracking" name="tracking" readOnly className="inputField__input inputField__input-first"
                                onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
                            >
                            </Field>
                        </div>
                    }
                </div>
                {
                    editMode && (
                        <div className="formButtonsBank">
                            <button className="medButtonPrimary" disabled={isSubmitting} type="submit">Save</button>
                            <button className="medButtonSecondary" type="button" onClick={() => cancelAction()}>Cancel</button>
                        </div>
                    )
                }
            </fieldset>
        </Form>
    );
};

const CommLogContactDetailsForm = withFormik({
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    mapPropsToValues(props) {
        let values = {};
        if (props.data) {
            values = {
                method: (props.data.method ? props.data.method : ''),
                sent_on: (props.data.sent_on ? props.data.sent_on === "0" ? null : props.data.sent_on : ''),
                sent_by: (props.data.sent_by?.email ? props.data.sent_by.email : ''),
                due_on: (props.data.due_on ? props.data.due_on : ''),
                status: (props.data.status ? props.data.status === 'error' ? `${props.data.status} - ${props.data.error}` : props.data.status : ''),
                notes: (props.data.notes ? props.data.notes : ''),
                tracking: (props.data.tracking ? props.data.tracking : ''),
                name: (props.data['info'] && props.data['info'].firstname ? props.data['info'].lastname !== undefined ? `${props.data['info'].firstname} ${props.data['info'].lastname}` : `${props.data['info'].firstname}` : ''),
                email: (props.data['info'] && props.data['info'].email ? props.data['info'].email : ''),
            };
        }

        return values;
    },

    validationSchema: Yup.object().shape({
        method: Yup.string().required('Method is required'),

    }),

    handleSubmit(values, { props, setSubmitting }) {

        Object.keys(values).forEach((key) => (values[key] == '') && delete values[key]);

        props.saveDetails(null, props.data.communication_type, values);
        setSubmitting(true);
        setTimeout(() => {
            setSubmitting(false);
        }, 3000);
    }
})(FormLayout);

export default CommLogContactDetailsForm; 
