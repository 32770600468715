import React from "react";
import { FormControl, FormHelperText, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import helperTextStyles from "../../../../styles/common/matieralUI/helperText.module.css";

const InputField = ({ label, type, suggestions, value, setValue, error, disabled }) => {
    // Some API endpoints require "number" fields to actually be a numeric value, so parse strings
    const parseValue = (val) => (type === "number" ? parseInt(val) : val);

    return (
        <FormControl error={!!error}>
            {suggestions?.length ? (
                <Autocomplete
                    freeSolo
                    options={suggestions}
                    value={value ?? ""}
                    onChange={(_, newValue) => setValue(parseValue(newValue))}
                    renderInput={(params) => <TextField {...params} label={label} variant="outlined" error={!!error} />}
                    disableClearable
                />
            ) : (
                <TextField
                    label={label}
                    variant="outlined"
                    type={type}
                    value={value ?? ""}
                    onChange={(event) => setValue(parseValue(event.currentTarget.value))}
                    error={!!error}
                    disabled={disabled}
                />
            )}
            {error && <FormHelperText classes={helperTextStyles}>{error}</FormHelperText>}
        </FormControl>
    );
};

export default InputField;
