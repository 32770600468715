import React from 'react';
import { Responsive as ResponsiveGridLayout } from 'react-grid-layout';
import ChartWidget from './ChartWidget';
import DataWidget from './DataWidget';
import TableWidget from './TableWidget';
import EmbedWidget from './EmbedWidget';
import { withSize } from 'react-sizeme';
import moment, * as Moment from 'moment';
import AddIcon from '@material-ui/icons/Add';
import { Tooltip } from '../Tooltip';
import { SizeMe } from 'react-sizeme';

const initialLayout = {
	lg: [{ i: 'Organization Counts', w: 4, h: 2, x: 0, y: 0 },
	{ i: 'Compliance Due Count', w: 4, h: 2, x: 0, y: 1 },
	{ i: 'Non-Compliant Assemblies', w: 8, h: 4, x: 5, y: 0 },
	{ i: 'Incomplete Assemblies', w: 8, h: 4, x: 0, y: 5 },
	{ i: 'Current Month Compliance Rate', w: 4, h: 2, x: 9, y: 5 },
	{ i: 'Current Year Compliance Rate', w: 4, h: 2, x: 9, y: 6 },
	{ i: 'Assembly Types', w: 4, h: 2, x: 0, y: 10 },
	{ i: 'Overall Compliance Rate', w: 4, h: 2, x: 0, y: 9 },
	{ i: 'Incomplete Locations', w: 8, h: 4, x: 5, y: 9 }
	]
}

class DashboardGridLayout extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			layouts: initialLayout,
			pauseDraggable: false
		};
	}

	componentDidMount() {
		if (!this.props.default) {
			this.setState(() => ({ layouts: {} }))
		}
	}

	onLayoutChange = (layout, layouts) => {
		this.setState({ layout, layouts })
		this.props.sendLayout(layout)
	}

	onDirtyLayout = () => {
		this.props.sendLayout(null, 'dirty')
	}

	renderWidgets = () => {
		// const filteredWidgets = this.props.activeWidgets.filter(widget => widget.type !== 'deleted')
		const layout = this.props.activeWidgets.map((widget, idx) => {
			let component, minW, minH;
			let positionProps = {};
			let componentProps = {};

			componentProps['layout'] = this.state.layout;
			componentProps['position'] = idx;
			componentProps['addWidget'] = this.props.addWidget;
			componentProps['deleteWidget'] = this.props.deleteWidget;
			componentProps['editMode'] = this.props.editMode;
			componentProps['updateWidget'] = this.props.updateWidget;
			componentProps['resetDashboard'] = this.props.resetDashboard;
			componentProps['toggleDraggablePause'] = this.toggleDraggablePause;

			if (widget.type === 'data') {
				component =
					<DataWidget
						{...widget}
						{...componentProps}
					/>;
				minW = 3;
				minH = 2;
			} else if (widget.type === 'table') {
				component =
					<TableWidget
						{...widget}
						{...componentProps}
					/>;
				minW = 3;
				minH = 2;
			} else if (widget.type === 'chart') {
				component =
					<SizeMe
						monitorHeight
						render={({ size }) => <ChartWidget
							default={this.props.default}
							size={size}
							{...widget}
							{...componentProps}
						/>} />;
				minW = 3;
				minH = 2;
			} else if (widget.type === 'embed') {
				component =
					<EmbedWidget
						{...widget}
						{...componentProps}
					/>;
				minW = 3;
				minH = 2;
			} else {
				component = null;
			}

			if (!this.props.default && widget.coordinates && !widget.staged) {

				positionProps['data-grid'] = {

					x: parseInt(widget.coordinates['x1']),
					y: parseInt(widget.coordinates['y1']),
					w: parseInt(widget.coordinates['x2']) - parseInt(widget.coordinates['x1']) + 1,
					h: parseInt(widget.coordinates['y2']) - parseInt(widget.coordinates['y1']) + 1,
					minW: minW,
					minH: minH
				}
			} else if (widget.staged) {
				positionProps['data-grid'] = {

					x: widget.coordinates['x1'],
					y: widget.coordinates['y1'],
					w: widget.coordinates['x2'],
					h: widget.coordinates['y2'],
					minW: minW,
					minH: minH
				}
			}

			return (
				<div
					key={this.props.default ? widget.title : idx}
					{...positionProps}
					className={this.props.editMode ? "widget__wrapper widget__wrapper-editMode" : 'widget__wrapper'}
				>
					{component}
				</div>
			)
		})
		return layout
	}

	toggleDraggablePause = (type) => {
		if (type === 'on') {
			this.setState(() => ({ pauseDraggable: true }));
		} else if (type === 'off') {
			this.setState(() => ({ pauseDraggable: false }));
		}
	}

	render() {
		const { size } = this.props;

		return (
			<div>
				<ResponsiveGridLayout
					className="layout"
					cols={{ lg: 12, md: 12, sm: 2, xs: 2, xxs: 2 }}
					rowHeight={140}
					containerPadding={[30, 30]}
					margin={[20, 20]}
					onLayoutChange={(layout, layouts) => this.onLayoutChange(layout, layouts)}
					layouts={this.state.layouts}
					isResizable={this.props.editMode}
					// resizeHandles={['s', 'e', 'se']}
					useCSSTransforms={false}
					width={size.width}
					isDraggable={!this.props.editMode || this.state.pauseDraggable ? false : true}
					padding={10, 10}
					// compactType={null}
					onDragStop={this.onDirtyLayout}
					onResizeStop={this.onDirtyLayout}
				>
					{this.renderWidgets()}
				</ResponsiveGridLayout>
			</div>
		);
	}

}

export default withSize()(DashboardGridLayout);