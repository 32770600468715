import React from "react";

import { DialogTitle, Dialog, DialogContent, DialogActions } from "@material-ui/core";

export const DialogContainer = ({
    open,
    handleClose,
    title,
    error = {},
    action = {},
    closeText = "Close",
    children,
}) => {
    const { showError, errMsg } = error;
    const { actionMsg, actionCb } = action;
    return (
        <Dialog
            open={open}
            aria-labelledby="form-dialog-title"
            className="widget__dialog"
            id="dialogTable"
            onClose={handleClose}
        >
            {title && <DialogTitle>{title}</DialogTitle>}
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                <div className="dialogActions__wrapper">
                    {errMsg && <span className="dialogActions__errorMessage">{errMsg}</span>}
                    {actionMsg && (
                        <button className="medButtonPrimary" onClick={actionCb}>
                            {actionMsg}
                        </button>
                    )}
                    <button className="medButtonSecondary" onClick={handleClose}>
                        {closeText}
                    </button>
                </div>
            </DialogActions>
        </Dialog>
    );
};

export default DialogContainer;
