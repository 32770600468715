import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { getPathname, getURLParentObject, getURLModule } from "../../../utils/url";
import { getSampleReportConfig } from "../../../api/analytesAPI";
import { BasicSampleReportConfigFields } from "./BasicSampleReportConfigFields";
import Page from "../../Common/Page";
import Card from "../../Common/Card";
import SummaryGrid from "../../Common/Forms/SummaryGrid";
import EditSampleReportConfig from "./EditSampleReportConfig";
import BasicReportTable from "../../Common/ReportTable/BasicReportTable";
import useGeneralControls from "../GeneralControls/useGeneralControls";
import { VerifiedUserRounded } from "@material-ui/icons";

const ViewSampleReportConfig = ({ history }) => {
    const { id: sampleReportConfigId } = getURLParentObject("src") || {};
    const pathname = getPathname();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [reportData, setReportData] = useState({});
    const { loading: generalControlLoading } = useGeneralControls();
    const { slug } = getURLModule();

    const refreshSampleReportConfig = () => {
        setLoading(true);
        setError(null);

        getSampleReportConfig(sampleReportConfigId)
            .then(setReportData)
            .catch(setError)
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        refreshSampleReportConfig();
    }, []);

    const onEditComplete = () => {
        setEditMode(false);
        refreshSampleReportConfig();
    };

    if (editMode && !loading && !error) {
        return (
            <EditSampleReportConfig
                preloadedBasicState={reportData}
                onEditComplete={onEditComplete}
                onEditCancelled={() => setEditMode(false)}
            />
        );
    }

    const notShownOnSummary = ["name", "general_control_uuid", "control_type"];
    const fields = BasicSampleReportConfigFields.filter((f) => !notShownOnSummary.includes(f.id));

    return (
        <Page loading={loading || generalControlLoading} error={error}>
            <Card title={reportData.name || "Unnamed Sample Report Config"} onEdit={() => setEditMode(true)}>
                <SummaryGrid
                    fields={fields}
                    formState={reportData}
                    arrayIcons={{ compliance_determinants: <VerifiedUserRounded /> }}
                />
            </Card>
            <Card
                title="Sampling Configuration"
                onCreate={() =>
                    history.push({
                        pathname: `${pathname}/addSamplingConfig`,
                        state: { reportConfigData: reportData },
                    })
                }
                onCreateLabel="New Sampling Configuration"
            >
                <BasicReportTable
                    reportUUID="6a8542cb-6309-4da1-a06f-55958e4ca036"
                    inputs={{ "Sample Report Config Id": sampleReportConfigId }}
                    visibleColumns={[
                        "Sampling Config Name",
                        "Analytes",
                        "Lab Methods",
                        "Sample Type",
                        "Pacing",
                        "Notes",
                    ]}
                    rowLinks={(row) => `${pathname}/sc/${row["Sampling Config ID"]}`}
                />
            </Card>
            {reportData.assigned_to === 'property' &&
                <Card title={"Enforceability"}>
                    <BasicReportTable
                        reportUUID="33bbab41-7bba-4acb-b743-af3c829faa75"
                        visibleColumns={["Status", "Legal Control Name", "Effective Date", "Expiration Date"]}
                        inputs= {{ "sample report config id": sampleReportConfigId }}
                        rowLinks={
                            (row) => row["Legal Control UUID"] ? 
                            `/${slug}/p/${row["Legal Control UUID"]}` : 
                            `/${slug}/cfg/gc/${row["General Control UUID"]}`}
                    />
                </Card>
            }
        </Page>
    );
};

export default withRouter(ViewSampleReportConfig);
