import React, { useState } from "react";
import { ScheduleFieldInput } from "../../Common/Forms/Fields/ScheduleField";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import Button from "../../Common/Button";
import Form from "../../Common/Forms/Form";

const SamplingScheduleField = ({ label, value, setValue, error }) => {
    const [showDialog, setShowDialog] = useState(false);
    const [dialogState, setDialogState] = useState({
        frequency: "weekly",
        interval: 1,
        stop_after: null,
        enable_stop_after: false,
    });

    return (
        <>
            <ScheduleFieldInput
                label={label}
                value={
                    value && {
                        schedule_type: "rep",
                        ...value
                    }
                }
                setValue={setValue}
                error={error}
                onClick={() => setShowDialog(true)}
            />
            <Dialog open={showDialog}>
                <DialogTitle>{label}</DialogTitle>
                <DialogContent>
                    <Form
                        fields={[
                            {
                                id: "frequency",
                                label: "Frequency",
                                inputType: "select",
                                options: [
                                    { label: "Daily", value: "daily" },
                                    { label: "Weekly", value: "weekly" },
                                    { label: "Monthly", value: "monthly" },
                                    { label: "Annually", value: "annually" },
                                ],
                                required: true,
                            },
                            {
                                id: "interval",
                                label: "Interval",
                                dataType: "numeric",
                                required: true,
                            },
                            {
                                id: "stop_after",
                                label: "# of occurrences",
                                dataType: "numeric",
                                toggle: { behavior: "enable_field" },
                            },
                        ]}
                        formState={dialogState}
                        onFormStateChange={setDialogState}
                    />
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={() => setShowDialog(false)}>
                        Cancel
                    </Button>
                    <Button
                        color="primary"
                        onClick={() => {
                            setValue(dialogState);
                            setShowDialog(false);
                        }}
                    >
                        Set schedule
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default SamplingScheduleField;
