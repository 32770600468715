import React from 'react';
import { NavLink } from 'react-router-dom';
import BallotIcon from '@material-ui/icons/Ballot';
import Flyout from '../Flyout';
import { updateURL } from '../../utils/url';
import validator from 'validator';

import ReportTable from '../ReportTable/ReportTable';

class SurveysDashboard extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			flyoutActive: false,
			complianceReportUUID: null,
			complianceReportInfo: {}
		};

		this.table = React.createRef();

		const urlParts = this.props.location.pathname.split("/");
		if (urlParts.length >= 3 && validator.isUUID(urlParts[2])) {
			this.state.complianceReportUUID = urlParts[2];
		}
	}

	async componentDidMount() {
		if (this.state.complianceReportUUID !== null) {
			this.openFlyout({ compliance_report_uuid: this.state.complianceReportUUID }, false);
		}
	}

	openFlyout = async (complianceReportInfo) => {
		const overlay = document.getElementById('overlay');

		complianceReportInfo = {
			compliance_report_uuid: complianceReportInfo.compliance_report_uuid
		};

		this.setState(() => ({ complianceReportInfo, flyoutActive: true }));
		// update the url in the browser without causing any reloads
		updateURL('/surveys/' + complianceReportInfo.compliance_report_uuid, {});

		overlay.classList.add('overlay__visible');
	}

	closeFlyout = () => {
		const flyout = document.getElementById('flyout');
		const overlay = document.getElementById('overlay');

		flyout.classList.remove('slideToVisible');
		overlay.classList.remove('overlay__visible');

		setTimeout(() => {
			this.setState(() => ({
				flyoutActive: false,
				complianceReportInfo: {},
				complianceReportUUID: null
			}));
		}, 200);

		this.table.current.ParentEventHandler('flyout closed', { path: '/surveys' });
	}

	render() {
		const { reportOutput, complianceReportInfo, flyoutActive } = this.state;
		return (
			<div className="componentContainer">
				<div className="componentHeader">
					<div className="flexAlignCenter">
						<span>
							<BallotIcon fontSize="inherit" />
						</span>
						<h3>Surveys</h3>
					</div>
					<NavLink to='/surveys/newSurvey'>
						<button className="longButtonPrimary">New Survey</button>
					</NavLink>
				</div>
				<ReportTable
					ref={this.table}
					page='surveys'
					tableType='Surveys'
					openFlyout={this.openFlyout}
					flyoutActive={flyoutActive}
					defaultReportIdx='0'
					reports={[
						{
							reportUUID: "4d7171d2-e261-4067-8698-cc02779d9fe8",
							name: "Location Surveys",
							columns: ['Result', 'Status', 'Location Name', 'Address', 'City']
						}
					]}
				/>
				<div className="dashboardIconLarge dashboardIconLarge__muiIcon">
					<BallotIcon fontSize="inherit" />
				</div>
				<div className="overlay" id="overlay" onClick={() => { this.closeFlyout() }}>
				</div>
				{flyoutActive && (
					<React.Fragment>
						<Flyout
							uuid={complianceReportInfo.compliance_report_uuid}
							newAddition={false}

							flyoutType='surveys'
							flyoutPriority="primary"
							closeFlyout={this.closeFlyout}
							openFlyout={this.openFlyout}
							activeTab='testReportDetails'

							refresh={this.updateTestReportsList}
						/>
					</React.Fragment>
				)}
			</div>
		);
	}
}

export default SurveysDashboard;