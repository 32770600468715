import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from '@material-ui/pickers';
import { format } from 'date-fns';
import moment from 'moment';

const useStyles = makeStyles({
	root: {
		display: 'block'
	},
	input: {
		fontSize: '1.8rem'
	}
});

const DatePicker = ({ field, form, setFieldValue, editMode, valueType, value, name, type, disablePast, disableFuture }) => {
	const classes = useStyles();

	let initialDate;
	if (value) {
		initialDate = value;
	} else if (field && field.value) {
		initialDate = field.value
	} else {
		initialDate = null
	}
	if (initialDate && !initialDate.toString().includes("-") && initialDate.toString().length <= 10) {
		// moment wants shit in ms
		initialDate *= 1000;
	}
	const [selectedDate, setSelectedDate] = useState(initialDate);
	const [isOpen, setIsOpen] = useState(false);

	useEffect(() => {
		if (field.value && !field.value.toString().includes("-")) {
			if (field.value.toString().length <= 10) {
				if (field.value.toString() === "0") {
					setSelectedDate(null)
				} else {
					setSelectedDate(field.value * 1000)
				}
			}
		} else if (field.value === null) {
			setSelectedDate(null)
		}
	}, [field])

	useEffect(() => {
		if (type === 'noTextInput') {
			setSelectedDate(value)
		}
	}, [value])

	const handleDateChange = (date) => {
		if (date == null) {
			setFieldValue(field.name, null);
			setSelectedDate(null);
			return;
		}
		// set our date object to midnight local time since we only care about the day not the hour / min / sec
		date.setHours(0);
		date.setMinutes(0);
		date.setSeconds(0);
		// if (data) due to issue of when date = null this errors.
		setIsOpen(false)
		if (date) {
			if (valueType === "epoch") {
				setSelectedDate(date);
				if (!field) {
					setFieldValue(name, date.getTime() / 1000)
				} else {
					setFieldValue(field.name, date.getTime() / 1000);
				}
			} else {
				let parsed = moment(date).startOf('day');
				setSelectedDate(parsed);
				if (parsed.valueOf()) {
					setFieldValue(field.name, format(date, 'yyyy-MM-dd'));
				}
			}
		}
	};

	let widgetProps = {};

	if (type === 'noTextInput') {
		widgetProps.open = isOpen;
		widgetProps.KeyboardButtonProps = {
			onFocus: e => {
				setIsOpen(true);
			}
		}
		widgetProps.PopoverProps = {
			disableRestoreFocus: true,
			onClose: () => {
				setIsOpen(false);
			}
		}
		widgetProps.InputProps = {
			onFocus: () => {
				setIsOpen(true);
			}
		}
	}


	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
			<KeyboardDatePicker
				format="yyyy-MM-dd"
				emptyLabel="YYYY-MM-DD"
				className={classes.root}
				inputProps={{ className: classes.input, style: { borderBottom: 'none' } }}
				disableToolbar
				autoOk
				inputVariant="outlined"
				variant="inline"
				disabled={!editMode}
				name={field ? field.name : null}
				disablePast={disablePast ? true : false}
				disableFuture={disableFuture ? true : false}
				value={selectedDate}
				onChange={handleDateChange}
				KeyboardButtonProps={{
					'aria-label': 'change date',
				}}
				{...widgetProps}
			/>
		</MuiPickersUtilsProvider>
	);
};

export default DatePicker;