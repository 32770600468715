import React from 'react';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { ClickAwayListener } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import { Tooltip } from './Tooltip';

const styles = {
	checkboxLabel: {
		fontSize: '1.6rem'
	},
	modalDialogRoot: {
		display: 'grid'
	}
};

class TableOptions extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			dropdownOpen: false,
			checked: {}
		};

		props.outputs.forEach((output) => {
			this.state.checked[output.name] = (this.props.defaultColumns.length === 0 || this.props.defaultColumns.includes(output.name)) ? true : false;
		});
	}

	componentDidUpdate = (prevProps) => {
		let { additionalDetails, outputs } = this.props;
		let checked = {};
		let detailsList = [];

		if (this.props.outputs !== prevProps.outputs) {

			// We are putting together an array of detail labels to be able to easily differentiate between regular and additional details outputs.
			// This is so by default if there are no user preferences set regular options will be checked by default and additonal options will be unchecked.
			for (const type in additionalDetails) {
				for (const detail in additionalDetails[type]) {
					outputs.forEach((output) => {
						if (output.name === additionalDetails[type][detail].label) {
							detailsList.push(output.name)
						}
					})
				}
			}

			this.props.outputs.forEach((output) => {
				if (detailsList.includes(output.name)) {
					if (this.props.defaultColumns.length === 0) {
						checked[output.name] = false;
					} else if (this.props.defaultColumns.includes(output.name)) {
						checked[output.name] = true;
					}
				} else {
					checked[output.name] = (this.props.defaultColumns.length === 0 || this.props.defaultColumns.includes(output.name)) ? true : false;
				}
			});
			this.setState(() => ({
				checked
			}))
		}
	}

	handleChange = (output) => {
		let checked = this.state.checked;
		checked[output.name] = !checked[output.name];
		this.setState({ checked });
	}

	displayOptions = () => {
		const { classes, outputs, additionalDetails } = this.props;
		let detailsList = [];
		let options = [];

		// Make a list of possible additional details and put them in an array so we can ignore them when creating our "normal" options.
		if (Object.keys(additionalDetails) !== 0) {
			for (const type in additionalDetails) {
				for (const detail in additionalDetails[type]) {
					detailsList.push(additionalDetails[type][detail].label)
				}
			}
		}

		outputs.forEach((output) => {

			if (!output.name.includes('UUID') && output.name !== 'Actions' && !detailsList.includes(output.name)) {
				let option = <FormControlLabel
					key={output.key ? output.key : output.name}
					classes={{
						label: classes.checkboxLabel
					}}
					control={
						<Checkbox
							checked={this.state.checked[output.name]}
							onChange={() => { this.handleChange(output); }}
							value={output.name}
							color='primary'
						/>
					}
					label={output.name}
				/>
				options.push(option);
			}
		});

		return options;
	}

	displayAdditionalDetailsOptions = () => {
		const { classes, outputs, additionalDetails } = this.props;
		let options = {};

		if (Object.keys(additionalDetails) !== 0) {

			for (const type in additionalDetails) {
				options[type] = []
				for (const detail in additionalDetails[type]) {
					outputs.forEach((output) => {
						if (output.name === additionalDetails[type][detail].label) {
							let option = <FormControlLabel
								key={output.key ? output.key : output.name}
								classes={{
									label: classes.checkboxLabel
								}}
								control={
									<Checkbox
										checked={this.state.checked[output.name]}
										onChange={() => { this.handleChange(output); }}
										value={output.name}
										color='primary'
									/>
								}
								label={output.name}
							/>
							options[type] = [...options[type], option]
						}
					})
				}
			}
		}

		const detailOptions = Object.keys(options).map((type, idx) => {
			let sectionHeader = '';
			if (type === 'equipment') {
				sectionHeader = 'Equipment Details'
			} else if (type === 'property') {
				sectionHeader = 'Location Details'
			} else if (type === 'contact') {
				sectionHeader = 'Contact Details'
			} else if (type === 'service_provider') {
				sectionHeader = 'Service Provider Details'
			} else if (type === 'compliance_report') {
				sectionHeader = 'Report Details'
			}

			if (options[type].length > 0) {
				return (
					<div className="sectionHeader" key={idx}>
						<span className="sectionHeader__sectionTitle">{sectionHeader}</span>
						{options[type]}
					</div>
				)
			}
		});

		return detailOptions;

	}

	toggleDropdown = (type) => {
		const picker = document.getElementsByClassName('MuiPopover-root');
		if (type === 'close') {
			if (picker.length) {
				return null;
			} else {
				this.setState(() => ({ dropdownOpen: false }));
			}
		} else {
			this.setState((prevState) => ({ dropdownOpen: !prevState.dropdownOpen }));
		}
	}

	handleSave = () => {
		let columns = [];
		// use the outputs so we keep things in the same order for now
		this.props.outputs.forEach((output) => {
			if (this.state.checked[output.name]) {
				columns.push(output.name);
			}
		});
		this.props.setTableOptions({ columns });
		this.toggleDropdown('close');
	}

	render() {
		const { dropdownOpen } = this.state;
		return (
			<div id="tableOptions">
				<ClickAwayListener onClickAway={() => this.toggleDropdown('close')}>
					<div className={dropdownOpen ? 'tableFilter__wrapper openBorderRadius slideDown' : 'tableFilter__wrapper'}>
						<div className='tableFilter'>
							<button className="filterButton" onClick={() => this.toggleDropdown()}>
								{!dropdownOpen && <Tooltip text="Table Options" noIcon={true} position="top" />}
								<SettingsIcon fontSize="inherit" />
							</button>
						</div>
						{dropdownOpen && (
							<div className='tableFilter__dropdownContent-wrapper'>
								<div className='tableFilter__dropdownContent'>
									<div className='tableFilter__content'>
										{this.displayOptions()}
										{this.displayAdditionalDetailsOptions()}
									</div>
									<div className='tableFilter__buttons'>
										<button className="smallButtonPrimary" onClick={this.handleSave}>save</button>
										<button className="smallButtonSecondary" onClick={() => this.toggleDropdown('close')}>close</button>
									</div>
								</div>
							</div>
						)}
					</div>
				</ClickAwayListener>
			</div>
		);
	}
}

export default withStyles(styles)(TableOptions);