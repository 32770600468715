import React, { useState, useEffect } from 'react';
import NotificationsRoundedIcon from '@material-ui/icons/NotificationsRounded';
import OrgNotifications from './OrgNotifications';
import UserNotifications from './UserNotifications';

const NotificationsDashboard = (props) => {

    const [userType, setUserType] = useState(null);

    useEffect(() => {
        const userData = SwiftComply.user;
        setUserType(userData.user_type);
    }, [])

    return (
        <div className={props.widget ? "componentContainerWidget" : "componentContainer"} id="notifications">
            {!props.widget &&
                <React.Fragment>
                    <div className="componentHeader">
                        <div className="flexAlignCenter">
                            <NotificationsRoundedIcon style={{ fontSize: '2rem', marginRight: '1rem' }} />
                            <h3>Notifications</h3>
                        </div>
                    </div>
                </React.Fragment>
            }
            <div className={props.widget ? null : "mainTableContainer"} id={userType === 'service provider' ? "userNotifications" : "orgNotifications"}>
                {userType && (userType === "service provider") &&
                    <UserNotifications widget={props.widget} />
                }
                {userType && (userType !== 'service provider') &&
                    <OrgNotifications widget={props.widget} />
                }
            </div>
            {!props.widget &&
                <div className="dashboardIconLarge">
                    <NotificationsRoundedIcon fontSize="inherit" color="inherit" />
                </div>
            }
        </div>
    )
};

export default NotificationsDashboard;