import React, { useState, useEffect } from "react";
import { Snackbar as MuiSnackbar, SnackbarContent } from "@material-ui/core";
import { CheckCircleRounded, CloseRounded, ErrorRounded, InfoRounded, WarningRounded } from "@material-ui/icons";
import styles from "../../styles/common/snackbar.module.css";

/**
 * Displays an alert message floated above the page content (see Material-UI Snackbar)
 *
 * @param {string|JSX.Element} [message] - Text to display, null or undefined to hide snackbar
 * @param {"error"|"warning"|"success"|"info"} [severity] - Sets the color and icon in the alert
 * @param [duration] - Time in milliseconds to display the alert
 * @param [onClose] - Called when closed
 */
const Snackbar = ({ message, onClose, duration = 5000, severity = "error" }) => {
    const [open, setOpen] = useState(false);
    const { icon: Icon, cssClass } = {
        error: { icon: ErrorRounded, cssClass: styles.error },
        warning: { icon: WarningRounded, cssClass: styles.warning },
        success: { icon: CheckCircleRounded, cssClass: styles.success },
        info: { icon: InfoRounded, cssClass: styles.info },
    }[severity];

    useEffect(() => {
        if (message) {
            setOpen(true);
        }
    }, [message]);

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
        onClose?.();
    };

    return (
        <MuiSnackbar
            anchorOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            open={open}
            autoHideDuration={duration}
            onClose={handleClose}
        >
            <SnackbarContent
                classes={{ root: `${styles.snackbar} ${cssClass}` }}
                message={
                    <div className={styles.message}>
                        <Icon />
                        {message}
                        <CloseRounded onClick={handleClose} />
                    </div>
                }
            />
        </MuiSnackbar>
    );
};

export default Snackbar;
