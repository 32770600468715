/**
 * Converts form state to permit data structure required by the API.
 *
 * @param {Object} formState - The current state of the form
 * @param {Object} templateData - The template data for the permit
 * @param {Object} tableSelections - The current selections in the table
 * @param {Object} recurrenceData - The recurrence data to be associated with the permit
 * @returns {Object} The converted permit data ready for API submission
 */
export const convertFormStateToPermitData = (formState, templateData, tableSelections, recurrenceData) => {
    // Initialize the converted data object with existing template data
    const convertedData = {
        legal_control_type: formState.legal_control_type || "permit",
        template_uuid: formState.template_uuid,
        template_data: {
            data_entry: (templateData?.data_entry || []).map((entry) => ({
                name: entry.name,
                label: entry.label,
                template_string: entry.template_string,
                type: entry.type,
                value: formState[entry.template_string] || "",
            })),
            document_fields: (templateData?.document_fields || []).map((field) => ({
                name: field.name,
                label: field.label,
                template_string: field.template_string,
                type: field.type,
                value: formState[field.template_string] || "",
            })),
            system_fields: Object.fromEntries(
                Object.entries(templateData?.system_fields || {}).map(([key, fields]) => [
                    key,
                    (fields || []).map((field) => ({
                        name: field.name,
                        label: field.label,
                        template_string: field.template_string,
                        type: field.type,
                        value: formState[field.template_string] || "",
                    })),
                ])
            ),
            system_uuids: {
                property_uuid: formState.industrial_user,
            },
            form_data: {
                ...formState,
                tableSelections: tableSelections,
            },
            reports: [],
            association_data: {
                sample_report_with_sampling_config_iu: recurrenceData,
            },
        },
    };

    // Iterate over reports to construct inputs, active rows, and association data
    (templateData?.reports || []).forEach((report) => {
        const isTableTypePivot = report.table_type === "pivot-table";
        const reportState = formState[report.report_uuid]?.[report.key];
        const reportRows = reportState?.rows || [];
        let inputs = null;

        if (isTableTypePivot) {
            // For pivot tables, construct inputs based on unselected rows
            inputs = reportRows
                .filter((_, index) => !tableSelections[report.key]?.[index])
                .map((row) => {
                    const sampleReportConfigIdIndex = reportState?.outputs?.findIndex(
                        (output) => output.name === "Sample Report Config ID"
                    );
                    const sampleReportConfigID = row.values[sampleReportConfigIdIndex];
                    return {
                        "Property UUID": formState.industrial_user,
                        "Sample Report Config ID": sampleReportConfigID,
                        "Assigned To": "Industry",
                    };
                });
        } else {
            // For non-pivot tables, construct inputs based on required input keys
            inputs = {};
            report.required_input_keys?.forEach((key) => {
                if (key === "Property UUID") {
                    inputs[key] = formState.industrial_user;
                } else if (key === "Effective Date") {
                    inputs[key] = formState["EffectiveDate"];
                } else if (key === "Expiration Date") {
                    inputs[key] = formState["ExpirationDate"];
                } else {
                    inputs[key] = formState[key] || "";
                }
            });
            inputs = [{ ...inputs, ...report.input_baseline }];
        }

        // For non-pivot tables, construct active rows based on unselected rows
        const activeRows = isTableTypePivot
            ? null
            : reportRows
                  ?.filter((_, index) => !tableSelections[report.key]?.[index])
                  .map((row) =>
                      report.row_key_label?.map((key) => {
                          const keyIndex = reportState?.outputs?.findIndex((output) => output.name === key);
                          return row.values[keyIndex];
                      })
                  );

        // Add the report to the template_data.reports array
        convertedData.template_data.reports.push({
            report_uuid: report.report_uuid,
            label: report.label,
            template_string: report.template_string,
            key: report.key,
            active_rows: activeRows,
            inputs: inputs,
            visible_columns: report.visible_columns || [],
            table_type: report.table_type,
            row_key_label: report.row_key_label,
            required_input_keys: report.required_input_keys,
        });

        // Construct association_data for the current report
        if (isTableTypePivot) {
            const reportKey = report.key;
            const reportRecurrenceData = {};
            reportRows.forEach((row, index) => {
                if (!tableSelections[report.key]?.[index]) {
                    const sampleReportConfigIdIndex = reportState?.outputs?.findIndex(
                        (output) => output.name === "Sample Report Config ID"
                    );
                    const sampleReportConfigID = row.values[sampleReportConfigIdIndex];
                    if (recurrenceData[sampleReportConfigID]) {
                        // Include the recurrence data under the appropriate sampleReportConfigID
                        reportRecurrenceData[sampleReportConfigID] = recurrenceData[sampleReportConfigID];
                    }
                }
            });
            if (Object.keys(reportRecurrenceData).length > 0) {
                convertedData.template_data.association_data[reportKey] = reportRecurrenceData;
            }
        }
    });

    return convertedData;
};

/**
 * Constructs initial table selections based on template data and form state.
 *
 * @param {Object} templateData - The template data for the permit
 * @param {Object} initialFormState - The initial state of the form
 * @returns {Object} An object representing the initial table selections
 */
export const constructTableSelections = (templateData, initialFormState) => {
    const tableSelections = {};

    templateData.reports.forEach((report) => {
        const reportState = initialFormState[report.report_uuid]?.[report.key];
        const reportRows = reportState?.rows || [];

        if (report.table_type === "pivot-table") {
            const savedSelections = initialFormState.tableSelections?.[report.key];
            if (savedSelections) {
                tableSelections[report.key] = savedSelections;
            } else {
                const inputs = report.inputs || [];
                const sampleReportConfigIDs = inputs.map((input) => input["Sample Report Config ID"]);
                const sampleReportConfigIdIndex = reportState?.outputs?.findIndex(
                    (output) => output.name === "Sample Report Config ID"
                );

                tableSelections[report.key] = reportRows.map((row) => {
                    const rowSampleReportConfigUUID = row.values[sampleReportConfigIdIndex];
                    return sampleReportConfigIDs.includes(rowSampleReportConfigUUID) ? null : true;
                });
            }
        } else if (report.active_rows && report.row_key_label) {
            const savedSelections = initialFormState.tableSelections?.[report.key];
            if (savedSelections) {
                tableSelections[report.key] = savedSelections;
            } else {
                const activeRows = report.active_rows || [];
                const rowKeyLabels = report.row_key_label;

                tableSelections[report.key] = reportRows.map((row) => {
                    const rowUUIDs = rowKeyLabels.map((label) => {
                        const labelIndex = reportState?.outputs?.findIndex((output) => output.name === label);
                        return row.values[labelIndex];
                    });

                    const isActiveRow = activeRows.some((activeRow) =>
                        rowUUIDs.every((uuid, index) => activeRow[index] === uuid)
                    );

                    return isActiveRow ? null : true;
                });
            }
        }
    });

    return tableSelections;
};

export const constructRecurrenceData = (templateData) => {
    const recurrenceData = {};

    if (templateData?.association_data?.sample_report_with_sampling_config_iu) {
        const sampleReportData = templateData.association_data.sample_report_with_sampling_config_iu;

        Object.entries(sampleReportData).forEach(([sampleReportConfigID, scheduleData]) => {
            if (scheduleData.recurrence_config) {
                recurrenceData[sampleReportConfigID] = {
                    schedule_type: "rep",
                    frequency: scheduleData.recurrence_config.frequency,
                    interval: scheduleData.recurrence_config.interval,
                    start: scheduleData.recurrence_config.start,
                    until: scheduleData.recurrence_config.until,
                    timezone: scheduleData.recurrence_config.timezone,
                };
            } else if (scheduleData.ad_hoc_dates && scheduleData.ad_hoc_dates.length > 0) {
                recurrenceData[sampleReportConfigID] = {
                    schedule_type: "one",
                    ad_hoc_date: scheduleData.ad_hoc_dates[0],
                };
            }
        });
    }

    return recurrenceData;
};

/**
 * Handles and displays errors with a default message.
 *
 * @param {Error} error - The error object to handle
 * @param {string} defaultMessage - The default message to display if no specific error message is available
 */
export const handleError = (error, defaultMessage) => {
    if (error) {
        const message = defaultMessage + (error.response?.data?.Error || error.message || "An error occurred");
        const capitalizedMessage = message.charAt(0).toUpperCase() + message.slice(1);
        return {
            message: capitalizedMessage,
            severity: "error",
            duration: 5000,
        };
    }
    return null;
};

/**
 * Converts document fields from camelCase to snake_case and extracts their values.
 *
 * @param {Object} convertedFormState - The converted form state containing template data
 * @returns {Object} An object with snake_case keys and corresponding field values
 */
export const convertDocumentFieldsToSnakeCase = (convertedFormState) => {
    return convertedFormState.template_data.document_fields.reduce((acc, td) => {
        const snake = td.name
            .split(/(?=[A-Z])/)
            .map((s) => s.toLowerCase())
            .join("_");

        // Exclude effective_date and expiration_date
        if (snake !== "effective_date" && snake !== "expiration_date") {
            acc[snake] = td.value || null;
        }
        return acc;
    }, {});
};

/**
 * Maps field types to corresponding input types for form rendering.
 *
 * This helper function takes a field type and returns an object with the appropriate
 * input type and additional properties needed for rendering the form field correctly.
 *
 * @param {string} type - The type of the field to be mapped
 * @returns {Object} An object containing the inputType and additional properties if needed
 */
export const mapInputType = (type) => {
    switch (type) {
        case "string":
            return { inputType: "input" };
        case "textarea":
            return { inputType: "textarea" };
        case "numeric":
            return { inputType: "input", dataType: "numeric" };
        case "checkbox":
            return { inputType: "checkbox" };
        case "date":
            return { inputType: "date" };
        default:
            return { inputType: "input" };
    }
};
