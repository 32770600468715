import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import { getReport } from '../../api/reportingAPI';
import { getReportValue, reportRowToObject } from '../../utils/reporting';
import BasicTable from '../BasicTable';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import DialogTable from '../DialogTable';

const initialTableParams = {
    reportUUID: null,
    displayColumns: [],
    inputs: null,
    search: null,
    searchInputs: null
}

const CommsModal = (props) => {

    const [tableData, setTableData] = useState(null);
    const [location, setLocation] = useState(null);
    const [contacts, setContacts] = useState(props.newAddition ? [] : null);
    const [equipment, setEquipment] = useState([]);
    const [method, setMethod] = useState(null);
    const [tableParams, setTableParams] = useState(initialTableParams);
    const { reportUUID, displayColumns, inputs, search, searchInputs } = tableParams;

    useEffect(() => {
        getTableParams();
    }, [])

    useEffect(() => {
        getTableParams();
    }, [props.modalType])

    useEffect(() => {
        getTableParams();
    }, [props.logContacts])

    const cancelAction = () => {
        props.modalControls('close')
    }

    const getTableParams = () => {
        let reportUUID, displayColumns, inputs, search, searchInputs;

        if (props.modalType === 'location') {
            reportUUID = '0eba7876-ec72-465c-919f-35138aefa6c3';
            displayColumns = ['Account Number', 'Name', 'Address', 'Device Count', 'Primary Contact', 'Phone', 'Email'];
            searchInputs = ['name', 'address']
        } else if (props.modalType === 'contacts') {
            reportUUID = 'a3666110-7257-4ad2-b741-ddd73ed34e99';
            displayColumns = ['Name', 'Phone', 'Email', 'Address', 'City'];
            inputs = { inputs: { 'Property UUID': props.location.property_uuid } }
        } else if (props.modalType === 'equipment') {
            reportUUID = 'b54a82b3-ae76-4c06-94cb-8098bdbf4e56';
            displayColumns = ["Type", "Make", "Model", "Serial Number", "Last Test Date", "Compliant", "Equipment UUID"];
            inputs = { inputs: { 'active': 'null' } }
            search = { search: { 'property uuid': props.location.property_uuid } }
            searchInputs = ['serial number']
        }

        setTableParams({ reportUUID, displayColumns, inputs, search, searchInputs })
    }

    const getReportOutput = (reportOutput) => {
        if (props.logContacts && props.modalType === 'contacts') {
            props.logContacts.forEach((contact) => {
                reportOutput.rows.forEach((row, idx) => {
                    if (row.values[0] === contact.contact_uuid) {
                        reportOutput.rows.splice(idx, 1)
                    }
                })
            })

        }
        setTableData(reportOutput);
    }

    const saveSelection = (payload) => {
        if (props.modalType === 'location') {
            setLocation({ property_uuid: getReportValue(tableData, payload, 'Property UUID'), name: getReportValue(tableData, payload, 'Name') });
            setEquipment([]);
            setContacts([]);
        } else {
            if (props.modalType === 'contacts') {
                if (props.newAddition) {
                    setContacts(payload);
                } else {
                    setContacts({ contact_uuid: getReportValue(tableData, payload, 'Contact UUID'), name: getReportValue(tableData, payload, 'Name') });
                }
            } else if (props.modalType === 'equipment') {
                setEquipment(payload);
            }
        }
    }

    const sendData = (type, data) => {
        props.saveSelection(type, data);
        if (!props.newAddition && props.modalType === 'contacts') {
            setMethod(null);
            setContacts(props.newAddition ? [] : null);
        }
    }

    const setOptions = () => {
        let options;

        options = props.availableMethods.map((method, idx) => {
            return (
                <FormControlLabel key={`${idx} ${method}`} value={method} control={<Radio />} label={method.charAt(0).toUpperCase() + method.slice(1)} />
            )
        })

        return options;
    }

    let dataToSend, selected;
    let disabled = true;
    if (props.modalType === 'location') {
        dataToSend = location;

        if (location) {
            disabled = false;
        }
    } else if (props.modalType === 'contacts') {
        if (!props.newAddition) {
            dataToSend = { ...contacts, method: method }
            if ((contacts && method) || props.selectedContacts.length > 0) {
                disabled = false;
            }
        } else {
            selected = contacts;
            let list = [];
            contacts.forEach((contact) => {
                list.push({ contact_uuid: getReportValue(tableData, { values: contact.split(",") }, 'Contact UUID'), name: getReportValue(tableData, { values: contact.split(",") }, 'Name') })
            })
            dataToSend = list;

            if (contacts.length > 0 || props.selectedContacts.length > 0) {
                disabled = false;
            }
        }

    } else if (props.modalType === 'equipment') {
        selected = equipment;
        let list = [];
        equipment.forEach((equipment) => {
            list.push({ equipment_uuid: getReportValue(tableData, { values: equipment.split(",") }, 'Equipment UUID'), serial_number: getReportValue(tableData, { values: equipment.split(",") }, 'Serial Number') })
        })
        dataToSend = list;
        if (equipment.length > 0) {
            disabled = false;
        }
    }

    return (
        <Dialog
            open={props.modalOpen}
            onClose={() => props.modalControls('close')}
            className="widget__dialog"
            id='commsModal'
        >
            <DialogContent>
                <div className="widget__options">
                    <div>
                        <span>{`Choose ${props.modalType.charAt(0).toUpperCase() + props.modalType.slice(1)}`}</span>
                        <DialogTable
                            tableType={props.modalType.charAt(0).toUpperCase() + props.modalType.slice(1)}
                            openFlyout={saveSelection}
                            reportUUID={reportUUID}
                            columnsToShow={displayColumns}
                            searches={searchInputs}
                            inputs={inputs}
                            sendReportOutput={getReportOutput}
                            multiSelect={props.multiSelect}
                            search={search}
                            selected={selected}
                            count={props.count}
                        />
                    </div>
                    {props.modalType === 'contacts' && !props.newAddition &&
                        <div className="contactTypes">
                            <span>Select Contact Method</span>
                            <div style={{ padding: "2rem" }}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend"></FormLabel>
                                    <RadioGroup
                                        row
                                        aria-label="gender"
                                        name="row-radio-buttons-group"
                                        value={method}
                                        onChange={(e) => setMethod(e.target.value)}
                                    >
                                        {setOptions()}
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div>
                    }
                </div>
            </DialogContent>
            <DialogActions>
                <button className="smallButtonPrimary" disabled={disabled} onClick={() => sendData(props.modalType, dataToSend)}>Save</button>
                <button className="smallButtonSecondary" onClick={cancelAction}>Cancel</button>
            </DialogActions>
        </Dialog>
    );
}

export default CommsModal;