import React, { useEffect, useState } from "react";
import { getErrorText } from "../../../utils/errors";
import { defaultColumnFormats } from "../../../utils/columnFormats";
import { AddRounded, RemoveRounded, AssignmentRounded, FiberManualRecordRounded } from "@material-ui/icons";
import sampleIcon from "../../../../images/sampleIcon.svg";
import styles from "../../../styles/common/multiStepFormReportTable.module.css";
import LoadingSpinner from "../LoadingSpinner";
import Button from "../Button";
import { dateToAPIString } from "../../../utils/forms";
import SchedulingDialog from "../Forms/Fields/SchedulingDialog";

const defaultScheduleValue = {
    schedule_type: "rep",
    day_or_date: "date",
    interval: "1",
    day_of_week: "MO",
    nth: "1",
    start: dateToAPIString(new Date(new Date().getTime() + 24 * 60 * 60 * 1000)),
    until: dateToAPIString(new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000)),
    ad_hoc_date: dateToAPIString(new Date(new Date().getTime() + 24 * 60 * 60 * 1000)),
};

/**
 * Renders a simple report table for multi-step forms (no search, filters, or column selection)
 *
 * @param {string} reportUUID - The report to be fetched
 * @param {string[]} [visibleColumns] - Array of columns that are shown in the table
 * @param {Object} [columnFormats] - Object containing functions called when rendering each cell, can be used to alter contents
 * @param {Object} formState - The current form state
 * @param {Function} onFormStateChange - Callback function to update the form state in the parent component
 * @param {Object} tableSelections - Object containing table selection state
 * @param {Function} setTableSelections - Function to update table selection state
 * @param {Object} columnConfig - Object containing column configuration for table rendering
 * @param {boolean} showSamplingConfigDialog - Flag to control the visibility of the sampling config dialog
 * @param {Function} setShowSamplingConfigDialog - Function to update the visibility of the sampling config dialog
 * @param {Function} fetchAndSetReportData - Function to fetch and set report data
 * @param {Object} templateData - Template data for the report
 * @param {string} tableKey - Key to identify the table
 * @param {Object} recurrenceData - Object containing recurrence data
 * @param {Function} onRecurrenceDataChange - Callback function to update the recurrence data in the parent component
 */
const MultiStepFormReportTable = ({
    reportUUID,
    visibleColumns,
    columnFormats,
    formState,
    onFormStateChange,
    tableSelections,
    setTableSelections,
    columnConfig,
    onOpenSamplingConfigDialog,
    fetchAndSetReportData,
    templateData,
    tableKey,
    recurrenceData,
    onRecurrenceDataChange,
    onTableSelectionsChange,
}) => {
    const [data, setData] = useState({});
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedSampleReportConfigID, setSelectedSampleReportConfigID] = useState(null);
    const [showSchedulingDialog, setShowSchedulingDialog] = useState(false);
    const [selectedRowIndex, setSelectedRowIndex] = useState(null);

    const reportObject = templateData?.reports?.find(
        (report) => report.report_uuid === reportUUID && report.key === tableKey
    );

    useEffect(() => {
        if (!formState[reportUUID]?.[tableKey] && templateData) {
            fetchAndSetReportData(reportUUID, formState.industrial_user, tableKey);
        }
        // TODO: add "{{.DocumentFields.EffectiveDate.String}}" and "{{.DocumentFields.ExpirationDate.String}}" as dependencies to force reloads of the impacted reports.
        // May need to limit the refresh to impacted tables only (atm only limits).
    }, [reportUUID, formState.industrial_user, templateData, fetchAndSetReportData]);

    useEffect(() => {
        if (formState[reportUUID]?.[tableKey]) {
            setData(formState[reportUUID][tableKey]);
        }
    }, [formState, reportUUID, tableKey]);

    const isColumnVisible = (col) => {
        const reportObject = templateData?.reports?.find(
            (report) => report.report_uuid === reportUUID && report.key === tableKey
        );
        const hideColumns = reportObject?.hide_columns || [];
        const uuidOrIdRegex = /[^a-z](?:uuid|id)$/i;
        return (
            !col.match(uuidOrIdRegex) &&
            (!visibleColumns?.length || visibleColumns.includes(col)) &&
            !hideColumns.includes(col)
        );
    };

    // Filter out UUID columns and hidden columns from the outputs array
    const filteredOutputs = data?.outputs?.filter((output) => isColumnVisible(output.name));

    const handleSampleClick = (sampleReportConfigID) => {
        onOpenSamplingConfigDialog(sampleReportConfigID);
    };

    // Function to handle opening the scheduling dialog
    const handleOpenSchedulingDialog = (rowIndex, sampleReportConfigID) => {
        setSelectedRowIndex(rowIndex);
        setSelectedSampleReportConfigID(sampleReportConfigID);
        setShowSchedulingDialog(true);
    };

    // Function to handle closing the scheduling dialog
    const handleCloseSchedulingDialog = () => {
        setShowSchedulingDialog(false);
        setSelectedRowIndex(null);
        setSelectedSampleReportConfigID(null);
    };

    const handleSetSchedule = (newSchedule) => {
        onRecurrenceDataChange(
            Object.keys(newSchedule).length ? newSchedule : defaultScheduleValue,
            selectedSampleReportConfigID
        );
        setShowSchedulingDialog(false);
    };

    // Function to get additional columns for pivot tables
    const getAdditionalColumns = () => {
        const reportObject = templateData?.reports?.find(
            (report) => report.report_uuid === reportUUID && report.key === tableKey
        );
        const hideColumns = reportObject?.hide_columns || [];

        if (reportObject?.table_type === "pivot-table") {
            return [
                {
                    name: "Status",
                    type: "string",
                    render: ({ sampleReportConfigID }) => {
                        const isScheduled = recurrenceData[sampleReportConfigID];
                        return (
                            <div className={styles.statusColumn}>
                                <FiberManualRecordRounded
                                    className={isScheduled ? styles.scheduledIcon : styles.pendingIcon}
                                />
                                <span className={isScheduled ? styles.scheduledText : styles.pendingText}>
                                    {isScheduled ? "Scheduled" : "Pending"}
                                </span>
                            </div>
                        );
                    },
                },
                {
                    name: "Samples",
                    type: "string",
                    render: ({ sampleReportConfigID }) => {
                        if (hideColumns.includes("Samples")) return null;
                        return (
                            <div className={styles.sampleIcon}>
                                <img
                                    src={sampleIcon}
                                    alt="Sample Icon"
                                    onClick={() => handleSampleClick(sampleReportConfigID)}
                                    classes={{ root: styles.sampleIcon }}
                                />
                            </div>
                        );
                    },
                },
                {
                    name: "Action",
                    type: "string",
                    render: ({ rowIndex, sampleReportConfigID, isSelected }) => {
                        if (hideColumns.includes("Action")) return null;
                        return (
                            <Button
                                size="small"
                                disabled={isSelected}
                                onClick={() => handleOpenSchedulingDialog(rowIndex, sampleReportConfigID)}
                            >
                                {recurrenceData[sampleReportConfigID] ? "Edit Schedule" : "Set Schedule"}
                            </Button>
                        );
                    },
                },
            ];
        }
        return [];
    };

    const additionalColumns = getAdditionalColumns();
    const combinedOutputs = [...additionalColumns, ...(filteredOutputs || [])];

    // Report Configs often have duplicate rows with the same Sample Report Config ID since there may be
    // multiple sampling configs for a single report config, the user can already see the sampling configs
    // in the sampling config dialog, so we don't need to show duplicate rows
    // Add this function to filter unique rows and keep track of original indices
    const getUniqueRowsWithIndices = (rows) => {
        const uniqueConfigIds = new Set();
        return rows.reduce((acc, row, index) => {
            const configIdIndex = data?.outputs?.findIndex((o) => o.name === "Sample Report Config ID");
            const configId = row.values[configIdIndex];
            if (!uniqueConfigIds.has(configId)) {
                uniqueConfigIds.add(configId);
                acc.push({ row, originalIndex: index });
            }
            return acc;
        }, []);
    };

    const toggleSelection = (row, originalIndex) => {
        setTableSelections((prevSelections) => {
            const newSelections = {
                ...prevSelections,
                [tableKey]: {
                    ...prevSelections[tableKey],
                    [originalIndex]: !prevSelections[tableKey]?.[originalIndex],
                },
            };
            onTableSelectionsChange(newSelections);
            return newSelections;
        });
    };
    const renderTableRows = () => {
        const reportObject = templateData?.reports?.find((report) => report.key === tableKey);
        const isPivotTable = reportObject?.table_type === "pivot-table";

        const rowsToRender = isPivotTable
            ? getUniqueRowsWithIndices(data?.rows || [])
            : (data?.rows || []).map((row, index) => ({ row, originalIndex: index }));

        return rowsToRender.map(({ row, originalIndex }, i) => {
            const isSelected = tableSelections[tableKey]?.[originalIndex] || false;

            return (
                <tr key={`row-${originalIndex}`} className={isSelected ? styles.selected : ""}>
                    {reportObject.table_type !== "grid-table" && (
                        <td className={styles.selectionColumn}>
                            <div
                                className={styles.selectionIcon}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    toggleSelection(row, originalIndex);
                                }}
                            >
                                {isSelected ? (
                                    <AddRounded className={styles.icon} />
                                ) : (
                                    <RemoveRounded className={styles.icon} />
                                )}
                            </div>
                        </td>
                    )}
                    {combinedOutputs.map((output, index) => {
                        const { name, type, render } = output;
                        if (!isColumnVisible(name)) return null;

                        let value;
                        let columnIndex;

                        if (index < additionalColumns.length) {
                            const additionalColumn = additionalColumns[index];
                            const sampleReportConfigID =
                                row.values[data?.outputs?.findIndex((o) => o.name === "Sample Report Config ID")];

                            return (
                                <td key={`row-${originalIndex}-additional-${additionalColumn.name}`}>
                                    {additionalColumn.render({
                                        rowIndex: originalIndex,
                                        sampleReportConfigID,
                                        isSelected,
                                    })}
                                </td>
                            );
                        } else {
                            columnIndex = data?.outputs?.findIndex((o) => o.name === name);
                            value = row.values[columnIndex];
                        }

                        const formatFunction = columnFormats?.[name] ?? defaultColumnFormats[type]?.format;
                        const config = columnConfig[name] || {};

                        return (
                            <td key={`row-${originalIndex}-${name}`}>
                                {config.render ? (
                                    config.render({
                                        value,
                                        isSelected,
                                        sampleReportConfigID:
                                            row.values[
                                                data?.outputs?.findIndex((o) => o.name === "Sample Report Config ID")
                                            ],
                                        handleSampleClick,
                                        rowIndex: originalIndex,
                                    })
                                ) : (
                                    <div>{formatFunction ? formatFunction(value) : value}</div>
                                )}
                            </td>
                        );
                    })}
                </tr>
            );
        });
    };

    if (error) {
        return getErrorText(error);
    }

    if (loading) {
        return <LoadingSpinner />;
    }

    return (
        <div className={styles.scrollableWrapper}>
            <h3 className={styles.tableHeader}>{reportObject?.label || data?.name}</h3>
            {data?.rows?.length > 0 ? (
                <div className={styles.tableWrapper}>
                    <div className={styles.tableContainer}>
                        <table className={styles.table}>
                            <thead>
                                <tr>
                                    {reportObject.table_type !== "grid-table" && (
                                        <th className={styles.selectionColumn}></th>
                                    )}
                                    {combinedOutputs.map(
                                        (output, index) =>
                                            isColumnVisible(output.name) && (
                                                <th key={`${output.name}-${index}`}>{output.name}</th>
                                            )
                                    )}
                                </tr>
                            </thead>
                            <tbody>{renderTableRows()}</tbody>
                        </table>
                    </div>
                </div>
            ) : (
                <div className={styles.noRowsFound}>No Rows Found</div>
            )}
            <SchedulingDialog
                title="Set Schedule"
                open={showSchedulingDialog}
                onClose={handleCloseSchedulingDialog}
                value={recurrenceData[selectedSampleReportConfigID] || defaultScheduleValue}
                setValue={(newSchedule) => handleSetSchedule(newSchedule)}
            />
        </div>
    );
};

export default MultiStepFormReportTable;
