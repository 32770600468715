import React from 'react';
import Table from '../Table';
import { getServiceProviderUsersReport, createServiceProviderUser, removeServiceProviderUser, getServiceProvider } from '../../api/serviceProvidersAPI';
import { getUser } from '../../api/usersAPI';
import { getReportValue } from '../../utils/reporting'
import Flyout from '../Flyout';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogTable from '../DialogTable';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import { getDefaultReportingParams, setReportingParams } from '../../utils/reporting';
import { NavLink } from 'react-router-dom';
import LoaderOverlay from '../LoaderOverlay';

class ServiceProviderUsers extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			usersList: [],
			flyoutActive: false,
			userInfo: {},
			newUser: false,
			reportingParams: getDefaultReportingParams(),
			addUserActive: false,
			selectedUser: null,
			showError: false,
			dialogReportingParams: getDefaultReportingParams(),
			serviceProvider: {}
		};
	}

	async componentDidMount() {
		this.updateUsersList();
		if (this.props.uuid) {
			getServiceProvider(this.props.uuid).then((response) => {
				this.setState(() => ({ serviceProvider: response }))
			})
		}
	}

	updateUsersList = async (reportingParams) => {
		if (reportingParams === undefined) {
			reportingParams = this.state.reportingParams;
		}
		reportingParams.inputs = { "Property UUID": this.props.uuid };

		getServiceProviderUsersReport(this.props.uuid, reportingParams).then(response => {
			if (response.nextPage !== undefined && response.nextPage > reportingParams.page) {
				reportingParams.nextPage = response.nextPage;
			}
			this.setState((prevState) => ({
				usersList: response.data,
				reportingParams
			}));
		});
	}

	openFlyout = async (userInfo, newUser) => {
		const overlay = document.getElementById('overlaySecondary');

		if (newUser) {
			this.setState({ newUser: true, userInfo: {} });
		} else {
			let uuid = userInfo.user_uuid;
			if (userInfo.values) {
				uuid = getReportValue(this.state.usersList, userInfo, "User UUID");
			}
			userInfo = await getUser(uuid);
			this.setState({ newUser: false, userInfo });
		}

		this.setState(() => ({ flyoutActive: true, userInfo }), () => {
			overlay.classList.add('overlaySecondary__visible');
		});
	}

	closeFlyout = async () => {
		const flyoutSecondary = document.getElementById('flyoutSecondary');
		const overlay = document.getElementById('overlaySecondary');

		flyoutSecondary.classList.remove('slideToVisible');
		overlay.classList.remove('overlaySecondary__visible');
		await setTimeout(() => {
			this.setState(() => ({ flyoutActive: false }));
		}, 200);
	}

	handleSelectUser = (payload) => {
		let show;
		if (this.state.selectedUser) {
			if (payload.values[0] !== this.state.selectedUser) {
				show = false;
			} else {
				show = true;
			}
		}
		this.setState(() => ({ selectedUser: payload.values[0], showError: show }))
	}

	handleAddUser = () => {
		const { selectedUser } = this.state;

		createServiceProviderUser(this.props.uuid, { service_provider_uuid: this.props.uuid, user_uuid: selectedUser }).then((response) => {
			if (response === "an entry for this user already exists") {
				this.handleUserAlreadyExists()
			} else {
				this.toggleDialog('close')
				this.updateUsersList()
			}
		})
	}

	handleUserAlreadyExists = () => {
		this.setState(() => ({ showError: true }))
	}

	toggleDialog = (type) => {
		if (type === 'open') {
			this.setState(() => ({ addUserActive: true }))
		} else if (type === 'close') {
			this.setState(() => ({
				addUserActive: false,
				showError: false,
				selectedUser: null
			}))
		}
	}

	// -----------------------------
	// table / reporting functions
	// -----------------------------
	setOrdering = (column) => {
		let newReportingParams = setReportingParams(this.state.reportingParams, { column }, false);
		this.updateUsersList(newReportingParams);
	}

	fetchData = (direction) => {
		let newReportingParams = setReportingParams(this.state.reportingParams, { direction }, false);
		if (newReportingParams !== undefined) {
			this.updateUsersList(newReportingParams);
		}
	}
	// END table / reporting functions

	render() {
		const { flyoutActive, newUser, userInfo, usersList, addUserActive } = this.state;

		return (
			<React.Fragment>
				<Dialog
					open={addUserActive}
					aria-labelledby="form-dialog-title"
					className="widget__dialog"
					id="dialogTable"
				>
					<DialogContent>
						<div className="widget__options">
							<div>
								<span>Choose a User to add to Service Provider</span>
								<DialogTable
									tableType='Users'
									openFlyout={this.handleSelectUser}
									reportUUID="f60dbccd-0d3e-4987-b78e-fbb46a0e2a27"
									columnsToShow={['Name', 'Email']}
									inputs={{ inputs: { "User Type": 'service provider' } }}
									searches={['email', 'name']}
								/>
							</div>
							<NavLink className="logLinks" to={{ pathname: '/serviceProviders/users/newuser', comingFrom: 'SP', serviceProvider: this.state.serviceProvider }}>Can't find a user? Create one here.</NavLink>
						</div>
					</DialogContent>
					<DialogActions>
						<div className="contactLocation__wrapper">
							{this.state.showError && <span className="contactLocation__errorMessage">{`This user already exists at service provider`}</span>}
							{this.state.selectedUser && <button className="medButtonPrimary" onClick={() => this.handleAddUser()}>Add</button>}
							<button className="medButtonSecondary" onClick={() => this.toggleDialog('close')}>Cancel</button>
						</div>
					</DialogActions>
				</Dialog>
				<div className="flyoutContentContainer">
					{usersList.length !== 0 ?
						<Table
							tableType='Service Providers Users'
							tableData={usersList}
							tableSize='small'
							clickable={(SwiftComply.user.user_type === "normal") ? true : false}
							openFlyout={this.openFlyout}
							fetchData={this.fetchData}
							setOrdering={this.setOrdering}
							paginationParams={this.state.reportingParams}
							columnsToShow={["Name", "Certification Status", "Status", "Approved Until", "Phone"]}
						/> : <LoaderOverlay />}
					{SwiftComply.user.user_type !== 'service provider' &&
						<div className="flexButtonContainer">
							<button className="medButtonPrimary" onClick={() => this.toggleDialog('open')}>Add User</button>
						</div>}
					<div className="overlaySecondary" id="overlaySecondary" onClick={() => { this.closeFlyout() }}></div>
					{flyoutActive && (userInfo.user_uuid || newUser) && (
						<React.Fragment>
							<Flyout
								uuid={userInfo.user_uuid}
								data={this.state.userInfo}
								source='serviceProviders'
								sourceUUID={this.props.uuid}
								newAddition={newUser}

								flyoutType='users'
								flyoutPriority='secondary'
								closeFlyout={this.closeFlyout}
								openFlyout={this.openFlyout}
								activeTab='userDetails'

								refresh={this.updateUsersList}

								isServiceProviderUser={true}
							/>
						</React.Fragment>
					)}
				</div>
			</React.Fragment>
		);
	}
}

export default ServiceProviderUsers;