import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { getURLParentObject } from "../../../utils/url";
import { createSamplingConfig, getSampleReportConfig } from "../../../api/analytesAPI";
import { getErrorText } from "../../../utils/errors";
import { BasicSamplingConfigFields } from "./BasicSamplingConfigFields";
import useAnalytes from "../useAnalytes";
import LoadingSpinner from "../../Common/LoadingSpinner";
import HomeForm from "../../Common/Home/HomeForm";
import Button from "../../Common/Button";

const AddSamplingConfig = ({ history }) => {
    const { id: sampleReportConfigId, path: sampleReportConfigPath } = getURLParentObject("src") || {};
    const { analytes, error } = useAnalytes();
    const [isAuthority, setIsAuthority] = useState(null);

    useEffect(() => {
        // Get parent sampling report to determine whether this is industry or authority
        getSampleReportConfig(sampleReportConfigId)
            .then((data) => setIsAuthority(data.assigned_to === "organization"))
            .catch(error => {
                console.error(error);
                setIsAuthority(false); // fail quietly, just means that the toggles won't be hidden
            });
    }, []);

    const createConfig = (basic) => {
        return createSamplingConfig({ ...basic, sample_report_config_id: sampleReportConfigId });
    };

    const onSubmit = (basic) => createConfig(basic).then(() => history.push(sampleReportConfigPath));

    const onSaveAndCreateAnother = (basic) =>
        createConfig(basic).then(() => {
            history.push(`${sampleReportConfigPath}/addSamplingConfig`);
        });

    const customButton = (doSave) => (
        <Button onClick={() => doSave(onSaveAndCreateAnother)}>Save and Create Another Sampling Configuration</Button>
    );

    if (error) {
        return getErrorText(error);
    }

    if (!analytes.length || isAuthority === null) {
        return <LoadingSpinner />;
    }

    const fields = [
        ...BasicSamplingConfigFields.map((f) => ({
            ...f,
            options: f.id === "analytes" ? analytes : f.options,
        })),
        { id: "isAuthority", inputType: "hidden", defaultValue: isAuthority }
    ];

    return (
        <HomeForm
            basicFields={fields}
            onSubmit={onSubmit}
            onCancel={() => history.push(sampleReportConfigPath)}
            customButton={customButton}
        />
    );
};

export default withRouter(AddSamplingConfig);
