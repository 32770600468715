import React from 'react';
import { getForm, getFieldsets, getFieldsetFields } from '../../api/formsAPI';
import TestKitCalibrationForm from './TestKitCalibrationForm';
import {
	createComplianceReport, createComplianceReportDetail, submitComplianceReport,
	listComplianceReports, updateComplianceReport, createComplianceReportUpload, getComplianceReportDetails,
	acceptComplianceReport, rejectComplianceReport, updateComplianceReportDetail, deleteComplianceReport, resetComplianceReport
} from '../../api/complianceAPI';
import { getServiceProviderUsersReport } from '../../api/serviceProvidersAPI';
import { getReportValue } from '../../utils/reporting';
import { getOwnUser } from '../../api/usersAPI';
import moment, * as Moment from 'moment';
import { uploadFile } from '../../api/uploadsAPI';
import { getEquipmentCompliance, listEquipmentCompliance } from '../../api/equipmentAPI';
import validator from 'validator';
import editModeIcon from '../../../images/editMode.svg';
import EditIcon from '@material-ui/icons/Edit';

class TestKitCalibration extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			fieldsets: [],
			fields: [],
			acceptedReports: [],
			calibrationReports: [],
			newCalibration: null,
			loading: true,
			serviceProviderUUID: props.sourceUUID,
			serviceProviderUsers: [],
			deleteMode: false
		};
	}

	componentDidMount = async () => {
		await this.getComplianceReportFormData();
		this.getComplianceReport();
		this.getServiceProviderUsers();
	}

	getComplianceReportFormData = async () => {
		const form = await getForm('Test Kit Calibration');
		const formFieldsets = await getFieldsets(form.form_uuid);
		await formFieldsets.form_fieldsets.forEach((fieldset) => {
			this.setState((prevState) => ({ fieldsets: [...prevState.fieldsets, fieldset] }), () => {
				getFieldsetFields(fieldset.form_uuid, fieldset.form_fieldset_uuid).then((response) => {
					response.form_fields.forEach((field) => {
						this.setState((prevState) => ({ fields: [...prevState.fields, field] }));
					});
				});
			});
		});
	}

	getComplianceReport = () => {
		listEquipmentCompliance(this.props.uuid).then((response) => {
			if (response.equipment_compliance.length > 0) {
				response.equipment_compliance.forEach((report) => {
					this.setState((prevState) => ({ acceptedReports: [...prevState.acceptedReports, report] }));
				});
			}
			listComplianceReports(this.props.uuid).then((response) => {
				response.compliance_reports.forEach((report) => {
					if (report.source_uuid === this.props.uuid && report.form_uuid === this.state.fieldsets[0].form_uuid) {
						getComplianceReportDetails(report.compliance_report_uuid).then((detailsResponse) => {
							let fields = [];
							detailsResponse.compliance_report_details.forEach((field) => {
								fields.push({ [`${field.form_field_uuid}`]: field.value });
							});
							fields = fields.reduce((result, current) => {
								return Object.assign(result, current);
							});
							this.setState((prevState) => ({ calibrationReports: [...prevState.calibrationReports, { ...report, fields }] }), () => {
								this.setState(() => ({ loading: false }));
							});
						});
					} else {
						this.setState(() => ({ loading: false }));
					}
				});
			});
		});
	}

	saveTestKit = async (payload, file, submit) => {

		const report = await createComplianceReport({
			source: "equipment",
			source_uuid: this.props.uuid,
			form_uuid: this.state.fieldsets[0].form_uuid,
			service_provider_uuid: this.props.sourceUUID,
			reported_by: payload.reported_by,
			reported_on: payload.reported_on
		});
		for (const field in payload) {
			if (validator.isUUID(field)) {
				await createComplianceReportDetail(report['compliance_report_uuid'], field, payload[field]);
			}
		}

		await this.submitTestKit(report['compliance_report_uuid'], this.props.sourceUUID, null, payload.reported_on);

		if (file) {
			const data = new FormData();
			data.set('file', file);
			data.set('scope', "organization");
			const upload = await uploadFile(data);
			await createComplianceReportUpload(report['compliance_report_uuid'], this.state.fields[0].form_field_uuid, upload.uuid);
		}
		this.refreshReports();
		this.toggleNewCalibration();
	}

	editTestKit = async (payload, file, reportUUID) => {
		const report = await updateComplianceReport(reportUUID, payload.reported_on, payload.reported_by);
		for (const field in payload) {
			if (validator.isUUID(field)) {
				await updateComplianceReportDetail(reportUUID, field, payload[field])
			}
		}

		await this.submitTestKit(report['compliance_report_uuid'], this.props.sourceUUID, null, payload.reported_on);

		if (file) {
			const data = new FormData();
			data.set('file', file);
			data.set('scope', "organization");
			const upload = await uploadFile(data);
			await createComplianceReportUpload(reportUUID, this.state.fields[0].form_field_uuid, upload.uuid);
		}
		this.refreshReports();
	}

	submitTestKit = async (reportUUID, payload) => {
		await submitComplianceReport(reportUUID, this.props.sourceUUID, null, payload.reported_on);
	}

	toggleNewCalibration = (type) => {
		if (type === 'new') {
			this.setState(() => ({ newCalibration: true }));
		} else {
			this.setState(() => ({ newCalibration: false }));
		}
	}

	refreshReports = () => {
		this.setState(() => ({ calibrationReports: [] }), () => {
			this.getComplianceReport();
		});
	}

	makeReportDecision = async (type, reportUUID, comments) => {
		if (type === 'accept') {
			await acceptComplianceReport(reportUUID);
			this.props.refresh();
		} else if (type === 'reject') {
			await updateComplianceReport(reportUUID, null, null, comments);
			await rejectComplianceReport(reportUUID);
			this.props.refresh();
		}
		this.setState(() => ({ calibrationReports: [] }), () => {
			this.getComplianceReport();
		});
	}

	deleteCalibration = (payload) => {
		resetComplianceReport(payload).then((response) => {
			deleteComplianceReport(payload);
			this.props.refresh();
			this.refreshReports();
			this.setState(() => ({ deleteMode: false }));
		})
	}

	renderCalibrations = () => {
		let calibrations = [];
		const sortOrder = ['in_progress', 'submitted', 'accepted']
		const sortedReportsByDate = this.state.calibrationReports.sort((a, b) => (a.expires_on > b.expires_on) ? 1 : -1);
		const sortedReports = sortedReportsByDate.sort((a, b) => {
			return sortOrder.indexOf(a.status) - sortOrder.indexOf(b.status);
		})
		sortedReports.forEach((report, idx) => {
			let validity;
			if (report.status === 'accepted') {
				validity = <span className="valid underline">VALID</span>;
			} else if (report.status === 'submitted') {
				validity = <span className="pending underline">PENDING</span>;
			} else if (report.status === 'in_progress') {
				validity = <span className="inProgress underline">IN PROGRESS</span>;
			}
			if (report.expires_on < (Date.now() / 1000)) {
				validity = <span className="expired underline">EXPIRED</span>;
			}
			if (report.status !== 'rejected') {
				calibrations.push(
					<div className="cardsContainer" key={report.compliance_report_uuid} id="testKitCalibration">
						<span className='card_header' >{'Backflow Test Kit Calibration   '}
							{validity}
						</span>
						<TestKitCalibrationForm
							idx={idx}
							data={this.state.fields}
							serviceProviderUsers={this.state.serviceProviderUsers}
							newCalibration={false}
							formData={report}
							formValues={report.fields}
							makeReportDecision={this.makeReportDecision}
							toggleNew={this.toggleNewCalibration}
							deleteCalibration={this.deleteCalibration}
							editTestKit={this.editTestKit}
							refresh={this.refreshReports}
							submitTestKit={this.submitTestKit}
							deleteMode={this.state.deleteMode}
						/>
					</div>
				);
			}
		});
		return calibrations;
	}

	getServiceProviderUsers = () => {
		getServiceProviderUsersReport(this.state.serviceProviderUUID, { count: 250 }).then(response => {
			let serviceProviderUsers = [];
			response.data.rows.map((row, idx) => {
				let uuid = getReportValue(response.data, row, 'User UUID');
				let name = getReportValue(response.data, row, 'Name');
				let status = getReportValue(response.data, row, 'Status');
				let approved_until = getReportValue(response.data, row, 'Approved Until');
				// if we have the approved until make it a number
				if (approved_until != "") {
					approved_until = parseInt(approved_until, 10);
				}
				serviceProviderUsers.push({ "uuid": uuid, "name": name, "status": status, "approved_until": approved_until });
			});
			this.setState({ serviceProviderUsers })
		});
	}

	toggleDeleteMode = () => {
		this.setState((prevState) => ({ deleteMode: !prevState.deleteMode }));
	}

	render() {
		const filteredReports = this.state.calibrationReports.filter(report => report.status !== 'rejected');
		return (
			<div className="flyoutContentContainer" id="flyoutBase">
				{this.state.newCalibration &&
					<div className="newCalibrationForm">
						<div className="newCalibrationForm_header">New Calibration Information</div>
						<TestKitCalibrationForm
							data={this.state.fields}
							serviceProviderUsers={this.state.serviceProviderUsers}
							saveTestKit={this.saveTestKit}
							toggleNew={this.toggleNewCalibration}
							newCalibration={true}
							idx={0}
						/>
					</div>
				}
				{filteredReports.length < 1 && !this.state.loading && !this.state.newCalibration &&
					<span>There are currently no accepted calibrations for this test kit.</span>
				}
				{!this.state.newCalibration && (
					<React.Fragment>
						{!filteredReports.length < 1 &&
							<button className="editModeButton" onClick={() => this.toggleDeleteMode()}>
								{!this.state.deleteMode && (
									<img src={editModeIcon} alt='' />
								)}
							</button>}
						{
							this.renderCalibrations()
						}
						{this.state.deleteMode &&
							<div className="flexButtonContainer">
								<button className="medButtonSecondary" onClick={() => this.toggleDeleteMode()}>Cancel</button>
							</div>
						}
						{!this.state.deleteMode &&
							<div className="flexButtonContainer">
								<button className="medButtonPrimary" onClick={() => this.toggleNewCalibration('new')}>Add Calibration</button>
							</div>}
					</React.Fragment>
				)}
			</div>
		);
	}
}


export default TestKitCalibration;
