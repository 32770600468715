import React, { useEffect, useState } from "react";
import { getURLParentObject } from "../../../utils/url";
import { getSamplingConfig } from "../../../api/analytesAPI";
import { BasicSamplingConfigFields } from "./BasicSamplingConfigFields";
import Page from "../../Common/Page";
import Card from "../../Common/Card";
import SummaryGrid from "../../Common/Forms/SummaryGrid";
import EditSamplingConfig from "./EditSamplingConfig";
import useAnalytes from "../useAnalytes";
import { intervalToFormField, scheduleToText } from "../../../utils/forms";
import { VerifiedUserRounded } from "@material-ui/icons";

const ViewSamplingConfig = () => {
    const { id: samplingConfigId } = getURLParentObject("sc") || {};
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [samplingConfigData, setSamplingConfigData] = useState({});
    const { analytes } = useAnalytes();

    const refreshSamplingConfig = () => {
        setLoading(true);
        setError(null);

        getSamplingConfig(samplingConfigId)
            .then((data) => {
                setSamplingConfigData({
                    ...BasicSamplingConfigFields.reduce((prev, curr) => ({ ...prev, [curr.id]: data[curr.id] }), {}),
                    compliance_determinants: data.compliance_determinants,
                });
            })
            .catch(setError)
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        refreshSamplingConfig();
    }, []);

    const onEditComplete = () => {
        setEditMode(false);
        refreshSamplingConfig();
    };

    if (editMode && !loading && !error) {
        const editableFields = { ...samplingConfigData };

        // For editing, the "interval" data type from the API must be converted to a usable string, e.g. "5 minutes"
        for (const key in editableFields) {
            if (BasicSamplingConfigFields.find((f) => f.id === key)?.inputType === "interval") {
                editableFields[key] = intervalToFormField(editableFields[key]);
            }
        }

        return (
            <EditSamplingConfig
                preloadedBasicState={editableFields}
                onEditComplete={onEditComplete}
                onEditCancelled={() => setEditMode(false)}
            />
        );
    }

    const fields = BasicSamplingConfigFields.filter((f) => f.id !== "name").map((f) => ({
        ...f,
        options: f.id === "analytes" ? analytes : f.options,
    }));

    return (
        <Page loading={loading || !analytes} error={error}>
            <Card title={samplingConfigData.name} onEdit={() => setEditMode(true)}>
                <SummaryGrid
                    fields={fields}
                    formState={samplingConfigData}
                    arrayIcons={{ compliance_determinants: <VerifiedUserRounded /> }}
                />
            </Card>
        </Page>
    );
};

export default ViewSamplingConfig;
