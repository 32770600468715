import { useEffect, useState } from "react";
import { getReport } from "../../../api/reportingAPI";

/**
 * Custom hook for fetching industrial users from a report
 * Returns an array of industrial users, error object, and loading state
 *
 * @returns {{industrialUsers: {label: string, value: string}[], error: Object, loading: boolean}}
 */

const useIndustrialUsers = () => {
    const [error, setError] = useState(null);
    const [industrialUsers, setIndustrialUsers] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const reportUUID = "2b408dd5-1df9-48be-8f28-b1e9cf8ea985";
        const reportingParams = { count: 300 };

        getReport(reportUUID, reportingParams)
            .then((response) => {
                const data = response.data;

                const users = data?.rows?.map((row) => ({
                    label: row.values[2], // Name is at index 2 in the row array
                    value: row.values[0], // Property UUID is at index 0 in the row array
                }));
                setIndustrialUsers(users || []);
                setLoading(false);
            })
            .catch((error) => {
                setError(error);
                setLoading(false);
            });
    }, []);

    return { industrialUsers, error, loading };
};

export default useIndustrialUsers;
