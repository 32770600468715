import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

const ModalError = () => {
    const [copied, setCopied] = useState(false);

    const copyDetails = () => {
        let textArea;

        try {
            // Have to use an off-screen textarea because navigaor.clipboard is not supported on non-secure (http) pages
            textArea = document.createElement("textarea");
            textArea.style.position = "absolute";
            textArea.style.left = "-999999px";
            textArea.value = JSON.stringify(
                {
                    message: document.getElementById("modal-error-message")?.innerText,
                    reference: document.getElementById("modal-error-reference")?.innerText,
                    data: JSON.parse(document.getElementById("modal-error-data")?.innerText),
                },
                null,
                2
            );

            document.getElementById("modal-error-message").prepend(textArea);
            textArea.focus();
            textArea.select();

            // ececCommand is deprecated but there is currently (2024) no replacement
            document.execCommand("copy");
            console.log("copying...");
        } catch (error) {
            console.error(error);
        } finally {
            textArea.remove();
            setCopied(true);
        }
    };

    return (
        <Dialog open={true} id="modal-error" className="hide">
            <DialogContent>
                <div id="modal-error-message"></div>
                <div id="modal-error-reference"></div>
                <div id="modal-error-data" style={{ display: "none" }}></div>
            </DialogContent>
            <DialogActions>
                <button onClick={copyDetails} disabled={copied} className="medButtonSecondary">
                    {copied ? "Details copied" : "Copy error details"}
                </button>
                <button
                    className="smallButtonPrimary"
                    onClick={() => {
                        document.getElementById("modal-error").classList.add("hide");
                        document.getElementById("modal-error-message").innerText = "";
                        document.getElementById("modal-error-reference").innerText = "";
                        document.getElementById("modal-error-data").innerText = "";
                        setCopied(false);
                    }}
                >
                    Close
                </button>
            </DialogActions>
        </Dialog>
    );
};

export default ModalError;
