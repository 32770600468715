import React from "react";
import { FormControl, FormHelperText, TextField, Checkbox } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import styles from "../../../../styles/common/matieralUI/autoComplete.module.css";
import chipStyles from "../../../../styles/common/matieralUI/chip.module.css";
import checkboxStyles from "../../../../styles/common/matieralUI/checkbox.module.css";
import helperTextStyles from "../../../../styles/common/matieralUI/helperText.module.css";

const MultiSelectField = ({ label, options, setValue, value, error }) => {

    // MUI Autocomplete cannot accept null as a value
    const muiValue = value ?? [];

    return (
        <FormControl error={!!error}>
            <Autocomplete
                multiple
                disableCloseOnSelect
                disableClearable
                classes={styles}
                options={options}
                value={muiValue}
                ChipProps={{ classes: chipStyles }}
                onChange={(_, __, action, option) => {
                    // MUI v4 multi-select passes an "action" to specify what the user wants to do (??)
                    // Sometimes, the "option" parameter is a string, sometimes it is an object (???)
                    const selected = option && (typeof option.option === "object" ? option.option.value : option.option);

                    switch (action) {
                        case "clear":
                            setValue([]);
                            break;
                        case "select-option":
                            setValue([...muiValue, selected]);
                            break;
                        case "remove-option":
                            setValue(muiValue.filter((v) => v !== selected));
                            break;
                    }
                }}
                renderInput={(params) => <TextField {...params} label={label} variant="outlined" error={!!error} />}
                getOptionLabel={(selectedValue) =>
                    selectedValue?.label ??
                    options.find((option) => option.value === selectedValue)?.label ??
                    selectedValue
                }
                renderOption={(option, { selected }) => (
                    <>
                        <Checkbox classes={checkboxStyles} checked={selected} />
                        {option.label}
                    </>
                )}
                getOptionSelected={(option, val) => option.value === val}
            />
            {error && <FormHelperText classes={helperTextStyles}>{error}</FormHelperText>}
        </FormControl>
    );
};

export default MultiSelectField;
