import React, { useState, useEffect } from 'react';
import { getOwnUser } from '../../api/usersAPI';
import {
	getBackflowTestReportsReport, getComplianceReport, submitComplianceReport,
	acceptComplianceReport, rejectComplianceReport, getBackflowSurveysReport,
	unsubmitComplianceReport, updateComplianceReport, deleteComplianceReport,
	resetComplianceReport, getComplianceReportDetails
} from '../../api/complianceAPI';
import { getFormFields } from '../../api/formsAPI';
import { getComplianceReportPDF } from '../../api/pdfAPI';
import { getReportValue } from '../../utils/reporting';
import { getProperty } from '../../api/propertiesAPI';
import { getEquipment } from '../../api/equipmentAPI';
import TestReportDetailsForm from './TestReportDetailsForm';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router-dom';

const TestReportDetails = (props) => {
	const [user, setUser] = useState(null);
	const [report, setReport] = useState(null);  // note this report here isn't the compliance report, its the result from reporting
	const [complianceReport, setComplianceReport] = useState(null); // the actual compliance report
	const [complianceReportDetails, setComplianceReportDetails] = useState(null);
	const [formFields, setFormFields] = useState(null);
	const [equipment, setEquipment] = useState(null);
	const [property, setProperty] = useState(null);
	const [reportStatus, setReportStatus] = useState("");
	const [reportSubmittedOn, setReportSubmittedOn] = useState(0);
	const [notes, setNotes] = useState('');
	const [deleteMode, setDeleteMode] = useState(false);
	const [reportErrors, setReportErrors] = useState({});
	const [hasDeletedSources, setHasDeletedSources] = useState(false);
	let history = useHistory();

	useEffect(() => {
		if (user === null) {
			getOwnUser().then(user => {
				setUser(user);
			});
		} else {
			getTestReportData();
		}
	}, [user]);

	// we will grab both the reporting version of the report and the actual compliance report object here
	const getTestReportData = () => {
		if (user !== null && props.uuid !== undefined && props.uuid !== '') {
			let reportFunc = (props.flyoutType === "testReports") ? getBackflowTestReportsReport : getBackflowSurveysReport;

			reportFunc({ "inputs": { "Compliance Report UUID": props.uuid } }).then(response => {
				if (response.data.rows.length > 0) {
					setReport(response.data);
					let status = getReportValue(response.data, response.data.rows[0], "Status");
					setReportStatus(status);
					props.setHeader(getReportValue(response.data, response.data.rows[0], "Location Name"));
					let submittedOn = getReportValue(response.data, response.data.rows[0], "Submitted On");
					if (submittedOn != "") {
						setReportSubmittedOn(parseInt(submittedOn, 10));
					}
				}
			});

			getComplianceReport(props.uuid).then((reportResponse) => {
				// we need the actual compliance report and then the form fields for its form if we have field_deltas
				// (discrepancies) so get that now
				setComplianceReport(reportResponse);
				if (reportResponse.field_deltas.length > 0) {
					getComplianceReportDetails(props.uuid).then((detailsResponse) => {
						let detailsObj = {};
						detailsResponse.compliance_report_details.forEach((detail) => {
							detailsObj[detail.form_field_uuid] = detail;
						})
						setComplianceReportDetails(detailsObj);
					});
					getFormFields(reportResponse.form_uuid).then((fieldsResponse) => {
						let fieldsObj = {};
						fieldsResponse.form_fields.forEach((field) => {
							fieldsObj[field.form_field_uuid] = field;
						})
						setFormFields(fieldsObj);
					});
				} else {
					setComplianceReportDetails({});
				}

				// we also need to try to fetch the property and equipment for this report.  If we can't get one of them
				// then its possible they have been deleted, so we need to let the user know this report can't be processed
				if (reportResponse.property_uuid != "") {
					getProperty(reportResponse.property_uuid).then((propertyResponse) => {
						setProperty(propertyResponse);
					}).catch((error) => {
						if (error.response.status == 404) {
							setProperty({ "deleted": true });
						}
					});
				}
				if (reportResponse.source == "equipment" && reportResponse.source_uuid != "") {
					getEquipment(reportResponse.source_uuid).then((equipResponse) => {
						setEquipment(equipResponse);
					}).catch((error) => {
						if (error.response.status == 404) {
							setEquipment({ "deleted": true });
						}
					});
				} else if (reportResponse.source == "property") {
					// if this is only looking at a property, we won't have any equipment to look up so just make it
					// an empty object
					setEquipment({});
				}
			});
		}
	};

	const getReportStatus = () => {
		let userStatus = 'In Progress';
		if (reportStatus === 'submitted') {
			userStatus = 'Awaiting Acceptance';
		} else if (reportStatus === 'accepted') {
			userStatus = 'Accepted';
		} else if (reportStatus === 'rejected') {
			userStatus = 'Rejected';
		} else if (reportStatus === 'pending_payment') {
			userStatus = 'Pending Payment'
		}
		return userStatus;
	};

	const getReportCompliance = () => {
		let compliant = getReportValue(report, report.rows[0], "Result");
		let userCompliant = 'In Progress';
		if (compliant === 'true') {
			userCompliant = 'Compliant';
		} else if (compliant === 'false') {
			userCompliant = 'Not Compliant';
		} 
		return userCompliant;
	};

	const returnToReport = (step) => {
		let stepParam = "";
		if (step !== undefined) {
			stepParam = "&step=" + step;
		}
		if (props.flyoutType === "testReports") {
			if (step === 0) {
				history.push({
					pathname: '/testReports/newTestReport',
					state: { 
						property_uuid: property.property_uuid,
						property_name: property.name,
						property_address: property.address1,
						property_city: property.city,
						property_state: property.state_prov,
						}
				});
			} else {
				window.location.href = '/testReports/newTestReport?report=' + props.uuid + stepParam;
			}
		} else if (props.flyoutType === "surveys") {
				window.location.href = '/surveys/newSurvey?report=' + props.uuid + stepParam;
		}
	};

	const checkCanSubmit = (status, compliance_report_uuid, service_provider_uuid) => {
		// we only need to show the submit button if the report is in progress, after that its already submitted
		if (status === 'in_progress') {
			submitComplianceReport(compliance_report_uuid, service_provider_uuid, true).then((result) => {
				// If it can submit without issue then enable the submit button
				if (!result.Error) {
					document.getElementById("submit-button").hidden = false;
				}
			}).catch(error => {
				// note we don't just want to set the errors here all the time, since I'd wager most of the time if
				// they are coming back to a report it will have some error and that would be annoying, so just set
				// ones that aren't obvious and we can add more later?
				if (error.name === 'APIUserError') {
					if (error.message == "reported_by is not approved for service provider") {
						setReportErrors({ type: "info", message: "The reporting tester is not yet approved to submit test reports." });
					}
				} else if (error.response.status == 409) {
					setReportErrors({ type: "error", message: error.response.data.Error });
				}
			});
		}
	};

	const submitReport = () => {
		getComplianceReport(props.uuid).then((response) => {
			submitComplianceReport(response.compliance_report_uuid, response.service_provider_uuid).then(async () => {
				if (notes.length > 0) {
					await updateComplianceReport(props.uuid, null, null, notes);
					setNotes('');
				}
				getTestReportData();
				props.refresh();
			});
		});
	};

	const acceptReport = async () => {
		await acceptComplianceReport(props.uuid);
		if (notes.length > 0) {
			await updateComplianceReport(props.uuid, null, null, notes);
			setNotes('');
		}
		getTestReportData();
		props.refresh();
	};

	const rejectReport = async () => {
		await rejectComplianceReport(props.uuid);
		if (notes.length > 0) {
			await updateComplianceReport(props.uuid, null, null, notes);
			setNotes('');
		}
		getTestReportData();
		props.refresh();
	};

	const unSubmitReport = async () => {
		await unsubmitComplianceReport(props.uuid);
		if (notes.length > 0) {
			await updateComplianceReport(props.uuid, null, null, notes);
			setNotes('');
		}
		getTestReportData();
		props.refresh();
	};

	const resetReport = async () => {
		await resetComplianceReport(props.uuid).catch((error) => {
			if (error.name === 'APIUserError') {
				setReportErrors({ type: "error", message: error.message });
			}
		});
		setDeleteMode(false);
		getTestReportData();
		props.refresh();
	};

	const saveNotes = async (payload, action) => {
		setNotes(payload);
		if (action === 'submit') {
			await updateComplianceReport(props.uuid, null, null, notes);
			setNotes('');
			props.refresh();
		}
	};

	const displayPDFReport = async () => {
		// Get the pdf blob and open it in a new tab
		getComplianceReportPDF(props.uuid).then(response => {

			setTimeout(() => {
				window.open(window.URL.createObjectURL(response), "_blank");
			}, 100);
		});
	};

	const deleteReport = async () => {
		await deleteComplianceReport(getReportValue(report, report.rows[0], "Compliance Report UUID"));
		if (props.flyoutType === "testReports") {
			window.location.href = '/testReports';
		} else if (props.flyoutType === "surveys") {
			window.location.href = '/surveys';
		}
	}

	let reportName = "Report";
	if (props.flyoutType === "surveys") {
		reportName = "Survey";
	}

	// wait for our property and equipment to get updated, then see if either are deleted.
	useEffect(() => {
		if (property != null && equipment != null && (property.deleted || equipment.deleted)) {
			let whatWasDeleted = "";
			let pronoun = "it has";
			if (property.deleted && equipment.deleted) {
				whatWasDeleted = "location and assembly";
				pronoun = "they have";
			} else if (property.deleted) {
				whatWasDeleted = "location";
			} else if (equipment.deleted) {
				whatWasDeleted = "assembly";
			}

			let deletedMessage = `We could not look up the ${whatWasDeleted} details, this possibly means ${pronoun} been deleted and this report can not be processed.\n\n`;
			if (SwiftComply.user.user_type === 'service provider') {
				deletedMessage += "Please contact your water utility backflow coordinator if you feel this is in error.";
			} else {
				deletedMessage += "Please contact SwiftComply support if you feel this is in error.";
			}

			setReportErrors({ type: "warning", message: deletedMessage });
			setHasDeletedSources(true);
		}
	}, [property, equipment]);

	// if we have our report as well as looked up our property and equipment see if we can submit it, we are only going
	// to check this if everything else has checked out.
	useEffect(() => {
		if (report != null && property != null && equipment != null && !property.deleted && !equipment.deleted) {
			checkCanSubmit(
				getReportValue(report, report.rows[0], "Status"),
				getReportValue(report, report.rows[0], "Compliance Report UUID"),
				getReportValue(report, report.rows[0], "Service Provider UUID")
			);
		}
	}, [report, property, equipment]);

	return (
		<div className="flyoutContentContainer">
			{report !== null && (
				<React.Fragment>
					<TestReportDetailsForm
						getReportStatus={getReportStatus}
						getReportCompliance={getReportCompliance}
						getReportValue={getReportValue}
						report={report}
						complianceReport={complianceReport}
						complianceReportDetails={complianceReportDetails}
						formFields={formFields}
						saveNotes={saveNotes}
						updateComplianceReport={updateComplianceReport}
						notes={notes}
						reportErrors={reportErrors}
						{...props}

					/>
					{SwiftComply.user.user_type !== 'service provider' && !props.source &&
						<React.Fragment>
							<div className="logLinks">
								<span>Go to <a href={`/locations/${getReportValue(report, report.rows[0], "Property UUID")}`} target="_blank" rel="noopener noreferrer"> Location </a></span>
								{props.flyoutType === 'testReports' && <span> or <a href={`/assemblies/${getReportValue(report, report.rows[0], "Equipment UUID")}`} target="_blank" rel="noopener noreferrer"> Assembly</a></span>}
							</div>

						</React.Fragment>
					}
					{(reportStatus === 'in_progress' && !hasDeletedSources) &&
						<React.Fragment>
							<div className="flexButtonContainer">
								<button id="submit-button" className="medButtonPrimary" type="button" hidden onClick={() => submitReport()}>Submit {reportName}</button>
								<button className="medButtonSecondary" type="button" onClick={() => returnToReport()}>Continue Filling Out {reportName}</button>
								{props.flyoutType === "testReports" && <button className="medButtonPrimary" type="button" onClick={() => returnToReport(0)}>New {reportName} At Same Location </button>}
							</div>
						</React.Fragment>
					}
					{(reportStatus === 'pending_payment' && !hasDeletedSources) &&
						<React.Fragment>
							<div className="flexButtonContainer">
								<button className="longButtonSecondary" type="button" onClick={() => unSubmitReport()}>Reset to In Progress</button>
							</div>
						</React.Fragment>
					}
					{reportStatus !== 'in_progress' &&
						<React.Fragment>
							<div className="formButtonsBank">
								{reportStatus !== 'pending_payment' &&
								<>
									<button className="medButtonSecondary" type="button" onClick={() => returnToReport(1)}>View {reportName}</button>
									{(props.flyoutType === "testReports" || props.flyoutType === "surveys") && (
										<button className="medButtonSecondary" type="button" onClick={() => displayPDFReport()}>Download {reportName}</button>
									)}
								</>
								}
								{props.flyoutType === "testReports" && <button className="medButtonPrimary" type="button" onClick={() => returnToReport(0)}>New {reportName} At Same Location </button>}
							</div>
							{!hasDeletedSources && reportStatus === 'submitted' && user.user_type === 'normal' && reportStatus !== 'pending_payment' && (
								<React.Fragment>
									<div className="flexButtonContainer">
										<button className="medButtonPrimary" type="button" onClick={() => acceptReport()}>Accept {reportName}</button>
										<button className="medButtonSecondary red" type="button" onClick={() => rejectReport()}>Reject {reportName}</button>
										<button className="medButtonSecondary yellow" type="button" onClick={() => unSubmitReport()}>Send Back {reportName}</button>
									</div>
								</React.Fragment>
							)}
						</React.Fragment>
					}
					{(reportStatus === 'in_progress' || reportStatus === 'pending_payment') &&
						<React.Fragment>
							{!deleteMode && (
								<div className="deleteButton__container">
									<button className="deleteButton" onClick={() => setDeleteMode(true)}><DeleteForeverIcon fontSize='inherit' color='inherit' /></button>
								</div>
							)}

							{deleteMode &&
								<React.Fragment>
									<div className="flyout__deleteMode">
										<span>Warning: These actions cannot be undone.</span>
										<button className="medButtonSecondary" onClick={() => deleteReport()}>Delete</button>
										<button className="exit" onClick={() => setDeleteMode(false)}><CloseIcon fontSize="large" /></button>
									</div>
								</React.Fragment>
							}
						</React.Fragment>
					}
					{user.user_type === 'normal' && (reportStatus === "submitted" || ((reportStatus === 'accepted' || reportStatus === 'rejected') && ((Math.floor(Date.now() / 1000) - reportSubmittedOn) < 90 * 86400))) && (
						<React.Fragment>
							{!deleteMode && (
								<div className="deleteButton__container">
									<button className="deleteButton" onClick={() => setDeleteMode(true)}><DeleteForeverIcon fontSize='inherit' color='inherit' /></button>
								</div>
							)}

							{deleteMode &&
								<div className="flyout__deleteMode">
									<span>Warning: These actions cannot be undone.</span>
									<button className="medButtonSecondary" onClick={() => resetReport()}>Reset to In Progress</button>
									<button className="exit" onClick={() => setDeleteMode(false)}><CloseIcon fontSize="large" /></button>
								</div>
							}
						</React.Fragment>
					)}
				</React.Fragment>
			)
			}
		</div >
	)
};

export default TestReportDetails;
