import axios from 'axios';

export const uploadFile = (payload) => {
	const url = '/uploads';

	return axios.post(url, payload)
		.then(response => {
			console.log('File successfully uploaded', response);
			return response.data;
		})
		.catch(error => {
			console.log('File could not be uploaded:', error.response?.data || error.response?.statusText || error);
			throw error;
		});
};

export const getUpload = (uploadUUID) => {
	const url = `/uploads/${uploadUUID}`;

	return axios.get(url, { responseType: 'blob' })
		.then(response => {
			console.log('Got uploaded file', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t get uploaded file:', error.response?.data || error.response?.statusText || error);
			throw error;
		});
};

