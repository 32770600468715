import React from "react";
import { FormControl, FormHelperText, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import styles from "../../../../styles/common/matieralUI/autoComplete.module.css";
import helperTextStyles from "../../../../styles/common/matieralUI/helperText.module.css";

const SelectField = ({ label, options, value, setValue, error, disabled }) => {
    // The complicated code below is a result of the following limitations:
    // - The MaterialUI v4 drop-down control, <Select>, does not handle selection via keyboard
    // - The MaterialUI v4 <AutoComplete> component only works with an array of strings, by default, as opposed to
    //    an array of <option> elements with both a "label" and a "value"
    // - The native HTML <select> control is difficult to style to match MaterialUI
    // - The native HTML <select> control does not allow an "undefined" state (nothing selected)
    return (
        <FormControl error={!!error}>
            <Autocomplete
                classes={styles}
                options={options}
                value={value ?? null}
                onChange={(_, selectedOption) => setValue(selectedOption?.value)}
                renderInput={(params) => <TextField {...params} label={label} variant="outlined" error={!!error} />}
                getOptionLabel={(selectedValue) =>
                    selectedValue.label ?? options.find((option) => option.value === selectedValue)?.label ?? ""
                }
                renderOption={(option) => <span>{option.label}</span>}
                getOptionSelected={(option, newValue) => option.value === (newValue ?? options[0].value)}
                disabled={disabled}
                disableClearable
            />
            {error && <FormHelperText classes={helperTextStyles}>{error}</FormHelperText>}
        </FormControl>
    );
};

export default SelectField;
