import React from "react";
import styles from "../../../styles/common/summaryGrid.module.css";
import { Link } from "react-router-dom";
import DownloadLink from "../DownloadLink";
import parseInterval from "postgres-interval";
import { formatDate, scheduleToText, timestampToDate } from "../../../utils/forms";

/**
 * Display all fields in a summary box, formatting values based on field inputType
 *
 * @param {formField[]} fields - The form fields to display, where "id" identifies the field in the "formState" object
 * @param {Object} formState - The values to display for each field
 * @param {Object} [arrayIcons] - Icons to show if formState contains "toggled" arrays (see <Form>)
 */
const SummaryGrid = ({ fields, formState, arrayIcons }) => {
    /** @param {formField} field */
    const formatField = (field) => {
        const value = formState[field.id];

        switch (field.inputType) {
            default:
                return value;

            case "select":
            case "radio":
                return field.options.find((o) => o.value === value)?.label ?? value;

            case "date":
            case "past_date":
            case "date_time":
                return value ? formatDate(timestampToDate(value)) : null;

            case "interval":
                return parseInterval(value).toPostgres();

            case "schedule":
                return scheduleToText(value);

            case "multi":
                return value?.map((v) => field.options.find((f) => f.value === v)?.label ?? v).join(", ");

            case "link":
                return (
                    <Link to={field.url} className={styles.link}>
                        Link
                    </Link>
                );

            case "download":
                return (
                    <DownloadLink url={field.url} filename={field.filename}>
                        Click to download
                    </DownloadLink>
                );

            case "custom":
                return field.customDisplay(value);
        }
    };

    const typesNotShownOnSummary = ["hidden", "label"];

    return (
        <div className={styles.content}>
            {fields
                ?.filter((f) => !typesNotShownOnSummary.includes(f.inputType) && !f.hideWhen?.(formState))
                .map((f, index) => (
                    <div className={styles.field} key={index}>
                        <div className={styles.name} data-testid={`summary-${f.id}-label`}>
                            {f.label}{" "}
                            {arrayIcons &&
                                Object.entries(arrayIcons).map(([key, icon]) => formState[key]?.includes(f.id) && icon)}
                        </div>
                        <div className={styles.value} data-testid={`summary-${f.id}-value`}>
                            {formatField(f)}
                        </div>
                    </div>
                ))}
        </div>
    );
};

export default SummaryGrid;
