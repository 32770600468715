import React, { useState, useEffect } from 'react';
import { getOrgNotifications } from '../../api/notificationsAPI';
import LaunchRoundedIcon from '@material-ui/icons/LaunchRounded';

const OrgNotifications = (props) => {

    const [notifications, setNotifications] = useState(null);

    useEffect(() => {
        getNotificationsList();
    }, [])

    const getNotificationsList = async () => {
        const orgNotificationsResponse = await getOrgNotifications();
        let orgNotificationsList = orgNotificationsResponse.notifications;
        // These notifications don't come through at all if the count is 0, so for the notification section we will show the notification type with count 0
        // so the user knows it exists. We will do the opposite if being used as a widget, and hide all types that have count 0.
        let potentialNotifications = ["reports_in_review:Backflow Assembly Test", "reports_in_review:Backflow Survey", "reports_in_review:Test Kit Calibration"];

        if (props.widget) {
            let filteredList = [];
            orgNotificationsList.forEach((type) => {
                if (type.count > 0) {
                    filteredList.push(type);
                }
            })
            orgNotificationsList = filteredList;
        } else {
            potentialNotifications.forEach((type) => {
                if (!orgNotificationsList.some(notification => notification.type === type)) {
                    orgNotificationsList.push({ count: 0, type: type });
                }
            })
        }

        setNotifications(orgNotificationsList);
    };

    const notificationOrdering = [
        {
            type: "properties_in_review",
            label: 'Locations To Review:',
            url: '/locations?inputs_needs%20review=true'
        },
        {
            type: "equipment_in_review",
            label: "Assemblies To Review:",
            url: "/assemblies?inputs_active=null&inputs_needs%20review=true"
        },
        {
            type: "sp_users_in_review",
            label: "Service Provider Users To Review:",
            url: "/reporting/b157ea13-0949-446b-84a5-84d5a27322eb?inputs_needs%20review=true"
        },
        {
            type: "sp_users_in_rereview",
            label: "Service Provider Users To Re-Review:",
            url: "/reporting/b157ea13-0949-446b-84a5-84d5a27322eb?inputs_needs%20rereview=true"
        },
        {
            type: "reports_in_review:Backflow Assembly Test",
            label: 'Test Reports To Review:',
            url: "/testReports?search_status=submitted"
        },
        {
            type: "reports_in_review:Backflow Survey",
            label: 'Surveys To Review:',
            url: "/surveys?search_status=submitted"
        },
        {
            type: "reports_in_review:Test Kit Calibration",
            label: 'Test Kit Calibrations To Review:',
            url: "/reporting/bce4c4af-9266-43b1-bed4-b9c9564798eb"
        }
    ];

    const buildNotificationsLists = () => {
        let lists = [];
        console.log(notifications);
        if (notifications) {
            notificationOrdering.forEach((notif, idx) => {
                let found = notifications.find(elem => elem.type == notif.type);
                lists.push(
                    <div key={idx} className="notificationsGroup" onClick={() => props.widget ? window.open(notif.url, "_blank") : null}>
                        <div className="notificationsGroup__label">{notif.label}</div>

                        <div className="notificationsGroup__count">
                            {(found) ? found.count : 0}
                        </div>
                        {!props.widget &&
                            <button className="notificationsGroup__openButton" onClick={() => window.open(notif.url, "_blank")}>
                                <LaunchRoundedIcon fontSize="large" />
                            </button>
                        }

                    </div>
                )
            })
        }

        return lists;
    }

    return (
        <div className="notificationsContainer" id={props.widget ? "widgetOrgNotifications" : null}>
            {buildNotificationsLists()}
        </div>
    )
};

export default OrgNotifications;