import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef } from "react";
import JoditEditor, { Jodit } from "jodit-react";
import styles from "../../styles/common/editor.module.css";
import PageBreakIcon from "../../../images/pagebreak.svg";

const Editor = forwardRef(({ initialContent, onChange }, ref) => {
    const joditRef = useRef();

    useEffect(() => {
        onChange(initialContent);
    }, []);

    useImperativeHandle(ref, () => ({
        insertField(field) {
            const { s } = joditRef.current || {};

            if (!s) {
                return;
            }

            if (!s.markers?.length) {
                s.focus();
                s.marker();
            }

            const { isBlockElement: block, template_string, section, required, inputType, dataType } = field;
            const before = block ? "<p>&nbsp;" : "<span>&nbsp;</span>";
            const after = block ? "&nbsp;</p>" : "<span>&nbsp;</span>";
            const cssClass = `class='${block ? "jodit-block" : "jodit-pill"}'`;
            const label = block ? `<strong>${field.label}</strong> will appear here` : field.label;
            const templateAttr = `data-${section.replaceAll("_", "-").replace(/s$/, "")}='${template_string}'`;
            const config = {
                required,
                input_type: inputType,
                data_type: dataType,
            };
            const configAttr = Object.values(config).filter((v) => typeof v !== "undefined").length
                ? `data-config='${JSON.stringify(config)}'`
                : "";

            s.insertHTML(
                `${before}<scchip contenteditable='false' ${cssClass} ${configAttr} ${templateAttr}>${label}</scchip>${after}`
            );
        },
    }));

    // prettier-ignore
    const buttons = [
        "undo", "redo", "|", "bold", "strikethrough", "underline", "italic", "align", "|", "ul", "ol", "|",
        "outdent", "indent", "|", "font", "fontsize", "brush", "paragraph", "|", "image", "table", "link",
        {
            name: "hr",
            tooltip: "Horizontal line",
            exec: (editor) => editor.s.insertHTML("<div class=\"jodit-hr\"></div>"),
        },
        {
            name: "pagebreak",
            // Using "iconURL" is ugly but works because webpack resolves PageBreakIcon to a URL string
            // Another option is to insert the entire <svg>...</svg> element as a text string, which is even more ugly
            iconURL: PageBreakIcon,
            tooltip: "Page break",
            exec: (editor) => editor.s.insertHTML("<div class=\"page-break\"></div>"),
        },
        "symbols", "|", "fullsize"
    ];

    return (
        /** @type JSX.Element */
        useMemo(
            () => (
                <div className={styles.joditWrapper}>
                    <JoditEditor
                        value={initialContent ?? ""}
                        editorRef={(jodit) => (joditRef.current = jodit)}
                        onChange={onChange}
                        config={{
                            controls: {
                                font: {
                                    list: Jodit.atom({
                                        "'Helvetica', 'Arial', 'sans-serif'": "Sans-serif",
                                        "'Times New Roman', 'Garamond', 'serif'": "Serif",
                                        "'Courier New', 'Menlo', 'monospace'": "Monospace",
                                    }),
                                },
                            },
                            uploader: { insertImageAsBase64URI: true },
                            spellcheck: true,
                            addNewLine: false,
                            saveSelectionOnBlur: true,
                            placeholder: "",
                            statusbar: false,
                            height: "max(100vh - 52rem, 30rem)",
                            buttons,
                            toolbarAdaptive: false,
                            enter: "p",
                        }}
                    />
                </div>
            ),
            []
        )
    );
});

export default Editor;
