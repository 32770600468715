import React from 'react';
import Table from '../Table';
import { withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import MessageBox from '../MessageBox';
import { getServiceProvidersReport, getServiceProviderUsersReport, getServiceProviderEquipmentReport } from '../../api/serviceProvidersAPI';
import { parseReportingParams, setReportingParams, getDefaultReportingParams, getReportValue } from '../../utils/reporting';

const styles = theme => ({
	textFieldInput: {
		height: 5,
		fontSize: '1.4rem',
		color: '#506788',
	},
	label: {
		'&$focused': {
			color: '#506788'
		},
	},
	focused: {},
	outlinedInput: {
		'&$focused $notchedOutline': {
			borderColor: '#506788',
			borderWidth: 1
		},
	},
	notchedOutline: {},
});

class TestReportServiceProviderInfo extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			activeTable: 'serviceProviders',
			searchErrorLevel: 'error',
			searchError: '',
			serviceProvidersList: null,
			serviceProviderUUID: null,
			serviceProviderUsersList: null,
			serviceProviderEquipmentList: null,
			autoSelectedUserIndex: undefined,
			reportingParams: {}
		};

		this.state.reportingParams = parseReportingParams();
	}

	componentDidMount() {
		if (SwiftComply.user.user_type === "service provider") {
			this.getServiceProvidersList();
		}
	}

	getServiceProvidersList = async (newReportingParams) => {
		let reportingParams = (newReportingParams !== undefined) ? newReportingParams : this.state.reportingParams;

		reportingParams.count = 100;

		const reportOutput = await getServiceProvidersReport(reportingParams);
		this.setState(
			{ activeTable: 'serviceProviders', serviceProvidersList: reportOutput.data, reportingParams },
			() => {
				if (reportOutput.data.rows.length === 1) {
					this.collectData('serviceProviders', reportOutput.data.rows[0]);
				}
			}
		);
	}

	getServiceProviderUsersList = async (serviceProviderUUID, newReportingParams) => {
		let reportingParams = (newReportingParams !== undefined) ? newReportingParams : this.state.reportingParams;

		reportingParams.count = 100;

		const reportOutput = await getServiceProviderUsersReport(serviceProviderUUID, reportingParams);
		let autoSelectedUserIndex = undefined;
		if (reportOutput.data.rows.length === 1) {
			autoSelectedUserIndex = 0;
		} else if (SwiftComply.user.user_type === "service provider") {
			reportOutput.data.rows.forEach((row, idx) => {
				if (getReportValue(reportOutput.data, row, "User UUID") === SwiftComply.user.user_uuid) {
					autoSelectedUserIndex = idx;
				}
			});
		}

		this.setState(
			{ serviceProviderUUID, activeTable: 'serviceProviderUsers', serviceProviderUsersList: reportOutput.data, reportingParams, autoSelectedUserIndex },
			() => {
				if (this.state.autoSelectedUserIndex !== undefined) {
					this.collectData('serviceProviderUsers', reportOutput.data.rows[this.state.autoSelectedUserIndex]);
				}
			}
		);
	}

	getServiceProviderEquipmentList = async (serviceProviderUUID, newReportingParams) => {
		let reportingParams = (newReportingParams !== undefined) ? newReportingParams : this.state.reportingParams;

		reportingParams.count = 100;

		reportingParams.inputs["Compliant"] = "true";
		const reportOutput = await getServiceProviderEquipmentReport(serviceProviderUUID, reportingParams);
		this.setState(
			{ serviceProviderUUID, activeTable: 'serviceProviderEquipment', serviceProviderEquipmentList: reportOutput.data, reportingParams }
		);
	}

	fetchData = (direction) => {
		let newReportingParams = setReportingParams(this.state.reportingParams, { direction });
		if (newReportingParams !== undefined) {
			if (this.state.activeTable === 'serviceProviders') {
				this.getServiceProvidersList(newReportingParams);
			} else if (this.state.activeTable === 'serviceProviderUsers') {
				this.getServiceProviderUsersList(this.state.serviceProviderUUID, newReportingParams);
			} else if (this.state.activeTable === 'serviceProviderEquipment') {
				this.getServiceProviderEquipmentList(this.state.serviceProviderUUID, newReportingParams);
			}
		}
	}

	collectData = (type, row) => {
		const { sendDataToReport } = this.props;

		if (type === 'serviceProviders') {
			let data = {
				service_provider_uuid: getReportValue(this.state.serviceProvidersList, row, "Service Provider UUID"),
				name: getReportValue(this.state.serviceProvidersList, row, "Name")
			}
			sendDataToReport('serviceProvider', data);
			this.getServiceProviderUsersList(data.service_provider_uuid, getDefaultReportingParams());
		} else if (type === 'serviceProviderUsers') {
			let data = {
				user_uuid: getReportValue(this.state.serviceProviderUsersList, row, "User UUID"),
				service_provider_uuid: getReportValue(this.state.serviceProviderUsersList, row, "Service Provider UUID"),
				name: getReportValue(this.state.serviceProviderUsersList, row, "Name"),
				phone: getReportValue(this.state.serviceProviderUsersList, row, "Phone")
			}
			sendDataToReport('serviceProviderUser', data);
			this.getServiceProviderEquipmentList(data.service_provider_uuid, getDefaultReportingParams());
		} else if (type === 'serviceProviderEquipment') {
			let data = {
				equipment_uuid: getReportValue(this.state.serviceProviderEquipmentList, row, "Equipment UUID"),
				service_provider_uuid: getReportValue(this.state.serviceProviderEquipmentList, row, "Service Provider UUID"),
				make: getReportValue(this.state.serviceProviderEquipmentList, row, "Make"),
				model: getReportValue(this.state.serviceProviderEquipmentList, row, "Model"),
				serial_number: getReportValue(this.state.serviceProviderEquipmentList, row, "Serial Number"),
				compliance_date: getReportValue(this.state.serviceProviderEquipmentList, row, "Compliance Date"),
				expiration_date: getReportValue(this.state.serviceProviderEquipmentList, row, "Expiration Date")
			}
			sendDataToReport('serviceProviderEquipment', data);
		}
	}

	serviceProviderSearch = () => {
		let name = document.getElementById('serviceprovider_name');

		if (name.value == "") {
			this.setState({
				searchError: "You must provide a Service Provider Company Name to search",
				searchErrorLevel: "error",
				serviceProvidersList: null,
				activeTable: "serviceProviders"
			});
			return;
		} else if (name.value.length < 3) {
			this.setState({
				searchError: "Minimum 3 characters required",
				searchErrorLevel: "error",
				serviceProvidersList: null,
				activeTable: "serviceProviders"
			});
			return
		}

		let payload = { search: { name: name.value }, count: 250 };

		getServiceProvidersReport(payload).then(response => {
			if (response.data.rows.length == 0) {
				this.setState({
					searchError: "No providers found, please revise your search and try again",
					searchErrorLevel: "info",
					serviceProvidersList: null,
					activeTable: "serviceProviders"
				});
			} else {
				this.setState({
					searchError: "",
					serviceProvidersList: response.data,
					activeTable: "serviceProviders"
				}, () => {
					if (response.data.rows.length === 1) {
						this.collectData('serviceProviders', response.data.rows[0]);
					}
				});
			}
		}).catch(error => {
			this.setState({
				searchError: error.message,
				searchErrorLevel: "error",
				serviceProvidersList: null
			});
		});
	}

	toggleTable = (type) => {
		this.setState(() => ({ activeTable: type, reportingParams: getDefaultReportingParams() }));
	}

	render() {
		const { serviceProvidersList, serviceProviderUsersList, serviceProviderEquipmentList, activeTable } = this.state;
		const { classes } = this.props;
		return (
			<div className='initialInfo' >
				<div className='locationAssemblyInfo'>
					<div className='locationAssemblyInfo__wrapper' >
						<div className="initialInfo__header-wrapper">
							<span className='initialInfo__header'>Choose a Service Provider</span>
						</div>
						{SwiftComply.user.user_type !== "service provider" &&
							<div className="locationAssemblyInfo__inputs">
								<TextField
									label="Service Provider Company Name"
									name="serviceprovider_name"
									id="serviceprovider_name"
									variant="outlined"
									InputProps={{
										classes: {
											input: classes.textFieldInput,
											root: classes.outlinedInput,
											focused: classes.focused,
											notchedOutline: classes.notchedOutline,
										}
									}}
									onKeyDown={(e) => {
										if (e.keyCode === 13) {
											this.serviceProviderSearch();
										}
									}}
								/>
								<button className="xsmallButtonPrimary" onClick={this.serviceProviderSearch}>
									<SearchIcon fontSize='large' />
								</button>
							</div>
						}
						<MessageBox options={{ type: this.state.searchErrorLevel, message: this.state.searchError }} />
						{serviceProvidersList !== null ?
							<Table
								highlight='true'
								tableType='Service Providers'
								tableSize='flexMaxLimit'
								tableData={serviceProvidersList}
								pageSize={100}
								openFlyout={(row) => { this.collectData('serviceProviders', row); }}
								fetchData={this.fetchData}
								columnsToShow={['Name', 'Address', 'City']}
								selectedIndex={(serviceProvidersList.rows.length === 1) ? 0 : undefined}
							/>
							: null}
					</div>
				</div>
				{serviceProviderUsersList !== null && (activeTable === 'serviceProviderUsers' || activeTable === 'serviceProviderEquipment') ?
					<React.Fragment>
						<div className="initialInfo__header-wrapper">
							<span className='initialInfo__header'>Choose a Tester</span>
						</div>
						<Table
							highlight='true'
							tableType='Testers'
							tableSize='flexMaxLimit'
							tableData={serviceProviderUsersList}
							pageSize={100}
							openFlyout={(row) => {
								this.collectData('serviceProviderUsers', row);
								// we need to wipe this out if it was set because now that we clicked on somethign we 
								// don't want this table to always think it needs to select the old value
								this.setState({ autoSelectedUserIndex: undefined });
							}}
							fetchData={this.fetchData}
							columnsToShow={['Name', 'Certification Status', 'Phone']}
							selectedIndex={this.state.autoSelectedUserIndex}
						/>
					</React.Fragment>
					: null
				}
				{serviceProviderEquipmentList !== null && activeTable === 'serviceProviderEquipment' ?
					<React.Fragment>
						<div className="initialInfo__header-wrapper">
							<span className='initialInfo__header'>Choose a Test Kit</span>
						</div>
						<Table
							highlight='true'
							tableType='Compliant Test Kits'
							tableSize='flexMaxLimit'
							tableData={serviceProviderEquipmentList}
							pageSize={100}
							openFlyout={(row) => { this.collectData('serviceProviderEquipment', row); }}
							fetchData={this.fetchData}
							columnsToShow={['Assigned To', 'Make', 'Model', 'Serial Number']}
						/>
					</React.Fragment>
					: null
				}
			</div>
		);
	}
}

export default withStyles(styles)(TestReportServiceProviderInfo);