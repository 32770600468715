import React from "react";
import { withRouter } from "react-router";
import { getURLParentObject } from "../../../utils/url";
import { createSampleReportConfig } from "../../../api/analytesAPI";
import { BasicSampleReportConfigFields } from "./BasicSampleReportConfigFields";
import { getErrorText } from "../../../utils/errors";
import useGeneralControls from "../GeneralControls/useGeneralControls";
import HomeForm from "../../Common/Home/HomeForm";
import LoadingSpinner from "../../Common/LoadingSpinner";
import Button from "../../Common/Button";

const AddSampleReportConfig = ({ history }) => {
    const { id: outfallId, path: outfallPath } = getURLParentObject("o") || {};
    const { generalControls, error: generalControlError, loading: generalControlLoading } = useGeneralControls();

    const onSubmit = (basic, addSamplingConfig) =>
        createSampleReportConfig({
            ...basic,
            reference_point_uuid: outfallId,
        }).then((response) =>
            history.push(
                `${outfallPath}/src/${response.sample_report_config_id}` + (addSamplingConfig ? "/addSamplingConfig" : "")
            )
        );

    const customButton = (doSave) => (
        <Button onClick={() => doSave((basic) => onSubmit(basic, true))}>Save and Add Sampling Configuration</Button>
    );

    if (generalControlError) {
        return getErrorText(generalControlError);
    }

    if (generalControlLoading) {
        return <LoadingSpinner />;
    }

    const basicFields = BasicSampleReportConfigFields.map((f) =>
        f.id === "general_control_uuid" ? { ...f, options: generalControls } : f
    );

    return (
        <HomeForm
            basicFields={basicFields}
            onSubmit={(basic) => onSubmit(basic, false)}
            onCancel={() => history.goBack()}
            preloadedBasicState={{ general_control: null }}
            customButton={customButton}
        />
    );
};

export default withRouter(AddSampleReportConfig);
