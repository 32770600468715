import queryString from "query-string";
import validator from "validator";
import { getContact } from "../api/contactsAPI";
import { getGeneralControl, getPermit } from "../api/permitsAPI";
import { getProperty } from "../api/propertiesAPI";
import { getLimit, getReferencePoint } from "../api/referencePointsAPI";
import { getSampleReportConfig, getSamplingConfig } from "../api/analytesAPI";
import { getTemplate } from "../api/templatesAPI";

const modulePathnames = {
    pt: "pretreatment",
    bf: "backflow",
};

/**
 * Get the current module and slug from the URL path
 * @returns {{module:string, slug:string|null, path:string, rest:string[]}}
 */
export const getURLModule = () => {
    const pathParts = window.location?.pathname?.split("/").filter(Boolean);
    const [first, ...rest] = pathParts;
    const module = modulePathnames[first];
    const slug = module ? first : null;

    return {
        module: module ?? "backflow",
        slug,
        path: `/${slug ?? ""}`,
        rest: slug ? rest : pathParts,
    };
};

/**
 * Get the base section from the URL path
 * @returns {{section:string|null, path:string, rest:string[]}}
 */
export const getURLBaseSection = () => {
    const module = getURLModule();
    const [first, ...rest] = module.rest;
    return { section: first, path: first ? `${module.path}/${first}` : module.path, rest };
};

/**
 * Break the current URL into pairs of objects of the form "/{section}/{id}", e.g. {outfalls, 7} or {contacts, 123}
 * - Note: "id" may be null if the section does not contain an object
 * @returns {{section:string, id:string|null, path:string}[]}
 */
export const getURLObjects = () => {
    const { slug, rest } = getURLModule();
    const { isUUID } = validator;
    const ret = [];

    const isID = (str) => str && (/^\d+$/.test(str) || isUUID(str));
    let path = slug ? `/${slug}` : "";

    while (rest.length) {
        let id = null;
        const section = rest.shift();

        path += `/${section}`;

        if (isID(rest[0])) {
            id = rest.shift();
            path += `/${id}`;
        }

        ret.push({ section, id, path });
    }

    return ret;
};

/**
 * Get the parent object from the URL path based on the specified section
 * @param {string} section - The section to search for in the URL path
 * @returns {{section:string, id:string, path:string}|null}
 */
export const getURLParentObject = (section) => getURLObjects().find((obj) => obj.section === section);

/**
 * The window.location.pathname string may or may not have a trailing slash, so this function makes it consistent
 * @returns {string}
 */
export const getPathname = () => window.location.pathname.replace(/\/$/, "");

/**
 * Information about each type of object, indexed by the name of the section in the URL
 */
export const URLSectionInfo = {
    addContact: { title: "New Contact" },
    addGeneralControl: { title: "New General Control" },
    addIU: { title: "New Industrial User" },
    addLimit: { title: "New Limit" },
    addLocation: { title: "New Location" },
    addOutfall: { title: "New Outfall" },
    addPermit: { title: "New Permit" },
    addSampleReportConfig: { title: "New Sample Report Config" },
    addSamplingConfig: { title: "New Sampling Config" },
    addTemplate: { title: "New Template" },
    c: { title: "Contacts", getObject: getContact, getObjectName: (obj) => `${obj.firstname} ${obj.lastname}` },
    cfg: { title: "Configuration" },
    dashboard: { title: "Dashboard" },
    datatables: { title: "Data Tables" },
    gc: { title: "POTW", getObject: getGeneralControl, getObjectName: (obj) => obj.name || "Unnamed Control" },
    iu: {
        title: "Industrial Users",
        getObject: getProperty,
        getObjectName: (obj) => obj.name || "Unnamed Industrial User",
    },
    l: {
        title: "Limits",
        getObject: (id) => getLimit(getURLParentObject("o").id, id),
        getObjectName: (obj) => obj.analyte || "Limit",
    },
    lo: { title: "Locations", getObject: getProperty, getObjectName: (obj) => obj.name || "Unnamed Location" },
    o: {
        title: "Outfalls",
        getObject: getReferencePoint,
        getObjectName: (obj) => obj.name || "Unnamed Outfall",
    },
    p: { title: "Permitting", getObject: getPermit, getObjectName: (obj) => obj.name || "Unnamed Permit" },
    sc: {
        title: "Sampling Configs",
        getObject: getSamplingConfig,
        getObjectName: (obj) => obj.name || "Unnamed Sampling Config",
    },
    src: {
        title: "Sample Report Configs",
        getObject: getSampleReportConfig,
        getObjectName: (obj) => obj.name || "Unnamed Sample Report Config",
    },
    t: { title: "Templates", getObject: getTemplate, getObjectName: (obj) => obj.name || "Unnamed Template" },
};

export const updateURL = (path, params, sendBack) => {
    let newPath = buildURLPath(path, params);

    // according to Mozilla the second param, title, is mostly ignored and passing an empty string should be safe for
    // now and the future
    if (sendBack) {
        return {}, "", newPath;
    } else {
        window.history.pushState({}, "", newPath);
    }
};

export const buildURLPath = (path, params) => {
    let qs = {};
    if (params !== undefined) {
        qs = params;
        for (let key in params) {
            qs[key] = params[key];
        }
    } else {
        qs = queryString.parse(window.location.search);
    }

    let query = queryString.stringify(qs);
    if (query !== "") {
        query = "?" + query;
    }
    return path + query;
};

// yes this is about useless but since we were doing all the query-string stuff here I figured I would just do it like this
export const parseQueryStringParams = (search) => {
    return queryString.parse(search);
};

export const isValidURL = (string) => {
    let url;

    try {
        url = new URL(string);
    } catch (_) {
        return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
};
