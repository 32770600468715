import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getReportWithRowCount } from "../../../api/reportingAPI";
import { getErrorText } from "../../../utils/errors";
import { MenuItem, Select } from "@material-ui/core";
import {
    ArrowUpwardRounded,
    ArrowDownwardRounded,
    ChevronRightRounded,
    ChevronLeftRounded,
    FirstPageRounded,
    LastPageRounded,
} from "@material-ui/icons";
import { defaultColumnFormats } from "../../../utils/columnFormats";
import styles from "../../../styles/common/reportTable.module.css";
import LoadingSpinner from "../LoadingSpinner";
import IconButton from "../IconButton";
import ToolTip from "../ToolTip";
import { isEqual } from "lodash";

/**
 * Renders a simple report table (no search, filters, or column selection)
 *
 * @param {string} reportUUID - The report to be fetched
 * @param {(columns: string[]) => void} onColumnsChanged - Called to tell the parent what columns are present
 * @param {string[]} [visibleColumns] - Array of columns that are shown in the table
 * @param {(Object) => string} [rowLinks] - Called when rendering each row, can return a link for when the row is clicked
 * @param {Object} [columnFormats] - Object containing functions called when rendering each cell, can be used to alter contents
 * @param {Object} [columnAlignments] - Object containing an optional CSS "text-align" value for each column
 * @param {Object} [detailsColumns] - Additional details columns to be included in the report
 * @param {Object} [inputs] - Object used to refine the report based on an input/filter
 * @param {Object} [search] - Object used to refine the report based on a text search
 */
const BasicReportTable = ({
    reportUUID,
    onColumnsChanged,
    visibleColumns,
    rowLinks,
    columnFormats,
    columnAlignments,
    detailsColumns = {},
    inputs,
    search,
}) => {
    const [data, setData] = useState({});
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [sortByColumn, setSortByColumn] = useState(null);
    const [sortAscending, setSortAscending] = useState(true);
    const rowCountOptions = [10, 25, 50, 100];

    // Need to remember the parameters of the last report that was fetched, except for "page", because if any
    // of these change, then page needs to be reset to 1
    const [lastReportParams, setLastReportParams] = useState({});

    useEffect(() => {
        setError(false);
        setLoading(true);

        const payload = {
            order: sortByColumn,
            count: rowsPerPage,
            page: currentPage,
            inputs,
            search,
        };

        if (sortByColumn) {
            payload.direction = sortAscending ? "asc" : "desc";
        }

        if (Object.keys(detailsColumns).length) {
            payload.include_columns = detailsColumns;
        }

        // If any of these parameters have changed, reset currentPage
        const reportParams = { reportUUID, rowsPerPage, search, inputs };
        const resetCurrentPage = !isEqual(reportParams, lastReportParams);

        if (resetCurrentPage) {
            setCurrentPage(1);
            setLastReportParams(reportParams);
        }

        // Also have to check for "resetCurrentPage" here because "setCurrentPage(1)" is not immediate
        getReportWithRowCount(reportUUID, { ...payload, page: resetCurrentPage ? 1 : currentPage })
            .then((data) => {
                setData(data);
                onColumnsChanged?.(data?.outputs?.map((output) => output.name));
            })
            .catch(setError)
            .finally(() => setLoading(false));
    }, [
        reportUUID,
        sortByColumn,
        sortAscending,
        currentPage,
        rowsPerPage,
        JSON.stringify(search),
        JSON.stringify(inputs),
    ]);

    const onHeaderClick = (header) => {
        setSortAscending((prev) => (sortByColumn === header ? !prev : true));
        setSortByColumn(header);
    };

    const isColumnVisible = (col) =>
        !col.match(/[^a-z](?:uuid|id)$/i) && (!visibleColumns?.length || visibleColumns.includes(col));
    const columnAlignment = (col, type) => columnAlignments?.[col] ?? defaultColumnFormats[type]?.alignment ?? "left";

    if (error) {
        return getErrorText(error);
    }

    if (loading) {
        return <LoadingSpinner />;
    }

    if (!data.rows?.length || !data.total_row_count) {
        return "No Rows Found";
    }

    const firstRow = (currentPage - 1) * rowsPerPage + 1;
    const lastRow = Math.min(currentPage * rowsPerPage, data.total_row_count);
    const totalPages = Math.ceil(data.total_row_count / rowsPerPage);

    return (
        <div className={styles.tableWrapper}>
            <table className={styles.table}>
                <thead>
                    <tr>
                        {data.outputs
                            .filter((output) => isColumnVisible(output.name))
                            .map((output) => (
                                <th
                                    key={output.name}
                                    onClick={data.rows.length > 1 ? () => onHeaderClick(output.name) : null}
                                    style={{ textAlign: columnAlignment(output.name, output.type) }}
                                >
                                    {output.name}
                                    &nbsp;
                                    {output.name === sortByColumn &&
                                        (sortAscending ? <ArrowUpwardRounded /> : <ArrowDownwardRounded />)}
                                </th>
                            ))}
                    </tr>
                </thead>
                <tbody>
                    {data.rows.map((row, i) => {
                        const rowAsObject = data.outputs.reduce(
                            (prev, curr, j) => ({ ...prev, [curr.name]: row.values[j] }),
                            {}
                        );
                        const rowlink = rowLinks?.(rowAsObject);
                        const linkTag = (content) => {
                            const cellContent = <div>{content}</div>;
                            if (!rowlink) {
                                return cellContent;
                            }

                            if (typeof rowlink === "string") {
                                return (
                                    <Link to={{ pathname: rowlink, state: { rowData: rowAsObject } }}>
                                        {cellContent}
                                    </Link>
                                );
                            } else if (typeof rowlink === "object" && rowlink.pathname) {
                                return (
                                    <Link
                                        to={{
                                            pathname: rowlink.pathname,
                                            state: rowlink.state,
                                        }}
                                    >
                                        {cellContent}
                                    </Link>
                                );
                            }

                            return cellContent; // fallback for unexpected rowlink types
                        };

                        return (
                            <tr key={`row-${i}`}>
                                {row.values.map((value, j) => {
                                    const { name, type } = data.outputs[j];
                                    const formatFunction = columnFormats?.[name] ?? defaultColumnFormats[type]?.format;
                                    return isColumnVisible(name) ? (
                                        <td key={`row-${i}-${name}`} style={{ textAlign: columnAlignment(name, type) }}>
                                            {linkTag(formatFunction ? formatFunction(value) : value)}
                                        </td>
                                    ) : null;
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            {data.total_row_count > rowCountOptions[0] && (
                <div className={styles.pagination}>
                    <div>
                        Rows per page:{" "}
                        <Select value={rowsPerPage} variant="standard" onChange={(e) => setRowsPerPage(e.target.value)}>
                            {rowCountOptions.map((o) => (
                                <MenuItem value={`${o}`} key={`${o}`}>
                                    {o}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                    <div>
                        <ToolTip title="First page">
                            <IconButton
                                icon={<FirstPageRounded />}
                                size="small"
                                onClick={() => currentPage > 1 && setCurrentPage(1)}
                            />
                        </ToolTip>
                        <ToolTip title="Previous page">
                            <IconButton
                                icon={<ChevronLeftRounded />}
                                size="small"
                                onClick={() => currentPage > 1 && setCurrentPage((prev) => prev - 1)}
                            />
                        </ToolTip>
                        Page {currentPage} of {totalPages}
                        <ToolTip title="Next page">
                            <IconButton
                                icon={<ChevronRightRounded />}
                                size="small"
                                onClick={() => currentPage < totalPages && setCurrentPage((prev) => prev + 1)}
                            />
                        </ToolTip>
                        <ToolTip title="Last page">
                            <IconButton
                                icon={<LastPageRounded />}
                                size="small"
                                onClick={() => currentPage < totalPages && setCurrentPage(totalPages)}
                            />
                        </ToolTip>
                    </div>
                    <div>
                        {firstRow}-{lastRow} of {data.total_row_count} rows
                    </div>
                </div>
            )}
        </div>
    );
};

export default BasicReportTable;
