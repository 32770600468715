import React, { useState, useEffect } from 'react';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TableChartIcon from '@material-ui/icons/TableChart';
import BarChartIcon from '@material-ui/icons/BarChart';
import TextFieldsIcon from '@material-ui/icons/TextFields';
import WebIcon from '@material-ui/icons/Web';
import SaveIcon from '@material-ui/icons/Save';
import ReplayIcon from '@material-ui/icons/Replay';
import CheckIcon from '@material-ui/icons/Check';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import { Tooltip } from '../Tooltip';


const EditDashboardFlyout = (props) => {

    const [dashboardInfo, setDashboardInfo] = useState(null);
    const [dashboardEditMode, setDashboardEditMode] = useState(false);
    const [dashboardName, setDashboardName] = useState('')
    const [deleteDashboardMode, setDeleteDashboardMode] = useState(false);
    const [layoutPrompt, setLayoutPrompt] = useState(false);
    const [creating, setCreating] = useState(null);

    useEffect(() => {
        if (props.dashboardInfo) {
            setDashboardInfo(props.dashboardInfo)
            setDashboardName(props.dashboardInfo.name)
        }
    }, [props.dashboardInfo])

    const closeFlyout = () => {
        props.toggleEditMode()
        setDeleteDashboardMode(false)
    }

    const deleteThisDashboard = (type) => {
        if (type === 'prompt') {
            setDeleteDashboardMode(true)
        } else if (type === 'cancel') {
            setDeleteDashboardMode(false)
        } else if (type === 'delete') {
            props.deleteDashboard()
            setDeleteDashboardMode(false)
        }
    }

    const layoutPromptTrigger = (widgetType) => {
        if (widgetType) {
            setLayoutPrompt(true)
            setCreating(widgetType)
        }
        setLayoutPrompt(true)
    }

    const layoutPromptOptions = (type) => {
        if (type === 'save') {
            props.saveLayout()
            setLayoutPrompt(false)
            if (creating) {
                setTimeout(() => {
                    props.addWidget({ type: creating }, 'stage')
                }, 500)
            } else {
                props.toggleEditMode('off')
            }
        } else if (type === 'discard') {
            props.resetDashboard()
            setDeleteDashboardMode(false)
            setLayoutPrompt(false)
            if (creating) {
                setTimeout(() => {
                    props.addWidget({ type: creating }, 'stage')
                }, 500)
            } else {
                props.toggleEditMode('off')
            }
        } else if (type === 'cancel') {
            setLayoutPrompt(false)
        }
        setTimeout(() => {
            setCreating(null)
        }, 1000)
    }

    const dashboardActions = (type) => {
        const resetLayoutButton = document.getElementById('resetLayoutButton');
        const saveLayoutButton = document.getElementById('saveLayoutButton');
        const layoutSaved = document.getElementById('layoutSaved');

        if (type === 'reset') {
            resetLayoutButton.classList.toggle('rotate');
            props.resetDashboard();
            setTimeout(() => {
                resetLayoutButton.classList.toggle('rotate');
            }, 1000)
        } else if (type === 'save') {
            saveLayoutButton.classList.toggle('hideIcon');
            layoutSaved.classList.toggle('showIcon');
            setTimeout(() => {
                saveLayoutButton.classList.toggle('hideIcon');
                layoutSaved.classList.toggle('showIcon');
            }, 2000)
            props.saveLayout();
        }
    }

    const onDashboardNameChange = (e) => {
        setDashboardName(e);
    }

    const handleDashboardUpdate = (type) => {
        if (type === 'update') {
            props.updateDashboard({ name: dashboardName })
        } else if (type === 'cancel') {
            setDashboardName(props.dashboardInfo.name);
        }
        setDashboardEditMode(false);
    }

    return (
        <div className={props.editMode ? "editDashboardFlyout__wrapper editDashboardFlyout__slideDown" : "editDashboardFlyout__wrapper"} id="editDashboardFlyout">
            <Dialog
                open={layoutPrompt ? true : false}
                onClose={closeFlyout}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">You have unsaved layout changes. Would you like to: </DialogTitle>
                <DialogActions>
                    <button className="medButtonPrimary" onClick={() => layoutPromptOptions('save')}>Save Layout Changes</button>
                    <button className="medButtonSecondary yellow" onClick={() => layoutPromptOptions('discard')}>Discard Layout Changes</button>
                    <button className="medButtonSecondary" onClick={() => layoutPromptOptions('cancel')}>Cancel</button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={dashboardEditMode ? true : false}
                onClose={handleDashboardUpdate}
                aria-labelledby="form-dialog-title"
                className="widget__dialog"
            >
                <DialogTitle id="form-dialog-title">{`${dashboardInfo ? dashboardInfo.name : ''} Settings`}</DialogTitle>
                <DialogContent>
                    <div className="widget__options">
                        <div>
                            <span>Dashboard Name</span>
                            <input type="text" value={dashboardName} onChange={(e) => onDashboardNameChange(e.target.value)}></input>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button className="medButtonPrimary" onClick={() => handleDashboardUpdate('update')}>Update Dashboard</button>
                    <button className="medButtonSecondary" onClick={() => handleDashboardUpdate('cancel')}>Cancel</button>
                </DialogActions>
            </Dialog>
            <div className="editDashboardFlyout">
                <span className="editDashboardFlyout__title">
                    {props.dashboardInfo ? `${props.dashboardInfo.name} Options` : null}
                </span>
                {!deleteDashboardMode &&
                    <div className="editDashboardFlyout__buttonBank-wrapper">
                        <span>Add a Widget</span>
                        <div className="editDashboardFlyout__buttonBank">
                            <div>
                                <button
                                    onClick={() => props.layoutDirty ? layoutPromptTrigger('chart') : props.addWidget({ type: 'chart' }, 'stage')}
                                >
                                    <BarChartIcon fontSize="inherit" color="inherit" />
                                </button>
                                <span>Chart</span>
                            </div>
                            <div>
                                <button
                                    onClick={() => props.layoutDirty ? layoutPromptTrigger('table') : props.addWidget({ type: 'table' }, 'stage')}
                                >
                                    <TableChartIcon fontSize="inherit" color="inherit" />
                                </button>
                                <span>Table</span>
                            </div>
                            <div>
                                <button
                                    onClick={() => props.layoutDirty ? layoutPromptTrigger('data') : props.addWidget({ type: 'data' }, 'stage')}
                                >
                                    <TextFieldsIcon fontSize="inherit" color="inherit" />
                                </button>
                                <span>Text</span>
                            </div>
                            <div>
                                <button
                                    onClick={() => props.layoutDirty ? layoutPromptTrigger('embed') : props.addWidget({ type: 'embed' }, 'stage')}
                                >
                                    <WebIcon fontSize="inherit" color="inherit" />
                                </button>
                                <span>Webpage</span>
                            </div>
                        </div>
                    </div>
                }
                {!deleteDashboardMode &&
                    <div className="editDashboardFlyout__controls">
                        <button onClick={() => setDashboardEditMode(true)}>
                            <div>
                                <Tooltip text='Dashboard Settings' noIcon={true} position="bottom" />
                                <EditRoundedIcon fontSize="inherit" />
                            </div>
                        </button>
                        <button onClick={() => dashboardActions('reset')}>
                            <div>
                                <Tooltip text='Reset Layout' noIcon={true} position="bottom" />
                                <ReplayIcon fontSize="inherit" id="resetLayoutButton" />
                            </div>
                        </button>
                        <button onClick={() => dashboardActions('save')}>
                            <div>
                                <Tooltip text='Save Layout' noIcon={true} position="bottom" />
                                <SaveIcon fontSize="inherit" id="saveLayoutButton" className="saveLayoutButton" />
                                <CheckIcon fontSize="inherit" id="layoutSaved" className="layoutSaved" />
                            </div>
                        </button>
                        <button className="editDashboardFlyout__delete" onClick={() => deleteThisDashboard('prompt')}>
                            <div>
                                <Tooltip text='Delete Dashboard' noIcon={true} position="bottom" />
                                <DeleteForeverIcon fontSize="inherit" />
                            </div>
                        </button>
                    </div>
                }
                {deleteDashboardMode &&
                    <React.Fragment>
                        <div className="flyout__deleteMode">
                            <span>Warning: These actions cannot be undone.</span>
                            <button className="medButtonSecondary" onClick={() => deleteThisDashboard('delete')}>Delete Dashboard</button>
                            <button className="exit" onClick={() => deleteThisDashboard('cancel')}><CloseIcon fontSize="large" /></button>
                        </div>
                    </React.Fragment>
                }
                <button className="medButtonSecondary close" onClick={() => { props.layoutDirty ? layoutPromptTrigger() : closeFlyout() }}>Close</button>
            </div>
        </div>
    )
}

export default EditDashboardFlyout;