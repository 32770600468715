import React from 'react';
import TestKitDetailsForm from './TestKitDetailsForm';
import editModeIcon from '../../../images/editMode.svg';
import {
	getEquipment, updateEquipment, createEquipment, deleteEquipment,
	getEquipmentCompliance, addEquipmentToServiceProvider
} from '../../api/equipmentAPI';
import { getServiceProviderEquipmentReport, getServiceProviderUsersReport, deleteServiceProviderEquipment } from '../../api/serviceProvidersAPI';
import { getReportValue } from '../../utils/reporting';
import { APIUserError } from '../../api/api';
import { checkDuplicates } from '../../api/duplicatesAPI';
import { ModalDuplicateContext } from '../../contexts/ModalDuplicateContext';
import { updateURL } from '../../utils/url';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CloseIcon from '@material-ui/icons/Close';

class TestKitDetails extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			serviceProviderUUID: (props.source === 'serviceProvider') ? props.sourceUUID : null,
			editMode: false,
			deleteMode: false,
			newAddition: props.newAddition,
			apiErrors: {},
			data: {},
			lastTest: null,
			serviceProviderUsers: []
		};
	}

	componentDidMount() {
		if (this.state.newAddition) {
			this.toggleEditMode();
			let data = { c3_additional_details: {} };
			this.setState({ data });
			this.getServiceProviderUsers();
		} else {
			this.getTestKitData(this.props.uuid);
			this.getServiceProviderUsers();
		}
	}

	getTestKitData = async (uuid) => {
		if (uuid !== undefined && uuid !== '') {
			let assemblyData = await getEquipment(uuid);
			assemblyData.c3_additional_details = {};
			this.props.setHeader(assemblyData.serial_number);
			let inspection = null;
			try {
				inspection = await getEquipmentCompliance(assemblyData.equipment_uuid, "Test Kit Calibration");
			} catch (e) {
				// ignore, this will 404 if there was no inspection entered yet
			}

			let reportingParams = { inputs: { "Property UUID": this.props.uuid } };
			if (this.state.serviceProviderUUID !== null) {
				reportingParams.inputs["Service Provider UUID"] = this.state.serviceProviderUUID;
			}

			let reportOutput = await getServiceProviderEquipmentReport(undefined, { ...reportingParams, count: 100 });
			reportOutput.data.rows.forEach(row => {
				if (getReportValue(reportOutput.data, row, 'Equipment UUID') === assemblyData.equipment_uuid) {
					assemblyData.owner = getReportValue(reportOutput.data, row, 'Owner');
					let assigned_to = getReportValue(reportOutput.data, row, 'User UUID');
					if (assigned_to !== "") {
						assemblyData.assigned_to = assigned_to
					}
				}
			});

			this.setState({ data: assemblyData, lastTest: inspection });
		}
	}

	getServiceProviderUsers = () => {
		getServiceProviderUsersReport(this.state.serviceProviderUUID, { count: 100 }).then(response => {
			let serviceProviderUsers = [];
			response.data.rows.map((row, idx) => {
				let uuid = getReportValue(response.data, row, 'User UUID');
				let name = getReportValue(response.data, row, 'Name');
				let status = getReportValue(response.data, row, 'Status');
				let approved_until = getReportValue(response.data, row, 'Approved Until');
				// if we have the approved until make it a number
				if (approved_until != "") {
					approved_until = parseInt(approved_until, 10);
				}
				serviceProviderUsers.push({ "uuid": uuid, "name": name, "status": status, "approved_until": approved_until });
			});
			this.setState({ serviceProviderUsers })
		});
	}

	saveDetails = async (ctx, payload) => {
		let dupFields = [
			{ name: 'Type', data: 'equipment_type' },
			{ name: 'Make', data: 'make' },
			{ name: 'Model', data: 'model' }
		];

		if (this.state.newAddition && this.props.duplicateCheck) {
			this.props.duplicateCheck(
				ctx,
				payload,
				dupFields,
				this.useSelectedDuplicate,
				this.saveNewTestKit
			);
			return;
		}

		if (this.state.newAddition) {
			checkDuplicates('equipment', payload.serial_number, [{ field: "equipment_type", value: "TK", operator: "neq" }]).then(response => {
				if (response.duplicates.length > 0) {
					// FIXME we don't have a test kit list yet, this isn't the right url to send them to
					ctx.setDuplicates(
						response.duplicates,
						dupFields,
						null,
						payload,
						this.saveNewTestKit,
						this.useSelectedDuplicate
					);
				} else {
					this.saveNewTestKit(payload);
				}
			});
		} else {
			try {
				if (this.props.update) {
					await this.props.update(payload);
				}

				const updatedEquipment = await updateEquipment(this.state.data['equipment_uuid'], payload);
				this.props.setHeader(updatedEquipment.serial_number);
				await this.props.refresh();
				await this.props.openFlyout(updatedEquipment);
				this.setState(() => ({ editMode: false }));
			}
			catch (error) {
				if (error instanceof APIUserError) {
					this.setState({ apiErrors: error.apiFieldErrors });
				}
			}
		}
	};

	saveNewTestKit = async (payload) => {
		try {
			let assemblyData;
			if (this.props.create) {
				assemblyData = await this.props.create(payload);
			} else {
				assemblyData = await createEquipment(payload);
			}
			this.setState({ editMode: false, newAddition: false });

			this.props.setHeader(assemblyData.serial_number);

			await this.getTestKitData(assemblyData.equipment_uuid);
			await this.props.refresh();
			await this.props.openFlyout(this.state.data, null);
		}
		catch (error) {
			if (error instanceof APIUserError) {
				this.setState({ apiErrors: error.apiFieldErrors });
			}
		}
	}

	useSelectedDuplicate = async (uuid, payload) => {
		payload.owner = false;
		await addEquipmentToServiceProvider(this.props.sourceUUID, uuid, payload);
		this.setState({ editMode: false, newAddition: false });
		await this.getTestKitData(uuid);
		if (this.props.refresh) {
			this.props.refresh();
		}
		await this.props.openFlyout(this.state.data, null);
	}

	toggleEditMode = () => {
		this.setState((prevState) => ({ editMode: !prevState.editMode, deleteMode: false }));
	};

	deleteThisEquipment = async (type) => {
		if (type === 'delete') {
			await deleteEquipment(this.state.data['equipment_uuid']);
			await this.props.refresh();
			await this.props.closeFlyout();
		} else if (type === 'cancel') {
			this.setState(() => ({ deleteMode: false }));
		} else if (type === 'remove') {
			await deleteServiceProviderEquipment(this.props.sourceUUID, this.state.data['equipment_uuid']);
			await this.props.refresh();
			await this.props.closeFlyout();
		} else {
			this.setState(() => ({ deleteMode: true }), () => {
				setTimeout(() => {
					document.getElementById('flyoutBase').scrollIntoView(false);
				}, 100);
			});
		}
	};

	render() {
		if (!this.state.data) {
			return <div />;
		}
		return (
			<ModalDuplicateContext.Consumer>
				{(ctx) => {
					return (
						<div className="flyoutContentContainer" id="flyoutBase">
							<button className="editModeButton" onClick={() => this.toggleEditMode()}>
								{!this.state.editMode && (
									<img src={editModeIcon} alt='' />
								)}
							</button>
							{this.state.data.c3_additional_details !== undefined && (
								<TestKitDetailsForm
									context={ctx}
									data={this.state.data}
									lastTest={this.state.lastTest}
									editMode={this.state.editMode}
									saveDetails={this.saveDetails}
									toggleEditMode={this.toggleEditMode}
									newAddition={this.props.newAddition}
									closeFlyout={this.props.closeFlyout}
									apiErrors={this.state.apiErrors}
									serviceProviderUsers={this.state.serviceProviderUsers}
									defaultAssignTo={this.props.defaultAssignTo}
								/>
							)}
							{this.state.editMode && !this.state.deleteMode && !this.props.newAddition && (
								<div className="deleteButton__container">
									<button className="deleteButton" onClick={() => this.deleteThisEquipment()}><DeleteForeverIcon fontSize='inherit' color='inherit' /></button>
								</div>
							)}
							{this.state.editMode && this.state.deleteMode && (
								<div className="flyout__deleteMode">
									<span>Warning: These actions cannot be undone.</span>
									<button className="medButtonSecondary" type="button" onClick={() => this.deleteThisEquipment('remove')}>Remove from Service Provider</button>
									{SwiftComply.user.user_type === 'normal' &&
										<button className="medButtonSecondary" type="button" onClick={() => this.deleteThisEquipment('delete')}>Delete</button>
									}
									<button className="exit" onClick={() => this.deleteThisEquipment('cancel')}><CloseIcon fontSize="large" /></button>
								</div>
							)}
						</div>
					);
				}}
			</ModalDuplicateContext.Consumer>
		);
	}
}

export default TestKitDetails;