import React, { useState } from "react";
import { FormControl, FormHelperText, TextField } from "@material-ui/core";
import { scheduleToText } from "../../../../utils/forms";
import helperTextStyles from "../../../../styles/common/matieralUI/helperText.module.css";
import SchedulingDialog from "./SchedulingDialog";

export const ScheduleFieldInput = ({ label, value, error, onClick }) => (
    <FormControl error={!!error}>
        <TextField
            onClick={onClick}
            InputProps={{ readOnly: true }}
            label={value ? label : `${label} - Click to set`}
            variant="outlined"
            value={value? scheduleToText(value) : ""}
            error={!!error}
        />
        {error && <FormHelperText classes={helperTextStyles}>{error}</FormHelperText>}
    </FormControl>
);

const ScheduleField = ({ label, value, setValue, error }) => {
    const [showDialog, setShowDialog] = useState(false);

    return (
        <>
            <ScheduleFieldInput
                label={label}
                value={value}
                error={error}
                onClick={() => setShowDialog(true)}
            />
            <SchedulingDialog
                title={label}
                open={showDialog}
                onClose={() => setShowDialog(false)}
                value={value}
                setValue={setValue}
            />
        </>
    );
};

export default ScheduleField;
