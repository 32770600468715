import React, { useState, useEffect } from 'react';
import Table from './Table';
import { getReport } from '../api/reportingAPI';
import { getDefaultReportingParams, setReportingParams } from '../utils/reporting';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import { Capitalize } from '../utils/misc';
import LoaderOverlay from './LoaderOverlay';

class DialogTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            reportOutput: null,
            reportingParams: getDefaultReportingParams(),
            searchInput: {}
        }

        if (this.props.searches) {
            this.props.searches.forEach(search => {
                this.state.searchInput[search] = '';
            })
        }

    }

    componentDidMount = () => {
        this.getTableData();
    }

    getTableData = (reportingParams) => {
        if (reportingParams === undefined) {
            reportingParams = this.state.reportingParams;
        }

        if (this.props.inputs) {
            reportingParams.inputs = this.props.inputs.inputs;
        }
        if (this.props.search) {
            const search = this.props.search.search;
            reportingParams.search = Object.assign(reportingParams.search, search)
        }
        if (this.props.count) {
            reportingParams.count = this.props.count;
        }

        getReport(this.props.reportUUID, reportingParams).then(response => {
            reportingParams.nextPage = this.state.reportingParams.page;
            if (response.nextPage !== undefined && response.nextPage > this.state.reportingParams.page) {
                reportingParams.nextPage = response.nextPage;
            }
            this.setState(() => ({ reportOutput: response.data, reportingParams }))
            if (this.props.sendReportOutput) {
                this.props.sendReportOutput(response.data);
            }
        })
    }

    handleSearchChange = (e, type) => {
        this.setState((prevState) => ({ searchInput: { ...prevState.searchInput, [type]: e } }))
    }

    search = () => {
        this.setState((prevState) => ({
            reportingParams: {
                ...prevState.reportingParams,
                search: this.state.searchInput
            }
        }), () => {
            this.getTableData(this.state.reportingParams)
        })
    }

    createSearchInputs = () => {

        const searches = this.props.searches;
        let inputs;

        if (searches) {
            inputs = searches.map(search => {
                return (
                    <div className="dialog__search-field" key={search}>
                        <TextField
                            label={Capitalize(search)}
                            name={search}
                            id={search}
                            variant="outlined"
                            value={this.state.searchInput[search]}
                            onChange={(e) => this.handleSearchChange(e.target.value, search)}
                            onKeyDown={(e) => {
                                if (e.keyCode === 13) {
                                    this.search();
                                }
                            }}
                        />
                        {this.clearIcon(search)}
                    </div>
                )
            })
        }

        return inputs;
    }

    clearIcon = (type, reset) => {

        if (type && reset) {
            this.setState((prevState) => ({ searchInput: { ...prevState.searchInput, [type]: '' } }), () => {
                this.search();
            })
        } else {
            if (this.state.searchInput[type].length > 0) {
                return (
                    <button className='clearIcon' onClick={() => this.clearIcon(type, 'reset')}>
                        <ClearIcon fontSize="large" />
                    </button>
                )
            }
        }
    }

    // -----------------------------
    // table / reporting functions
    // -----------------------------
    setOrdering = (column) => {
        let newReportingParams = setReportingParams(this.state.reportingParams, { column }, false);
        this.getTableData(newReportingParams);
    }

    fetchData = (direction) => {
        let newReportingParams = setReportingParams(this.state.reportingParams, { direction }, false);
        if (newReportingParams !== undefined) {
            this.getTableData(newReportingParams);
        }
    }
    // END table / reporting functions

    render() {
        return (
            <React.Fragment>
                {this.state.reportOutput ? (
                    <div>
                        {this.props.searches &&
                            <div className="dialog__search">
                                {this.createSearchInputs()}
                                <button className="xsmallButtonPrimary" onClick={() => this.search()}>
                                    <SearchIcon fontSize='large' />
                                </button>
                            </div>}
                        <Table
                            tableData={this.state.reportOutput}
                            tableType={this.props.tableType}
                            tableSize={this.props.count && this.state.reportOutput?.rows.length > 10 ? 'small' : 'smallFlex'}
                            columnsToShow={this.props.columnsToShow}
                            openFlyout={this.props.openFlyout}
                            fetchData={this.fetchData}
                            setOrdering={this.setOrdering}
                            paginationParams={this.state.reportingParams}
                            highlight={true}
                            multiSelect={this.props.multiSelect}
                            selected={this.props.selected}
                            pageSize={this.props.count ? this.props.count : null}
                        />
                    </div>
                ) : <LoaderOverlay />}
            </React.Fragment>
        )
    }
}

export default DialogTable;