import axios from 'axios';

// Users

export const getUsersReport = (userType, payload) => {
	const url = '/api/v1/reporting/f60dbccd-0d3e-4987-b78e-fbb46a0e2a27';

	if (userType !== undefined && userType !== null) {
		payload.inputs['user type'] = userType;
	}

	return axios.post(url, payload)
		.then(response => {
			console.log('Got Users Report!', response);
			return { data: response.data, nextPage: response.headers['x-scc3-reporting-next-page'] };
		})
		.catch(error => {
			console.log('Couldn\'t get Users Report!', error.response.data);
			throw error;
		});
};

const baseURL = '/api/v1/user';

export const createUser = (payload) => {
	const url = baseURL;

	return axios.post(url, payload)
		.then(response => {
			console.log('Created User!', response);
			return response.data;
		})
		.catch(error => {
			console.log("Couldn't create User", error.response.data);
			throw error;
		});
};

export const getUser = (userUUID) => {
	const url = `${baseURL}s/${userUUID}`; // add the 's' because users paths are weird

	return axios.get(url)
		.then(response => {
			console.log('Got User!', response);
			return response.data;
		})
		.catch(error => {
			console.log("Couldn't get User", error.response.data);
			throw error;
		});
};

export const deleteUser = (userUUID) => {
	const url = `${baseURL}/${userUUID}`;

	return axios.delete(url)
		.then(response => {
			console.log('Deleted User!', response);
			return response.data;
		})
		.catch(error => {
			console.log("Couldn't delete user", error.response.data);
			throw error;
		});
};

export const getUserRoles = (userUUID) => {
	const url = `/api/v1/users/${userUUID}/roles`;

	return axios.get(url)
		.then(response => {
			console.log('Got user roles', response);
			return response.data;
		})
		.catch(error => {
			console.log("Couldn't get user roles", error.response.data);
			throw error;
		});
};

export const inviteUser = (payload) => {
	const url = '/api/v1/users/invites';

	return axios.post(url, payload)
		.then(response => {
			console.log('Invited new user', response);
			return response.data;
		})
		.catch(error => {
			console.log("Couldn't invite new user", error.response.data);
			throw error;
		});
};

export const removeUserRoles = (userUUID) => {
	const url = `/api/v1/users/${userUUID}/roles`;

	let payload = {
		user_uuid: userUUID,
		roles: []
	}

	return axios.put(url, payload)
		.then(response => {
			console.log('Removed all roles from user', response);
			return response.data;
		})
		.catch(error => {
			console.log("Couldn't remove all roles from user", error.response.data);
			throw error;
		});
};

export const removeUserRole = (userUUID, role) => {
	const url = `/api/v1/users/${userUUID}/role/${role}`;

	return axios.delete(url)
		.then(response => {
			console.log(`Removed ${role} role from user`, response);
			return response.data;
		})
		.catch(error => {
			console.log(`Couldn\'t remove ${role} role from user`, error.response.data);
			throw error;
		});
};

export const addUserRole = (userUUID, role) => {
	const url = `/api/v1/users/${userUUID}/roles`;

	let payload = {
		user_uuid: userUUID,
		roles: [{ role_name: role }]
	}

	return axios.post(url, payload)
		.then(response => {
			console.log(`Added ${role} role to user`, response);
			return response.data;
		})
		.catch(error => {
			console.log(`Couldn\'t add ${role} role to user`, error.response.data);
			throw error;
		});
};

// User certifications
export const getUserCertifications = (userUUID) => {
	let url = '';
	// if we don't pass in a user, assume we are asking for our own certs
	if (userUUID === undefined) {
		url = `${baseURL}/certification`;
	} else {
		url = `${baseURL}s/${userUUID}/certification`;
	}

	return axios.get(url)
		.then(response => {
			console.log('Got User Certifications!', response);
			return response.data;
		})
		.catch(error => {
			console.log("Couldn't get User Certifications", error.response.data);
			throw error;
		});
};

export const createUserCertification = (payload) => {
	let url;

	url = `${baseURL}/certification`;

	return axios.post(url, payload)
		.then(response => {
			console.log('Created User Certifications!', response);
			return response.data;
		})
		.catch(error => {
			console.log("Couldn't create User Certifications", error.response.data);
			throw error;
		});
};

export const updateUserCertification = (certificationUUID, payload) => {
	let url;

	url = `${baseURL}/certification/${certificationUUID}`;

	return axios.put(url, payload)
		.then(response => {
			console.log('Updated User Certifications!', response);
			return response.data;
		})
		.catch(error => {
			console.log("Couldn't update User Certifications", error.response.data);
			throw error;
		});
};

export const deleteUserCertification = (certificationUUID) => {
	const url = `${baseURL}/certification/${certificationUUID}`;

	return axios.delete(url)
		.then(response => {
			console.log('Deleted User Certifications!', response);
			return response.data;
		})
		.catch(error => {
			console.log("Couldn't delete User Certifications", error.response.data);
			throw error;
		});
};

export const checkCertificationCompliance = (userUUID) => {
	const url = `/api/v1/users/${userUUID}/certification/check`;

	return axios.get(url)
		.then(response => {
			console.log('Checked certification compliance', response);
			return response.data;
		})
		.catch(error => {
			console.log("Couldn't check certification compliance", error.response.data);
			throw error;
		});
};

// For users acting on their own object

export const getOwnUser = () => {

	return axios.get(baseURL)
		.then(response => {
			console.log('Got own User!', response);
			// since this might get called a few times, if we already have preferences set, store them and put them
			// back once we overwrite the user object;
			let preferences = {};
			if (SwiftComply.user.preferences) {
				preferences = SwiftComply.user.preferences;
			}
			// 2021-03-10 BC - ok, why is this needed???  I was looking into the issue in index.js where the catch
			// and redirect to / would overwrite the whereTo value.  Turns out, after having to log stuff to localstorage
			// that we were throwing an error here where response was undefined.  I can't for the life of me right now
			// think of how we would get in here with an undefined response, like it should at LEAST have the http
			// status or something right?  RIGHT?
			if (response) {
				SwiftComply.user = response.data;
				SwiftComply.user.preferences = preferences;
			}
			return SwiftComply.user;
		})
		.catch(error => {
			console.log("Couldn't get own User", error);
			throw error;
		});
};

export const editOwnUser = (payload) => {
	const url = `${baseURL}`;

	return axios.put(url, payload)
		.then(response => {
			console.log('Edited own user', response);
			return response.data;
		})
		.catch(error => {
			console.log("Couldn't edit own user", error.response.data);
			throw error;
		});
};

export const updatePassword = (payload) => {
	const url = `${baseURL}/password`;

	return axios.put(url, payload)
		.then(response => {
			console.log('Updated Password', response);
			return response.data;
		})
		.catch(error => {
			console.log("Couldn't update password", error.response.data);
			throw error;
		});
};

export const createCertificationUpload = (payload) => {
	console.log(payload)
	const url = `${baseURL}/certification/upload`;

	return axios.post(url, payload)
		.then(response => {
			console.log('Successfully linked file to certification', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t link file to certification', error.response.data);
			throw error;
		});
};

export const getCertificationUploads = (certUUID, useAdminUrl) => {
	let url = `${baseURL}/certification/upload/${certUUID}`;
	if (useAdminUrl) {
		url = `${baseURL}s/certification/upload/${certUUID}`;
	}

	return axios.get(url)
		.then(response => {
			console.log('Got uploads for user certification', response);
			return response.data;
		})
		.catch(error => {
			console.log('Couldn\'t get uploads for user certification', error.response.data);
			throw error;
		});
};

// user preferences
export const getUserPreferences = () => {
	return axios.get(baseURL + "/preferences")
		.then(response => {
			SwiftComply.user.preferences = response.data.preferences;
			return response.data.preferences;
		})
		.catch(error => {
			throw error;
		});
}

export const setUserPreference = (pref, value) => {
	if (value === undefined || value === null) {
		value = ""
	} else if (typeof (value) !== "string") {
		value = JSON.stringify(value);
	}
	let payload = {
		"preference": pref,
		"value": value
	}

	return axios.put(baseURL + "/preferences", payload)
		.then(response => {
			SwiftComply.user.preferences = response.data.preferences;
			return response.data.preferences;
		})
		.catch(error => {
			throw error;
		});
}

// the backend enforces this is only for swiftcomply admins to help customers fix issues
export const updateUser = (userUUID, payload) => {
	const url = `/api/v1/users/${userUUID}`;

	return axios.put(url, payload)
		.then(response => {
			return response.data;
		})
		.catch(error => {
			throw error;
		});
}

// Auto Accept Settings

export const listAutoAcceptSettings = (userUUID) => {
	const url = `/api/v1/users/${userUUID}/auto_accept`;

	return axios.get(url)
		.then(response => {
			console.log('Got auto accept settings', response);
			return { data: response.data, nextPage: response.headers['x-scc3-reporting-next-page'] };
		})
		.catch(error => {
			console.log('Couldn\'t get auto accept settings', error.response.data);
			throw error;
		});
};

export const getAutoAcceptSetting = (userUUID, formType) => {
	const url = `/api/v1/users/${userUUID}/auto_accept/${formType}`;

	return axios.get(url)
		.then(response => {
			console.log('Got auto accept setting', response);
			return { data: response.data, nextPage: response.headers['x-scc3-reporting-next-page'] };
		})
		.catch(error => {
			console.log('Couldn\'t get auto accept setting', error.response.data);
			throw error;
		});
};

export const updateAutoAcceptSetting = (userUUID, formType, payload) => {
	const url = `/api/v1/users/${userUUID}/auto_accept/${formType}`;

	return axios.put(url, payload)
		.then(response => {
			console.log('Updated auto accept setting', response);
			return { data: response.data, nextPage: response.headers['x-scc3-reporting-next-page'] };
		})
		.catch(error => {
			console.log('Couldn\'t update auto accept setting', error.response.data);
			throw error;
		});
};

export const removeAutoAcceptSetting = (userUUID, formType) => {
	const url = `/api/v1/users/${userUUID}/auto_accept/${formType}`;

	return axios.delete(url)
		.then(response => {
			console.log('Deleted auto accept setting', response);
			return { data: response.data, nextPage: response.headers['x-scc3-reporting-next-page'] };
		})
		.catch(error => {
			console.log('Couldn\'t delete auto accept setting', error.response.data);
			throw error;
		});
};
