import React from "react";
import { getURLModule } from "../../utils/url";
import { getErrorText } from "../../utils/errors";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Card from "./Card";
import LoadingSpinner from "./LoadingSpinner";
import styles from "../../styles/common/page.module.css";
import transitions from "../../styles/common/transitions.module.css";
import {
    AccountBalanceRounded,
    AssessmentRounded,
    AssignmentRounded,
    BallotRounded,
    BuildRounded,
    CalendarTodayRounded,
    DashboardRounded,
    ImportContactsRounded,
    LocationCityRounded,
    MailRounded,
    NotificationsRounded,
    NotInterestedRounded,
    PersonRounded,
    SchoolRounded,
    SettingsRounded,
    VerifiedUserRounded,
    WarningRounded,
} from "@material-ui/icons";
import AssembliesIcon from "../../../images/assemblies";

/**
 * A generic "page" that displays the header and sidebar navigation
 *
 * @param {Object|string} [error] - If present, will be displayed as a card and all other content will be omitted
 * @param {boolean} [loading] - If true, the loading spinner will be displayed and all other content will be omitted
 * @param {React.ReactNode} children - Page content
 */
const Page = ({ error, loading, children }) => {
    const { module } = getURLModule();
    let sections;

    switch (module) {
        case "pretreatment":
            sections = [
                { title: "Dashboard", icon: DashboardRounded },
                { title: "Notifications", icon: NotificationsRounded },
                { divider: true },
                { title: "Industrial Users", icon: LocationCityRounded, link: "/pt/iu" },
                { title: "Sampling Results", icon: AssignmentRounded },
                { title: "Permitting", icon: VerifiedUserRounded, link: "/pt/p" },
                { title: "Inspections", icon: BallotRounded },
                { title: "Violations", icon: WarningRounded },
                { title: "Non Compliance Actions", icon: NotInterestedRounded },
                { title: "Communications", icon: MailRounded },
                { title: "Calendar", icon: CalendarTodayRounded },
                { title: "Reporting", icon: AssessmentRounded },
                { title: "Users", icon: PersonRounded },
                { title: "Configuration", icon: SettingsRounded, link: "/pt/cfg" },
            ];
            break;

        default:
        case "backflow":
            sections = [
                { title: "Dashboard", icon: DashboardRounded, link: "/bf/dashboard" },
                { title: "Notifications", icon: NotificationsRounded },
                { divider: true },
                { title: "Locations", icon: LocationCityRounded, link: "/bf/lo" },
                { title: "Contacts", icon: ImportContactsRounded, link: "/bf/c" },
                { title: "Service Providers", icon: BuildRounded },
                { title: "Assemblies", icon: AssembliesIcon },
                { title: "Test Reports", icon: AssignmentRounded },
                { title: "Surveys", icon: BallotRounded },
                { title: "Reporting", icon: AssessmentRounded, link: "/bf/datatables" },
                { title: "Users", icon: PersonRounded },
                { title: "Communications", icon: MailRounded },
                { title: "State Reports", icon: AccountBalanceRounded },
                { title: "Settings", icon: SettingsRounded },
                { title: "Knowledge Base", icon: SchoolRounded, link: "https://knowledge.swiftcomply.com/" },
            ];
            break;
    }

    let content;

    if (error) {
        content = <Card title="Error">{getErrorText(error)}</Card>;
    } else if (loading) {
        content = <LoadingSpinner />;
    } else {
        content = children;
    }

    return (
        <div className={styles.page}>
            <Header />
            <div className={styles.main}>
                <Sidebar sections={sections} />
                <div className={`${styles.content} ${transitions.fast}`}>{content}</div>
            </div>
        </div>
    );
};

export default Page;
