import React, { useState, useEffect } from 'react';
import { getBillingSyncStatus, approveSyncPlan, uploadCSV } from '../../api/billingSyncAPI';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import LoaderOverlay from '../LoaderOverlay';
import BillingSyncSettings from './BillingSyncSettings';

const BillingSync = ({ sendStatus }) => {

    const [status, setStatus] = useState(null);
    const [fileUploaded, setFileUploaded] = useState(null);
    const [warnings, setWarnings] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [approveButtonIsDisabled, setApproveButtonIsDisabled] = useState(false);

    useEffect(() => {
        getStatus();
    }, [])

    let statusPollInterval = 3000;
    let apiPoll = setTimeout(getStatus, statusPollInterval);

    const hiddenFileInput = React.useRef(null);

    const handleUploadClick = (e) => {
        hiddenFileInput.current.click();
    }

    const getStatus = async () => {
        const statusResponse = await getBillingSyncStatus();
        let topLevelWarnings = [];

        // Pull out any errors or warnings. We will display those to the user on the UI.
        if (statusResponse.errors) {
            let errors = JSON.parse(statusResponse.errors);
            if (errors && Array.isArray(errors) && errors.length > 0) {
                errors.forEach((error) => {
                    let field = ""
                    if (error.field) {
                        field += " (" + error.field + ") ";
                    }
                    topLevelWarnings.push(error.message + field);
                });
            }
        }
        if (statusResponse.warnings) {
            let warnings = JSON.parse(statusResponse.warnings);
            let lineWarningsExist = false;
            if (warnings && Array.isArray(warnings) && warnings.length > 0) {
                warnings.forEach((warning) => {
                    // Only display warnings that aren't related to a specific line in the CSV
                    // file because those appear in the sync plan and there could be a large
                    // number of them. However, let the user know they exist.
                    if ("line" in warning) {
                        lineWarningsExist = true;
                    } else {
                        let field = ""
                        if (warning.field) {
                            field += " (" + warning.field + ") ";
                        }
                        topLevelWarnings.push(warning.message + field);
                    }
                });
            }
            if (lineWarningsExist) {
                let warningMsg = "The sync plan contains line-specific warnings. Please download and review";
                if (statusResponse.status === "waiting for review") {
                    warningMsg += " before approving";
                }
                warningMsg += ".";
                topLevelWarnings.push(warningMsg);
            }
        }

        // The following status responses indicate we should continue polling the status until the current
        // billing sync action is completed.
        let repollStatus = {
            'sync file uploaded': true,
            'generating sync plan': true,
            'ready to apply': true,
            'applying sync plan': true
        };

        if (repollStatus[statusResponse.status]) {
            apiPoll = setTimeout(getStatus, statusPollInterval);
            setShowLoader(true);
        } else {
            clearTimeout(apiPoll);
            setShowLoader(false);
        }

        // Intead of showing the Postgres trigger statuses, show the status that is about to happen.
        if (statusResponse.status === 'sync file uploaded') {
            statusResponse.status = 'generating sync plan';
        } else if (statusResponse.status === 'ready to apply') {
            statusResponse.status = 'applying sync plan';
        }

        setWarnings(topLevelWarnings);
        setStatus(statusResponse);
        sendStatus(statusResponse.status);
    }

    const upload = async () => {
        const data = new FormData();
        data.append('file', fileUploaded);

        try {
            setStatus({ status: "uploading sync file" });
            setWarnings([]);
            setShowLoader(true);
            await uploadCSV(data);
        }
        catch (error) {
            setShowLoader(false);
            let msg = "There was an error processing your uploaded file: ";
            if (error.message) {
                msg += error.message + ". ";
            }
            msg += "Please fix the issue and re-upload or contact support for assistance.";
            if (error.response && error.response.headers && error.response.headers["x-scc3-request-id"]) {
                msg += "\nError code: " + error.response.headers["x-scc3-request-id"];
            }

            document.getElementById("modal-error-message").innerText = msg;
            document.getElementById("modal-error").classList.remove("hide");
        }

        setFileUploaded(null);
        getStatus();
    }

    const handleFileChange = (e) => {
        const fileUploaded = e.target.files[0];
        e.target.value = "";
        setFileUploaded(fileUploaded);
    }

    const clearUpload = () => {
        setFileUploaded(null);
    }

    const handleModalClose = () => {
        setModalOpen(false);
    };

    const syncPlanActions = (type) => {
        if (type === 'download') {
            window.open("/service/billing_sync/export", "_blank");
        } else if (type === 'approve') {
            setModalOpen(true);
        }
    }

    const handleApproveSyncPlan = async () => {
        setApproveButtonIsDisabled(true);
        setModalOpen(false);
        await approveSyncPlan();
        getStatus();
        setApproveButtonIsDisabled(false);
    }

    const warningsList = () => {
        let list = [];
        warnings.map((warning, idx) => {
            list.push(
                <li key={idx}>{warning}</li>
            )
        });

        return list;
    }


    return (
        <React.Fragment>
            {status && status.status &&
                <React.Fragment>
                    <div className="currentStatus">
                        <span>Current Status: </span><span>{` ${status.status}`}</span>
                    </div>
                    {(status && status.status === "error applying sync plan") ?
                        <div className="currentStatus">
                            <p>There was an internal error applying your last sync plan. Please contact
                                support and reference the following error code: {status.request_id}</p>
                        </div>
                        :
                        <div className="warningsList">
                            {warningsList()}
                        </div>
                    }
                </React.Fragment>
            }
            {showLoader ? <LoaderOverlay /> :
                <div className="billingSyncButtonContainer">
                    {(!status || status.status === "waiting for review" || status.status === "sync completed"
                        || status.status === "error generating sync plan") &&
                        <button className="medButtonSecondary" onClick={handleUploadClick}>
                            Select New CSV File
                        </button>
                    }
                    {fileUploaded &&
                        <button className="medButtonPrimary" onClick={() => upload()}>
                            Start Billing Sync
                        </button>
                    }
                    <input
                        type="file"
                        accept='.csv'
                        ref={hiddenFileInput}
                        onChange={handleFileChange}
                        style={{ display: 'none' }}
                    >
                    </input>
                    {fileUploaded &&
                        <div className="flexAlignCenter">
                            <span>{fileUploaded.name}</span>
                            <button onClick={() => clearUpload()}><CloseIcon style={{ color: '#FF005C' }} fontSize='large' /></button>
                        </div>
                    }
                    {status && (status.status === 'waiting for review' || status.status === 'sync completed') && !fileUploaded &&
                        <React.Fragment>
                            <button className="medButtonSecondary" onClick={() => syncPlanActions('download')}>Download Sync Plan</button>
                            {status.status == 'waiting for review' &&
                                <button className="medButtonPrimary" onClick={() => syncPlanActions('approve')} disabled={approveButtonIsDisabled}>Approve Sync Plan</button>}
                        </React.Fragment>
                    }
                </div>
            }
            <BillingSyncSettings />
            <Dialog className="widget__dialog" id="syncModal" open={modalOpen} onClose={handleModalClose}>
                <DialogContent>
                    <div style={{ textAlign: "center", padding: "3rem" }}>
                        <p>You have selected to Approve the Sync Plan</p>

                        <p style={{ color: "red", fontWeight: "bolder" }}>
                            This will update the data in your system; this action cannot be undone!!
                        </p>

                        <p>We strongly recommend before proceeding that you download a copy of your current
                            records.</p>

                        <p>You can download your current records by clicking Cancel in this window,
                            navigating to the Reporting section and downloading the Locations Contacts Expanded
                            List and if necessary the Backflow Assemblies List. Once complete, return to the
                            Billing Sync section of the Settings page and click the Approve Sync Plan button to
                            return to this window.</p>

                        <p>Press Approve to apply the sync plan to your data now.</p>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button className="smallButtonPrimary" onClick={handleModalClose}>Cancel</button>
                    <button className="smallButtonSecondary" onClick={handleApproveSyncPlan} disabled={approveButtonIsDisabled}>Approve</button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}


export default BillingSync;
