import React, { useState, useEffect } from 'react';
import { updateDownloadRequestStatus, markDownloadRequestSent } from '../../api/communicationsAPI';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import CloseIcon from '@material-ui/icons/Close';

const CommDownloadRequestDetails = (props) => {
    const [status, setStatus] = useState(props.data["Status"]);
    const [processing, setProcessing] = useState(false);
    const [deleteMode, setDeleteMode] = useState(false);

    useEffect(() => {
        props.setHeader("Communications Download Request");
    }, []);

    const updateRequestStatus = (newStatus) => {
        updateDownloadRequestStatus(props.data["communication_download_request_uuid"], newStatus).then(() => {
            props.refresh();
            setStatus(newStatus)
        });
    }

    const markSent = () => {
        setProcessing(true);
        markDownloadRequestSent(props.data["communication_download_request_uuid"]).then(() => {
            setProcessing(false);
            props.refresh();
            setStatus("sent");
        });
    }

    const downloadRequest = () => {
        window.open("/service/communications/dlrequest/download?communication_download_request_uuid=" + props.data["communication_download_request_uuid"], "_blank");
    }

    const downloadButton = () => {
        if (status == 'ready' || status == 'downloaded') {
            if (props.data["size"] > 0) {
                return <button className="medButtonPrimary" onClick={() => downloadRequest()} type="button">Download</button>
            } else {
                return <p>Download contents have expired</p>
            }
        } else if (status == 'expired') {
            return <p>Download contents have expired</p>
        }

        return <></>
    }

    return (
        <div className="flyoutContentContainer">
            <form className="userDetailsForm" id="userDetailsForm">
                <fieldset disabled={true} className="detailsFieldset">
                    <div className='inputField long'>
                        <label>Description
                            <input type="text" name="description" value={props.data["Description"]} readOnly={true} />
                        </label>
                    </div>
                    <div className='inputField medium'>
                        <label>Status
                            <input type="text" name="status" value={status} readOnly={true} />
                        </label>
                    </div>
                    <div className='inputField medium'>
                        <label>Range
                            <input type="text" name="range" value={props.data["Range"]} readOnly={true} />
                        </label>
                    </div>
                    <div className='inputField medium'>
                        <label>Requested On
                            <input type="text" name="req_on" value={props.data["Requested On"]} readOnly={true} />
                        </label>
                    </div>
                    <div className='inputField medium'>
                        <label>Requested By
                            <input type="text" name="req_by" value={props.data["Requested By"]} readOnly={true} />
                        </label>
                    </div>
                </fieldset>
                <p>
                    Downloads are only available for a limited time.  Once a file has been downloaded, you should mark
                    it as downloaded, after which you can mark it as sent, and then completed after that.
                </p>
                <p>
                    If a request expires you will have the option to remove it and then you may request the downloads again.
                </p>
                <div className="formButtonsBank">
                    {downloadButton()}
                </div>
                <div className="flexButtonContainer">
                    {(status == 'ready' || status == 'expired') && (
                        <button className="medButtonSecondary" onClick={() => updateRequestStatus("downloaded")} type="button">Mark Downloaded</button>
                    )}
                    {(status == 'downloaded') && (
                        <button className="medButtonSecondary" onClick={() => markSent()} type="button">
                            {(processing) ? "Processing..." : "Update Comms to Sent"}
                        </button>
                    )}
                    {(status == 'sent') && (
                        <button className="medButtonSecondary" onClick={() => updateRequestStatus("completed")} type="button">Mark Completed</button>
                    )}
                    {(status == 'expired') && (
                        <button className="medButtonSecondary" onClick={() => updateRequestStatus("completed")} type="button">Remove Request</button>
                    )}
                </div>
                {!deleteMode && (
                    <div className="deleteButton__container">
                        <button className="deleteButton" onClick={() => setDeleteMode(true)}><DeleteForeverIcon fontSize='inherit' color='inherit' /></button>
                    </div>
                )}
                {deleteMode && (
                    <div className="flyout__deleteMode">
                        <span className="longWarning">
                            Warning: This will delete the request and cannot be undone.
                            This should only be used to correct mistakes! Use Mark Downloaded / Sent / Completed for normal workflows.
                        </span>
                        <button
                            className="medButtonSecondary"
                            type="button"
                            onClick={() => { updateRequestStatus("completed"); setDeleteMode(false); props.closeFlyout(); }}
                        >
                            Delete Request
                        </button>
                        <button className="exit" onClick={() => setDeleteMode(false)}><CloseIcon fontSize="large" /></button>
                    </div>
                )}
            </form>
        </div>
    );
};

export default CommDownloadRequestDetails;
