/* eslint-disable */
import React from 'react';
import { NavLink } from 'react-router-dom';
import Fieldset from './Fieldset';
import TestReportServiceProviderInfo from './TestReportServiceProviderInfo';
import TestReportLocationAssemblyInfo from './TestReportLocationAssemblyInfo';
import { getForm, getFieldsets, getFormFields } from '../../api/formsAPI';
import { listRulesets, evaluateRuleset } from '../../api/formRulesAPI';
import testReports from '../../../images/testReportsBlue.svg';
import testReportLarge from '../../../images/testReportsBlue.svg';
import {
	createComplianceReport, updateComplianceReport, createComplianceReportDetail,
	getComplianceReport, getComplianceReportDetails, submitComplianceReport
} from '../../api/complianceAPI';
import { createProperty, getProperty } from '../../api/propertiesAPI';
import { createEquipment, getEquipment, createEquipmentProperty, getEquipmentProperty } from '../../api/equipmentAPI';
import { getServiceProvider, getServiceProviderEquipmentReport, getServiceProviderUserInfoReport } from '../../api/serviceProvidersAPI';
import { updateURL, parseQueryStringParams } from '../../utils/url';
import { getReportValue } from '../../utils/reporting';
import validator from 'validator';
import { getOwnUser } from '../../api/usersAPI';
import { checkDuplicates } from '../../api/duplicatesAPI';


class TestReport extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			user: null,
			formFields: [],
			fieldsets: [],
			reportedOnField: null,
			renderedFieldset: [],
			activeFieldset: 0,
			progress: -1,
			overallProgress: 0,
			steps: 0,
			reportPhase: 'locationAssemblyInfo',
			submitted: false,
			reportCreated: false,
			newAssembly: false,
			assemblyInfo: null,
			newLocation: false,
			locationInfo: null,
			assemblyLocationInfo: null,
			serviceProviderInfo: null,
			reporterInfo: null,
			complianceReportInfo: {},
			pendingComplianceReportDetails: {}, // Answers we can't save yet if we don't have a report
			complianceReportDetails: {},
			rulesets: null,
			lastFieldsetIndex: 0,
			messageOptions: {}
		};

		let params = parseQueryStringParams(this.props.location.search);
		// to get here currently you need to go to /testReports/newTestReport and then you can either send an
		// assembly uuid along if this is really new, or report uuid if you are picking up where you left off
		if (params.report && validator.isUUID(params.report)) {
			this.state.complianceReportInfo = { 'compliance_report_uuid': params.report };
			// this phase isn't anything, its more just to not show the SP table while we wait for data to load
			this.state.reportPhase = 'loadingForm';
		} else if (params.assembly && validator.isUUID(params.assembly)) {
			this.state.assemblyInfo = { equipment_uuid: params.assembly };
			this.state.reportPhase = 'serviceProviderInfo';
		} else {
			// if we don't have an assembly ID to make a report for, just bail and kick them to assemblies to pick one
			// FIXME the page renders the table before this is done, so you get a brief flash of things then redirect
			//window.location.replace('/assemblies');
		}

		// JS is extremely dumb, and just doing one of these number checks won't guarantee you that the thing passed in
		// was a number.  ParseInt will parse things like 123lkdjfkj to 123, and Number will return 0 on empty string
		// but by their powers combined, they are Captain ThisIsTheDumbestLanguageEver
		if (params.step && !isNaN(Number.parseInt(params.step, 10)) && !isNaN(Number(params.step)) && params.step >= 1) {
			// this is used to index arrays so starts at 0, but we show to the users as 1,2,3,...
			this.state.progress = Number.parseInt(params.step, 10) - 1;
		}
	}

	// promisedSetState is a helper so we can do things like await setState(...) in places that was not built correctly
	// to handle the async nature of setState.  When this gets redone we should just do it the right way but this will
	// help us for now
	promisedSetState = (newState) => new Promise(resolve => this.setState(newState, resolve));

	componentDidMount() {
		getOwnUser().then(user => {
			this.setState({ user });
			if (this.state.complianceReportInfo.compliance_report_uuid) {
				this.getTestReportFormData();
			}
		})
	}

	// Calling the api to get the backflow assembly test form UUID, 
	// then using that to get the fieldsets for that form and storing it in state
	getTestReportFormData = async () => {
		if (this.state.complianceReportInfo.compliance_report_uuid) {
			let reportData = await getComplianceReport(this.state.complianceReportInfo.compliance_report_uuid);
			let formFieldsets = await getFieldsets(reportData.form_uuid);
			let formFields = await getFormFields(reportData.form_uuid);
			let detailsData = await getComplianceReportDetails(this.state.complianceReportInfo.compliance_report_uuid)
			let assemblyInfo = {};
			let equipProp = {};
			let locationInfo = {};

			try {
				assemblyInfo = await getEquipment(reportData.source_uuid);
				equipProp = (assemblyInfo?.equipment_uuid) ? await getEquipmentProperty(assemblyInfo.equipment_uuid) : {};
				locationInfo = (equipProp?.property_uuid) ? await getProperty(equipProp.property_uuid) : {};
			} catch (error) {
				// if we caught any 404 errors here it is LIKELY they deleted the thing we were looking up.  Since this
				// is a test report that they already started we will just make these empty object since the data is
				// just used for prepopulation stuff
				if (error.response.status == 404) {
					assemblyInfo = {};
					equipProp = {};
					locationInfo = {};
				}
			}

			let serviceProviderInfo = null;
			let reporterInfo = null;
			if (reportData.service_provider_uuid) {
				serviceProviderInfo = await getServiceProvider(reportData.service_provider_uuid);

				if (reportData.reported_by) {
					reporterInfo = await this.getTesterInfo(reportData.reported_by.user_uuid, reportData.service_provider_uuid, true);
				}
			}

			let complianceReportDetails = {};
			detailsData.compliance_report_details.forEach((detail) => {
				complianceReportDetails[detail.form_field_uuid] = detail.value;
			});

			// figure out where they last left off by looking for details in each fieldset, if they saved them, they
			// would have clicked next so move them to the next step
			let reportedOnField = null;
			formFields.form_fields.forEach((field) => {
				if (field.form_field_type === 'Test Date') {
					reportedOnField = field.form_field_uuid;
				}
			});

			this.setState(() => ({
				steps: formFieldsets.form_fieldsets.length,
				submitted: (reportData.status === 'in_progress') ? false : true,
				reportCreated: true,
				complianceReportInfo: reportData,
				complianceReportDetails: complianceReportDetails,
				assemblyInfo: assemblyInfo,
				locationInfo: locationInfo,
				assemblyLocationInfo: equipProp,
				fieldsets: formFieldsets.form_fieldsets,
				formFields: formFields.form_fields,
				reportedOnField: reportedOnField,
				serviceProviderInfo: serviceProviderInfo,
				reporterInfo: reporterInfo
			}), async () => {
				// do these two first as it will build the list of what fieldsets are actually active, and then set
				// the users progress
				await this.getRulesetList(() => {
					this.evaluateFieldsets(() => {
						this.setActiveFieldset(this.state.progress);
						// you have to do this here and rerender since the activeFieldSet call does important work
						this.setState({ reportPhase: 'form' });
					});
				});


			});
		} else {
			let form = await getForm('Backflow Assembly Test');
			let formFieldsets = await getFieldsets(form.form_uuid);
			let formFields = await getFormFields(form.form_uuid);

			let assemblyInfo = this.state.assemblyInfo;
			let locationInfo = this.state.locationInfo;
			let assemblyLocationInfo = this.state.assemblyLocationInfo;
			let newLocation = this.state.newLocation;
			if (this.state.assemblyInfo !== null && this.state.assemblyInfo.equipment_uuid) {
				assemblyInfo = await getEquipment(this.state.assemblyInfo.equipment_uuid);
				let equipProp = await getEquipmentProperty(this.state.assemblyInfo.equipment_uuid);
				if (equipProp.property_uuid) {
					locationInfo = await getProperty(equipProp.property_uuid);
					assemblyLocationInfo = equipProp;
				} else {
					newLocation = true;
				}
			} else if (this.state.locationInfo !== null && this.state.locationInfo.property_uuid) {
				locationInfo = await getProperty(this.state.locationInfo.property_uuid);
			}

			let reportedOnField = null;
			formFields.form_fields.forEach((field) => {
				if (field.form_field_type === 'Test Date') {
					reportedOnField = field.form_field_uuid;
				}
			});

			this.setState(() => (
				{
					steps: formFieldsets.form_fieldsets.length,
					fieldsets: formFieldsets.form_fieldsets,
					formFields: formFields.form_fields,
					reportedOnField: reportedOnField,
					assemblyInfo,
					locationInfo,
					assemblyLocationInfo,
					newLocation
				}
			), () => {
				this.setActiveFieldset();
				this.setState({ reportPhase: 'form' });
				this.getRulesetList(() => { this.evaluateFieldsets(); });
			});
		}
	}

	determineProgress = (
		formFields = this.state.formFields,
		formFieldsets = this.state.fieldsets,
		complianceReportDetails = this.state.complianceReportDetails
	) => {
		// figure out where they last left off by looking for details in each fieldset, if they saved them, they
		// would have clicked next so move them to the next step
		let lastFieldset = null;
		formFields.forEach((field) => {
			if (complianceReportDetails[field.form_field_uuid]) {
				lastFieldset = field.form_fieldset_uuid;
			}
		});

		let progress = 0;
		let overallProgress = 0;
		let foundLastFieldset = false;
		for (let idx = 0; idx < formFieldsets.length; idx++) {
			let fieldset = formFieldsets[idx];
			if (fieldset.form_fieldset_uuid === lastFieldset) {
				foundLastFieldset = true;
				progress = idx;
			} else if (foundLastFieldset && fieldset.active) {
				// this is here because if we found the last fieldset they submitted, next we want to find the next
				// fieldset that is active and actually put them on that, since those are the fields they need to keep
				// filling out.  If it was the last fieldset, we would just ignore this section
				progress = idx;
				break;
			}
		}

		overallProgress = progress;
		if (this.state.progress !== -1 && progress > this.state.progress) {
			// if we went to a step=X and X is < than what we've filled out, let them go back, I guess
			// we should really enable all the breadcrumbs though so they can jump ahead as well
			progress = this.state.progress;
		}

		// if they somehow overran the form, set them back to the last page
		if (progress >= formFieldsets.length) {
			progress = formFieldsets.length - 1;
			overallProgress = progress;
		}

		return { progress, overallProgress };
	}

	getTesterInfo = async (testerUUID, serviceProviderUUID, includeEquipment) => {
		let reportData = await getServiceProviderUserInfoReport(serviceProviderUUID, testerUUID);
		reportData = reportData.data;
		if (reportData.rows.length == 0) {
			// FIXME ???
		}
		let reporterInfo = {
			user_uuid: getReportValue(reportData, reportData.rows[0], "User UUID"),
			name: getReportValue(reportData, reportData.rows[0], "Name"),
			email: getReportValue(reportData, reportData.rows[0], "Email"),
		};
		let certifications = [];
		reportData.rows.forEach(row => {
			certifications.push({
				certifying_agency: getReportValue(reportData, row, "Certifying Agency"),
				certification_number: getReportValue(reportData, row, "Certification Number"),
				certification_type: getReportValue(reportData, row, "Certification Type"),
				expiration_date: getReportValue(reportData, row, "Expiration Date"),
			});
		});
		reporterInfo.certifications = certifications;

		if (includeEquipment) {
			let reportingParams = {
				inputs: {
					Compliant: "true"
				}
			};
			let equipmentOuput = await getServiceProviderEquipmentReport(serviceProviderUUID, reportingParams);
			equipmentOuput = equipmentOuput.data;
			reporterInfo.equipment = [];
			equipmentOuput.rows.forEach(row => {
				reporterInfo.equipment.push({
					equipment_uuid: getReportValue(equipmentOuput, row, "Equipment UUID"),
					service_provider_uuid: getReportValue(equipmentOuput, row, "Service Provider UUID"),
					make: getReportValue(equipmentOuput, row, "Make"),
					model: getReportValue(equipmentOuput, row, "Model"),
					serial_number: getReportValue(equipmentOuput, row, "Serial Number"),
					compliance_date: getReportValue(equipmentOuput, row, "Compliance Date"),
					expiration_date: getReportValue(equipmentOuput, row, "Expiration Date")
				});
			})

		}

		return reporterInfo;
	}

	// Storing the rulesets to state so we can use them when evaluating
	// the field inputs
	getRulesetList = async (callback) => {
		const rulesetData = await listRulesets();
		const rulesets = await rulesetData.rulesets;

		this.setState({ rulesets }, callback);
	}

	// Rendering the fieldset component. I at one point had all of the fieldsets in an array and only showed one
	// to the user, but this means the Fieldsets dont re-render, and we need the fields to either auto-populate,
	// or show the compliance details already present in the DB.
	defineFieldsets = () => {
		const { steps, lastFieldsetIndex, activeFieldset, complianceReportInfo, complianceReportDetails, renderedFieldset } = this.state;

		let prepopulateData = {
			equipment: this.state.assemblyInfo,
			properties: this.state.locationInfo,
			equipment_properties: this.state.assemblyLocationInfo,
			service_providers: this.state.serviceProviderInfo, // todo
			reporter: this.state.reporterInfo
		}

		return (
			<Fieldset
				key={activeFieldset}
				index={activeFieldset}
				active={activeFieldset}
				data={renderedFieldset[0]}
				length={steps}
				submitted={this.state.submitted}
				setActiveFieldset={this.setActiveFieldset}
				submitReport={this.submitReport}
				saveFormInputData={this.saveFormInputData}
				complianceReportInfo={complianceReportInfo}
				complianceReportDetails={complianceReportDetails}
				prepopulateData={prepopulateData}
				isLastFieldset={lastFieldsetIndex === activeFieldset && activeFieldset !== 0}
				messageOptions={this.state.messageOptions}
			/>
		);
	}

	// This function gets the index of the fieldset we want currently active, and puts it in a separate array
	// that gets rendered. 
	setActiveFieldset = async (index = 0) => {
		const { fieldsets, activeFieldset } = this.state;
		let { progress, overallProgress } = this.state;

		// are we asking to go forward or back, this then determines how we move the fieldset index below
		let direction = (index > activeFieldset) ? 'fwd' : 'back';

		while (index >= 0 && index < fieldsets.length) {
			if (fieldsets[index].active) {
				this.setState({
					renderedFieldset: [fieldsets[index]],
					activeFieldset: index,
					progress: (index > progress) ? index : progress,
					overallProgress: (index > overallProgress) ? index : overallProgress,
					messageOptions: {} // reset any errors if we are moving between fieldsets
				});
				if (this.state.complianceReportInfo.compliance_report_uuid) {
					updateURL('/testReports/newTestReport', { report: this.state.complianceReportInfo.compliance_report_uuid, step: fieldsets[index].ordering })
				}
				return;
			}
			if (direction === 'fwd') {
				index++;
			} else {
				index--;
			}
		}
	}

	// Much like initial info below, if a user is making a new "blank" report they need to look up the location and
	// the assembly, if they don't pass info for one of them, we will assume it is new.  This should be fine because
	// we run a dupe check on both before creating them
	getLocationAssemblyInfo = (locationUUID, assemblyUUID) => {
		let reportPhase = 'serviceProviderInfo';
		let locationInfo = {};
		let assemblyInfo = {};
		let newLocation = false;
		let newAssembly = false;

		if (!locationUUID) {
			newLocation = true;
		} else {
			locationInfo = { property_uuid: locationUUID };
		}
		if (!assemblyUUID) {
			newAssembly = true;
		} else {
			assemblyInfo = { equipment_uuid: assemblyUUID };
		}

		this.setState({ locationInfo, assemblyInfo, newLocation, newAssembly, reportPhase });
	}

	// This function receives the service provider and tester information from the initial table selections
	// This data to be used when creating compliance report and possibly auto-populating the test report
	getServiceProviderInfo = async (type, data) => {
		if (type === 'serviceProvider') {
			let spInfo = await getServiceProvider(data.service_provider_uuid);
			this.promisedSetState({ serviceProviderInfo: spInfo })
		} else if (type === 'serviceProviderUser') {
			let testerInfo = await this.getTesterInfo(data.user_uuid, data.service_provider_uuid)
			await this.promisedSetState({ reporterInfo: testerInfo });
		} else if (type === 'serviceProviderEquipment') {
			let reporterInfo = this.state.reporterInfo;
			reporterInfo.equipment = [data];
			this.setState({ reporterInfo }, () => {
				this.getTestReportFormData();
			});
		}
	}

	// Called when the user hits the yes button confirming the information is correct 
	// and they move to the next fieldset. 
	submitReport = async (payload, setSubmittedStatus, isLastFieldset) => {
		const { reportCreated, fieldsets, assemblyInfo, locationInfo, serviceProviderInfo, reporterInfo } = this.state;
		const formUUID = fieldsets[0]['form_uuid'];

		// we might not have this if the report hasn't been created, so make it here and then set it to the newly
		// created reportInfo if we have to, so that we know its a sync operation and don't rely on setstate
		let complianceReportInfo = this.state.complianceReportInfo;

		// if the report isn't in progress, we should not be submitting the fields again
		if (
			complianceReportInfo.status &&
			complianceReportInfo.status.toLowerCase() !== "in_progress"
		) {
			if (isLastFieldset) {
				window.location.replace("/testReports/" + this.state.complianceReportInfo.compliance_report_uuid);
			} else {
				this.evaluateFieldsets(() => { this.setActiveFieldset(this.state.activeFieldset + 1) });
			}
			return;
		}

		// Checks to see if compliance report has been created. If not, it creates one, saves that 
		// report data to state and marks that a report has now been created for this Test Report. Finally it will now
		// call the api to create compliance report details for each field in the fieldset using the 
		// payload data. "type" is to determine whether report details need to be created or
		// updated.
		if (!reportCreated) {
			// if we haven't created the report, we are just going to toss all the items in the payload into the 
			// pending details list, so we know we can just look in one place later.  But if we do end up looking in
			// both pending + payload that doesn't matter since the uuid -> answers will be the same.
			let pendingComplianceReportDetails = this.state.pendingComplianceReportDetails;
			Object.keys(payload).forEach(key => {
				pendingComplianceReportDetails[key] = payload[key];
			});
			this.setState({ pendingComplianceReportDetails });

			// If the user indicated that they are at a new location or have a new assembly, we need to make sure we
			// have all the info we need to make a report (i.e. we have both property and equipment).  Until we know we
			// have the info for both, we shouldn't make either as that could lead to duplicates if they close and come
			// back since we can't start the report without both.
			//
			// Each of these functions will call their "handleXCreationOrSelection" function which should, after the
			// equip or prop info is created, will call this submitReport function again to move the progress along,
			// i.e. it will create equip, then call this again, then create a location (maybe), then call this again
			// then proceed with creating the report.
			if (this.state.newAssembly && this.state.newLocation) {
				// if we have both a new assumbly and new location, we need to call one at a time so that the dupe check can trigger for each. 
				this.beginPropertyCreation(true);
			} else if (this.state.newAssembly || this.state.newLocation) {
				if (this.state.newAssembly) {
					this.beginEquipmentCreation();
				}
				if (this.state.newLocation) {
					this.beginPropertyCreation();
				}
				return;
			}

			// if we have enough info, start the report
			if (
				assemblyInfo !== null && assemblyInfo.equipment_uuid !== null
				&& locationInfo !== null && locationInfo.property_uuid !== null
			) {
				const reportInfo = await createComplianceReport({
					source: "equipment",
					source_uuid: assemblyInfo.equipment_uuid,
					form_uuid: formUUID,
					service_provider_uuid: serviceProviderInfo.service_provider_uuid,
					reported_by: reporterInfo.user_uuid,
				});
				complianceReportInfo = reportInfo;
				// set the URL to the report uuid so they don't refresh and end up making a new one
				updateURL('/testReports/newTestReport', { report: reportInfo.compliance_report_uuid, step: 2 });
				this.setState(() => ({ complianceReportInfo: reportInfo, reportCreated: true }));
			}
		}

		if (complianceReportInfo != null && complianceReportInfo.compliance_report_uuid != null) {
			let reportedOn = null;
			Object.keys(payload).forEach(key => {
				if (key === this.state.reportedOnField && payload[key] != "") {
					reportedOn = payload[key];
				}
			});
			if (reportedOn !== null) {
				updateComplianceReport(complianceReportInfo.compliance_report_uuid, reportedOn);
			}

			await this.createReportDetails(payload);
		}

		// They clicked submit or save & submit
		if (setSubmittedStatus) {
			let reportUUID = this.state.complianceReportInfo.compliance_report_uuid;
			let serviceProviderUUID = serviceProviderInfo ? serviceProviderInfo.service_provider_uuid : null;
			submitComplianceReport(reportUUID, serviceProviderUUID).then(response => {
				if (isLastFieldset) {
					// after submitting go back to that report on a flyout, not doing that I felt was confusing
					window.location.replace("/testReports/" + this.state.complianceReportInfo.compliance_report_uuid);
				}
			}).catch(error => {
				let errorMessage = "There was an error submitting the report.";
				if (error.name === 'APIUserError') {
					document.getElementById('componentHeader').scrollIntoView({ behavior: "smooth" });

					// We will need to customize the errors from the backend here depending on what report type this is
					// just mentioning it now in case we generalize this
					if (error.apiFieldErrors.reported_by) {
						errorMessage = "The tester has not yet been approved to submit test reports, the report has been saved, once approved you can try to submit again.";
					}
				} else if (error.response.status == 409) {
					errorMessage = error.response.data.Error;
				} else if (error.response.status == 400) {
					// this is bad since we can tell them the field labels they are missing I believe, but likely they
					// won't get here since a 400 is also likely an APIUserError, also they shouldn't get to this stage
					// since each page should have checked the values
					errorMessage = "Some values may be missing or invalid, please review the form for completeness.";
				}
				this.setState({
					messageOptions: {
						type: "error",
						message: errorMessage
					}
				});
			})
		} else {
			// If we didn't submit and this is the last page, send them back to the report flyout?
			if (isLastFieldset) {
				window.location.replace("/testReports/" + this.state.complianceReportInfo.compliance_report_uuid);
			} else {
				await this.evaluateFieldsets(() => { this.setActiveFieldset(this.state.activeFieldset + 1) });
			}
		}
		// Scroll to top when advancing to next fieldset. If it's the last page we don't need to.
		if (!setSubmittedStatus) {
			setTimeout(() => {
				const header = document.getElementById('componentHeader');
				header.scrollIntoView({ behavior: "smooth" });
			}, 100)
		}
	};

	// checks the current payload and any pending details to see if we have enough info to create an equipment entry,
	// if we do creates and sets the state
	beginEquipmentCreation = async () => {
		let required = ["equipment_type", "make", "model", "serial_number"];
		let request = {};
		this.state.formFields.forEach(field => {
			if (field.populate_from != null && field.populate_from.startsWith("equipment.")) {
				let param = field.populate_from.split('.')[1];
				if (param !== undefined && this.state.pendingComplianceReportDetails[field.form_field_uuid] !== undefined) {
					request[param] = this.state.pendingComplianceReportDetails[field.form_field_uuid];
				}
			}
		});

		let readyToCreate = true;
		required.forEach(param => {
			if (request[param] === undefined) {
				readyToCreate = false;
			}
		});

		if (readyToCreate) {
			await checkDuplicates('equipment', request.serial_number).then(response => {
				if (response.duplicates.length > 0) {
					this.props.duplicateContext.setDuplicates(
						response.duplicates,
						[
							{ name: 'Type', data: 'equipment_type' },
							{ name: 'Make', data: 'make' },
							{ name: 'Model', data: 'model' }
						],
						(this.state.user.user_type === 'service provider') ? null : '/assemblies',
						request,
						(data) => {
							this.handleEquipmentCreationOrSelection(data);
						},
						(equipment_uuid) => {
							this.handleEquipmentCreationOrSelection(null, equipment_uuid);
						},
					);
				} else {
					this.handleEquipmentCreationOrSelection(request);
				}
			});
		}
	}

	// pulling out calling createEquipmentProperty because we want to look to see if we have a form field for the notes
	// on where the assembly is located, so instead of adding the same code in both location creation and assembly
	// creation just do it here.
	doEquipmentPropertyCreation = async () => {
		let notes = "";
		this.state.formFields.forEach(field => {
			if (field.populate_from == "equipment_properties.notes") {
				notes = this.state.pendingComplianceReportDetails[field.form_field_uuid];
			}
		});
		await createEquipmentProperty(this.state.assemblyInfo.equipment_uuid, this.state.locationInfo.property_uuid, notes);
	}

	handleEquipmentCreationOrSelection = async (data, equipment_uuid) => {
		let assemblyInfo = {};
		if (equipment_uuid !== undefined) {
			assemblyInfo = { equipment_uuid };
		} else {
			assemblyInfo = await createEquipment(data);
		}

		this.setState({ newAssembly: false, assemblyInfo }, async () => {
			if (this.state.locationInfo && this.state.locationInfo.property_uuid) {
				this.doEquipmentPropertyCreation();
				// just call submit report with nothing so it runs again, it should have any old payload in its pending data
				this.submitReport({}, false, false);
			} else {
				await this.evaluateFieldsets();
			}
		});
	}

	// checks the current payload and any pending details to see if we have enough info to create an property entry,
	// if we do creates and sets the state
	beginPropertyCreation = async (newEquip) => {
		let required = ["name", "address1", "city", "state_prov", "postal_code", "country_code"];
		let request = { "country_code": SwiftComply.organization.country_code.toLocaleUpperCase() };
		this.state.formFields.forEach(field => {
			if (field.populate_from != null && field.populate_from.startsWith("properties.")) {
				let param = field.populate_from.split('.')[1];
				if (param !== undefined && this.state.pendingComplianceReportDetails[field.form_field_uuid] !== undefined) {
					request[param] = this.state.pendingComplianceReportDetails[field.form_field_uuid];
				}
			}
		});

		let readyToCreate = true;
		required.forEach(param => {
			if (request[param] === undefined) {
				readyToCreate = false;
			}
		});

		if (readyToCreate) {
			await checkDuplicates('properties', request.name).then(response => {
				if (response.duplicates.length > 0) {
					this.props.duplicateContext.setDuplicates(
						response.duplicates,
						[
							{ name: 'address', data: 'address1' },
							'city'
						],
						(this.state.user.user_type === 'service provider') ? null : '/locations',
						request,
						(data) => {
							this.handlePropertyCreationOrSelection(data, null, newEquip);
						},
						(property_uuid) => {
							this.handlePropertyCreationOrSelection(null, property_uuid, newEquip);
						},
					);
				} else {
					this.handlePropertyCreationOrSelection(request, null, newEquip);
				}
			});
		}
	}

	handlePropertyCreationOrSelection = async (data, property_uuid, newEquip) => {
		let locationInfo = {};
		if (property_uuid) {
			locationInfo = { property_uuid };
		} else {
			locationInfo = await createProperty(data);
		}

		this.setState({ newLocation: false, locationInfo }, async () => {
			if (this.state.assemblyInfo && this.state.assemblyInfo.equipment_uuid) {
				this.doEquipmentPropertyCreation();
				// just call submit report with nothing so it runs again, it should have any old payload in its pending data
				this.submitReport({}, false, false);
			} else {
				if (newEquip) {
					await this.evaluateFieldsets();
					this.beginEquipmentCreation();
				} else {
					await this.evaluateFieldsets();
				}
			}
		});
	}

	// Called on submission of each fieldset, this function adds all of the fields submitted in that fieldset with
	// their values to state. This will be used to evaluate fields for the formrules service.
	saveFormInputData = (payload, direction) => {

		this.setState((prevState) => ({ complianceReportDetails: { ...prevState.complianceReportDetails, ...payload } }), async () => {
			await this.evaluateFieldsets(() => {
				// needed to make sure we evaluate the fields before we set the active fieldset so moved it into here, I don't
				// like it but thats how the flow works right now.
				if (direction && direction === 'fwd') {
					this.setActiveFieldset(this.state.activeFieldset + 1);
				}
			});
		});

	};

	// This function will evaluate all the fields stored in the complianceReportDetails array in state. 
	// For each formRule, it evalutes the fieldsets and if the evaluation comes back false,
	// it filters that  fieldset UUID from the list of fieldsets stored in state.
	evaluateFieldsets = async (callback) => {
		const { rulesets, complianceReportDetails } = this.state;
		let updatedFieldsets = this.state.fieldsets;

		// ok so if we just assign the details from state here, javascript will or can treat it as a ref, where
		// if we add data to the object later, it will make its way into the state and then mess us up when we go to
		// save the details since the "stored data" in the state matches the pending values so we think its already
		// saved.
		let evaluationData = Object.assign({}, complianceReportDetails);
		// if this is a report where we had an assembly with no location, we might not have saved yet so everything
		// will be in pending, maybe?
		if (Object.keys(this.state.pendingComplianceReportDetails).length !== 0) {
			Object.keys(this.state.pendingComplianceReportDetails).forEach(key => {
				evaluationData[key] = this.state.pendingComplianceReportDetails[key];
			});
		}

		await Promise.all(rulesets.map(async (rule) => {
			let response = await evaluateRuleset(rule.form_ruleset_uuid, evaluationData);
			for (let i = 0; i < updatedFieldsets.length; i++) {
				if (updatedFieldsets[i].form_fieldset_uuid === rule.form_fieldset_uuid) {
					updatedFieldsets[i].active = response.evaluation;
				}
			}
		}));

		let steps = 0; // wil be the total number of steps (pages) the user will see filling out the form
		let lastFieldsetIndex = 0; // the last active fieldset that the user will see (usually the last index as that is always active)
		for (let idx = 0; idx < updatedFieldsets.length; idx++) {
			if (updatedFieldsets[idx].active) {
				steps++;
				lastFieldsetIndex = idx;
			}
		};

		let progress = this.determineProgress();
		this.setState({
			steps: steps,
			fieldsets: updatedFieldsets,
			lastFieldsetIndex: lastFieldsetIndex,
			progress: progress.progress,
			overallProgress: progress.overallProgress,
		}, callback);
	}

	// Here we are creating or updating complaince report details for each field submitted.
	createReportDetails = async (payload) => {
		const { complianceReportInfo } = this.state;
		let { complianceReportDetails } = this.state;
		const reportUUID = complianceReportInfo['compliance_report_uuid'];

		// if we have any pending details that we couldn't save yet, add them to the payload
		if (Object.keys(this.state.pendingComplianceReportDetails).length !== 0) {
			Object.keys(this.state.pendingComplianceReportDetails).forEach(key => {
				payload[key] = this.state.pendingComplianceReportDetails[key];
			});
		}

		await Promise.all(Object.keys(payload).map(async (key) => {
			const fieldUUID = key;
			const value = payload[key];

			// We can probably use this to decide whether we want to create or update, however the backend was changed
			// so that you can always create these and it will do the right thing, so just call create on anything that
			// has changed (or doesn't exist yet)
			if (!complianceReportDetails[fieldUUID] && value === "") {
				// first, if the detail is undefined then its a new value, but if the value is blank the user probably
				// put something in and erased it so we don't need to store it.
				return;
			}
			if (value !== complianceReportDetails[fieldUUID]) {
				await createComplianceReportDetail(reportUUID, fieldUUID, value);
			}

			complianceReportDetails[fieldUUID] = value;
		}));
		this.setState({ complianceReportDetails, pendingComplianceReportDetails: {} });
	}

	goBack = () => {
		this.props.history.goBack();
	}

	// This populates the breadcrumbs from the available fieldsets stored in state.
	defineBreadCrumbs = () => {
		const { progress, overallProgress, activeFieldset } = this.state;

		return (
			this.state.fieldsets.map((fieldset, idx) => {
				if (fieldset.active) {
					return (
						<div
							key={fieldset + idx}
							onClick={overallProgress >= idx ? () => this.setActiveFieldset(idx) : null}
							className={overallProgress >= idx ? "breadCrumbs__container breadCrumbs__active" : "breadCrumbs__container"}
						>
							<span className={activeFieldset === idx ? "breadCrumbs__breadCrumb breadCrumbs__breadCrumb-active" : "breadCrumbs__breadCrumb"}>
								{fieldset.name}
							</span>
						</div>
					);
				}
			})
		);
	}

	render() {
		const { reportPhase, locationInfo, assemblyInfo } = this.state;

		let header = "";
		if (locationInfo && locationInfo.name) {
			header = locationInfo.name;
		}
		if (assemblyInfo && assemblyInfo.serial_number) {
			if (header != "") {
				header += " "; // if we have a location, put a space, if not it would just be empty string
			}
			header += assemblyInfo.equipment_type + " " + assemblyInfo.serial_number;
		}
		if (header !== "") {
			header = "(" + header + ")";
		}

		return (
			<div className="componentContainer" id="testReport">
				<div className="componentHeader" id="componentHeader">
					<div className="flexAlignCenter">
						<img src={testReports} alt='' />
						<h3><NavLink to='/testReports'>Test Reports</NavLink> / New Test Report {header}</h3>
					</div>
				</div>
				<div className="testReportContainer">
					{this.state.user !== null && reportPhase === 'locationAssemblyInfo' &&
						<TestReportLocationAssemblyInfo
							user={this.state.user}
							sendDataToReport={this.getLocationAssemblyInfo}
						/>
					}
					{this.state.user !== null && reportPhase === 'serviceProviderInfo' &&
						<TestReportServiceProviderInfo
							user={this.state.user}
							sendDataToReport={this.getServiceProviderInfo}
						/>
					}
					{this.state.user !== null && reportPhase === 'form' &&
						<React.Fragment>
							{this.defineFieldsets()}
							< div className="breadCrumbs" >
								{this.defineBreadCrumbs()}
							</div>
						</React.Fragment>
					}

				</div>
				<img className="dashboardIconLarge dashboardIconLarge__adjusted" src={testReportLarge} alt='' />
			</div >
		);
	}
}

export default TestReport;
