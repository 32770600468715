import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { stateProvLists } from "../../../utils/stateProvLists";
import { pickBy } from "lodash";
import styles from "../../../styles/common/form.module.css";
import InputField from "./Fields/InputField";
import SelectField from "./Fields/SelectField";
import RadioField from "./Fields/RadioField";
import CheckboxField from "./Fields/CheckboxField";
import TextAreaField from "./Fields/TextAreaField";
import DateField from "./Fields/DateField";
import MultiSelectField from "./Fields/MultiSelectField";
import IntervalField from "./Fields/IntervalField";
import Snackbar from "../Snackbar";
import ScheduleField from "./Fields/ScheduleField";
import AmountField from "./Fields/AmountField";
import IconButton from "../IconButton";
import { ClearRounded } from "@material-ui/icons";
import ToolTip from "../ToolTip";

/**
 * An object that defines a field toggle
 *
 * @typedef {Object} formFieldToggle
 * @property {string} label - Text displayed on the toggle
 * @property {string} id - The id of associated form field (or API field)
 * @property {"add_to_array"|"enable_field"} behavior - What the toggle actually does
 * @property {(formState:Object) => boolean} [hideWhen] - Callback function that hides the toggle if returns true
 */

/**
 * An object that defines a field on a form
 *
 * @typedef {Object} formField
 * @property {string} id - The name of the field (property) in the formState object
 * @property {string} label - The field name displayed on the form
 * @property {string} [inputType] - The type of control to use (default is "input")
 * @property {string} [dataType] - The type of data in the field (default is "text")
 * @property {string[]} [suggestions] - Array of suggestion values shown as the user is typing
 * @property {{label:string, value:string}[]} [options] - Array of options used to create a drop-down list
 * @property {boolean} [required] - True if this is a required field
 * @property {boolean} [disabled] - Set to disable the form component for this field
 * @property {formFieldToggle} [toggle] - If set, a form will display a "toggle" checkbox along with the input component
 * @property {string} [timeLabel] - Optional label to display on the "Time" input for date/time fields
 * @property {(formState:Object) => boolean} [hideWhen] - Callback function that hides the component if returns true
 * @property {React.Component} [customComponent] - Component to render for fields with inputType "custom"
 * @property {(value:Object) => string} [customDisplay] - Function to display "custom" field's value on the summary grid
 * @property {*} [defaultValue] - Default value
 */

/**
 * Renders a form with the specified fields, updates state based on user input
 * - Note: does not handle form submission, this is left to the parent component
 *
 * @param {formField[]} fields - Array of objects defining the fields to be rendered
 * @param {Object} formState - The current value of all fields
 * @param {React.Dispatch<Object>} onFormStateChange - Called every time any field's value changes
 * @param {{id:string, error:string}[]} [validationErrors] - Array of errors to be displayed on each field
 * @param {React.Dispatch<Object>} onValidationErrorsChange - Called when a field's value changes and we might need to update the inline errors
 * @param {{message:string|JSX.Element, severity?:string, duration?:number}} [generalError]
 *    - If present, will be displayed as a snackbar message above the form
 */
const Form = ({
    fields,
    formState,
    onFormStateChange,
    onLegacyFormStateChange,
    validationErrors = [],
    onValidationErrorsChange = () => {}, // defaults to a no-op
    generalError,
    
}) => {
    const { organization } = useSelector((state) => state.swiftComply);
    const [snackbarMessage, setSnackbarMessage] = useState(null);
    const [hiddenState, setHiddenState] = useState({});
    const errorRef = useRef();

    // Some API fields allow "" as a value but this should be treated as empty by the UI
    const isEmpty = (value) =>
        typeof value === "undefined" || value === "" || value === null || (Array.isArray(value) && !value.length);

    useEffect(() => {
        if (generalError?.message) {
            setSnackbarMessage(generalError);
        }

        if (validationErrors?.length) {
            errorRef?.current?.scrollIntoView({ behavior: "smooth" });
        }

    }, [JSON.stringify(validationErrors), generalError?.message]);

    useEffect(() => {
        // Initialize any fields that have a defaultValue
        // Initialize the "enabled_toggles" array
        //  Note: merge with the "prevState" here because the form may have been passed an initial formState value
        onFormStateChange((prevState) => {
            const newState = {
                ...prevState,
                ...fields
                    .filter((f) => typeof f.defaultValue !== "undefined")
                    .reduce((prev, curr) => ({ ...prev, [curr.id]: curr.defaultValue }), {}),
            };

            const enabled_toggles = fields
                .filter((f) => f.toggle?.behavior === "enable_field" && !isEmpty(newState[f.id]))
                .map((f) => f.id);

            if (enabled_toggles.length) {
                newState.enabled_toggles = enabled_toggles;
            }

            return newState;
        });
    }, []);

    const updateInput = (field, newValue) => {
        
        // when a user changes the value in a field with an inline error, we can remove the error
        onValidationErrorsChange((prevState) => {
            return prevState.filter(error => error.id !== field.id);
        });
        
        onFormStateChange((prevState) => {
            const newState = {
                ...hiddenState, // Restore the state of anything that was previously hidden
                ...prevState, // Get current state of all fields
                [field.id]: newValue, // Add the new value currently being set
            };

            // Find all fields with an "add_to_array" toggle (that isn't hidden)
            const toggleArrayNames = [
                ...new Set(
                    fields
                        ?.filter((f) => f.toggle?.behavior === "add_to_array" && !f.toggle?.hideWhen?.(newState))
                        .map((f) => f.toggle.id)
                ),
            ];

            // Set any "add_to_array" toggles to false for empty fields
            toggleArrayNames.forEach((arr) => {
                if (newState[arr]?.length) {
                    newState[arr] = newState[arr].filter((f) => !isEmpty(newState[f]));
                }
            });

            // Add "enable_field" toggles
            toggleArrayNames.push("enabled_toggles");

            // Check "hideWhen" for all the fields to determine which are now visible
            const visibleFields = fields.filter((f) => !f.hideWhen?.(newState)).map((f) => f.id);

            // Save the state of anything that is hidden
            setHiddenState(pickBy(newState, (_, key) => !visibleFields.includes(key)));

            // Return only the visible fields and toggle arrays as the new state to set
            return pickBy(newState, (_, key) => visibleFields.includes(key) || toggleArrayNames.includes(key));
        });

        if (onLegacyFormStateChange) {
            onLegacyFormStateChange(field.id, newValue);
        }
    };

    
    /**
     * Convert a formField object to a JSX element to render on the form
     *
     * @param {formField} field
     * @returns {JSX.Element}
     */
    const renderInput = (field) => {
        const baseProps = {
            label: field.label,
            value: formState[field.id],
            disabled: field.disabled || (field.toggle?.behavior === "enable_field" && !getToggle(field.id)),
            error: validationErrors?.find((err) => err.id === field.id)?.error || "",
            setValue: (newValue) => updateInput(field, newValue),
        };

        if (field.required) {
            baseProps.label += " *";
        }

        switch (field.inputType) {
            case "label":
                return (
                    <span className={styles.formLabel} data-testid={field.id}>
                        {field.label}
                    </span>
                );

            default:
            case "input":
                if (field.dataType === "numeric") {
                    return <InputField {...baseProps} type="number" />;
                }

                return <InputField {...baseProps} suggestions={field.suggestions} />;

            case "select":
                return <SelectField {...baseProps} options={field.options} />;

            case "state_prov_select":
                return (
                    <SelectField
                        {...baseProps}
                        options={stateProvLists[organization?.country_code?.toLocaleUpperCase() ?? "US"]?.map((s) => ({
                            label: s.name,
                            value: s.abbrev,
                        }))}
                    />
                );

            case "radio":
                return <RadioField {...baseProps} options={field.options} />;

            case "checkbox":
                return <CheckboxField {...baseProps} />;

            case "textarea":
                return <TextAreaField {...baseProps} />;

            case "date":
            case "date_time":
                return <DateField {...baseProps} timeLabel={field.timeLabel} />;

            case "past_date":
                return <DateField {...baseProps} timeLabel={field.timeLabel} disableFuture />;

            case "multi":
                return <MultiSelectField {...baseProps} options={field.options} />;

            case "interval":
                return <IntervalField {...baseProps} />;

            case "amount":
                return <AmountField {...baseProps} units={field.options} />;

            case "schedule":
                return <ScheduleField {...baseProps} />;

            case "custom":
                const Component = field.customComponent;
                return <Component {...baseProps} />;

            case "hidden":
                return null;
        }
    };

    const setToggle = (fieldID, checked) => {
        onFormStateChange((prev) => {
            const field = fields.find((f) => f.id === fieldID);

            if (!field?.toggle?.behavior) {
                return;
            }

            const isEnableToggle = field.toggle.behavior === "enable_field";
            const arrayID = isEnableToggle ? "enabled_toggles" : field.toggle.id;

            return {
                ...prev,
                [fieldID]: isEnableToggle && !checked ? null : prev[fieldID],
                [arrayID]: checked
                    ? [...new Set([...(prev[arrayID] || []), fieldID])]
                    : (prev[arrayID] || []).filter((f) => f !== fieldID),
            };
        });
    };

    const getToggle = (fieldID) => {
        const field = fields.find((f) => f.id === fieldID);

        if (!field?.toggle?.behavior) {
            return false;
        }

        const arrayID = field.toggle.behavior === "add_to_array" ? field.toggle.id : "enabled_toggles";

        return formState[arrayID]?.includes(field.id) ?? false;
    };

    // Field types that should not include the "X" clear button
    const nonClearableTypes = ["radio", "checkbox", "label", "hidden"];

    return (
        <>
            <div className={styles.formWrapper}>
                {fields
                    .filter((f) => !f.hideWhen?.(formState))
                    .map((f) => (
                        <div
                            className={styles.fieldWrapper}
                            key={`${f.id}-input`}
                            ref={f.id === validationErrors[0]?.id ? errorRef : null}
                        >
                            {renderInput(f)}
                            {!isEmpty(formState[f.id]) && !nonClearableTypes.includes(f.inputType) && !f.disabled && (
                                <ToolTip title="Clear field">
                                    <IconButton icon={<ClearRounded />} onClick={() => updateInput(f, null)} />
                                </ToolTip>
                            )}
                            {f.toggle && !f.toggle.hideWhen?.(formState) && (
                                <div data-testid={`${f.id}-toggle`}>
                                    <CheckboxField
                                        disabled={f.toggle.behavior === "add_to_array" && isEmpty(formState[f.id])}
                                        type="switch"
                                        label={<span className={styles.toggleText}>{f.toggle.label}</span>}
                                        value={getToggle(f.id)}
                                        setValue={(checked) => setToggle(f.id, checked)}
                                    />
                                </div>
                            )}
                        </div>
                    ))}
            </div>
            {/* The message needs to be reset when closed, otherwise the same message won't show a second time */}
            <Snackbar {...snackbarMessage} onClose={() => setSnackbarMessage({ message: "" })} />
        </>
    );
};

export default Form;
