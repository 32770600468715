import React, { useState, useEffect } from 'react';
import {
    getAPIToken, createAPIToken, updateAPIToken,
    getTokenRoles, removeTokenRole, addTokenRole
} from '../../api/tokens';
import { getRoles } from '../../api/permissionsAPI';
import MessageBox from '../MessageBox';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

const hiddenRoles = [
    "SwiftComply.Admin",
    "Org.Admin",
    "Default.NoLogin",
    "Default.Auth",
    "Default.Deny",
    "Provider",
    "Notifications.Organization",
    "PDF.Read",
    "Users.Admin",
    "Users.User",
    "Users.NoDelete",
    "Users.List",
    "Users.Read",
    "Users.Create",
    "Users.Update",
    "Users.Delete",
];

const APITokenFlyout = (props) => {
    const [tokenDetails, setTokenDetails] = useState({});
    const [tokenRoles, setTokenRoles] = useState([]);
    const [roles, setRoles] = useState([]);
    const [rolesMessageOptions, setRolesMessageOptions] = useState(undefined);
    const [deleteMode, setDeleteMode] = useState(false);

    useEffect(() => {
        fetchFlyoutData();
    }, []);

    const fetchFlyoutData = () => {
        if (!props.newAddition) {
            getAPIToken(props.uuid).then((data) => {
                setTokenDetails(data);
                props.setHeader(data.name);
            });
            getTokenRoles(props.uuid).then((data) => {
                setTokenRoles(data.roles);
            });
        } else {
            props.setHeader("New API Token");
        }

        getRoles().then((data) => {
            let parsedRoles = [];
            for (let i = 0; i < data.length; i++) {
                if (!hiddenRoles.includes(data[i].role_name)) {
                    parsedRoles.push(data[i]);
                }
            }
            parsedRoles.sort((a, b) => {
                if (a.role_name < b.role_name) {
                    return -1;
                } else if (a.role_name > b.role_name) {
                    return 1;
                }
                return 0;
            })
            setRoles(parsedRoles);
        });
    };

    // because react wants to control everything
    const handleTokenNameChange = (event) => {
        setTokenDetails({ ...tokenDetails, name: event.target.value });
    }

    const saveToken = () => {
        if (tokenDetails.name) {
            if (tokenDetails.api_token_uuid) {
                updateAPIToken(tokenDetails.api_token_uuid, tokenDetails.name).then((data) => {
                    console.log("update data", data);
                    setTokenDetails(data);
                    props.setHeader(data.name);
                    props.refresh();
                });
            } else {
                createAPIToken(tokenDetails.name).then((data) => {
                    setTokenDetails(data);
                    props.setHeader(data.name);
                    props.refresh();
                })
            }
        }
    }

    const showTokenRoles = () => {
        let selections = [];
        selections = tokenRoles.map((role, idx) => (
            <div key={idx} className="selection__container-element">
                <div className="flexJustifyCenter">{role.role_name}<DeleteIcon className="deleteIcon" onClick={() => deleteTokenRole(role.role_name)} /></div>
            </div>
        ))

        if (tokenRoles.length > 0) {
            return selections;
        } else {
            return <span className="flexJustifyCenter" style={{ width: "100%", marginTop: "2rem" }}>No roles found.</span>
        }
    }

    const deleteTokenRole = (role) => {
        if (role != "") {
            setRolesMessageOptions({});
            removeTokenRole(tokenDetails.api_token_uuid, role).then(() => {
                let newRoles = [...tokenRoles];
                for (let i = 0; i < newRoles.length; i++) {
                    if (newRoles[i].role_name == role) {
                        newRoles.splice(i, 1);
                    }
                }
                setTokenRoles(newRoles);
            });
        }
    }

    const getRoleOptions = () => {
        let options = [<option value="" key=""></option>];
        roles.forEach((r) => {
            options.push(<option value={r.role_name} title={r.description} key={r.role_name}>{r.role_name}</option>);
        })
        return options;
    }

    const newTokenRole = (role) => {
        if (role != "") {
            setRolesMessageOptions({});
            if (tokenRoles.some(r => r.role_name === role)) {
                return null
            } else {
                addTokenRole(tokenDetails.api_token_uuid, role).then(() => {
                    let newRoles = [...tokenRoles];
                    newRoles.push({ "role_name": role });
                    setTokenRoles(newRoles);
                }).catch((err) => {
                    setRolesMessageOptions({ type: "error", message: err.message })
                });
            }
        }
    }

    const deleteThisToken = async (type) => {

        if (type === 'delete') {
            await props.removeAPIToken(tokenDetails.api_token_uuid);
            await props.refresh();
            await props.closeFlyout();
        } else if (type === 'cancel') {
            setDeleteMode(false);
        } else {
            setDeleteMode(true);
            setTimeout(() => {
                document.getElementById('flyoutBase').scrollIntoView(false);
            }, 100);
        }
    };

    return (
        <div className="flyoutContentContainer" id="flyoutBase">
            <div className="inputField long">
                <label htmlFor="name">Name *</label>
                <input type="text" name="name" id="name" placeholder="Token Name" value={tokenDetails.name} onChange={handleTokenNameChange} />
            </div>
            {tokenDetails.api_token_uuid && (
                <div className="inputField long">
                    <label htmlFor="token_id">Token ID</label>
                    <input type="text" name="token_id" disabled value={tokenDetails.api_token_uuid} />
                </div>
            )}
            {tokenDetails.token && (
                <div className="inputField long">
                    <label htmlFor="token">Token (please keep secure, this will not be shown again)</label>
                    <textarea name="token" cols="50" rows="3" disabled value={tokenDetails.token} />
                </div>
            )}
            <button className="medButtonPrimary" type="button" onClick={saveToken}>Save</button>
            {tokenDetails.api_token_uuid && (
                <React.Fragment>
                    <hr style={{ marginBottom: "2rem" }} />
                    <MessageBox options={rolesMessageOptions} style={{ margin: '1rem, 0rem' }} />
                    <div className="inputField long">
                        <label htmlFor="new_role">Roles</label>
                        <div className="flexJustifyCenter">
                            <select id="new_role" size="1">
                                {getRoleOptions()}
                            </select>
                            <button
                                className="medButtonPrimary"
                                type="button"
                                onClick={(e) => newTokenRole(document.getElementById("new_role").value)}
                                style={{ marginLeft: "1rem", height: "4.4rem" }}
                            >
                                Add Role
                            </button>
                        </div>
                    </div>
                    <label style={{ color: "#7689A3" }}>Current Roles</label>
                    <div className="selection__container">
                        {showTokenRoles()}
                    </div>
                </React.Fragment>
            )}
            {!deleteMode && tokenDetails.api_token_uuid && (
                <div className="deleteButton__container">
                    <button className="deleteButton" onClick={() => deleteThisToken()}><DeleteForeverIcon fontSize='inherit' color='inherit' /></button>
                </div>
            )}
            {deleteMode &&
                <React.Fragment>
                    <div className="flyout__deleteMode">
                        <span>Warning: These actions cannot be undone.</span>
                        <button className="medButtonSecondary" onClick={() => deleteThisToken('delete')}>Delete Token</button>
                        <button className="exit" onClick={() => deleteThisToken('cancel')}><CloseIcon fontSize="large" /></button>
                    </div>
                </React.Fragment>
            }
        </div>
    );
}

export default APITokenFlyout;