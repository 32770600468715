import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getPathname } from "../../../utils/url";
import { deleteTemplate, duplicateTemplate, getTemplateList } from "../../../api/templatesAPI";
import { Accordion, AccordionDetails, AccordionSummary, Menu, MenuItem } from "@material-ui/core";
import { ExpandMoreRounded, MoreVertRounded } from "@material-ui/icons";
import { templateTypes } from "./TemplateTypes";
import accordionStyles from "../../../styles/common/matieralUI/accordion.module.css";
import styles from "../../../styles/common/templates.module.css";
import IconButton from "../IconButton";
import Home from "../Home/Home";
import DeleteDialog from "../Home/DeleteDialog";

const TemplatesHome = () => {
    const pathname = getPathname();
    const [templates, setTemplates] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [anchor, setAnchor] = useState(/** @type {Element} */ null);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [expandedSections, setExpandedSections] = useState({});

    const onCloseMenu = () => setAnchor(null);

    const refreshTemplateList = () => {
        setLoading(true);

        getTemplateList()
            .then((data) => {
                const byType = {};

                data.templates.forEach((template) => {
                    const typeLabel =
                        templateTypes.find((t) => t.value === template.legal_control_type)?.label ??
                        template.legal_control_type;

                    if (byType[typeLabel]) {
                        byType[typeLabel].push(template);
                    } else {
                        byType[typeLabel] = [template];
                    }
                });

                setTemplates(byType);
            })
            .catch(setError)
            .finally(() => setLoading(false));
    };

    const onDeleteTemplate = () => {
        if (!selectedTemplate) {
            return;
        }

        onCloseMenu();
        setShowDeleteDialog(false);
        deleteTemplate(selectedTemplate.legal_control_template_uuid).then(refreshTemplateList);
    };

    const onDuplicateTemplate = () => {
        onCloseMenu();
        duplicateTemplate(selectedTemplate.legal_control_template_uuid).then(refreshTemplateList);
    };

    useEffect(() => {
        refreshTemplateList();
    }, []);

    return (
        <>
            <Home loading={loading} error={error} addNewForm="addTemplate">
                {Object.keys(templates).length
                    ? Object.keys(templates)
                          .sort()
                          .map((type) => (
                              <Accordion
                                  classes={{ root: accordionStyles.accordion }}
                                  square={false}
                                  key={type}
                                  expanded={expandedSections[type] ?? false}
                                  onChange={(_, expanded) =>
                                      setExpandedSections((prev) => ({ ...prev, [type]: expanded }))
                                  }
                              >
                                  <AccordionSummary
                                      classes={{
                                          root: accordionStyles.accordionSummary,
                                          expandIcon: accordionStyles.expandIcon,
                                          expanded: accordionStyles.expanded,
                                      }}
                                      expandIcon={<ExpandMoreRounded />}
                                  >
                                      {type}
                                  </AccordionSummary>
                                  <AccordionDetails classes={{ root: accordionStyles.accordionDetails }}>
                                      {templates[type].map((template) => (
                                          <div
                                              className={styles.detailsContent}
                                              key={template.legal_control_template_uuid}
                                          >
                                              <div className={styles.template}>
                                                  <Link
                                                      className={styles.link}
                                                      to={`${pathname}/${template.legal_control_template_uuid}`}
                                                  >
                                                      {template.name}
                                                  </Link>
                                                  <IconButton
                                                      icon={<MoreVertRounded />}
                                                      onClick={(event) => {
                                                          setSelectedTemplate(template);
                                                          setAnchor(event.currentTarget);
                                                      }}
                                                  />
                                              </div>
                                          </div>
                                      ))}
                                  </AccordionDetails>
                              </Accordion>
                          ))
                    : "No templates found"}
            </Home>
            <Menu open={Boolean(anchor)} anchorEl={anchor} onClose={onCloseMenu}>
                <MenuItem onClick={onDuplicateTemplate}>Duplicate Template</MenuItem>
                <MenuItem onClick={() => setShowDeleteDialog(true)}>Delete Template</MenuItem>
            </Menu>
            <DeleteDialog
                open={showDeleteDialog}
                onConfirm={onDeleteTemplate}
                onCancel={() => setShowDeleteDialog(false)}
            />
        </>
    );
};

export default TemplatesHome;
