import React from 'react';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import { withStyles } from '@material-ui/core/styles';
import { ClickAwayListener } from '@material-ui/core';
import DatePicker from './DatePicker';
import ClearIcon from '@material-ui/icons/Clear';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Tooltip } from './Tooltip';
import moment, * as Moment from 'moment';
import { getForm, getFormFields } from '../api/formsAPI';
import validator from 'validator';

const styles = theme => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: '194px'
	},
	textFieldInput: {
		fontSize: '1.4rem'
	},
	checkboxLabel: {
		fontSize: '1.4rem'
	},
	checkboxLabelRoot: {
		marginLeft: '0px'
	},
	datePickerRoot: {
		display: 'flex',
		flexDirection: 'column',
		fontSize: '1.4rem'
	}
});

class TableInputsFilter extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			values: {},
			dropdownOpen: false,
			removeInputs: (props.removeInputs) ? props.removeInputs : []
		};

		// inputs might have a default, so if its not set in params, set it to the default
		this.props.data.inputs.forEach((input) => {
			let lcname = input.name.toLowerCase();
			if (input.default && !this.props.params.inputs[lcname]) {
				this.props.params.inputs[lcname] = input.default;
			}
		});

		this.props.data.inputs.forEach((input) => {
			let lcname = input.name.toLowerCase();
			this.state.values[lcname] = "";

			if (this.props.params.inputs[lcname]) {
				if (input.type === 'boolean') {
					this.state.values['inputs_' + lcname] = (this.props.params.inputs[lcname] === 'true') ? true : false;
				} else {
					this.state.values['inputs_' + lcname] = this.props.params.inputs[lcname];
				}
			} else {
				this.state.values['inputs_' + lcname] = "";
			}
		});

		if (Object.keys(this.props.params.details).length !== 0) {
			for (const type in this.props.params.details) {
				for (const field in this.props.params.details[type].inputs) {
					this.state.values[field] = this.props.params.details[type].inputs[field];
				}
				this.state.values['details_' + type] = this.props.params.details[type].inputs
			}

		}
	}

	fieldFormatter = (input, type, additionalFieldType) => {

		let inputVariable = {};
		const { classes } = this.props;

		if (type === 'additionalDetails') {
			inputVariable.id = input.form_field_uuid
			inputVariable.key = input.form_field_uuid
			inputVariable.label = input.label
			inputVariable.props = { uuid: input.form_field_uuid, type: additionalFieldType }
			inputVariable.dataType = input.data_type
		} else {
			inputVariable.id = 'inputs_' + input.name
			inputVariable.key = 'inputs-' + input.name
			inputVariable.label = input.name
			inputVariable.props = null
			inputVariable.dataType = input.type
		}

		if (this.state.removeInputs.includes(inputVariable.label.toLowerCase())) {
			return;
		}

		let field = null;
		if (inputVariable.label.endsWith('UUID')) {
			field = null;
		} else if (inputVariable.dataType === 'boolean') {
			field =
				<FormControl
					key={inputVariable.key}
					classes={{
						root: classes.formControl
					}}
					detailsprops={inputVariable.props}
				>
					<FormControlLabel
						classes={{
							label: classes.checkboxLabel,
							root: classes.checkboxLabelRoot
						}}
						control={
							<Checkbox
								name={inputVariable.id}
								checked={(this.state.values[inputVariable.id.toLowerCase()]) ? true : false}
								onChange={(e) => { this.handleChange(inputVariable.id, e, inputVariable.props); }}
								value={true}
								color='primary'
							/>
						}
						label={inputVariable.label}
					/>
				</FormControl>;
		} else if (inputVariable.dataType === 'ternary_boolean') {
			field =
				<FormControl key={inputVariable.key} variant="outlined" classes={{ root: classes.formControl }} detailsprops={inputVariable.props}>
					<InputLabel htmlFor={inputVariable.id}>{inputVariable.label}</InputLabel>
					<Select
						native
						onChange={(e) => { this.handleChange(inputVariable.id, e, inputVariable.props); }}
						label={inputVariable.label}
						inputProps={{
							name: inputVariable.id,
							id: inputVariable.id,
						}}
						value={this.state.values[inputVariable.id.toLowerCase()]}
					>
						<option value="true">True</option>
						<option value="false">False</option>
						<option value="null">All</option>
					</Select>
				</FormControl>
		}
		else if (inputVariable.dataType === 'timestamp') {
			field =
				<FormControl
					key={inputVariable.key}
					classes={{
						root: classes.formControl
					}}
					detailsprops={inputVariable.props}
				>
					<FormControlLabel
						classes={{
							root: classes.datePickerRoot,
							label: classes.checkboxLabel
						}}
						control={
							<DatePicker
								valueType="epoch"
								disabled={false}
								editMode={true}
								name={inputVariable.id}
								color='primary'
								setFieldValue={this.handleDateChange}
								field={input}
								value={this.state.values[`inputs_${input.name.toLowerCase()}`] ? this.convertTimestamp(this.state.values[`inputs_${input.name.toLowerCase()}`], 'ms') : ''}
							/>
						}
						label={inputVariable.label}
					/>
				</FormControl>;
		} else if (input.values.length > 0) {
			// if this input has a list of acceptable values, we want to use a select box
			let options = [];
			let idx = 0;
			if (!input.required) {
				options.push(<option key={idx} value=""></option>);
			}
			input.values.forEach((v) => {
				idx++;
				options.push(<option key={idx} value={v}>{v}</option>);
			})

			field =
				<FormControl key={inputVariable.key} variant="outlined" classes={{ root: classes.formControl }} detailsprops={inputVariable.props}>
					<InputLabel htmlFor={inputVariable.id}>{inputVariable.label}</InputLabel>
					<Select
						native
						onChange={(e) => { this.handleChange(inputVariable.id, e, inputVariable.props); }}
						label={inputVariable.label}
						inputProps={{
							name: inputVariable.id,
							id: inputVariable.id,
						}}
						value={this.state.values[inputVariable.id.toLowerCase()]}
					>
						{options}
					</Select>
				</FormControl>
		}
		else {
			field =
				<FormControl key={input.form_field_uuid} variant="outlined" classes={{ root: classes.formControl }} detailsprops={inputVariable.props}>
					<TextField
						key={inputVariable.key}
						id={inputVariable.key}
						label={inputVariable.label}
						name={inputVariable.id}
						classes={{
							root: classes.textField
						}}
						InputProps={{
							classes: {
								input: classes.textFieldInput
							}
						}}
						variant='outlined'
						onChange={(e) => { this.handleChange(inputVariable.id.toLowerCase(), e, inputVariable.props); }}
						value={this.state.values[inputVariable.id.toLowerCase()]}
					/>
				</FormControl>
		}
		return field;
	}

	displayInputOptions = () => {
		const { classes } = this.props;

		let inputFields = [];

		this.props.data.inputs.sort((a, b) => {
			let nameA = a.name.toUpperCase();
			let nameB = b.name.toUpperCase();
			return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
		});

		this.props.data.inputs.forEach((input) => {
			inputFields.push(this.fieldFormatter(input, 'filters'));
		});

		if (inputFields.length > 0) {
			return (
				<div className="sectionHeader noBorder">
					{inputFields}
				</div>
			)
		}
	}

	displayAdditionalDetailsFields = () => {
		const { additionalDetails } = this.props;
		let inputFields = {};

		for (const type in additionalDetails) {
			inputFields[type] = [];
			for (const x in additionalDetails[type]) {
				let input = additionalDetails[type][x];
				inputFields[type] = [...inputFields[type], this.fieldFormatter(input, 'additionalDetails', type)]
			}
		}

		const inputs = Object.keys(inputFields).map((type, idx) => {
			let sectionHeader = '';
			if (type === 'equipment') {
				sectionHeader = 'Equipment Details'
			} else if (type === 'property') {
				sectionHeader = 'Location Details'
			} else if (type === 'contact') {
				sectionHeader = 'Contact Details'
			} else if (type === 'service_provider') {
				sectionHeader = 'Service Provider Details'
			} else if (type === 'compliance_report') {
				sectionHeader = 'Report Details'
			}

			if (inputFields[type].length > 0) {
				return (
					<div className="sectionHeader" key={idx}>
						<span className="sectionHeader__sectionTitle">{sectionHeader}</span>
						{inputFields[type]}
					</div>
				)
			}
		}
		)

		return inputs;
	}

	handleChange = (name, e, info) => {
		let values = this.state.values;
		let value = e.target.value;
		let type = e.target.type
		let checkboxValue = !this.state.values[name];

		if (info) {
			this.setState((prevState) => ({
				values: {
					...prevState.values,
					['details_' + info.type]: {
						...prevState.values['details_' + info.type],
						[info.uuid]: type === 'checkbox' ? !checkboxValue ? "" : value : value
					},
					[name.toLowerCase()]: type === 'checkbox' ? !checkboxValue ? "" : value : value
				}
			}))

		} else {
			if (e.target.type === 'checkbox') {
				values[name.toLowerCase()] = !values[name.toLowerCase()];
			} else {
				values[name.toLowerCase()] = e.target.value;
			}
			this.setState({ values })
		}
	}

	handleDateChange = (name, timestamp) => {
		let values = this.state.values;
		// moment I swear used to be a good library but now is kind if clunky.  This needs to take in seconds, and the
		// timestamp from js will be in milliseconds (and the ms parser in moment doesn't work like the docs say).  So
		// divide by 1000 when we construct the object.  Then set the value to the .unix value again because we need
		// that in seconds and moment will act like other js things and be ms if you don't
		let jstsToUnix = Moment.unix(this.convertTimestamp(timestamp, 's'));
		values[`inputs_${name.toLowerCase()}`] = jstsToUnix.unix();
	};

	// convert to and from unix / js timstamps, becaue js in its infinite wisdom decided on millisecond timestamps that
	// no one else uses
	convertTimestamp = (timestamp, to) => {
		// make sure we are an int first, so garbage would just get removed
		timestamp = parseInt(timestamp, 10);
		if (to === 's') {
			if (Math.ceil(Math.log10(timestamp)) === 13) {
				return timestamp / 1000;
			}
		} else if (to === 'ms') {
			if (Math.ceil(Math.log10(timestamp)) === 10) {
				return timestamp * 1000;
			}
		}
		// if we didn't do anything just return
		return timestamp;
	}

	handleSave = () => {
		this.props.setTableFilters(this.state.values);
		this.toggleDropdown('close');

	}

	handleClear = () => {
		let values = {};
		for (let term in this.state.values) {
			values[term] = "";
		}
		this.props.data.inputs.forEach((input) => {
			let lcname = 'inputs_' + input.name.toLowerCase();
			if (input.default) {
				if (input.type === 'boolean') {
					values[lcname] = (input.default === 'true') ? true : false;
				} else {
					values[lcname] = input.default;
				}
			}
		});
		this.setState({ values }, () => {
			if (!this.props.widget) {
				this.handleSave();
			}
		});
	}

	toggleDropdown = (type) => {
		const picker = document.getElementsByClassName('MuiPopover-root');
		if (type === 'close') {
			if (picker.length) {
				return null;
			} else {
				this.setState(() => ({ dropdownOpen: false }));
			}
		} else {
			this.setState((prevState) => ({ dropdownOpen: !prevState.dropdownOpen }));
		}
	}

	filterCounter = () => {
		let count = 0;
		for (const value in this.state.values) {
			if (this.state.values[value] !== '') {
				if (validator.isUUID(value)) {
					count = count + 1;
				} else {
					for (const value2 of this.props.data.inputs) {
						if ('inputs_' + value2.name.toLowerCase() === value && !this.state.removeInputs.includes(value2.name.toLowerCase())) {
							if (this.state.values[value].toString() !== value2.default) {
								count = count + 1;
							}
						}
					}
				}
			}
		}
		return count;
	}

	render() {
		const { dropdownOpen } = this.state;
		const counter = this.filterCounter();

		if (this.props.widget) {
			return (
				<div className='tableFilter__content'>
					{this.displayInputOptions()}
				</div>
			)
		} else {
			return (
				<div id="tableInputsFilter">
					<ClickAwayListener onClickAway={() => this.toggleDropdown('close')}>
						<div className={dropdownOpen ? 'tableFilter__wrapper openBorderRadius slideDown' : 'tableFilter__wrapper'}>
							<div className='tableFilter'>
								{/*<span onClick={() => this.toggleDropdown()} title={`${this.filterCounter()} Active Filter/s`}>{`${this.filterCounter()}`}</span>*/}
								<button className="filterButton" onClick={() => this.toggleDropdown()}>
									<div>
										{!dropdownOpen && <Tooltip text={counter > 0 ? `${counter.toString()} Active Filter/s` : 'Table Filters'} noIcon={true} position="top" />}
										<FilterListIcon fontSize="inherit" />
									</div>
								</button>
								{counter > 0 && (
									<button onClick={this.handleClear} className='clearIcon' title='Clear filters'>
										<ClearIcon fontSize={'large'} />
									</button>
								)}
							</div>
							{dropdownOpen && (
								<div className='tableFilter__dropdownContent-wrapper'>
									<div className='tableFilter__dropdownContent'>
										<div className='tableFilter__content'>
											{this.displayInputOptions()}
											{this.displayAdditionalDetailsFields()}
											{Object.keys(this.state.values).length === 0 && Object.keys(this.props.additionalDetails).length === 0 ?
												<span className="tableFilter__content-noFiltersMessage">There are currently no filters for this report.</span>
												: null}
										</div>
										<div className='tableFilter__buttons'>
											<button className="smallButtonPrimary" onClick={this.handleSave}>save</button>
											<button className="smallButtonSecondary" onClick={this.handleClear}>clear</button>
										</div>
									</div>
								</div>
							)}
						</div>
					</ClickAwayListener>
				</div>
			);
		}
	}
}

export default withStyles(styles)(TableInputsFilter);

