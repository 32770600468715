import React from "react";
import { FormControl, FormHelperText, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import styles from "../../../../styles/common/intervalField.module.css";
import helperTextStyles from "../../../../styles/common/matieralUI/helperText.module.css";

const AmountField = ({ label, units, value, setValue, error }) => {
    const defaultUnit = units[0];
    const split = value?.match(/^(\S+)\s(.+)$/);
    const count = split?.[1] || "";
    const unitValue = split?.[2] || defaultUnit.value;
    const unitLabel = units.find((u) => u.value === unitValue)?.label ?? defaultUnit.label;

    return (
        <FormControl error={!!error} data-testid={`${label} amount`}>
            <div className={styles.wrapper}>
                <TextField
                    label={label}
                    type="number"
                    variant="outlined"
                    value={count}
                    onChange={(event) => setValue(`${event.currentTarget.value ?? 0} ${unitValue}`)}
                    error={!!error}
                />
                {!!count && (
                    <Autocomplete
                        style={{ flexGrow: 1 }}
                        lable="Units"
                        options={units.map((u) => u.label)}
                        value={unitLabel}
                        onChange={(_, newUnitLabel) =>
                            setValue(`${count} ${units.find((u) => u.label === newUnitLabel).value}`)
                        }
                        renderInput={(params) => <TextField {...params} variant="outlined" error={!!error} />}
                        disableClearable
                    />
                )}
            </div>
            {error && <FormHelperText classes={helperTextStyles}>{error}</FormHelperText>}
        </FormControl>
    );
};

export default AmountField;
