import React, { useState, useEffect } from 'react';
import CommsEditor from './CommsEditor';
import { withFormik, Form, Field, FieldArray } from 'formik';
import MessageBox from '../MessageBox';
import * as Yup from 'yup';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { Tooltip } from '../Tooltip';
import { getCommType, sendPreviewEmail } from '../../api/communicationsAPI';
import { getForm, getFormFields } from '../../api/formsAPI';
import { getStateReportFields } from '../../api/statereports';
import queryString from 'query-string';

const FormLayout = ({
    data,
    errors,
    touched,
    isSubmitting,
    editMode,
    toggleEditMode,
    resetForm,
    newAddition,
    closeFlyout,
    setFieldValue,
    apiErrors,
    messageOptions,
    editorControls,
    commEditorOpen,
    commText,
    emailEditorOpen,
    emailText,
    values,
    formErrors,
    setFormErrors,
    openSecondaryFlyout,
    closeSecondaryFlyout,
    typesList,
    setCommStatus,
    cloneComm
}) => {
    const [targetingData, setTargetingData] = useState({});
    const [targetingOptions, settargetingOptions] = useState({});
    const [targetingOptionsLoaded, settargetingOptionsLoaded] = useState(false);
    const [targetingFieldValue, setTargetingFieldValue] = useState('');
    const [communicationsType, setCommunicationsType] = useState({});
    const [testEmailError, setTestEmailError] = useState(false);
    const [sentCooldown, setSentCooldown] = useState(false);

    useEffect(() => {
        // depending on the comm type we will figure out the fields, etc we need to setup our targeting options
        if (values.communication_type != "") {
            getCommType(values.communication_type).then((commType) => {
                setCommunicationsType(commType);
                // I hate formik, there should be no need for this, I have a form with fields that get their value
                // set, it should use them.
                setFieldValue("communication_type_source", commType.source);
                // comp exp will be based on some exp of property or equipment compliance so pull those detail forms
                if (commType.trigger_type == "compliance expiration" || commType.trigger_type == "compliance delinquency") {
                    let propDetailsForm = getForm("property details");
                    let equipDetailsForm = getForm("equipment details");

                    Promise.all([propDetailsForm, equipDetailsForm]).then((values) => {
                        let propFields = (values[0]) ? getFormFields(values[0].form_uuid) : Promise.resolve();
                        let equipFields = (values[1]) ? getFormFields(values[1].form_uuid) : Promise.resolve();
                        let srFields = getStateReportFields();
                        Promise.all([propFields, equipFields, srFields]).then((fields) => {
                            // assm test the obj is equipment (and property is the parent)
                            // for surveys the obj is the property
                            let targetingObj = {
                                propertyDetails: [],
                                propertyStateReports: {},
                                equipmentDetails: [],
                                equipmentStateReports: {}
                            };
                            if (commType.trigger_info == "assembly test") {
                                targetingObj.propertyDetails = (fields[0]) ? fields[0].form_fields : [];
                                targetingObj.equipmentDetails = (fields[1]) ? fields[1].form_fields : [];
                            } else if (commType.trigger_info == "survey") {
                                targetingObj.propertyDetails = (fields[0]) ? fields[0].form_fields : [];
                            }
                            targetingObj.propertyStateReports = (fields[2] && fields[2].properties) ? fields[2].properties : {};
                            targetingObj.equipmentStateReports = (fields[2] && fields[2].equipment) ? fields[2].equipment : {};
                            settargetingOptions(targetingObj);
                            settargetingOptionsLoaded(true);
                        });
                    });
                }
            });
        }
    }, [values.communication_type]);

    useEffect(() => {
        setTargetingData(values.targeting);
    }, [values.targeting]);

    useEffect(() => {
        setTestEmailError(false);
    }, [editMode])

    const cancelAction = () => {
        if (newAddition) {
            resetForm();
            closeFlyout();
        } else {
            toggleEditMode();
            resetForm();
        }
    };

    const getError = (fieldname) => {
        if (apiErrors && apiErrors[fieldname]) {
            return apiErrors[fieldname];
        } else if (touched[fieldname] && errors[fieldname]) {
            return errors[fieldname];
        }
    };

    const getTargeting = () => {
        return (
            <React.Fragment>
                {
                    Object.keys(targetingData).map((targ, idx) => {
                        let parts = targ.split(":");
                        let label = targ;
                        if (parts[1] == "sr") {
                            label = parts[2];
                        } else {
                            if (parts[0] == "property") {
                                targetingOptions.propertyDetails.forEach((det) => {
                                    if (det.form_field_uuid == parts[1]) {
                                        label = det.label;
                                    }
                                })
                            } else if (parts[0] == "equipment") {
                                targetingOptions.equipmentDetails.forEach((det) => {
                                    if (det.form_field_uuid == parts[1]) {
                                        label = det.label;
                                    }
                                })
                            }
                        }

                        return (
                            <div key={idx} className="selection__container-element">
                                {`${label}: ${targetingData[targ]}`}
                                {editMode &&
                                    <button
                                        type="button"
                                        onClick={() => { removeTargeting(targ); }}
                                        style={{ color: "rgb(255, 0, 92)" }}
                                    >
                                        X
                                    </button>
                                }
                            </div>
                        )

                    })
                }
            </React.Fragment>
        );
    }

    const handleTargetingChange = (e) => {
        setTargetingFieldValue(e.target.value);
        handleFormChange(e);
    }

    // builds up a select of all the possible targeting options.  property details first, prop state report fields, 
    // equipment details, equipment state report fields.  We skip any date and boolean data types since dates are jacked
    // up until we fix the data picker, and booleans don't quite work since you can't not select it right now.
    const getTargetingOptions = () => {
        if (!targetingOptionsLoaded) {
            return [];
        }

        return (
            <React.Fragment>
                <option value=""></option>
                {
                    targetingOptions.propertyDetails.length > 0 && (
                        <optgroup label="Property Details">
                            {targetingOptions.propertyDetails.map((det, idx) => {
                                if (det.data_type != "boolean" && det.data_type != "date") {
                                    return <option key={"pd" + idx} value={"property:" + det.form_field_uuid}>{det.label}</option>
                                }
                            })}
                        </optgroup>
                    )
                }
                {
                    Object.keys(targetingOptions.propertyStateReports).length > 0 && (
                        <optgroup label="Property State Report Fields">
                            {Object.keys(targetingOptions.propertyStateReports).map((field, idx) => {
                                if (targetingOptions.propertyStateReports[field] != "boolean" && !field.includes(".")) {
                                    return <option key={"psr" + idx} value={"property:sr:" + field}>{field}</option>
                                }
                            })}
                        </optgroup>
                    )
                }
                {
                    targetingOptions.equipmentDetails.length > 0 && (
                        <optgroup label="Equipment Details">
                            {targetingOptions.equipmentDetails.map((det, idx) => {
                                if (det.data_type != "boolean" && det.data_type != "date") {
                                    return <option key={"pd" + idx} value={"equipment:" + det.form_field_uuid}>{det.label}</option>
                                }
                            })}
                        </optgroup>
                    )
                }
                {
                    Object.keys(targetingOptions.equipmentStateReports).length > 0 && (
                        <optgroup label="Equipment State Report Fields">
                            {Object.keys(targetingOptions.equipmentStateReports).map((field, idx) => {
                                if (targetingOptions.equipmentStateReports[field] != "boolean" && !field.includes(".")) {
                                    return <option key={"esr" + idx} value={"equipment:sr:" + field}>{field}</option>
                                }
                            })}
                        </optgroup>
                    )
                }
            </React.Fragment>
        );
    }

    // depending on which field they select for targeting, we would want them to either select a set of pre-defined
    // values or I guess enter one themself
    const getTargetingValueField = () => {
        // we would have set this when the field changed, as long as we have some value we can figure out what field
        // it is.
        if (targetingFieldValue != '') {
            let inputType = "";
            let inputValues = [];
            let parts = targetingFieldValue.split(":");
            // the parts of the value will be property:uuid, property:sr:field, equipment:uuid, etc
            if (parts[0] == "property") {
                if (parts[1] == "sr") {
                    inputType = targetingOptions.propertyStateReports[parts[2]];
                } else {
                    targetingOptions.propertyDetails.forEach((det) => {
                        if (det.form_field_uuid == parts[1]) {
                            inputType = det.input_type;
                            inputValues = det.values;
                        }
                    });
                }
            } else if (parts[0] == "equipment") {
                if (parts[1] == "sr") {
                    inputType = targetingOptions.equipmentStateReports[parts[2]];
                } else {
                    targetingOptions.equipmentDetails.forEach((det) => {
                        if (det.form_field_uuid == parts[1]) {
                            inputType = det.input_type;
                            inputValues = det.values;
                        }
                    });
                }
            }

            if (inputType == "string" || inputType == "numeric" || inputType == "input") {
                // for any normal input, string, number, etc just give them a regular old input field.
                return <Field type="text" name="targetingValue" id="targetingValue" autoComplete="off" className="inputField__input inputField__input-first" />
            } else if (inputType.includes("|")) {
                // mostly for state report fields, if there is a list of allowed values we will indicate that via a
                // pipe separated list (because that is REALLY unilkely to be used in an actual value)
                return <React.Fragment>
                    <Field name="targetingValue" id="targetingValue" list={targetingFieldValue + "-list"} />
                    <datalist id={targetingFieldValue + "-list"}>
                        {inputType.split("|").map((val, idx) => {
                            return <option key={idx} value={val}>{val}</option>
                        })}
                    </datalist>
                </React.Fragment>
            } else if (inputValues.length > 0) {
                // selects, radios will have a list of possible values, so for those we will give the user a select.
                // maybe one day we can do the same for booleans
                return <React.Fragment>
                    <Field name="targetingValue" id="targetingValue" list={targetingFieldValue + "-list"} />
                    <datalist id={targetingFieldValue + "-list"}>
                        {inputValues.map((val, idx) => {
                            return <option key={idx} value={val}>{val}</option>
                        })}
                    </datalist>
                </React.Fragment>
            }
        }
        return null;
    }

    // grabs our targeting inputs by id, makes a new object and sets that to our targeting data so that the list we
    // show updates, and also sets the values.targeting field so we can save it later
    const addTargeting = () => {
        let targetField = document.getElementById("targetingField").value;
        let targetValue = document.getElementById("targetingValue").value;
        let newData = { ...targetingData };
        newData[targetField] = targetValue;
        setTargetingData(newData);
        values.targeting = newData;
        document.getElementById("targetingValue").value = "";
        document.getElementById("targetingField").value = "";
        setFieldValue('targetingValue', "")
    }

    // like add targeting, except we are deleting the key from the targeting object
    const removeTargeting = (target) => {
        let newData = { ...targetingData };
        delete newData[target];
        setTargetingData(newData);
        values.targeting = newData;
    }

    const handleFormChange = (e) => {
        // this is called when the user changed a value, so if they did, we are going to wipe out any api errors that
        // might be set so we can check it again
        if (apiErrors && apiErrors[e.target.name]) {
            delete apiErrors[e.target.name];
        }
    };

    const getFormErrors = () => {
        let errorList = [];
        messageOptions = {};
        ['name', 'communication_type', 'compliance_type', 'period_num', 'period_type', 'template',
            'period_time', 'email_subject',
            'email_cc', 'email_bcc', 'contact_types', 'contact_methods',
            'use_first_available_method', 'use_primary_contact',
            'email_body', 'date_format', 'include_pdf_with_email', 'disable_item_grouping', 'targeting'].forEach((field) => {
                let err = getError(field);
                if (err) {
                    errorList.push(err);
                }
            });

        if (errorList.length !== 0) {
            messageOptions = {
                type: "error",
                message: "Please correct the following errors",
                list: errorList,
            };
            if (formErrors != errorList.join(",")) {
                document.getElementById('commDetailsForm').scrollIntoView();
            }
            setFormErrors(errorList.join(","));
        }

        return messageOptions;
    };

    let emailActive = false;
    let postcardActive = false;

    if (values.contact_methods) {
        if (values.contact_methods.includes('email')) {
            emailActive = true;
        }
        if (values.contact_methods.includes('postcard')) {
            postcardActive = true;
        }
    }

    const getSelectOptions = (optionType) => {
        let contactTypesOptions = ['Owner', 'Property Manager', 'Onsite', 'Mailing', 'Maintenance'];
        let contactMethodsOptions = ['email', 'letter', 'postcard'];

        let optionsList;
        if (optionType === 'contact_types') {
            optionsList = contactTypesOptions.map((type, index) => {
                return (
                    <option
                        value={type}
                        className={values.contact_types.includes(type) ? 'hide' : ''}
                        key={index}
                    >
                        {type.charAt(0).toUpperCase() + type.slice(1)}
                    </option>
                );
            });
        } else if (optionType === 'contact_methods') {
            optionsList = contactMethodsOptions.map((type, index) => {
                let optionClass;
                if ((values.contact_methods.includes('postcard') && type === 'letter') ||
                    (values.contact_methods.includes('letter') && type === 'postcard') ||
                    values.contact_methods.includes(type)) {
                    optionClass = "hide";
                }
                return (
                    <option
                        value={type}
                        className={optionClass}
                        key={index}
                    >
                        {type.charAt(0).toUpperCase() + type.slice(1)}
                    </option>
                );
            });
        } else if (optionType === 'communication_type') {
            optionsList = typesList.map((type, index) => {
                return (
                    <option
                        value={type}
                        key={index}
                    >
                        {type.charAt(0).toUpperCase() + type.slice(1)}
                    </option>
                );
            });
        }

        return optionsList;
    };

    const previewCommunication = (commUUID, method) => {
        let options = {
            method: method,
            communication_uuid: commUUID
        }
        let query = queryString.stringify(options);
        window.open("/service/communications/preview?" + query, "_blank");
    }

    const previewEmail = () => {
        if ((emailText && emailText !== "" || data.email_body !== (null || ''))) {
            sendPreviewEmail({ "communication_uuid": data.communication_uuid, "email_address": SwiftComply.user.email })
            setSentCooldown(true);
            setTimeout(() => {
                setSentCooldown(false);
            }, [3000])
        } else {
            setTestEmailError(true)
            setTimeout(() => {
                setTestEmailError(false)
            }, [5000])
        }
    }

    return (
        <Form className="commDetailsForm" id="commDetailsForm">
            <MessageBox options={getFormErrors()} />
            <fieldset disabled={!editMode}>
                <div className="detailsFieldset">
                    <div className="flexAlignCenter" style={{ width: "100%" }}>
                        <div className={'inputField long ' + ((getError('communication_type')) ? 'formRed' : '')}>
                            {editMode &&
                                <Tooltip text="Every Communication Template falls under a Communication Type. There may be multiple Templates per Type in order to send different communications based on Targeting." />
                            }
                            <label htmlFor="communication_type">Type *</label>
                            <Field component="select" id="communication_type" name="communication_type" autoComplete="off" className="inputField__input inputField__input-first"
                                onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
                            >
                                <option value=""></option>
                                {getSelectOptions('communication_type')}
                            </Field>
                        </div>
                        {newAddition && <button className="medButtonPrimary" type="button" style={{ marginLeft: "2rem" }} onClick={() => openSecondaryFlyout(null, true)}>Create New Type</button>}
                    </div>
                    <div className={newAddition ? 'inputField long ' + ((getError('name')) ? 'formRed' : '') : 'inputField medium'}>
                        <label htmlFor="name">Communication Name *</label>
                        <Field type="text" name="name" autoComplete="off" className="inputField__input inputField__input-first"
                            onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
                        />
                    </div>
                    {!newAddition &&
                        <div className='inputField medium '>
                            <label htmlFor="name">Status</label>
                            <Field type="text" name="status" autoComplete="off" readOnly className="inputField__input inputField__input-first" />
                        </div>
                    }
                    {communicationsType.source !== "ad hoc" && (
                        <div className={'inputField medium ' + ((getError('period_num', 'period_type')) ? 'formRed' : '')}>
                            {editMode &&
                                <Tooltip text="Period is the time in relation to the compliance expiration of a piece of equipment." />
                            }
                            <label htmlFor="period">Period *</label>
                            <div className="flex">
                                <Field type="text" name="period_num" className="multiInput__1"
                                    onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
                                />
                                <Field component="select" name="period_type" className="multiInput__2"
                                    onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}>
                                    <option value=""></option>
                                    <option value="days">Days</option>
                                    <option value="weeks">Weeks</option>
                                    <option value="months">Months</option>
                                    <option value="years">Years</option>
                                </Field>
                                <Field component="select" name="period_time"
                                    onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
                                >
                                    <option value=''></option>
                                    <option value='before'>Before</option>
                                    <option value='after'>After</option>

                                </Field>
                            </div>
                        </div>
                    )}
                    <div className={'inputField medium ' + ((getError('date_format')) ? 'formRed' : '')}>
                        <label htmlFor="date_format">Date Format</label>
                        <Field component="select" name="date_format" className="inputField__input inputField__input-first"
                            onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}>
                            <option value=""></option>
                            <option value="MM/DD/YYYY">MM/DD/YYYY</option>
                            <option value="MM-DD-YYYY">MM-DD-YYYY</option>
                            <option value="DD/MM/YYYY">DD/MM/YYYY</option>
                            <option value="DD-MM-YYYY">DD-MM-YYYY</option>
                            <option value="YYYY-MM-DD">YYYY-MM-DD</option>
                            <option value="YYYY/MM/DD">YYYY/MM/DD</option>
                        </Field>
                    </div>
                    {communicationsType.source !== "ad hoc" && (
                        <React.Fragment>
                            {editMode &&
                                <div className="flexAlignCenter" style={{ width: "100%" }}>
                                    <div className={targetingFieldValue != '' ? 'inputField medium' : 'inputField long'}>
                                        {editMode &&
                                            <Tooltip
                                                text={(
                                                    <React.Fragment>
                                                        <p>Use targeting to send communications based on specific criteria, e.g. hazard type, service type, zoning, protection type, etc.</p>
                                                        <p>This is not required. Communications with Targeting will generate first and remove recipients from receiving other communications under the same Type.</p>
                                                    </React.Fragment>
                                                )}
                                                html={true}
                                            />
                                        }
                                        <label htmlFor="name">{targetingFieldValue != '' ? 'Targeting Field' : 'Targeting'}</label>
                                        <Field component="select" name="targetingField" id="targetingField"
                                            onChange={(e) => { handleTargetingChange(e); }}
                                        >
                                            {getTargetingOptions()}
                                        </Field>
                                    </div>
                                    {targetingFieldValue != '' && (
                                        <div className='inputField medium' style={{ marginLeft: '2rem' }}>
                                            <Tooltip
                                                text={(
                                                    <React.Fragment>
                                                        <p>Values entered into this field will be used to find exact matching values to target. Please note:</p>
                                                        <ul>
                                                            <li>The complete word/value must be used; using “Hi” to target all “High” values will not work.</li>
                                                            <li>To exclude a single value from the target, add an exclamation point, !, to the beginning of the value. Example “!High” this will target all other values that do not match “High”</li>
                                                            <li>Any field that does not have any of the targeting fields selected, i.e. they are left blank, will not be included in the targeting.</li>
                                                            <li>If the Targeting field values are numeric values (this is a very rare scenario) please see the Knowledge Base Article or reach out to SwiftComply Customer Success.</li>
                                                        </ul>
                                                    </React.Fragment>
                                                )}
                                                html={true}
                                            />
                                            <label htmlFor="name">Targeting Value</label>
                                            {getTargetingValueField()}
                                        </div>
                                    )}
                                    <button style={{ marginLeft: "2rem" }} disabled={!values.targetingValue} className="xsmallButtonPrimary" type="button" onClick={() => addTargeting()}>+</button>
                                </div>
                            }
                            <div className="inputField long">
                                <label htmlFor="name">{newAddition ? 'Targeting Selections' : 'Targeting'}</label>
                                <div className="flexAlignCenter targetingSelection">
                                    {targetingOptionsLoaded &&
                                        getTargeting()
                                    }
                                    {Object.keys(targetingData).length === 0 && <span style={{ margin: '2rem 0rem 0rem 2rem', opacity: '50%' }}>No Targets Selected</span>}
                                </div>
                            </div>
                            <div className={'inputField medium flex-checkbox' + ((getError('use_primary_contact')) ? 'formRed' : '')}>
                                <label htmlFor="use_primary_contact">Use Primary Contact</label>
                                {editMode &&
                                    <Tooltip text="Check this box to send communication to the contact listed as primary regardless of the listed contact types below.  If no primary contact is defined then we will revert to sending the communication to all the listed contact types" />
                                }
                                <Field type="checkbox" name="use_primary_contact" className="inputField__input inputField__input-first"
                                    onChange={(e) => { setFieldValue(e.target.name, e.target.checked); handleFormChange(e); }}
                                />
                            </div>
                            <div className={'inputField medium flex-checkbox' + ((getError('use_first_available_method')) ? 'formRed' : '')}>
                                <label htmlFor="use_first_available_method">Use Only First Available Method</label>
                                {editMode &&
                                    <Tooltip
                                        text={
                                            (<React.Fragment><p>Check this box to use only 1 contact method per contact type.
                                                Contact methods will be attempted in the order listed, e.g if the contact method order is 1.Email 2. Letter,
                                                the system will generate only emails for all listed contact types with an email on file and will generate only letters for the listed contact types without emails on file.
                                            </p> <p>
                                                    If this box is not checked, the system will generate all contact methods listed for all contact types listed
                                                    e.g. if a contact type listed has an email on file and a mailing address on file the system will generate both types of communications for that contact type.</p></React.Fragment>)
                                        }
                                        html={true}
                                    />
                                }
                                <Field type="checkbox" name="use_first_available_method" className="inputField__input inputField__input-first"
                                    onChange={(e) => { setFieldValue(e.target.name, e.target.checked); handleFormChange(e); }}
                                />
                            </div>
                            <div className={'inputField medium ' + ((getError('contact_types')) ? 'formRed' : '')}>
                                {editMode &&
                                    <Tooltip text="Choose the types of contacts this communication will be sent to. For locations without the selected contact type, it will default to the Location address." />
                                }
                                <label htmlFor="contact_types">Contact Types *</label>
                                <FieldArray
                                    name="contact_types"
                                    render={arrayHelpers => (
                                        <div className="commArrays">
                                            {values.contact_types && values.contact_types.length > 0 ? (
                                                values.contact_types.map((type, index) => (
                                                    <div key={index} className="arrayInput">
                                                        <span>{`${index + 1}.`}</span>
                                                        <Field name={`contact_types.${index}`}
                                                            className="inputField__input inputField__input-first"
                                                            component="select"
                                                            onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}>
                                                            <option value=''></option>
                                                            {getSelectOptions('contact_types')}
                                                        </Field>
                                                        {editMode && (
                                                            <React.Fragment>
                                                                <button
                                                                    type="button"
                                                                    className={index + 1 === values.contact_types.length ? "removeButton" : "removeButton pushed"}
                                                                    onClick={() => arrayHelpers.remove(index)}
                                                                >
                                                                    <HighlightOffIcon fontSize="large" />
                                                                </button>
                                                                {index + 1 === values.contact_types.length &&
                                                                    <button
                                                                        type="button"
                                                                        className="addButton"
                                                                        onClick={() => arrayHelpers.push('')}>
                                                                        <AddBoxIcon fontSize="large" />
                                                                    </button>
                                                                }
                                                            </React.Fragment>
                                                        )}
                                                    </div>
                                                ))
                                            ) : (
                                                arrayHelpers.push('')
                                            )}
                                        </div>
                                    )}
                                />
                            </div>
                        </React.Fragment>
                    )}
                    <div className={'inputField medium ' + ((getError('contact_methods')) ? 'formRed' : '')}>
                        {editMode &&
                            <Tooltip text="Choose, in order, how you would like the communication to generate. All Methods will generate unless the ‘Use Only First Available Method’ box is selected." />
                        }
                        <label htmlFor="contact_methods">Contact Methods *</label>
                        <FieldArray
                            name="contact_methods"
                            render={arrayHelpers => (
                                <div className="commArrays">
                                    {values.contact_methods && values.contact_methods.length > 0 ? (
                                        values.contact_methods.map((type, index) => (
                                            <div key={index} className="arrayInput">
                                                <span>{`${index + 1}.`}</span>
                                                <Field name={`contact_methods.${index}`}
                                                    className="inputField__input inputField__input-first"
                                                    component="select"
                                                    onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}>
                                                    <option value=''></option>
                                                    {getSelectOptions('contact_methods')}
                                                </Field>
                                                {editMode && (
                                                    <React.Fragment>
                                                        <button
                                                            type="button"
                                                            className={index + 1 === values.contact_methods.length ? "removeButton" : "removeButton pushed"}
                                                            onClick={() => arrayHelpers.remove(index)}>
                                                            <HighlightOffIcon fontSize="large" />
                                                        </button>
                                                        {index + 1 === values.contact_methods.length &&
                                                            <button
                                                                type="button"
                                                                className="addButton"
                                                                onClick={() => arrayHelpers.push('')}>
                                                                <AddBoxIcon fontSize="large" />
                                                            </button>
                                                        }
                                                    </React.Fragment>
                                                )}
                                            </div>
                                        ))
                                    ) : (
                                        arrayHelpers.push('')
                                    )}
                                </div>
                            )}
                        />
                    </div>
                    {communicationsType.source !== "ad hoc" && (
                        <div className={'inputField medium flex-checkbox' + ((getError('disable_item_grouping')) ? 'formRed' : '')}>
                            <label htmlFor="disable_item_grouping">Disable Assembly Grouping</label>
                            {editMode &&
                                <Tooltip text="Check this box to send a separate communication for each assembly. This is recommended for postcards. Default will group all assemblies due at the same location." />
                            }
                            <Field type="checkbox" name="disable_item_grouping" className="inputField__input inputField__input-first"
                                onChange={(e) => { setFieldValue(e.target.name, e.target.checked); handleFormChange(e); }}
                            />
                        </div>
                    )}
                    {values.contact_methods.includes('email') &&
                        <div className={'inputField medium flex-checkbox' + ((getError('include_pdf_with_email')) ? 'formRed' : '')}>
                            <label htmlFor="include_pdf_with_email">Include PDF In Email</label>
                            {editMode &&
                                <Tooltip text="Check this box to include a PDF attachment of the communication with the email. Default will only include the Email Body details." />
                            }
                            <Field type="checkbox" name="include_pdf_with_email" className="inputField__input inputField__input-first"
                                onChange={(e) => { setFieldValue(e.target.name, e.target.checked); handleFormChange(e); }}
                            />
                        </div>
                    }
                    {emailActive && (
                        <React.Fragment>
                            <div className={'inputField long ' + ((getError('email_subject')) ? 'formRed' : '')}>
                                <label htmlFor="email_subject">Email Subject *</label>
                                <Field type="text" name="email_subject" autoComplete="off" disabled={data.status === 'published'} className="inputField__input inputField__input-first"
                                    onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
                                />
                            </div>
                            <div className={'inputField medium ' + ((getError('email_cc')) ? 'formRed' : '')}>
                                <label htmlFor="email_cc">CC</label>
                                <FieldArray
                                    name="email_cc"
                                    render={arrayHelpers => (
                                        <div className="commArrays">
                                            {values.email_cc && values.email_cc.length > 0 ? (
                                                values.email_cc.map((type, index) => (
                                                    <div key={index} className="arrayInput">
                                                        <span>{`${index + 1}.`}</span>
                                                        <Field name={`email_cc.${index}`}
                                                            className="inputField__input inputField__input-first"
                                                            onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
                                                        />
                                                        {editMode && (
                                                            <React.Fragment>
                                                                <button
                                                                    type="button"
                                                                    className={index + 1 === values.email_cc.length ? "removeButton" : "removeButton pushed"}
                                                                    onClick={() => arrayHelpers.remove(index)}
                                                                >
                                                                    <HighlightOffIcon fontSize="large" />
                                                                </button>
                                                                {index + 1 === values.email_cc.length &&
                                                                    <button
                                                                        type="button"
                                                                        className="addButton"
                                                                        onClick={() => arrayHelpers.push('')}>
                                                                        <AddBoxIcon fontSize="large" />
                                                                    </button>
                                                                }
                                                            </React.Fragment>
                                                        )}
                                                    </div>
                                                ))
                                            ) : (
                                                arrayHelpers.push('')
                                            )}
                                        </div>
                                    )}
                                />
                            </div>
                            <div className={'inputField medium ' + ((getError('email_bcc')) ? 'formRed' : '')}>
                                <label htmlFor="email_bcc">BCC</label>
                                <FieldArray
                                    name="email_bcc"
                                    render={arrayHelpers => (
                                        <div className="commArrays">
                                            {values.email_bcc && values.email_bcc.length > 0 ? (
                                                values.email_bcc.map((type, index) => (
                                                    <div key={index} className="arrayInput">
                                                        <span>{`${index + 1}.`}</span>
                                                        <Field name={`email_bcc.${index}`}
                                                            className="inputField__input inputField__input-first"
                                                            onChange={(e) => { setFieldValue(e.target.name, e.target.value); handleFormChange(e); }}
                                                        />
                                                        {editMode && (
                                                            <React.Fragment>
                                                                <button
                                                                    type="button"
                                                                    className={index + 1 === values.email_bcc.length ? "removeButton" : "removeButton pushed"}
                                                                    onClick={() => arrayHelpers.remove(index)}
                                                                >
                                                                    <HighlightOffIcon fontSize="large" />
                                                                </button>
                                                                {index + 1 === values.email_bcc.length &&
                                                                    <button
                                                                        type="button"
                                                                        className="addButton"
                                                                        onClick={() => arrayHelpers.push('')}>
                                                                        <AddBoxIcon fontSize="large" />
                                                                    </button>
                                                                }
                                                            </React.Fragment>
                                                        )}
                                                    </div>
                                                ))
                                            ) : (
                                                arrayHelpers.push('')
                                            )}
                                        </div>
                                    )}
                                />
                            </div>
                        </React.Fragment>
                    )
                    }
                </div>
            </fieldset>
            <div className="formButtonsBank">
                <button
                    type="button"
                    className="longButtonSecondary"
                    disabled={editMode && data.status === 'published'}
                    onClick={() => {
                        if (editMode) {
                            delete apiErrors['template'];
                            editorControls('open', 'commText')
                        } else {
                            previewCommunication(data.communication_uuid, (values.contact_methods.includes("postcard")) ? "postcard" : "letter")
                        }
                    }
                    }>
                    <span>{editMode ? 'Edit Communication Text' : 'Preview Communication Text'}</span>
                </button>
                {values.contact_methods.includes('email') &&
                    <div className="flexCenteredColumn">
                        {testEmailError && <Tooltip text="An email body is required" error={true} noIcon={true} />}
                        <button
                            id="previewEmailButton"
                            className="longButtonSecondary"
                            disabled={editMode && data.status === 'published'}
                            type="button"
                            onClick={() => {
                                if (editMode) {
                                    editorControls('open', 'emailText')
                                } else {
                                    previewEmail();
                                }
                            }
                            }>
                            {!sentCooldown &&
                                <React.Fragment>
                                    {!editMode && <Tooltip text="This will send a preview email with example data to the current signed-in user." />}
                                    <span>{editMode ? 'Edit Email Body' : 'Send Preview Email'}</span>
                                </React.Fragment>
                            }
                            {sentCooldown &&
                                <span>Sent</span>
                            }
                        </button>
                    </div>
                }
            </div>
            {
                editMode &&
                <div className="flexButtonContainer">
                    <button className="medButtonPrimary" disabled={isSubmitting} type="submit">Save</button>
                    <button className="medButtonSecondary" type="button" onClick={() => cancelAction()}>Cancel</button>
                </div>
            }
            {
                !editMode && !newAddition && (
                    <div className="flexButtonContainer">
                        <button
                            className="medButtonSecondary"
                            type="button"
                            onClick={() => setCommStatus((data.status === 'unpublished') || (data.status === 'draft') ? 'publish' : 'unpublish')}>
                            {(data.status === 'unpublished') || (data.status === 'draft') ? 'Publish' : 'Unpublish'}
                        </button>
                        <button className="medButtonSecondary" type="button" onClick={() => cloneComm(data)}>Clone</button>
                    </div>
                )
            }
            {
                (data.communication_uuid || newAddition) &&
                <CommsEditor
                    editorControls={editorControls}
                    open={commEditorOpen}
                    data={data}
                    textEditorContents={commText}
                    newAddition={newAddition}
                    editMode={editMode}
                    postcard={postcardActive}
                    type='commText'
                />
            }
            {
                values.contact_methods.includes('email') &&
                <CommsEditor
                    editorControls={editorControls}
                    open={emailEditorOpen}
                    data={data}
                    textEditorContents={emailText}
                    newAddition={newAddition}
                    editMode={editMode}
                    type='emailText'
                />
            }
            <Field type="hidden" name="communication_type_source" value={communicationsType.source} />
        </Form>
    );
};

const CommDetailsForm = withFormik({
    validateOnChange: false,
    validateOnBlur: false,
    enableReinitialize: true,
    mapPropsToValues(props) {
        let values = {
            name: (props.data.name ? props.data.name : ''),
            status: (props.data.status ? props.data.status : ''),
            communication_type: (props.data.communication_type ? props.data.communication_type : ''),
            period_num: (props.data.period ? Math.abs(props.data.period.split(' ')[0]) : ''),
            period_type: (props.data.period ? props.data.period.split(' ')[1] : ''),
            period_time: (props.data.period ? (Math.sign(props.data.period.split(' ')[0]) == -1 ? 'after' : 'before') : ''),
            email_subject: (props.data.email_subject ? props.data.email_subject : ''),
            email_cc: (props.data.email_cc ? props.data.email_cc : []),
            email_bcc: (props.data.email_bcc ? props.data.email_bcc : []),
            contact_methods: (props.data.contact_methods ? props.data.contact_methods : []),
            targeting: (props.data.targeting ? props.data.targeting : {})
        };

        for (let option in props.data.options) {
            values[option] = (props.data.options[option] && props.data.options[option] !== "false")
                ? props.data.options[option]
                : '';
        }

        let contact_types;
        if (props.data.contact_types) {
            if (props.data.contact_types.length < 1) {
                contact_types = ['all'];
            } else {
                contact_types = props.data.contact_types;
            }

        } else {
            contact_types = [];
        }

        values.contact_types = contact_types;

        // convert period types if we get back a singular from the backend, since we expect plural and values like
        // months are shortened to mon / mons
        switch (values.period_type) {
            case "day":
                values.period_type = "days";
                break;
            case "mon":
                values.period_type = "months";
                break;
            case "mons":
                values.period_type = "months";
                break;
            case "year":
                values.period_type = "years";
                break;
        }

        return values;
    },

    validationSchema: Yup.object().shape({
        name: Yup.string().required('Communication name is required'),
        communication_type: Yup.string().required('Communication Type is required'),
        email_subject: Yup.string().when('contact_methods', (contact_methods, schema) => {
            return contact_methods.includes('email') ?
                Yup.string().required('Email subject is required when email is set as contact method') : schema;
        }),
        contact_types: Yup.array(Yup.string()).compact().when('communication_type_source', {
            is: 'ad hoc',
            then: null,
            otherwise: Yup.array(Yup.string()).compact().min(1, 'At least one contact type is required').required()
        }),
        contact_methods: Yup.array(Yup.string()).compact().min(1, 'At least one contact method is required').required(),
        period_num: Yup.number().when('communication_type_source', {
            is: 'ad hoc',
            then: null,
            otherwise: Yup.number().required('A number is required'),
        }),
        period_type: Yup.string().when('communication_type_source', {
            is: 'ad hoc',
            then: null,
            otherwise: Yup.string().required('An interval is required')
        })
    }),

    handleSubmit(values, { props, setSubmitting }) {
        let period = `${values.period_num} ${values.period_type}`;
        if (values.period_time === 'after') {
            period = `${-Math.abs(values.period_num)} ${values.period_type}`;
        }

        Object.keys(values).forEach((key) => (values[key] == '') && delete values[key]);

        // deal with unchecked checkboxes
        ['include_pdf_with_email', 'disable_item_grouping', 'use_first_available_method', 'use_primary_contact'].forEach(field => {
            if (props.data[field] && !values[field]) {
                // so I think this will set the value to false only if it was true to begin with
                values[field] = false;
            }
        });

        // the cc and bcc fields are weird in that they are arrays in the API, and in order for it to know if you want
        // to clear it out you need to pass it an array with one value that is the "empty" value.  This is going to
        // change in the future.
        ['email_cc', 'email_bcc'].forEach(field => {
            if (props.data[field]?.length > 0 && (typeof values[field] === "undefined" || values[field]?.length == 0)) {
                values[field] = [""];
            }
        });

        let options = {
            include_pdf_with_email: values['include_pdf_with_email'],
            disable_item_grouping: values['disable_item_grouping'],
            use_first_available_method: values['use_first_available_method'],
            use_primary_contact: values['use_primary_contact'],
            date_format: values['date_format']
        }

        // if we don't do this when once it saves, it reloads the targeting with the values from when we loaded the
        // component so ... yeah, annoying
        props.data.targeting = values.targeting;

        props.saveDetails(null, props.data.communication_uuid, { ...values, period, options });
        setSubmitting(true);
        setTimeout(() => {
            setSubmitting(false);
        }, 3000);
    }
})(FormLayout);

export default CommDetailsForm; 
