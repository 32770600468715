import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from '@material-ui/pickers';
import moment from 'moment';

// Before I go looking again, this is ONLY used from the FormField component for things like test reports, surveys,
// and I suppose additional details would use it?  Every other date field like table filters, cert exp, etc would
// use the DatePicker component

const useStyles = makeStyles({
	root: {
		display: 'block'
	},
	input: {
		fontSize: '1.8rem'
	}
});

const DatePickerField = (props) => {
	const { details, prepopulateValues, values, setFieldValue } = props;
	const classes = useStyles();
	const [selectedDate, setSelectedDate] = useState(null);

	useEffect(() => {
		let m = null;
		let dateValue = "";
		// the fields value might come from values meaning it was saved as that particular value in the db, or from
		// prepopulate values which is some value we looked up and are suggesting to the user.  So use values first
		// since that is what a user would have saved, if not that use prepopulate.
		if (values && values[details.form_field_uuid]) {
			dateValue = values[details.form_field_uuid];
		} else if (prepopulateValues && prepopulateValues[details.form_field_uuid]) {
			dateValue = prepopulateValues[details.form_field_uuid];
		}

		// if we found a value parse it depending on what format its in
		if (dateValue != "") {
			if (dateValue.length <= 10) {
				// seconds
				m = moment.unix(Number.parseInt(dateValue, 10));
			} else {
				// milliseconds
				m = moment(Number.parseInt(dateValue, 10));
			}
		} else if (details.populate_from === 'f:date.now') {
			// if we didn't already havea  value then we might still want to set this field to now
			m = moment();
		}

		// we want the epoch seconds on the backend, so we need to set the field value to that here so that if the user
		// doesn't change anything, it goes through right (I hope)
		if (m != null) {
			let date = m.startOf('day').valueOf();
			setFieldValue(details.form_field_uuid, "" + Math.floor(date / 1000));
			setSelectedDate(date);
		}
	}, [values]);


	const handleDateChange = (date) => {
		// before I go looking again, this as well as the moment(); call on load should make a date time where the time
		// is whatever time it is now in our local time.  This should be ok across time zones I believe.
		let parsed = moment(date).startOf('day');
		if (!parsed.isValid()) {
			// date isn't valid, either they are still entering it or put in something crazy
			return;
		}
		if (parsed.diff(moment.now(), 'years') > 100) {
			// date is too far in the future so bail, 100 might be too much here but for now, whateves
			return;
		}
		setSelectedDate(parsed.valueOf());
		// again we want the seconds not milliseconds
		setFieldValue(details.form_field_uuid, "" + Math.floor(parsed.valueOf() / 1000)); // string because we want things on the backend as that
	};


	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
			<KeyboardDatePicker
				name={details && details.form_field_uuid ? details.form_field_uuid : ''}
				className={classes.root}
				inputProps={{
					className: classes.input, style: {
						borderBottom: '5px',
						paddingTop: '1.5rem',
						paddingBottom: '1.5rem'
					}
				}}
				disabled={props.disabled}
				disableToolbar
				disableFuture={props.disableFuture}
				autoOk
				variant="inline"
				inputVariant="outlined"
				format="yyyy-MM-dd"
				emptyLabel="YYYY-MM-DD"
				// id={details.form_field_uuid}
				value={selectedDate}
				onChange={handleDateChange}
				KeyboardButtonProps={{
					'aria-label': 'change date',
				}}
			/>
		</MuiPickersUtilsProvider>
	);
};

export default DatePickerField;