import React, { useState } from "react";
import styles from "../../../styles/common/dataEntryFields.module.css";
import InputField from "../Forms/Fields/InputField";
import Button from "../Button";
import SelectField from "../Forms/Fields/SelectField";
import CheckboxField from "../Forms/Fields/CheckboxField";

const DataEntryFields = ({ onAddClicked }) => {
    const [label, setLabel] = useState("");
    const [type, setType] = useState("input");
    const [optional, setOptional] = useState(false);

    const fieldTypes = [
        { label: "Short Text", value: "input" },
        { label: "Long Text", value: "textarea" },
        { label: "Date", value: "date" },
        { label: "Time", value: "time" },
        { label: "Numeric", value: "numeric" },
        { label: "Checkbox", value: "checkbox" },
    ];

    return (
        <div className={styles.wrapper}>
            <SelectField label="Field Type" value={type} setValue={setType} options={fieldTypes} />
            <InputField label="Field Name" value={label} setValue={setLabel} />
            <div className={styles.buttonRow}>
                <CheckboxField type="switch" label="Optional" value={optional} setValue={setOptional} />
                <Button
                    color="primary"
                    onClick={() =>
                        onAddClicked({
                            label: `${fieldTypes.find((ft) => ft.value === type).label}: ${label}${
                                optional ? " (opt)" : ""
                            }`,
                            template_string: `{{.DataEntry.${label.replaceAll(/\W/g, "")}.String}}`,
                            section: "data_entry",
                            inputType: type === "numeric" ? "input" : type,
                            dataType: type === "numeric" ? "numeric" : undefined,
                            required: !optional,
                        })
                    }
                    disabled={!label.length}
                >
                    Add Field
                </Button>
            </div>
        </div>
    );
};

export default DataEntryFields;
