import React from "react";
import { Dialog, DialogActions, DialogContent } from "@material-ui/core";
import { WarningRounded } from "@material-ui/icons";
import styles from "../../../styles/common/deleteDialog.module.css";
import Button from "../Button";

const DeleteDialog = ({ open, onConfirm, onCancel, text }) => {
    return (
        <Dialog open={open} onClose={onCancel}>
            <DialogContent>
                {text ? (
                    <span>{text}</span>
                ) : (
                    <span>
                        Are you sure you want to delete this?
                        <br />
                        This action cannot be undone!
                    </span>
                
                )}
            </DialogContent>
            <DialogActions classes={styles}>
                <Button onClick={onCancel}>Cancel</Button>
                <Button color="warning" onClick={onConfirm}>
                    <WarningRounded /> Confirm Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteDialog;
