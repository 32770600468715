import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getURLBaseSection } from "../../../utils/url";
import { downloadReport, getReportInfoWithoutFilters } from "../../../api/reportingAPI";
import { getForm, getFormFields } from "../../../api/formsAPI";
import { CloseRounded, GetAppRounded, SearchRounded } from "@material-ui/icons";
import { InputBase, MenuItem, Paper, Select } from "@material-ui/core";
import { detailsReportMapping } from "../../../utils/reporting";
import { updateReportingOptionsPreferences } from "../../../store/actions/swiftComplyActions";
import { getErrorText } from "../../../utils/errors";
import { reportInputToFilterField } from "../../../utils/forms";
import IconButton from "../IconButton";
import ToolTip from "../ToolTip";
import BasicReportTable from "./BasicReportTable";
import ColumnMenu from "./ColumnMenu";
import FilterMenu from "./FilterMenu";
import LoadingSpinner from "../LoadingSpinner";
import styles from "../../../styles/common/reportTable.module.css";
import searchStyles from "../../../styles/common/searchBox.module.css";
import searchInputStyles from "../../../styles/common/searchInput.module.css";
import searchBySelectStyles from "../../../styles/common/searchBySelect.module.css";

/**
 * Renders an "advanced" report table with search, filters, and column selection
 *
 * @param {string} reportUUID - The report to be fetched
 * @param {Object} [inputs] - Object passed to <BasicReportTable> to refine results
 * @param {string[]} [defaultColumns] - Columns to be displayed if the current user has not yet set them in preferences
 * @param {(Object) => string} [rowLinks] - Called when rendering each row, can return a link for when the row is clicked
 * @param {Object} [columnFormats] - Object containing functions called when rendering each cell, can be used to alter contents
 * @param {Object} [columnAlignments] - Object containing an optional CSS "text-align" value for each column
 */
const AdvancedReportTable = ({
    reportUUID,
    inputs = {},
    defaultColumns,
    rowLinks,
    columnFormats,
    columnAlignments,
}) => {
    const { user } = useSelector((state) => state.swiftComply);
    const dispatch = useDispatch();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [reportTitle, setReportTitle] = useState("");
    const [allColumns, setAllColumns] = useState([]);
    const [filterFields, setFilterFields] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState({});
    const [searchFieldValue, setSearchFieldValue] = useState("");
    const [searchBy, setSearchBy] = useState("");
    const [search, setSearch] = useState(null);
    const [defaultSearchBy, setDefaultSearchBy] = useState("");
    const [searchableColumns, setSearchableColumns] = useState([]);
    const [detailsColumns, setDetailsColumns] = useState([]);
    const baseSection = getURLBaseSection().section;
    const options = JSON.parse(user?.preferences?.["reporting options"] || "{}");
    let { columns } = options[baseSection]?.[reportTitle] ?? {};

    if (!columns?.length) {
        columns = defaultColumns;
    }

    useEffect(() => {
        setLoading(true);

        let reportInfo = null;

        getReportInfoWithoutFilters(reportUUID)
            .then((data) => {
                reportInfo = data;

                // Save title for the download option
                setReportTitle(data.name);

                // Set available filters based on the "inputs" to this report
                const processedFilterFields = data.inputs.map(reportInputToFilterField);
                setFilterFields(processedFilterFields);

                // Set initial selected filters based on default values
                setSelectedFilters(
                    processedFilterFields.reduce(
                        (prev, curr) => ({
                            ...prev,
                            [curr.id]: curr.defaultValue,
                        }),
                        {}
                    )
                );

                setSearchableColumns(data.outputs?.filter((col) => col.searchable)?.map((col) => col.name) ?? []);
                setDefaultSearchBy(data.default_search);

                // For every "details type" listed in the report data, fetch the associated form fields
                return Promise.all(
                    data.details?.map((detailsType) =>
                        getForm(detailsReportMapping[detailsType]).then((data) =>
                            data ? getFormFields(data.form_uuid) : { form_fields: [] }
                        )
                    ) ?? []
                );
            })
            .then((detailsArray) => {
                const allDetails = detailsArray.map((details) =>
                    details.form_fields.filter(
                        // This filter removes fields that have a label like "Name" that conflicts with basic
                        // property info fields - bug filed to fix this
                        (field) => !reportInfo.outputs.some((output) => output.name === field.label)
                    )
                );

                setDetailsColumns(
                    reportInfo.details.reduce(
                        (prev, curr, i) => ({
                            ...prev,
                            [curr]: {
                                details: allDetails[i].map((field) => field.form_field_uuid),
                            },
                        }),
                        {}
                    )
                );
            })
            .catch(setError)
            .finally(() => setLoading(false));
    }, [reportUUID]);

    useEffect(() => {
        // Default "search by" has changed, reset the current "search by" setting
        setSearchBy(defaultSearchBy);
    }, [defaultSearchBy]);

    const handleSearchChange = () =>
        setSearch(searchFieldValue ? { [searchBy.toLowerCase()]: searchFieldValue } : null);

    if (error) {
        return getErrorText(error);
    }

    if (loading) {
        return <LoadingSpinner />;
    }

    const allFilterInputs = {
        ...inputs,
        ...Object.entries(selectedFilters).reduce((acc, [key, value]) => {
            if (typeof value === "boolean") {
                acc[key] = value.toString();
            } else if (Array.isArray(value)) {
                acc[key] = value.join(", ");
            } else if (value) {
                acc[key] = value;
            }

            return acc;
        }, {}),
    };

    return (
        <div className={styles.advancedTable}>
            <div className={styles.advancedHeader}>
                <Paper variant="outlined" classes={searchStyles}>
                    <IconButton icon={<SearchRounded />} onClick={handleSearchChange} />
                    <InputBase
                        classes={searchInputStyles}
                        placeholder="Search..."
                        value={searchFieldValue}
                        onChange={(e) => setSearchFieldValue(e.target.value ?? "")}
                        onKeyDown={(e) => e.key === "Enter" && handleSearchChange()}
                    />
                    {searchFieldValue && (
                        <IconButton
                            icon={<CloseRounded />}
                            onClick={() => {
                                setSearchFieldValue("");
                                setSearch(null);
                            }}
                        />
                    )}
                    <Select
                        classes={searchBySelectStyles}
                        value={searchBy}
                        onChange={(event) => setSearchBy(event.target.value)}
                        disableUnderline
                    >
                        {searchableColumns.map((col) => (
                            <MenuItem key={col} value={col}>
                                {col}
                            </MenuItem>
                        ))}
                    </Select>
                </Paper>
                <ToolTip title="Download CSV">
                    <IconButton
                        icon={<GetAppRounded />}
                        onClick={() => downloadReport(reportUUID, reportingParams, reportTitle)}
                    />
                </ToolTip>
                <FilterMenu
                    filterFields={filterFields}
                    selectedFilters={selectedFilters}
                    onApplyFilters={setSelectedFilters}
                />
                {!!allColumns?.length && (
                    <ColumnMenu
                        columns={allColumns.filter((c) => !c.match(/uuid/i))}
                        selectedColumns={columns}
                        onApply={(columns) =>
                            dispatch(updateReportingOptionsPreferences(baseSection, reportTitle, columns))
                        }
                    />
                )}
            </div>
            <BasicReportTable
                reportUUID={reportUUID}
                inputs={allFilterInputs}
                search={search}
                detailsColumns={detailsColumns}
                onColumnsChanged={setAllColumns}
                visibleColumns={columns?.length ? columns : allColumns}
                rowLinks={rowLinks}
                columnFormats={columnFormats}
                columnAlignments={columnAlignments}
            />
        </div>
    );
};

export default AdvancedReportTable;
