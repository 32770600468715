import React, { useState, useEffect } from 'react';
import DatePicker from '../DatePicker';
import { getReportsList, filterCounter, getReportsOptions } from './WidgetReportsUtil';
import { setReportingParams, parseReportingParams } from '../../utils/reporting';
import { listReports, getReport } from '../../api/reportingAPI';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TableInputsFilter from '../TableInputsFilter';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Tooltip } from '../Tooltip';
import { isValidURL } from '../../utils/url';

class EmbedWidget extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            reportsList: null,
            reportData: { name: "webpage", type: "embed", component: true },
            reportingParams: {},
            widgetEditMode: false,
            title: "Webpage",
            url: '',
            URLError: false
        };

        const properties = JSON.parse(this.props.properties);
        this.state.properties = properties;
    };

    componentDidMount() {
        if (this.props.staged) {
            this.setState({ widgetEditMode: true })
            this.props.toggleDraggablePause('on')
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.properties !== prevProps.properties) {
            this.setState(() => ({ properties: JSON.parse(this.props.properties) }))
        }
    }

    getValues = () => {
        let values = [];

        if (this.state.reportData && this.state.reportData.component) {
            if (this.state.reportData.name === 'webpage') {
                return <iframe
                    src={!this.props.staged && this.state.properties.url ? this.state.properties.url : ''}
                    style={{ border: "none", width: "100%", height: "100%", margin: "2rem 0rem" }}
                >
                </iframe>
            }
        }
        return values;
    }

    handleDateChange = (payload, inputName) => {
        this.setState((prevState) => ({ inputs: { ...prevState.inputs, [inputName]: JSON.stringify(payload) } }))
    }

    setFilters = (filters, noUpdate) => {

    }

    addWidget = (reportChange) => {
        let skipUpdate = false;
        let validatedURL = isValidURL(this.state.url);

        if (validatedURL) {
            if (!this.props.staged) {
                this.props.updateWidget({
                    "properties":
                        JSON.stringify({
                            ...this.state.properties,
                            url: this.state.url !== '' ? this.state.url : this.state.properties.url
                        }),
                    "title": this.state.title
                }
                    , this.props.dashboard_widget_uuid,
                    this.props.position).then(() => {
                        if (!reportChange) {
                            this.handleClose()
                        } else {
                            this.inputFiltersRef.current.handleClear()
                        }
                    })
            } else {
                this.props.addWidget({
                    ...this.state.reportData,
                    coords: this.props.layout[this.props.position],
                    url: this.state.url
                }, 'create')
                this.handleClose()
            }
        } else {
            this.setState(() => ({ URLError: true }))
        }
    }

    deleteWidget = () => {
        if (confirm(`Are you sure you want to delete ${this.state.title} widget?`)) {
            this.props.deleteWidget(this.props.dashboard_widget_uuid, this.props.position)
        }
    }

    onURLChange = (value) => {
        this.setState(() => ({ url: value, URLError: false }))
    }

    toggleWidgetEditMode = () => {
        this.setState((prevState) => ({ widgetEditMode: !prevState.widgetEditMode }))
        this.props.toggleDraggablePause('on')
    }

    cancelEdit = () => {
        if (this.props.staged) {
            this.props.addWidget('cancel')
        }
        this.handleClose();
        this.setState(() => ({ URLError: false }))
    }

    handleClose = () => {
        this.setState(() => ({ widgetEditMode: false }));
        this.props.toggleDraggablePause('off')
    }

    render() {

        let editModeTitle;

        if (this.props.staged) {
            editModeTitle = 'Webpage';
        } else {
            editModeTitle = this.state.properties.report;
        }

        return (
            <React.Fragment>
                <Dialog
                    open={this.state.widgetEditMode ? true : false}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                    className="widget__dialog"
                    id="embedWidget"
                >
                    <DialogTitle id="form-dialog-title">{`Webpage Widget Options`}</DialogTitle>
                    <DialogContent>
                        <div className="widget__options" id="">
                            <div style={{ padding: "4rem" }} className={this.state.URLError ? 'formRed' : null}>
                                <label for="url">Website URL</label>
                                <input
                                    type="text"
                                    id="url"
                                    defaultValue={this.state.properties && this.state.properties.url ? this.state.properties.url : ''}
                                    onChange={(e) => this.onURLChange(e.target.value)}
                                >
                                </input>
                                {this.state.URLError &&
                                    <span className="URLError">Please enter a valid URL. ex. http://www.swiftcomply.com or https://swiftcomply.com</span>
                                }
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        {this.state.reportData && <button className="medButtonPrimary" onClick={() => this.addWidget()}>{this.props.staged ? 'Set Widget' : 'Update Widget'}</button>}
                        <button className="medButtonSecondary" onClick={() => this.cancelEdit()}>Cancel</button>
                    </DialogActions>
                </Dialog>
                <div className="widget widget__text" id={this.props.dashboard_widget_uuid}>
                    {this.getValues()}
                    {this.props.editMode && !this.props.staged &&
                        <div className="widget__controls widget__controls-editMode">
                            <React.Fragment>
                                <div>
                                    <EditRoundedIcon onClick={() => this.toggleWidgetEditMode()} fontSize="inherit" />
                                </div>
                                <div className="widget__controls-delete">
                                    <DeleteForeverIcon onClick={() => this.deleteWidget()} fontSize="inherit" />
                                </div>
                            </React.Fragment>
                        </div>
                    }
                </div>
            </React.Fragment>
        )
    }
};

export default EmbedWidget;