import React, { useEffect, useState, useRef } from 'react';
import { getUserNotifications, markNotificationAsRead } from '../../api/notificationsAPI';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import { format, differenceInDays } from 'date-fns';
import { Tooltip } from '../Tooltip';
import { FormControlLabel, Typography } from '@material-ui/core';
import DeleteForeverRoundedIcon from '@material-ui/icons/DeleteForeverRounded';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '65%',
        backgroundColor: theme.palette.background.paper,
        padding: '0',
        marginTop: '.5rem'
    },
}));


const UserNotifications = () => {

    const classes = useStyles();
    const [notifications, setNotifications] = useState(null);
    const [unreadCount, setUnreadCount] = useState('0');
    const [readCount, setReadCount] = useState('0');
    const [checked, setChecked] = useState([]);
    const [allChecked, setAllChecked] = useState(false);
    const [showRead, setShowRead] = useState(false);

    useEffect(() => {
        getNotificationsList();
    }, [])

    // Whenever the select all checkbox is changed, determine whether to clear out all checks or set everything checked.
    useEffect(() => {
        if (notifications) {
            const allNotifications = [...notifications.certifications, ...notifications.equipment, ...notifications.reports];
            const newChecked = [];
            allNotifications.forEach((notification) => {
                if (notification.was_read === false) {
                    newChecked.push(notification)
                }
            });
            if (allChecked) {
                setChecked(newChecked);
            } else {
                if (checked.length === newChecked.length) {
                    setChecked([])
                }
            }
        }

    }, [allChecked]);

    // Whenever a list item checkbox is changed, see if we need to set the select all checkbox to checked or not.
    useEffect(() => {
        if (notifications) {
            const allNotifications = [...notifications.certifications, ...notifications.equipment, ...notifications.reports];
            const newChecked = [];
            allNotifications.forEach((notification) => {
                if (notification.was_read === false) {
                    newChecked.push(notification);
                }
            })

            if (newChecked.length === checked.length) {
                setAllChecked(true);
            } else {
                setAllChecked(false);
            }
        }
    }, [checked])

    const getNotificationsList = async () => {
        const response = await getUserNotifications();
        let responseCopy = { ...response };
        const allNotifications = [...response.certifications, ...response.equipment, ...response.reports];
        let read = 0;

        // allNotifications.forEach((notification) => {
        //     if (notification.was_read === true) {
        //         read++;
        //     }
        // })

        setUnreadCount(response.unread_count);
        setReadCount(allNotifications.length - response.unread_count);

        delete responseCopy['unread_count'];

        // Add type to notification objects since markAsRead requires type
        Object.keys(responseCopy).forEach((section) => {
            responseCopy[section].forEach((notification) => {
                notification.type = section
            })
        })

        setNotifications(responseCopy);
    }

    const formatDate = (epoch) => {
        let formattedDate;
        formattedDate = format(epoch * 1000, 'yyyy-MM-dd');
        return formattedDate;
    }

    const getDaysUntilExpiration = (expiration_date) => {
        const today = new Date();
        const todayEpoch = today.getTime();
        const difference = differenceInDays(expiration_date * 1000, todayEpoch);
        return difference;
    }

    const markAsRead = async (payload, type) => {

        if (payload) {
            await markNotificationAsRead(payload.uuid, type);
            getNotificationsList();
        } else {
            let promises = [];
            checked.forEach((notification) => {
                promises.push(markNotificationAsRead(notification.uuid, notification.type))
            })
            Promise.all(promises).then(() => {
                getNotificationsList();
            })
        }
    }

    const buildNotificationsLists = () => {
        let lists = [];
        let label;
        if (notifications) {
            Object.keys(notifications).forEach((type) => {
                let hasUnread = false;
                let hasRead = false;
                notifications[type].forEach((x) => {
                    if (x.was_read === false) {
                        hasUnread = true;
                    } else if (x.was_read === true) {
                        hasRead = true;
                    }
                });

                if (type === 'certifications') {
                    label = 'Expiring Certifications'
                } else if (type === 'equipment') {
                    label = 'Expiring Test Kit Calibrations'
                } else if (type === 'reports') {
                    label = 'Rejected/Returned Test Reports'
                }

                lists.push(
                    <div key={type} className="notificationsGroup">
                        <span>{label}</span>
                        {hasUnread || (showRead && hasRead) ?
                            <List className={classes.root}>
                                {notifications[type].map((notification) => {
                                    const labelId = `checkbox-list-label-${notification}`;
                                    const metadata = notification.metadata
                                    let itemText, url;
                                    if (type === 'certifications') {
                                        if (getDaysUntilExpiration(metadata.expiration_date) < 0) {
                                            itemText = `${metadata.certifying_agency} certification expired ${Math.abs(getDaysUntilExpiration(metadata.expiration_date))} day/s ago on ${formatDate(metadata.expiration_date)}`
                                        } else {
                                            itemText = `${metadata.certifying_agency} certification is expiring in ${getDaysUntilExpiration(metadata.expiration_date)} day/s on ${formatDate(metadata.expiration_date)}`
                                        }
                                        url = `/profile`
                                    } else if (type === 'equipment') {
                                        if (getDaysUntilExpiration(metadata.expiration_date) < 0) {
                                            itemText = `Calibration for Test Kit #${metadata.serial_number} expired ${Math.abs(getDaysUntilExpiration(metadata.expiration_date))} day/s ago on ${formatDate(metadata.expiration_date)}`
                                        } else {
                                            itemText = `Calibration for Test Kit #${metadata.serial_number} is expiring in ${getDaysUntilExpiration(metadata.expiration_date)} day/s on ${formatDate(metadata.expiration_date)}`
                                        }
                                        url = `/serviceProviders/${metadata.service_provider_uuid}`
                                    } else if (type === 'reports') {
                                        itemText = `Assembly #${metadata.equipment.serial_number} at ${metadata.property_name}`,
                                            url = `/testReports/${notification.uuid}`
                                    }
                                    if ((!showRead && !notification.was_read) || showRead) {
                                        return (
                                            <ListItem
                                                key={notification['uuid']}
                                                role={undefined}
                                                dense
                                                button
                                                className={notification.was_read ? "listItemRead" : "listItemUnread"}
                                            >
                                                <ListItemIcon>
                                                    {!notification.was_read &&
                                                        <Checkbox
                                                            edge="start"
                                                            checked={checked.indexOf(notification) !== -1}
                                                            tabIndex={-1}
                                                            onChange={handleCheckboxToggle(notification)}
                                                            inputProps={{ 'aria-labelledby': labelId }}
                                                        />
                                                    }
                                                </ListItemIcon>
                                                <ListItemText
                                                    disableTypography
                                                    primary={<Typography type="body2" className={notification.was_read ? "readText" : "unreadText"}>{itemText}</Typography>}
                                                    id={labelId}
                                                    onClick={() => window.open(url, "_blank")}
                                                />
                                                {notification.was_read === false &&
                                                    <ListItemSecondaryAction>
                                                        <IconButton edge="end" aria-label="comments" onClick={() => markAsRead(notification, type)}>
                                                            {
                                                                type === "reports" ?
                                                                    <React.Fragment>
                                                                        <div>
                                                                            <Tooltip text='Delete Notification' noIcon={true} position="top" />
                                                                        </div>
                                                                        <DeleteForeverRoundedIcon style={{ fontSize: '1.8rem' }} />
                                                                    </React.Fragment>
                                                                    :
                                                                    <React.Fragment>
                                                                        <div>
                                                                            <Tooltip text='Mark as Read' noIcon={true} position="top" />
                                                                        </div>
                                                                        <CheckRoundedIcon style={{ fontSize: '1.8rem' }} />
                                                                    </React.Fragment>
                                                            }
                                                        </IconButton>
                                                    </ListItemSecondaryAction>
                                                }
                                            </ListItem>
                                        );
                                    }
                                })}
                            </List>
                            : <div className="noUnreadMessage">{`No Unread Notifications`}</div>
                        }
                    </div>
                )
            })
        }
        return lists;
    }

    const handleCheckboxToggle = (payload) => () => {

        if (payload === 'all') {
            setAllChecked(!allChecked);
        } else if (payload === 'showRead') {
            setShowRead(!showRead);
            // setTimeout(() => {
            //     getNotificationsList();
            // }, 500);
        } else {
            const currentIndex = checked.indexOf(payload);
            const newChecked = [...checked];

            if (currentIndex === -1) {
                newChecked.push(payload);
            } else {
                newChecked.splice(currentIndex, 1);
            }

            setChecked(newChecked);
        }
    };

    return (
        <React.Fragment>
            <div className="notificationCounts" >{`Unread Notifications: ${unreadCount} | Read Notifications: ${readCount}`}</div>
            <div className="broadOptions">
                <React.Fragment>
                    <div>
                        {unreadCount > 0 &&
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id="allCheck"
                                        edge="start"
                                        onChange={handleCheckboxToggle('all')}
                                        checked={allChecked}
                                    />
                                }
                                label="Select All"
                            />
                        }
                        <FormControlLabel
                            control={
                                <Checkbox
                                    id="showRead"
                                    edge="start"
                                    onChange={handleCheckboxToggle('showRead')}
                                    checked={showRead}
                                />
                            }
                            label="Show Read"
                        />
                    </div>
                </React.Fragment>
                {checked.length > 0 &&
                    <div className="markAsRead">
                        <button onClick={() => markAsRead()}>
                            Mark as Read
                            </button>
                    </div>
                }
            </div>
            <div className="notificationsContainer">
                {buildNotificationsLists()}
            </div>
        </React.Fragment>
    )
}

export default UserNotifications;