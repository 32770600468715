import React from "react";
import { withRouter } from "react-router";
import { AddRounded } from "@material-ui/icons";
import { getPathname, getURLObjects, URLSectionInfo } from "../../../utils/url";
import styles from "../../../styles/common/home.module.css";
import Page from "../Page";
import Card from "../Card";
import Button from "../Button";

/**
 * A "Home" is a general page that shows a particular data type, report table, etc
 * - Example: <ContactsHome> shows a report table of all of the "Contacts" in the system
 *
 * @param {string} [cardTitle] - Optional title for the Card component
 * @param {string} [addNewForm] - Creates the link to the "add new" form, for example, if addNewForm is
 *     "addContact" then the link generated will look like "/bf/c/addContact"
 * @param {Object|string} [error] - see <Page>
 * @param {boolean} [loading] - see <Page>
 * @param {React.ReactNode} children
 * @param {*} [history]
 */
const Home = ({ cardTitle, addNewForm, loading, error, children, history }) => {
    const pathname = getPathname();
    const { section } = getURLObjects().pop();

    return (
        <Page loading={loading} error={error}>
            <div className={styles.header}>
                <h1 className={styles.title}>{URLSectionInfo[section].title}</h1>
                {addNewForm && (
                    <Button color="primary" size="large" onClick={() => history.push(`${pathname}/${addNewForm}`)}>
                        <AddRounded className={styles.addIcon} /> {URLSectionInfo[addNewForm].title}
                    </Button>
                )}
            </div>
            <Card title={cardTitle}>{children}</Card>
        </Page>
    );
};

export default withRouter(Home);
