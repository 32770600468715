import React from 'react';
import { getReportsList, filterCounter, getReportsOptions } from './WidgetReportsUtil';
import { setReportingParams, parseReportingParams } from '../../utils/reporting';
import { getReport } from '../../api/reportingAPI';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TableInputsFilter from '../TableInputsFilter';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Tooltip } from '../Tooltip';
import NotificationsDashboard from '../Notifications/NotificationsDashboard';

class DataWidget extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			reportsList: null,
			reportData: null,
			reportingParams: {},
			widgetEditMode: false,
			title: '',
			columns: null,
			labels: []
		};

		const properties = JSON.parse(this.props.properties);
		this.state.properties = properties;
		this.state.reportingParams = properties && properties.reportingParams ? properties.reportingParams : parseReportingParams();

		this.inputFiltersRef = React.createRef();
	};

	componentDidMount() {
		if (this.props.staged) {
			this.setState({ widgetEditMode: true })
			this.props.toggleDraggablePause('on')
		}
		getReportsList('data').then((response) => {
			this.setState(() => ({ reportsList: response }));
		})
		this.getWidgetData();
	}

	componentDidUpdate(prevProps) {
		if (this.props.properties !== prevProps.properties) {
			this.setState(() => ({ properties: JSON.parse(this.props.properties) }))
		}

		if (prevProps.staged !== this.props.staged) {
			this.getWidgetData(JSON.parse(this.props.properties).reportingParams)
		}
	}

	getWidgetData = async (newReportingParams) => {

		let reportingParams = (newReportingParams !== undefined) ? newReportingParams : this.state.reportingParams;

		if (this.state.properties.report === 'notifications') {
			this.setState(() => ({ reportData: { name: "notifications", type: "data", component: true }, title: "Notifications" }))
		} else {
			getReport(this.state.properties.report_uuid, reportingParams).then(response => {
				if (response.nextPage !== undefined && response.nextPage > this.state.reportingParams.page) {
					reportingParams.nextPage = response.nextPage;
				}
				this.defineColumns(response.data)
				this.setState(() => ({
					reportData: response.data, reportingParams,
					title: this.props.title
				}));
			});
		}
	}

	getValues = () => {
		let values = [];

		if (this.state.reportData && this.state.reportData.component) {
			if (this.state.reportData.name === 'notifications') {
				return <NotificationsDashboard widget="true" />
			}
		}
		if (this.state.reportData && this.state.columns) {
			this.state.columns.forEach((column, idx) => {
				values.push(
					<div key={idx} className="widget__text-contents">
						<div className="widget__text-row">
							<span className="widget__text_value">{column.value}</span>
							<span className="widget__text_label">{this.state.properties.labels && this.state.properties.labels[idx] ? this.state.properties.labels[idx] : column.label}</span>
						</div>
					</div>
				);
			});
		}
		return values;
	}

	convertTimestamp = (timestamp, to) => {
		timestamp = parseInt(timestamp, 10);
		if (to === 's') {
			if (Math.ceil(Math.log10(timestamp)) === 13) {
				return timestamp / 1000;
			}
		} else if (to === 'ms') {
			if (Math.ceil(Math.log10(timestamp)) === 10) {
				return timestamp * 1000;
			}
		}
		return timestamp;
	}

	defineColumns = (data) => {
		let columns = []
		let labels = []
		if (data) {
			data.rows.forEach((row) => {
				columns.push({ label: row.values[0], value: row.values[1] })
				labels.push(row.values[0])
			})
		}
		this.setState(() => ({ columns, labels }))
	}

	handleDateChange = (payload, inputName) => {
		this.setState((prevState) => ({ inputs: { ...prevState.inputs, [inputName]: JSON.stringify(payload) } }))
	}

	onLabelChange = (value, idx) => {
		let labels = [...this.state.labels]
		labels[idx] = value
		this.setState(() => ({ labels }))
	}

	defineLabelInputs = () => {
		let labels = [];
		if (this.state.labels) {
			this.state.labels.forEach((label, idx) => {
				labels.push(
					<input
						type="text"
						id={`label_${idx}`}
						key={idx}
						style={{ margin: '1rem 0' }}
						defaultValue={this.state.properties.labels && this.state.properties.labels[idx] ? this.state.properties.labels[idx] : label}
						onChange={(e) => this.onLabelChange(e.target.value, idx)}
					>
					</input>
				)
			})
		}
		return labels;
	}

	handleLabelChange = () => {

	}

	setFilters = (filters, noUpdate) => {
		let newReportingParams = setReportingParams(
			this.state.reportingParams,
			{ filters },
			false
		);
		if (!noUpdate) {
			this.getWidgetData(newReportingParams);
		}
		return newReportingParams;
	}

	addWidget = (reportChange) => {
		let skipUpdate = false;

		// if (reportChange) {
		// 	skipUpdate = true
		// }

		if (!this.props.staged) {

			this.props.updateWidget({
				"properties":
					JSON.stringify({
						...this.state.properties,
						reportingParams: this.setFilters({ ...this.inputFiltersRef.current.state.values }, skipUpdate),
						labels: reportChange ? [] : this.state.labels
					}),
				"title": this.state.title
			}
				, this.props.dashboard_widget_uuid,
				this.props.position).then(() => {
					if (!reportChange) {
						this.handleClose()
					} else {
						this.inputFiltersRef.current.handleClear()
					}
				})
		} else {
			let params;
			if (!this.state.reportData.component) {
				params = this.setFilters({ ...this.inputFiltersRef.current.state.values })
			} else {
				params = {}
			}
			this.props.addWidget({
				...this.state.reportData,
				coords: this.props.layout[this.props.position],
				reportingParams: params
			}, 'create')
			this.handleClose()
		}
	}

	deleteWidget = () => {
		if (confirm(`Are you sure you want to delete ${this.state.title} widget?`)) {
			this.props.deleteWidget(this.props.dashboard_widget_uuid, this.props.position)
		}
	}

	toggleWidgetEditMode = () => {
		this.setState((prevState) => ({ widgetEditMode: !prevState.widgetEditMode }))
		this.props.toggleDraggablePause('on')
	}

	cancelEdit = () => {
		if (this.props.staged) {
			this.props.addWidget('cancel')
		}
		this.handleClose();
		this.getWidgetData();
	}

	handleClose = () => {
		this.setState(() => ({ widgetEditMode: false }));
		this.props.toggleDraggablePause('off')
	}

	handleSelectedReportChange = (e) => {
		let selectedReport;
		if (isNaN(e.target.value)) {
			selectedReport = { name: e.target.value, component: true, title: e.target.value, type: "data" }
		} else {
			selectedReport = this.state.reportsList[e.target.value]
		}


		if (!this.props.staged) {
			if (confirm('Selecting a new report will permanently change this widget. Proceed?')) {
				this.setState({
					reportData: selectedReport,
					title: selectedReport.name,
					properties: { ...this.state.properties, report_uuid: selectedReport.report_uuid, labels: [] }
				}, () => {
					this.addWidget('reportChange')
				})
			}
		} else {
			this.setState({
				reportData: selectedReport,
				title: selectedReport.name,
				properties: { ...this.state.properties, report_uuid: selectedReport.report_uuid, labels: [] }
			})
		}
	}

	render() {

		let editModeTitle;

		if (this.props.staged) {
			editModeTitle = 'Text';
		} else {
			editModeTitle = this.state.properties.report;
		}

		return (
			<React.Fragment>
				<Dialog
					open={this.state.widgetEditMode ? true : false}
					onClose={this.handleClose}
					aria-labelledby="form-dialog-title"
					className="widget__dialog"
				>
					<DialogTitle id="form-dialog-title">{`${editModeTitle} Widget Options`}</DialogTitle>
					<DialogContent>
						<div className="widget__options">
							<div>
								<span>{this.props.staged ? 'Choose A Report' : 'Report'}</span>
								<select id="selectedReport" onChange={(e) => this.handleSelectedReportChange(e)}>
									<option></option>
									<option value={'notifications'} key={'notifications'}>{'Notifications'}</option>
									{getReportsOptions(this.state.reportsList, 'data', this.state.properties)}
								</select>
							</div>
							{this.state.reportData && !this.state.reportData.component &&
								<React.Fragment>
									{this.state.columns && this.state.reportData && this.state.reportData.rows &&
										<div>
											<span>Labels</span>
											{this.defineLabelInputs()}
										</div>}
									{this.state.reportData && this.state.reportingParams &&
										<div style={_.isEmpty(this.state.reportData.inputs) ? { display: 'none' } : null}>
											<span>Filters</span>
											<div className="widget__options-groupSelection">
												<button onClick={() => this.inputFiltersRef.current.handleClear()}>Clear</button>
											</div>
											<TableInputsFilter
												data={this.state.reportData}
												params={this.state.reportingParams}
												widget={true}
												ref={this.inputFiltersRef}
											/>
										</div>
									}
								</React.Fragment>}
						</div>
					</DialogContent>
					<DialogActions>
						{this.state.reportData && <button className="medButtonPrimary" onClick={() => this.addWidget()}>{this.props.staged ? 'Set Widget' : 'Update Widget'}</button>}
						<button className="medButtonSecondary" onClick={() => this.cancelEdit()}>Cancel</button>
					</DialogActions>
				</Dialog>
				<div className="widget widget__text" id={this.props.dashboard_widget_uuid}>
					<span className="widget__title">{/*this.state.reportData && this.state.reportData.component ? this.state.title : null*/}
						<div className="widget__filters">
							{filterCounter(this.state.properties, this.state.reportData) > 0 &&
								<div>
									{!this.state.widgetEditMode && <Tooltip text="Active Filters" noIcon={true} position="top" />}
									<FilterListIcon fontSize="inherit" color="inherit" />
								</div>
							}
						</div>
					</span>
					{this.getValues()}
					{this.props.editMode && !this.props.staged && this.state.columns &&
						<div className="widget__controls widget__controls-editMode">
							<React.Fragment>
								<div>
									<EditRoundedIcon onClick={() => this.toggleWidgetEditMode()} fontSize="inherit" />
								</div>
								<div className="widget__controls-delete">
									<DeleteForeverIcon onClick={() => this.deleteWidget()} fontSize="inherit" />
								</div>
							</React.Fragment>
						</div>
					}
					{this.state.reportData && this.state.reportData.component && this.props.editMode && !this.props.staged &&
						<div className="widget__controls widget__controls-editMode">
							<div className="widget__controls-delete">
								<DeleteForeverIcon onClick={() => this.deleteWidget()} fontSize="inherit" />
							</div>
						</div>
					}
				</div>
			</React.Fragment>
		)
	}
};

export default DataWidget;