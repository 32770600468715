import React, { useEffect, useState } from "react";
import { useForm, useFormState, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup";
import MessageBox from '../MessageBox';
import DatePicker from '../DatePicker';
import ClearIcon from '@material-ui/icons/Clear';
import { ClickAwayListener } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import AutoAccept from "../AutoAccept";

// Yup validation schema requires the @hookform library and a "yupResolver" import, which are a part of react-hook-forms.
const validationSchema = Yup.object().shape({
    firstname: Yup.string()
        .required('First Name is required'),
    lastname: Yup.string()
        .required('Last Name is required'),
    email: Yup.string().email()
        .required('Email is required'),
    user_type: Yup.string()
        .required('Please choose a User Type'),
}).required();

// This is where we define some useful react-hook-form props. 
// "Register" is how we are connecting inputs to the form. (ex. <input {...register("field name") />) This allows us to ignore certain fields from validation or passing data via submission.
// "Control" is necessary for using custom input functions like the DatePicker. It works the same as register just for components instead of inputs.
// "Reset" is the same as resetForm in formik, but this will also be how we update props and set defaultValues in a useEffect function watching props that could potentially change.
// Usage can be resetForm() to reset everything to defaultValues, or to reset specific fields via reset({ firstName: 'Bob' })
// "setValue" is the same as setFieldValue in formik, and "getValues" replaces the values prop that formik used. (ex. getValues("firstName") vs values.firstName)
// I also set some basic default values here as the documentation says to at least make things null or empty string to avoid things being undefined which could potentially throw errors.
export default function UserDetailsForm(props) {
    const { register, handleSubmit, formState: { errors }, control, reset, setValue, getValues } = useForm({
        defaultValues: {
            firstname: '',
            lastname: '',
            phone: '',
            email: '',
            user_type: '',
            job_title: '',
            status: '',
            approved_until: null,
            can_login: true,
            role_name: true,
        },
        resolver: yupResolver(validationSchema)
    });

    const [approveUntilDropdown, setApproveUntilDropdown] = useState(false);
    const [submitType, setSubmitType] = useState(null);

    // This allows us to access fields that have been changed or fields that have been focused by the user. Potentially useful but not being used right now.
    const { dirtyFields, touchedFields } = useFormState({
        control
    });

    // This is how we will be creating an "enableReinitialization" like Formik uses. Basically when the props change react-hook-form doesn't automatically update the default values.
    // Here I am setting all of the values when the props.parentData get loaded, and then whenever they change the values will get updated.
    useEffect(() => {
        reset({
            firstname: props.parentData.firstname,
            lastname: props.parentData.lastname,
            phone: props.parentData.phone,
            email: props.parentData.email,
            user_type: props.newAddition ? props.userType === 'Organization Users' ? 'normal' : 'service provider' : props.parentData.user_type,
            job_title: props.parentData.job_title,
            status: props.parentData.status,
            approved_until: props.parentData.approved_until,
            can_login: props.newAddition ? props.userType === 'Organization Users' ? true : false : props.canLogin ? true : false,
            role_name: props.newAddition ? true : props.isAdmin ? true : false,
        })
    }, [props.parentData, props.isAdmin, props.canLogin])

    const onSubmit = (data) => {
        let role = '';

        if (data.user_type === 'service provider') {
            role = 'Provider'
        } else if (data.user_type === 'normal') {
            if (data.role_name) {
                role = 'Org.Admin'
            } else {
                role = 'Org.User'
            }
        }

        const inviteFormat = {
            user: {
                email: data.email,
                firstname: data.firstname,
                lastname: data.lastname,
                phone: data.phone,
                user_type: data.user_type,
                job_title: data.job_title,
                password: 'default'
            },
            can_login: data.can_login ? true : false,
            send_email_invite: data.send_email_invite ? true : false,
            role_name: role
        }

        props.saveDetails(inviteFormat, props.newAddition, submitType);
        props.toggleEditMode();

        if ((inviteFormat.user.user_type !== 'service provider' && props.newAddition || submitType === 'invite')) {
            reset();
        }
    };

    const getFormErrors = () => {
        let errorList = [];
        let messageOptions = {};
        // We were listing out all of the keys manually here, why not just build the list from the registered inputs?
        Object.keys(getValues()).forEach((field) => {
            let err = getError(field);
            if (err) {
                errorList.push(err.message);
            }
        });

        if (props.apiUserErrors.length > 0) {
            props.apiUserErrors.forEach((error) => {
                if (error.type === 'email in use') {
                    errorList.push(
                        <span>This email address is currently in use. Please choose an alternate, or send an
						<span onClick={() => setSubmitType('invite').then(() => { onSubmit(getValues()) })} style={{ color: "blue", cursor: 'pointer' }}> invite </span>
                            to the email address entered.
						</span>
                    )
                }
            })
        }

        if (errorList.length !== 0) {
            messageOptions = {
                type: "error",
                message: "Please correct the following errors",
                list: errorList,
            };
            document.getElementById('userDetailsForm').scrollIntoView();
        }

        return messageOptions;
    }

    const getError = (fieldname) => {
        if (props.apiErrors && props.apiErrors[fieldname]) {
            return apiErrors[fieldname];
        } else if (errors[fieldname]) {
            return errors[fieldname];
        }
    }

    const handleApproveProviderUser = () => {
        props.approveProviderUser(getValues());
        toggleApproveUntilDropdown();
        reset();
    }

    const toggleApproveUntilDropdown = (type) => {
        const picker = document.getElementsByClassName('MuiPopover-root');
        if (type === 'close') {
            if (picker.length) {
                return null;
            } else {
                setApproveUntilDropdown(false);
            }
        } else if (type === 'cancel') {
            if (picker.length) {
                return null;
            } else {
                setApproveUntilDropdown(false);
                reset();
            }
        }

        else {
            setApproveUntilDropdown(!approveUntilDropdown);
        }
    }

    const cancelAction = () => {
        if (props.newAddition) {
            props.closeFlyout();
        } else {
            props.toggleEditMode();
            reset();
            props.setApiUserErrors([]);
            props.getAASettings();

        }
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="userDetailsForm" id="userDetailsForm">
            <MessageBox options={getFormErrors()} />
            <fieldset disabled={!(props.newAddition || (props.editMode && props.isSCAdmin))} className="detailsFieldset">
                <div className={'inputField medium ' + ((getError('firstname')) && 'formRed' || "")}>
                    <label>First Name *
                    <input {...register("firstname")} type="text" name="firstname" />
                    </label>
                </div>
                <div className={'inputField medium ' + ((getError('lastname')) && 'formRed' || "")}>
                    <label>Last Name *
                    <input {...register("lastname")} type="text" name="lastname" />
                    </label>
                </div>
                <div className={'inputField medium ' + ((getError('email') || props.apiUserErrors.some(error => error.type === 'email in use')) && 'formRed' || "")}>
                    <label>Email *
                    <input {...register("email")} type="text" name="email" />
                    </label>
                </div>
                <div className={'inputField medium ' + ((getError('phone')) && 'formRed' || "")}>
                    <label>Phone #
                    <input {...register("phone")} type="text" name="phone" />
                    </label>
                </div>
                <div className={'inputField medium ' + ((getError('user_type')) && 'formRed' || "")}>
                    <label>User Type *
                    <select {...register("user_type")} name="user_type">
                            <option value="normal">Organization</option>
                            <option value="service provider">Service Provider</option>
                        </select>
                    </label>
                </div>
                <div className={'inputField medium ' + ((getError('job_title')) && 'formRed' || "")}>
                    <label>Job Title
                    <input {...register("job_title")} type="text" name="job_title" />
                    </label>
                </div>
                {getValues("user_type") === "service provider" && props.newAddition &&
                    <div className='inputField long flexAlignCenter' style={{ marginBottom: "2rem" }}>
                        <button className="medButtonSecondary" type="button" onClick={() => props.toggleDialog('open')}>Choose Service Provider</button>
                        {props.selectedSP &&
                            <React.Fragment>
                                <button id="selectedSPClear" className="xsmallButtonTertiary" type="button" onClick={() => props.clearSelectedSP()}>
                                    <ClearIcon fontSize="large" style={{ color: "#FF005C" }} />
                                </button>
                                <span id="selectedSP">{props.selectedSP.name}</span>
                            </React.Fragment>
                        }
                    </div>}
            </fieldset>
            {props.parentData.user_type === 'service provider' && props.sourceUUID && (
                <fieldset disabled className="detailsFieldset">
                    <div className="inputField medium">
                        <label>Status
                        <input {...register("status")} type="text" name="status" />
                        </label>
                    </div>
                    <div className="inputField medium">
                        <label>Approved Until
                        <Controller
                                control={control}
                                name="approved_until"
                                render={({ field }) => (
                                    <DatePicker
                                        valueType="epoch"
                                        setFieldValue={setValue}
                                        editMode={false}
                                        field={field}
                                    />
                                )}
                            />
                        </label>
                    </div>
                </fieldset>
            )}
            <fieldset disabled={!props.editMode}>
                <div className={'inputField medium ' + ((getError('can_login')) && 'formRed' || "")}>
                    {/*
                        // Saving this for later, example of using material UI for checkboxes.
                        <Controller
                        control={control}
                        name="can_login"
                        render={({ field: { value, onChange } }) => (
                            <Checkbox
                                checked={value}
                                onChange={onChange}
                                value={value}
                                color='primary'
                            />
                        )}
                        />*/}
                    <input {...register("can_login")} type="checkbox" name="can_login" />
                    <label htmlFor="can_login">Allow Login Access</label>
                </div>
                {false && props.newAddition &&
                    <div className={'inputField medium ' + ((getError('send_email_invite')) && 'formRed' || "")}>
                        <input {...register("send_email_invite")} type="checkbox" name="send_email_invite" />
                        <label htmlFor="send_email_invite">Send Email Invite?</label>
                    </div>
                }
                {getValues("user_type") === "normal" &&
                    <div className={'inputField medium ' + ((getError('role_name')) && 'formRed' || "")}>
                        <input {...register("role_name")} type="checkbox" name="role_name" />
                        <label htmlFor="role_name">Admin</label>
                    </div>}
            </fieldset>
            <AutoAccept
                data={props.parentData}
                newAddition={props.newAddition}
                editMode={props.editMode}
                AASettings={props.AASettings}
                AASettingsRef={props.AASettingsRef}
                hasPermissions={props.isOrgAdmin}
            />
            {props.parentData.user_type === 'service provider' &&
                <div className="flexButtonContainer">
                    {/*<button className="longButtonPrimary" type="button" onClick={() => props.approveProviderUser(values)}>Approve User</button>*/}
                    {props.activeCertExpiration &&
                        <button className="longButtonPrimary" type="button" onClick={() => props.approveProviderUser(null, activeCertExpiration)}>Approve Until Next Cert Expiration</button>
                    }
                    <div className="flexAlignCenter">
                        <ClickAwayListener onClickAway={() => toggleApproveUntilDropdown('close')}>
                            <div className={approveUntilDropdown ? 'tableFilter__wrapper openBorderRadius slideUp' : 'tableFilter__wrapper'}>
                                <div className='tableFilter'>
                                    <button className="filterButton" type="button" onClick={() => toggleApproveUntilDropdown()}>
                                        Approve Until Specific Date
										</button>
                                </div>
                                {approveUntilDropdown && (
                                    <div className='tableFilter__dropdownContent-wrapper'>
                                        <div className='tableFilter__dropdownContent'>
                                            <div className='tableFilter__content'>
                                                <div className="inputField medium">
                                                    <label>Approve Until
                                                    <Controller
                                                            control={control}
                                                            name="approved_until"
                                                            render={({ field }) => (
                                                                <DatePicker
                                                                    valueType="epoch"
                                                                    disablePast={true}
                                                                    setFieldValue={setValue}
                                                                    editMode={true}
                                                                    field={field}
                                                                />
                                                            )}
                                                        />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className='tableFilter__buttons'>
                                                <button className="smallButtonPrimary" onClick={() => handleApproveProviderUser()} type="button">Approve</button>
                                                <button className="smallButtonSecondary" onClick={() => toggleApproveUntilDropdown('cancel')} type="button">Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </ClickAwayListener>
                    </div>
                    <button className="longButtonSecondary red" type="button" onClick={() => props.denyProviderUser()}>Deny User</button>
                </div>
            }
            {props.editMode && (
                <div className="flexButtonContainer">
                    <button className="medButtonPrimary" onClick={() => setSubmitType('persist')} type="submit">Save</button>
                    {props.newAddition &&
                        <React.Fragment>
                            <button className="medButtonPrimary" onClick={() => setSubmitType(null)} type="submit">Save and Close</button>
                            <button className="medButtonPrimary" onClick={() => setSubmitType('add')} type="submit">Save and Add Another User</button>
                        </React.Fragment>
                    }
                    <button className="medButtonSecondary" onClick={() => cancelAction()} type="button">Cancel</button>
                </div>
            )}
        </form>
    )
}
