import React from 'react';
import { updateURL } from '../../utils/url';
import Flyout from '../Flyout';
import MailRoundedIcon from '@material-ui/icons/MailRounded';
import ReportTable from '../ReportTable/ReportTable';
import { getReport } from '../../api/reportingAPI';
import { getReportValue, reportRowToObject } from '../../utils/reporting';
import { getCommList, sendEmails } from '../../api/communicationsAPI';
import CommDownloadRequests from './CommDownloadRequests';
import CommDownloadsForm from './CommDownloadsForm';
import BasicTable from '../BasicTable';
import validator from 'validator';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import LoaderOverlay from '../LoaderOverlay';

class CommsDashboard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            flyoutActive: false,
            commInfo: {},
            commUUID: null,
            commTypes: [],
            downloadFormErrors: "",
            downloadFormData: null,
            activeTab: 'commDetails',
            path: '/communications',
            dashboardType: comms,
            newAddition: false,
            reportIdx: 1,
            reportType: null,
            showDownloads: false,
            sentCooldown: false,
            reportResponse: null,
            emailsSentPrompt: false,
            emailsSent: false,
            fetchingDownloadData: false,
            downloadRequestRandomNum: 0
        };

        this.table = React.createRef();
        this.downloadForm = React.createRef();

        if (props.location.state?.originData) {

            this.state.newAddition = true;
            this.state.activeTab = 'commLogDetails';
            this.state.flyoutActive = true;
            this.state.commInfo =  props.location.state.originData;
        }

        let urlParts = this.props.location.pathname.split('/');
        if (urlParts.length >= 3) {
            if (urlParts[2] === 'newCommunication') {
                this.state.newAddition = true
            } else if (urlParts[2] === 'types') {
                this.state.reportIdx = 0;
                this.state.path = '/communications/types';
                this.state.dashboardType = commTypes;
                if (urlParts.length === 4) {
                    this.state.flyoutActive = true;
                    this.state.commUUID = decodeURIComponent(urlParts[3]);
                    this.state.activeTab = 'commTypeDetails';
                }
            } else if (urlParts[2] === 'logs') {
                this.state.reportIdx = 2;
                this.state.path = '/communications/logs';
                this.state.dashboardType = commLogs;
                this.state.reportType = 'Communication Logs';
                if (urlParts.length === 8) {
                    this.state.flyoutActive = true;
                    this.state.activeTab = 'commLogContactDetails';
                    this.state.commInfo = { communication_log_uuid: urlParts[3], contact_uuid: urlParts[5], contact_method: urlParts[6], communication_type: decodeURIComponent(urlParts[7]) }
                } else if (urlParts.length === 4 && validator.isUUID(urlParts[3])) {
                    this.state.flyoutActive = true;
                    this.state.commUUID = urlParts[3];
                    this.state.activeTab = 'commLogDetails';
                } else if (urlParts[3] === 'downloads') {
                    this.state.showDownloads = true;
                    if (urlParts.length === 5) {
                        this.state.flyoutActive = true;
                        this.state.commUUID = urlParts[4];
                        this.state.activeTab = 'commLogDetails';
                    }
                }
            } else if (urlParts[2] === 'errors') {
                this.state.reportIdx = 3;
                this.state.path = '/communications/errors';
                this.state.dashboardType = commErrors;
                this.state.reportType = 'Communications with Errors';
                if (urlParts.length === 8 && validator.isUUID(urlParts[3])) {
                    this.state.flyoutActive = true;
                    this.state.activeTab = 'commLogContactDetails';
                    this.state.commInfo = { communication_log_uuid: urlParts[3], contact_uuid: urlParts[5], contact_method: urlParts[6], communication_type: urlParts[7] }
                }
            } else if (validator.isUUID(urlParts[2])) {
                this.state.flyoutActive = true;
                this.state.commUUID = urlParts[2];
            }
        }
    }

    componentDidMount() {
        const overlay = document.getElementById('overlay');

        if (this.state.flyoutActive === true) {
            overlay.classList.add('overlay__visible');
        }

        if (this.state.newAddition) {
            this.openFlyout(null, true, 'comm')
        }

        this.getCommunicationTypes();
    }

    openFlyout = async (commInfo, newAddition = false, commType) => {
        const overlay = document.getElementById('overlay');
        const { dashboardType, activeTab } = this.state;
        let tab;

        if (!commType) {
            if (dashboardType === comms) {
                updateURL(`/communications/${commInfo.communication_uuid}`);
                tab = 'commDetails'
            } else if (dashboardType === commTypes) {
                updateURL('/communications/types/' + encodeURIComponent(commInfo.communication_type));
                tab = 'commTypeDetails'
            } else if (dashboardType === commLogs) {
                if (commInfo.contact_uuid) {
                    updateURL(`/communications/logs/${commInfo.communication_log_uuid}/contacts/${commInfo.contact_uuid}/${commInfo.contact_method}/` + encodeURIComponent(commInfo.communication_type));
                    tab = 'commLogContactDetails'
                } else {
                    updateURL(`/communications/logs/${commInfo.communication_log_uuid}`);
                    tab = 'commLogDetails';
                }
            } else if (dashboardType === commErrors) {
                updateURL(`/communications/errors/${commInfo.communication_log_uuid}/contacts/${commInfo.contact_uuid}/${commInfo.contact_method}/` + encodeURIComponent(commInfo.communication_type));
                tab = 'commLogContactDetails'
            }
        } else {
            if (commType === 'type') {
                if (!newAddition) {
                    updateURL('/communications/types/' + encodeURIComponent(commInfo.communication_type));
                }
                tab = 'commTypeDetails'
            } else if (commType === 'log') {
                if (!newAddition) {
                    updateURL(`/communications/logs/${commInfo.communication_log_uuid}`);
                }
                tab = 'commLogDetails'
            } else if (commType === 'comm') {
                if (!newAddition) {
                    updateURL(`/communications/${commInfo.communication_uuid}`);
                }
                tab = 'commDetails'
            } else if (commType === 'logContact') {
                tab = 'commLogContactDetails'
            }
        }

        let commData;

        if (newAddition) {
            if (commInfo && (commInfo.clone || commInfo.property_uuid)) {
                commData = commInfo;
            } else {
                commData = {};
            }
        } else {
            commData = commInfo;
        }

        this.setState(() => ({ newAddition, commInfo: commData, flyoutActive: true, activeTab: tab }), () => {
            overlay.classList.add('overlay__visible');
            if (commType === 'comm' && newAddition) {
                updateURL(`/communications/newCommunication`);
            }
        });

    }

    closeFlyout = () => {
        const flyout = document.getElementById('flyout');
        const overlay = document.getElementById('overlay');

        flyout.classList.remove('slideToVisible');
        overlay.classList.remove('overlay__visible');

        setTimeout(() => {
            this.setState(() => ({
                flyoutActive: false,
                commInfo: {},
                commUUID: null
            }));
        }, 200);


        if (this.state.showDownloads) {
            updateURL(`/communications/logs/downloads`)
        } else {
            this.table.current.ParentEventHandler('flyout closed', { path: this.state.path });
        }
    }

    callbackHandler = (event, info) => {
        if (event === 'report selected') {
            let path = '/communications';
            let reportIdx = 0;
            if (info.name === comms) {
                reportIdx = 1;
            } else if (info.name === commTypes) {
                path += '/types';
                reportIdx = 0;
            } else if (info.name === commLogs) {
                path += '/logs';
                reportIdx = 2;
            } else if (info.name === commErrors) {
                path += '/errors';
                reportIdx = 3;
            }
            this.setState({ dashboardType: info.name, reportIdx, path });

            updateURL(path);
        }
    }

    cloneComm = (commData) => {
        this.closeFlyout();
        setTimeout(() => {
            commData.clone = true;
            this.openFlyout(commData, true, 'comm')
        }, [500])
    }

    getReportType = (type) => {
        this.setState(() => ({ reportType: type.name }));
    }

    getCommunicationTypes = () => {
        getReport('14d17cfb-ac90-4b30-84fb-5c7ecf076503', { count: 100 }).then(response => {
            let types = [];
            response.data.rows.map((row) => {
                let type = getReportValue(response.data, row, 'Communication Type');
                types.push(type);
            });
            this.setState({ commTypes: types });
        });
    }

    handleDownloadFormSubmit = (payload) => {
        this.setState({ fetchingDownloadData: true });
        getCommList(payload).then((response) => {
            let data = [];
            response.items.forEach((item) => {
                let info = JSON.parse(item.communication_info);
                let contact = JSON.parse(item.contact_info);

                let contactName = "No Contact";
                if (contact.firstname || contact.lastname) {
                    contactName = [contact.firstname, contact.lastname].join(" ");
                }

                data.push({
                    "communication_log_uuid": item.communication_log_uuid,
                    "contact_uuid": item.contact_uuid,
                    "method": item.method,
                    "communication_type": item.communication_type,
                    "location_name": info.property.name,
                    "contact_name": contactName,
                    "due_on": item.due_on,
                    "generated_on": item.generated_on
                });
            });

            this.setState({ downloadFormErrors: "", downloadFormData: data, emailsSent: false, fetchingDownloadData: false });
        }).catch(error => {
            if (error.name === 'APIUserError') {
                this.setState({ downloadFormErrors: error.message, downloadFormData: null, fetchingDownloadData: false });
            }
        })
    }

    toggleDownloads = () => {
        this.setState((prevState) => ({ showDownloads: !prevState.showDownloads, downloadFormData: null, downloadFormErrors: "" }), () => {
            if (this.state.showDownloads) {
                updateURL(`/communications/logs/downloads`, {});
            } else {
                updateURL(this.state.path, {});
            }
        })
    }

    sendEmail = async (contact, type, comm_log_uuid) => {
        const { commInfo } = this.state;

        let payload = {
            communication_type: type,
            method: 'email'
        }

        if (contact) {
            payload.contact_uuid = contact;
        }
        if (comm_log_uuid) {
            payload.communication_log_uuid = comm_log_uuid;
        } else if (commInfo.communication_log_uuid) {
            payload.communication_log_uuid = commInfo.communication_log_uuid;
        }

        const sentResponse = await sendEmails(payload);

        if (sentResponse) {
            this.setState(() => ({ sentCooldown: true }))
            setTimeout(() => {
                this.setState(() => ({ sentCooldown: false }))
                if (!this.state.showDownloads) {
                    this.table.current.ParentEventHandler('needs update');
                }
            }, 2000)
        }
    }

    getReportResponse = (response) => {
        if (Object.keys(this.state.commInfo).length > 0 && !this.state.commInfo.communication_type) {
            response.data.rows.forEach((row, idx) => {
                if (getReportValue(response.data, row, 'Contact UUID') === this.state.commInfo.contact_uuid &&
                    getReportValue(response.data, row, 'Communication Log UUID') === this.state.commInfo.communication_log_uuid) {
                    this.setState({ reportResponse: reportRowToObject(row.values, response.data.outputs) })
                }
            })
        }
    }

    emailsSentPromptOptions = (type) => {
        if (type === 'sent') {
            this.setState(() => ({ emailsSentPrompt: true, emailsSent: true }))
        } else {
            this.setState(() => ({ emailsSentPrompt: false }))
        }
    }

    clearDownloads = () => {
        this.setState(() => ({ downloadFormData: null }))
    }

    // we need something to pass to the download request component to have it refresh things, since it has no real props
    // just send it a random number
    refreshDownloads = () => {
        this.setState({ downloadRequestRandomNum: Math.random() });
    }

    render() {
        const { commInfo, flyoutActive, newAddition, activeTab, dashboardType, showDownloads } = this.state;
        let flexUUID, flexFlyoutType;

        if (dashboardType === comms) {
            if (commInfo.communication_uuid) {
                flexUUID = commInfo.communication_uuid;
            } else if (this.state.commUUID) {
                flexUUID = this.state.commUUID;
            }
        } else if (dashboardType === commTypes) {
            if (commInfo.communication_type) {
                flexUUID = commInfo.communication_type;
            } else if (this.state.commUUID) {
                flexUUID = this.state.commUUID;
            }
        } else if (dashboardType === commLogs || dashboardType === commErrors) {
            if (commInfo.communication_log_uuid) {
                flexUUID = commInfo.communication_log_uuid;
            } else if (this.state.commUUID) {
                flexUUID = this.state.commUUID;
            }
        }

        if (activeTab === 'commDetails') {
            flexFlyoutType = 'comms';
        } else if (activeTab === 'commLogDetails') {
            flexFlyoutType = 'commLogs';
        } else if (activeTab === 'commTypeDetails') {
            flexFlyoutType = 'commTypes';
        } else if (activeTab === 'commLogContactDetails') {
            flexFlyoutType = 'commLogContact'
        }

        let refreshProps = {};

        if (!showDownloads) {
            refreshProps.refresh = () => this.table.current.ParentEventHandler('needs update');
        } else {
            if (this.state.downloadFormData !== null) {
                refreshProps.refresh = () => this.downloadForm.current.click();
            } else {
                refreshProps.refresh = () => _.noop();
            }
            refreshProps.downloads = true;
        }

        return (
            <div className="componentContainer" id="communicationsDashboard">
                <div className="componentHeader">
                    <div className="flexAlignCenter">
                        <MailRoundedIcon style={{ fontSize: '2rem', marginRight: '1rem' }} />
                        <h3>Communications</h3>
                    </div>
                    <div className="componentHeader__buttonBank">
                        <button onClick={this.toggleDownloads} className="medButtonSecondary">
                            {(showDownloads) ? 'Dashboard' : 'Downloads'}
                        </button>
                        <button onClick={() => this.openFlyout(null, true, 'comm')} className="medButtonPrimary">Create Template</button>
                        <button onClick={() => this.openFlyout(null, true, 'type')} className="medButtonPrimary">Create Type</button>
                        <button onClick={() => this.openFlyout(null, true, 'log')} className="medButtonPrimary">Create Log</button>
                    </div>
                </div>
                {showDownloads && (
                    <React.Fragment>
                        <CommDownloadRequests refreshRandomNum={this.state.downloadRequestRandomNum} />
                        <CommDownloadsForm
                            typesList={this.state.commTypes}
                            formErrors={this.state.downloadFormErrors}
                            formValues={{}}
                            handleSubmit={this.handleDownloadFormSubmit}
                            downloadFormData={this.state.downloadFormData}
                            emailsSentPromptOptions={this.emailsSentPromptOptions}
                            emailsSent={this.state.emailsSent}
                            clearDownloads={this.clearDownloads}
                            downloadFormRef={this.downloadForm}
                            refreshDownloads={this.refreshDownloads}
                        />
                        <Dialog
                            open={this.state.emailsSentPrompt ? true : false}
                            aria-labelledby="form-dialog-title"
                        >
                            <DialogTitle id="form-dialog-title">Processing - your emails are being sent. </DialogTitle>
                            <DialogActions>
                                <button className="medButtonPrimary" onClick={() => this.emailsSentPromptOptions()}>Return To Downloads</button>
                            </DialogActions>
                        </Dialog>
                        {this.state.fetchingDownloadData ?
                            <LoaderOverlay /> : this.state.downloadFormData && (
                                <div className="mainTableContainer">
                                    <BasicTable
                                        tableData={this.state.downloadFormData}
                                        tableType="Communication Logs"
                                        dataSource="list"
                                        columns={downloadTableColumns}
                                        openFlyout={(row) => {
                                            this.openFlyout(
                                                {
                                                    communication_log_uuid: row.communication_log_uuid,
                                                    contact_uuid: row.contact_uuid,
                                                    method: row.method,
                                                    communication_type: row.communication_type
                                                },
                                                false,
                                                (row.contact_uuid !== "" ? 'logContact' : 'log'));
                                        }}
                                        highlight={true}
                                    />
                                </div>)
                        }
                    </React.Fragment>
                )}
                {!showDownloads && (
                    <React.Fragment>
                        <ReportTable
                            ref={this.table}
                            page='communications'
                            tableType='Communications'
                            openFlyout={this.openFlyout}
                            flyoutActive={flyoutActive}
                            callback={this.callbackHandler}
                            defaultReportIdx={this.state.reportIdx}
                            sendReportType={this.getReportType}
                            sendReportResponse={this.getReportResponse}
                            reports={[
                                {
                                    reportUUID: "14d17cfb-ac90-4b30-84fb-5c7ecf076503",
                                    name: "Communication Types",
                                    columns: ['Communication Type', 'Source', 'Trigger Type', 'Trigger Info']
                                },
                                {
                                    reportUUID: "9f160db4-7658-4cb2-b4e5-29c242f148e2",
                                    name: "Communication Templates",
                                    columns: ['Name', 'Communication Type', 'Status', 'Period', 'Contact Types', 'Contact Methods']
                                },
                                {
                                    reportUUID: "32946bbb-9f20-4ca2-82a1-3e3f9138853e",
                                    name: "Unsent Communications",
                                    columns: ['Communication Type', 'Location Name', 'Due On', 'Generated On', 'Contact Name', 'Contact Method']
                                },
                                {
                                    reportUUID: "ba9116de-49b0-40d8-bd60-470d5605dacd",
                                    name: "Communications with Errors",
                                    columns: ['Communication Type', 'Location Name', 'Contact Name', 'Sent On', 'Contact Method', 'Error']
                                }
                            ]}
                        />
                        <div className="dashboardIconLarge dashboardIconLarge__muiAdjusted">
                            <MailRoundedIcon fontSize="inherit" color="inherit" />
                        </div>
                    </React.Fragment>
                )}
                <div className="overlay" id="overlay" onClick={() => { this.closeFlyout() }}>
                </div>
                {flyoutActive && (
                    <React.Fragment>
                        <Flyout
                            uuid={flexUUID}
                            data={commInfo}
                            commUUID={commInfo.communication_uuid}
                            newAddition={newAddition}
                            flyoutType={flexFlyoutType}
                            flyoutPriority="primary"
                            closeFlyout={this.closeFlyout}
                            openFlyout={this.openFlyout}
                            activeTab={activeTab}
                            {...refreshProps}
                            cloneComm={this.cloneComm}
                            sendEmail={this.sendEmail}
                            sentCooldown={this.state.sentCooldown}
                            reportResponse={this.state.reportResponse}
                            dashboardType={this.state.dashboardType}
                        />
                    </React.Fragment>
                )}
            </div>
        )
    }
}

export default CommsDashboard;

const comms = "Communication Templates";
const commTypes = "Communication Types";
const commLogs = "Unsent Communications"
const commErrors = "Communications with Errors"

const downloadTableColumns = [
    {
        dataName: "communication_log_uuid",
        displayName: "communication_log_uuid",
        display: false
    },
    {
        dataName: "contact_uuid",
        displayName: "contact_uuid",
        display: false
    },
    {
        dataName: "method",
        displayName: "method",
        display: false
    },
    {
        dataName: "communication_type",
        displayName: "communication_type",
        display: false
    },
    {
        dataName: "location_name",
        displayName: "Location Name"
    },
    {
        dataName: "contact_name",
        displayName: "Contact Name"
    },
    {
        dataName: "due_on",
        displayName: "Due On"
    },
    {
        dataName: "generated_on",
        displayName: "Generated On"
    }
];