import React from 'react';
import Table from '../Table';
import { getContactLocationsReport } from '../../api/contactsAPI';
import { getPropertiesReport, createPropertyContact } from '../../api/propertiesAPI';
import Flyout from '../Flyout';
import { getDefaultReportingParams, setReportingParams } from '../../utils/reporting';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogTable from '../DialogTable';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import LoaderOverlay from '../LoaderOverlay';

const contactTypesList = ["Owner", "Property Manager", "Onsite", "Mailing", "Maintenance"]

class ContactLocations extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			locationInfo: {},
			showAddressForm: false,
			addressInfo: {},
			contactLocations: {},
			listDisplayColumns: ["Property UUID", "Name", "Address", "City"],
			flyoutActive: false,
			reportingParams: getDefaultReportingParams(),
			addLocationActive: false,
			selectedLocation: null,
			checked: { "Owner": false, "Property Manager": false, "Onsite": false, "Mailing": false, "Maintenance": false },
			showError: false
		};
	}

	componentDidMount = () => {
		this.getLocationsList();
	}

	getLocationsList = async (reportingParams) => {
		const { uuid } = this.props;

		if (reportingParams === undefined) {
			reportingParams = this.state.reportingParams;
		}
		reportingParams.inputs = { "contact uuid": uuid }

		getContactLocationsReport(reportingParams).then(response => {
			if (response.nextPage !== undefined && response.nextPage > reportingParams.page) {
				reportingParams.nextPage = response.nextPage;
			}
			this.setState(() => ({ contactLocations: response.data, reportingParams }));
		});
	}

	openFlyout = (locationInfo) => {
		const overlay = document.getElementById('overlaySecondary');

		if (locationInfo.values) {
			locationInfo = {
				property_uuid: locationInfo.values[0]
			};
		}

		this.setState(() => ({ flyoutActive: true, locationInfo }), () => {
			overlay.classList.add('overlaySecondary__visible');
		});
	}

	closeFlyout = () => {
		const flyoutSecondary = document.getElementById('flyoutSecondary');
		const overlay = document.getElementById('overlaySecondary');

		flyoutSecondary.classList.remove('slideToVisible');
		overlay.classList.remove('overlaySecondary__visible');
		setTimeout(() => {
			this.setState(() => ({ flyoutActive: false }));
		}, 200);
	}

	handleSelectLocation = (payload) => {
		let show;
		if (this.state.selectedLocation) {
			if (payload.values[0] !== this.state.selectedLocation) {
				show = false;
			} else {
				show = true;
			}
		}
		this.setState(() => ({ selectedLocation: payload.values[0], showError: show }))
	}

	handleContactTypeSelection = (e) => {
		let checked = this.state.checked;
		checked[e.target.value] = !checked[e.target.value]
		this.setState({ checked })
	}

	handleAddLocation = () => {
		const { checked, selectedLocation } = this.state;
		let contactTypes = [];
		for (const contactType in checked) {
			if (checked[contactType] === true) {
				contactTypes.push(contactType)
			}
		}

		createPropertyContact(selectedLocation, this.props.uuid, { contact_uuid: this.props.uuid, contact_types: contactTypes }).then((response) => {
			if (response === "an entry for this contact already exists") {
				this.handleContactAlreadyExists()
			} else {
				this.toggleDialog('close')
				this.setState(() => ({ checked: _.mapValues(this.state.checked, () => false) }));
				this.getLocationsList()
			}
		})
	}

	handleContactAlreadyExists = () => {
		this.setState(() => ({ showError: true }))
	}

	createContactTypeCheckboxes = () => {
		let options = [];
		contactTypesList.forEach((type) => {
			let option =
				<FormControlLabel
					key={type}
					control={
						<Checkbox
							checked={this.state.checked[type]}
							onChange={(e) => { this.handleContactTypeSelection(e) }}
							value={type}
							color='primary'
						/>
					}
					label={type}
				/>
			options.push(option);
		});
		return options;
	}

	toggleDialog = (type) => {
		if (type === 'open') {
			this.setState(() => ({ addLocationActive: true }))
		} else if (type === 'close') {
			this.setState(() => ({ addLocationActive: false, showError: false, selectedUser: null }))
		}
	}

	// -----------------------------
	// table / reporting functions
	// -----------------------------
	setOrdering = (column) => {
		let newReportingParams = setReportingParams(this.state.reportingParams, { column }, false);
		this.getLocationsList(newReportingParams);
	}

	fetchData = (direction) => {
		let newReportingParams = setReportingParams(this.state.reportingParams, { direction }, false);
		if (newReportingParams !== undefined) {
			this.getLocationsList(newReportingParams);
		}
	}
	// END table / reporting functions

	render() {
		const { flyoutActive, contactLocations, listDisplayColumns, locationInfo, addLocationActive, allLocationsList } = this.state;

		if (Object.keys(contactLocations).length === 0) {
			return null;
		}

		return (
			<React.Fragment>
				<Dialog
					open={addLocationActive}
					aria-labelledby="form-dialog-title"
					className="widget__dialog"
					id="dialogTable"
				>
					<DialogContent>
						<div className="widget__options">
							<div>
								<span>Choose a Location</span>
								<DialogTable
									tableType='Locations'
									openFlyout={this.handleSelectLocation}
									reportUUID="0eba7876-ec72-465c-919f-35138aefa6c3"
									columnsToShow={['Name', 'Address']}
									searches={['name', 'address']}
								/>
							</div>
							<div className="contactTypes">
								<span>Select Contact Type/s</span>
								<div style={{ padding: "2rem" }}>
									{this.createContactTypeCheckboxes()}
								</div>
							</div>
						</div>
					</DialogContent>
					<DialogActions>
						<div className="contactLocation__wrapper">
							{this.state.showError && <span className="contactLocation__errorMessage">This contact already exists at selected location</span>}
							{this.state.selectedLocation && <button className="medButtonPrimary" onClick={() => this.handleAddLocation()}>Add</button>}
							<button className="medButtonSecondary" onClick={() => this.toggleDialog('close')}>Cancel</button>
						</div>
					</DialogActions>
				</Dialog>
				<div className="flyoutContentContainer">
					{contactLocations ? <Table
						tableData={contactLocations}
						tableType='Locations'
						tableSize='small'
						columnsToShow={listDisplayColumns}
						openFlyout={this.openFlyout}
						fetchData={this.fetchData}
						setOrdering={this.setOrdering}
						paginationParams={this.state.reportingParams}
					/> : <LoaderOverlay />}
					<div className="flexButtonContainer">
						<button className="medButtonPrimary" onClick={() => this.toggleDialog('open')}>Add Location</button>
					</div>
					<div className="overlaySecondary" id="overlaySecondary" onClick={() => { this.closeFlyout() }}></div>
					{flyoutActive && locationInfo.property_uuid && (
						<React.Fragment>
							<Flyout
								uuid={locationInfo.property_uuid}
								source='contact'
								sourceUUID={this.props.uuid}

								flyoutType='locations'
								flyoutPriority='secondary'
								closeFlyout={this.closeFlyout}
								openFlyout={this.openFlyout}
								activeTab='locationDetails'

								refresh={this.getLocationsList}
							/>
						</React.Fragment>
					)}
				</div>
			</React.Fragment>
		);
	}
}

export default ContactLocations;